import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { SelectOptionModel } from '../models/SelectOptionModel';

interface ImportPurchasePricesProperties {
    options: Array<SelectOptionModel>,
    defaultSelectedOptions: Array<string>,
    handleChangeLogisticsUnitsSelected: (selectedLogisticsUnitIds: Array<string>) => void
}

export const ImportPurchasePricesComponent = (props: ImportPurchasePricesProperties): JSX.Element => {
    const [selectedLogisticsUnits, setSelectedLogisticsUnits] = useState<Array<string>>(props.defaultSelectedOptions);

    useEffect(() => {
        props.handleChangeLogisticsUnitsSelected(selectedLogisticsUnits);
    }, []);

    const handleChangeTransportLogisticsUnitsSelected = (opts: SelectOptionModel[]): void => {
        const logisticsUnits: string[] = opts.map(o => o.value);
        setSelectedLogisticsUnits(logisticsUnits)
        props.handleChangeLogisticsUnitsSelected(logisticsUnits);
    }

    const selectedTransportLogisticsUnitsValues: SelectOptionModel[] = selectedLogisticsUnits.length > 0 ? props.options.filter(p => selectedLogisticsUnits.includes(p.value)) : null;

    return (
        <Box display='flex' flexDirection='column' p={1}>
            <Box pb={1} className='purchase-price-import-label'>
                Spécifiez les zones logistiques sur lesquelles rendre visibles les tarifs:
            </Box>
            <Box pb={2} maxWidth={'700px'}>
                <Select
                    isMulti
                    options={props.options}
                    onChange={handleChangeTransportLogisticsUnitsSelected}
                    value={selectedTransportLogisticsUnitsValues}
                    menuPosition="fixed"
                    menuShouldBlockScroll={true}
                />
            </Box>
        </Box>
    );
}