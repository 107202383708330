import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material';
import { orderBy, SortDescriptor } from '@progress/kendo-data-query';
import { Grid, GridCellProps, GridColumn, GridPageChangeEvent, GridRowClickEvent, GridSortChangeEvent } from '@progress/kendo-react-grid';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import React, { useEffect, useState } from 'react';
import Select, { components } from 'react-select';
import { ScaleLoaderComponent } from '../../../../../shared/components/Common/ScaleLoaderComponent';
import ToastService from '../../../../../ToastService';
import BusinessErrors from '../../../../../utils/BusinessErrors';
import { CoupaPurchasePriceModel } from '../../models/CoupaPurchasePriceModel';
import { SelectOptionModel } from '../../models/SelectOptionModel';
import { ImportCoupaPurchasePricesRequestArgs } from '../../services/dataContracts/controller/ImportCoupaPurchasePricesRequestArgs';
import { VehicleTypePriceRequestArgs } from '../../services/dataContracts/controller/VehicleTypePriceRequestArgs';
import { TransportPurchasePricesReferentialApiClient } from '../../services/TransportPurchasePricesReferentialApiClient';
import { ImportPurchasePricesComponent } from '../ImportPurchasePricesComponent';
import './ImportCoupaPurchasePricesStyles.scss';

interface HeaderContentComponentProps {
    open: boolean,
    transporterId: string,
    allLogisticsUnits: Array<SelectOptionModel>,
    selectedLogisticsUnits: Array<string>,
    handleClose: (hasImportSuccess: boolean) => void
}

function useForceUpdate() {
    const [, setTick] = React.useState(0);
    const update = React.useCallback(() => {
        setTick(tick => tick + 1);
    }, [])
    return update;
}

export const ImportCoupaPurchasePricesComponent = (props: HeaderContentComponentProps): JSX.Element => {

    const [agencies, setAgencies] = useState<SelectOptionModel[]>([]);
    const [selectedOption, setSelectedOption] = useState<SelectOptionModel>(null);
    const [purchasePrices, setPurchasePrices] = useState<CoupaPurchasePriceModel[]>([]);

    const initialSort: SortDescriptor[] = [{ field: 'vehicleTypeLabel', dir: 'asc' }];
    const [sort, setSort] = useState<SortDescriptor[]>(initialSort);
    const [skip, setSkip] = useState<number>(0);

    const [loading, setLoading] = useState<boolean>(false);

    const [hasImportSuccess, setHasImportSuccess] = useState<boolean>(false);
    const [logisticsUnitDialogOpened, setLogisticsUnitDialogOpened] = useState<boolean>(false);
    const [selectedLogisticsUnitIds, setSelectedLogisticsUnitIds] = useState<string[]>([]);

    const forceUpdate = useForceUpdate();

    useEffect(() => {
        GetAgencies();
    }, [])

    const GetAgencies = () => {
        TransportPurchasePricesReferentialApiClient.GetAgencies()
            .then((res) => {
                let data = res.data;
                let agencyList: SelectOptionModel[] = [];
                data.forEach((agency) => {
                    agencyList.push({
                        label: agency.label,
                        value: agency.agencyId
                    });
                });

                setAgencies(agencyList);
            });
    }

    const handleSearchPurchasePrices = () => {
        setLoading(true);

        TransportPurchasePricesReferentialApiClient.SearchArticlesTransport(selectedOption.value, props.transporterId)
            .then((res) => {
                let data = res.data;
                let purchasePrices: CoupaPurchasePriceModel[] = [];
                data.forEach((purchasePrice, index) => {
                    purchasePrices.push({
                        isSelected: false,
                        index: index,
                        vehicleTypeId: purchasePrice.vehicleTypeId,
                        vehicleTypeLabel: purchasePrice.vehicleTypeLabel,
                        price: purchasePrice.price,
                        priceKind: purchasePrice.priceKind,
                        priceKindLabel: getPriceKindLabel(purchasePrice.priceKind),
                        description: purchasePrice.description,
                        startDate: purchasePrice.startDate,
                        endDate: purchasePrice.endDate
                    })
                });

                setPurchasePrices(purchasePrices);
                setLoading(false);
            });
    }

    const getPriceKindLabel = (priceKind: string): string => {
        switch (priceKind) {
            case "Day":
                return "Jour";
            case "HalfDay":
                return "Demi-journée";
            case "Night":
                return "Nuit";
            case "PerTon":
                return "Tonne";
            case "PerHour":
                return "Heure";
            case "PerTurn":
                return "Tour";
            default:
                return "";
        }
    }

    const handleAgencyChanged = (e: SelectOptionModel): void => {
        setSelectedOption({
            value: e.value,
            label: `${e.label} - ${e.value}`
        });
    }

    const pageChange = (event: GridPageChangeEvent): void => {
        setSkip(event.page.skip);
    }

    const handleSelectPrice = (e: React.ChangeEvent<HTMLInputElement>, index: number): void => {
        let purchasePricesList = [...purchasePrices];
        let itemIndex = purchasePricesList.findIndex(x => x.index === index);
        purchasePricesList[itemIndex].isSelected = e.target.checked;
        setPurchasePrices(purchasePricesList);
    }

    const handleSelectAllPrice = (e: React.ChangeEvent<HTMLInputElement>): void => {
        let purchasePricesList = [...purchasePrices];
        purchasePricesList.forEach((price) => {
            price.isSelected = e.target.checked;
        });
        setPurchasePrices(purchasePricesList);
    }

    const handleRowClick = (e: GridRowClickEvent) => {
        let purchasePricesList = [...purchasePrices];
        let itemIndex = purchasePricesList.findIndex(x => x.index === e.dataItem.index);
        purchasePricesList[itemIndex].isSelected = !purchasePricesList[itemIndex].isSelected;
        setPurchasePrices(purchasePricesList);
    }

    const handleImportPurchasePrice = (): void => {
        setLoading(true);
        setLogisticsUnitDialogOpened(false);
        let vehicleTypePricesRequestArgs: VehicleTypePriceRequestArgs[] = [];
        purchasePrices.filter(x => x.isSelected).forEach(price => {
            vehicleTypePricesRequestArgs.push({
                vehicleTypeId: price.vehicleTypeId,
                description: price.description,
                price: price.price,
                priceKind: price.priceKind,
                startDate: price.startDate,
                endDate: price.endDate
            });
        });

        let requestArgs: ImportCoupaPurchasePricesRequestArgs = {
            transporterId: props.transporterId,
            logisticsUnitIds: selectedLogisticsUnitIds,
            vehicleTypePrices: vehicleTypePricesRequestArgs
        };

        TransportPurchasePricesReferentialApiClient.ImportCoupaPurchasePrices(requestArgs)
            .then((res) => {
                setLoading(false);

                const data = res?.data;
                const errors = BusinessErrors.Get(data);
                if (errors.length > 0) {
                    ToastService.showErrorToast("", errors);
                    return;
                }

                if (data.extraResult.importedCount == 0) {
                    ToastService.showErrorToast("Les tarifs séléctionnés existent déjà.");
                    return;
                }

                ToastService.showSuccessToast('Les tarifs ont bien été intégrés.');
                setHasImportSuccess(true);
                let purchasePricesList = [...purchasePrices];
                purchasePricesList.forEach((price) => {
                    price.isSelected = false;
                });
                setPurchasePrices(purchasePricesList);
            });
    }

    const handleChangeLogisticsUnitIdsSelected = (selectedLogisticsUnitIds: Array<string>): void => {
        setSelectedLogisticsUnitIds(selectedLogisticsUnitIds);
    }

    let gridHeight = ((window.innerHeight * 80) / 100) - 155;
    const rowHeight: number = 40;
    let gridPageSize: number = Number((gridHeight / rowHeight).toFixed(0));
    const totalGrid: number = purchasePrices.length;
    const gridStyle: React.CSSProperties = { height: gridHeight };
    const resize = (): void => {
        gridHeight = window.innerHeight - 50;
        gridPageSize = Number((gridHeight / rowHeight).toFixed(0));
        forceUpdate();
    }
    window.onresize = resize;

    const dataGrid: CoupaPurchasePriceModel[] = orderBy(purchasePrices, sort).slice(skip, skip + gridPageSize)

    return (
        <>
            <Dialog
                disableEscapeKeyDown
                aria-modal="false"
                open={props.open}
                scroll="paper"
                className="import-coupa-purchase-prices"
            >
                <DialogTitle>
                    <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
                        <div>Import Tarifs COUPA</div>
                        <IconButton aria-label="close" onClick={() => props.handleClose(hasImportSuccess)}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </DialogTitle>
                <DialogContent dividers>
                    <Box className="content">
                        <Box className="header">
                            <Select
                                className="agencies"
                                placeholder=""
                                menuPosition="fixed"
                                menuShouldBlockScroll={true}
                                options={agencies}
                                value={selectedOption}
                                components={{
                                    Option: (props) => <div>
                                        <components.Option {...props} className="item-option">
                                            <label className="label">{props.data.label}</label>
                                            <label className="label">{props.data.value}</label>
                                        </components.Option>
                                    </div>
                                }}
                                onChange={(e) => handleAgencyChanged(e)}
                            />
                            <Button variant="contained" color="primary" title="Appeler les tarifs COUPA" className="header-item"
                                disabled={!selectedOption}
                                onClick={handleSearchPurchasePrices}>
                                Appeler les tarifs COUPA
                            </Button>
                            <Button variant="contained" color="primary" title="Importer dans LORIE" className="header-item"
                                disabled={!purchasePrices.some(x => x.isSelected)}
                                onClick={() => setLogisticsUnitDialogOpened(true)}>
                                Importer dans LORIE
                            </Button>
                            {loading && <ScaleLoaderComponent loading={loading} />}
                        </Box>
                        <Box className="purchases-list">
                            <LocalizationProvider language="fr-FR">
                                <IntlProvider locale="fr">
                                    <Grid
                                        className="purchases-list-grid"
                                        data={dataGrid}
                                        selectedField="isSelected"
                                        rowHeight={rowHeight}
                                        scrollable="virtual"
                                        sortable
                                        sort={sort}
                                        onSortChange={(e: GridSortChangeEvent) => setSort(e.sort)}
                                        skip={skip}
                                        total={totalGrid}
                                        pageSize={gridPageSize}
                                        onPageChange={pageChange}
                                        style={gridStyle}
                                        onRowClick={handleRowClick}
                                    >
                                        <GridColumn field="isSelected"
                                            headerCell={() => {
                                                let checked = purchasePrices.every(x => x.isSelected === true);
                                                let indeterminate = !purchasePrices.every(x => x.isSelected === false) && !purchasePrices.every(x => x.isSelected === true);

                                                return (
                                                    <Checkbox
                                                        checked={checked}
                                                        indeterminate={indeterminate}
                                                        onChange={(e) => handleSelectAllPrice(e)}
                                                        color="primary"
                                                        inputProps={{ 'aria-label': 'primary checkbox' }}
                                                    />
                                                )
                                            }}
                                            cell={(cellProps: GridCellProps) =>
                                                <td>
                                                    <Checkbox
                                                        checked={cellProps.dataItem.isSelected}
                                                        onChange={(e) => handleSelectPrice(e, cellProps.dataItem.index)}
                                                        color="primary"
                                                        inputProps={{ 'aria-label': 'primary checkbox' }}
                                                    />
                                                </td>}
                                        />
                                        <GridColumn field="vehicleTypeLabel" title="Type du véhicule" />
                                        <GridColumn field="priceKindLabel" title="Type du tarif" />
                                        <GridColumn field="price" title="Coût" />
                                        <GridColumn field="description" title="Description" />
                                        <GridColumn field="startDate" format="{0:dd-MM-yyyy}" title="Date début" />
                                        <GridColumn field="endDate" format="{0:dd-MM-yyyy}" title="Date fin" />
                                    </Grid>
                                </IntlProvider>
                            </LocalizationProvider>
                        </Box>
                    </Box>
                </DialogContent>
            </Dialog>
            <Dialog
                disableEscapeKeyDown
                aria-modal="false"
                open={logisticsUnitDialogOpened}
                onClose={() => setLogisticsUnitDialogOpened(false)}
                scroll="paper"
                className="simple-dialog-purchase-price"
            >
                <DialogTitle id="scroll-dialog-title">
                    Import des nouveaux tarifs
                </DialogTitle>
                <DialogContent dividers>
                    <ImportPurchasePricesComponent
                        handleChangeLogisticsUnitsSelected={handleChangeLogisticsUnitIdsSelected}
                        options={props.allLogisticsUnits}
                        defaultSelectedOptions={props.selectedLogisticsUnits}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setLogisticsUnitDialogOpened(false)} color="primary">
                        Annuler
                    </Button>
                    <Button onClick={handleImportPurchasePrice} color="primary">
                        Importer
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
