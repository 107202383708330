import { GridCellProps } from '@progress/kendo-react-grid';
import React, { MutableRefObject, useEffect, useRef } from 'react';

interface CustomCheckboxCellProperties extends GridCellProps {
    keyName: string,
    updateChoicesLogisticsUnit?: (checked: boolean, logisticsUnit: string, id: string | number) => void,
    updateChoicesAllLogisticsUnits?: (checked: boolean, id: string | number) => void
}

export const CustomCheckboxCell = (props: CustomCheckboxCellProperties) => {

    const checkboxRef: MutableRefObject<HTMLInputElement> = useRef(null);

    useEffect(() => {
        checkboxRef.current.indeterminate = props.field === "isSelectedAll" ? props.dataItem["isIndeterminateAll"] : false;
    }, []);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>, field: string) => {
        if (props.field !== "isSelectedAll") {
            props.updateChoicesLogisticsUnit(event.target.checked, field, props.dataItem[props.keyName]);
        } else {
            props.updateChoicesAllLogisticsUnits(event.target.checked, props.dataItem[props.keyName]);
        }
    };

    const checked: boolean = props.field === "isSelectedAll" ? (props.dataItem["isSelectedAll"] === true ? true : (props.dataItem['isSelectedAll'] === false ? false : null)) : props.dataItem[props.field];

    return (
        <td>
            <input type="checkbox" ref={checkboxRef} checked={checked} onChange={(e) => handleChange(e, props.field)} className="custom-checkbox" />
        </td>
    );
}
