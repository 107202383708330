import { Box, Button } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { ScaleLoaderComponent } from 'src/shared/components/Common/ScaleLoaderComponent';
import ToastService from 'src/ToastService';
import { AssociateOrphanDeliveryNoteRequestArgs } from '../services/dataContracts/controller/AssociateOrphanDeliveryNoteRequestArgs';
import { OrphanDeliveryNotes } from '../services/dataContracts/controller/OrphanDeliveryNotes';
import { OperationalMonitoringApiClient } from '../services/OperationalMonitoringApiClient';
import { AttachZephyrFlowManuallyGridComponent } from './AttachZephyrFlowManuallyGridComponent';

interface AttachZephyrFlowManuallyComponentProps {
    licencePlate: string;
    onClose: () => void,
    businessId: string,
    refresh: () => void
}

export const AttachZephyrFlowManuallyComponent = (props: AttachZephyrFlowManuallyComponentProps): JSX.Element => {

    const [orphanDeliveryNotes, setOrphanDeliveryNotes] = useState<OrphanDeliveryNotes[]>([]);
    const [orphanDeliveryNoteDefault, setOrphanDeliveryNoteDefault] = useState<OrphanDeliveryNotes>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [isDefaultSelectedNote, setIsDefaultSelectedNote] = useState<boolean>(true);
    const [orphanDeliveryNoteSelected, setOrphanDeliveryNoteSelected] = useState<string>("");
    const [orphanDeliveryNoteLineIdSelected, setOrphanDeliveryNoteLineIdSelected] = useState<string>("");

    useEffect(() => {
        OperationalMonitoringApiClient.GetOrphanDeliveryNotes(props.businessId)
            .then((res) => {
                setOrphanDeliveryNoteSelected(null);
                let notes = res.data;

                // on met la licencePlate en 1er si fournie
                if (props.licencePlate) {
                    notes = notes.filter(d => d.licencePlate == props.licencePlate).concat(notes.filter(d => d.licencePlate != props.licencePlate));
                }

                setOrphanDeliveryNotes(notes);
                setLoading(false);
                const alreadyAssocied = notes.find(d => d.businessId != null && d.selected);
                if (alreadyAssocied) {
                    setOrphanDeliveryNoteDefault(alreadyAssocied);
                }
            });
    }, []);

    useEffect(() => {
        if (orphanDeliveryNoteDefault) {
            handleSelectedOrphanDeliveryNote(orphanDeliveryNoteDefault.deliveryNoteId, orphanDeliveryNoteDefault.lineId);
        }
    }, [orphanDeliveryNoteDefault]);

    useEffect(() => {
        if (orphanDeliveryNoteDefault
            && orphanDeliveryNoteDefault.deliveryNoteId == orphanDeliveryNoteSelected
            && orphanDeliveryNoteDefault.lineId == orphanDeliveryNoteLineIdSelected) {
            setIsDefaultSelectedNote(true);
        } else {
            setIsDefaultSelectedNote(false);
        }

    }, [orphanDeliveryNoteLineIdSelected]);

    const handleSelectedOrphanDeliveryNote = (deliveryNoteId: string, lineId: string): void => {
        const newOrphanDeliveryNotesList = orphanDeliveryNotes.map(note => ({
            ...note,
            selected: note.deliveryNoteId === deliveryNoteId && note.lineId === lineId
        }));
        setOrphanDeliveryNotes(newOrphanDeliveryNotesList);
        setOrphanDeliveryNoteSelected(deliveryNoteId);
        setOrphanDeliveryNoteLineIdSelected(lineId)
    };

    const handleAssociateOrphanDeliveryNote = (toAssociate: boolean): void => {
        const request: AssociateOrphanDeliveryNoteRequestArgs = {
            businessId: props.businessId,
            deliveryNoteId: toAssociate ? orphanDeliveryNoteSelected : null,
            toAssociate: toAssociate,
            deliveryNoteLineId: toAssociate ? orphanDeliveryNoteLineIdSelected : null,
        };

        OperationalMonitoringApiClient.AssociateOrphanDeliveryNote(request)
            .then(async res => {
                if (res.status == 200) {
                    if (toAssociate) {
                        ToastService.showSuccessToast("Association du flux avec succès");
                    }
                    else {
                        ToastService.showSuccessToast("Dissociation du flux avec succès");
                    }

                    props.refresh();
                }
                else {
                    ToastService.showErrorToast("Association/Dissociation du flux en erreur");
                }
            });
    }

    const orphanDeliveryNotesGridComponent: JSX.Element = useMemo(() =>
        <AttachZephyrFlowManuallyGridComponent
            orphanDeliveryNotes={orphanDeliveryNotes}
            handleSelectedOrphanDeliveryNote={handleSelectedOrphanDeliveryNote}
        />, [orphanDeliveryNotes]);

    return (
        <>
            {loading && <ScaleLoaderComponent loading={loading} />}
            {!loading &&
                <>
                    {orphanDeliveryNotesGridComponent}
                    <Box display="flex" flexDirection="row" flex="wrap" justifyContent="center" alignItems="center" className="orphan-delivery-notes-buttons">
                        <Box ml={'10px'}>
                            <Button variant="contained" className="cancel" title="Annuler" onClick={props.onClose}>
                                Annuler
                            </Button>
                        </Box>
                        <Box ml={'10px'}>
                            <Button variant="contained" color="primary" title="Supprimer" disabled={!isDefaultSelectedNote} onClick={() => handleAssociateOrphanDeliveryNote(false)}>
                                Supprimer
                            </Button>
                        </Box>
                        <Box ml={'10px'}>
                            <Button variant="contained" color="primary" title="Associer" disabled={isDefaultSelectedNote || (!orphanDeliveryNoteSelected && !orphanDeliveryNoteLineIdSelected)} onClick={() => handleAssociateOrphanDeliveryNote(true)}>
                                Associer
                            </Button>
                        </Box>
                    </Box>
                </>
            }
        </>
    );
}