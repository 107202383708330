import { InteractionStatus } from "@azure/msal-browser";
import { withMsal, WithMsalProps } from "@azure/msal-react";
import { createInstance, MatomoProvider } from "@datapunt/matomo-tracker-react";
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { each } from 'lodash';
import moment from 'moment';
import 'moment/locale/fr';
import React from 'react';
import { Navigate, Route, Routes } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { appInsightsReactPlugin } from './appInsightsReactPlugin';
import buildinfo from './config/buildinfo.json';
import { MsalConfig } from './MsalConfig';
import { SettingsProvider } from './SettingsProvider';
import { LogisticsUnitChoice } from './shared/models/LogisticsUnitChoice';
import { LogisticsUnitLightModel } from './shared/User/services/dataContracts/queryStack/LogisticsUnitLightModel';
import { UserApiClient } from './shared/User/services/UserApiClient';
import './utils/JSON';
import { LocalStorage } from './utils/Storage';
import { CartographyView } from "./views/Cartography/CartographyView";
import Layout from "./views/Layout";
import { OperationalMonitoringView, PrefixesOfDatedLocalStorageKeys } from './views/OperationalMonitoring/OperationalMonitoringView';
import { PurchaseProvisioningsView } from "./views/PurchaseProvisionings/PurchaseProvisioningsView";
import { CustomerContactsReferentialView } from "./views/Referentials/CustomerContactsReferential/CustomerContactsReferentialView";
import { DieselIndexesReferentialView } from "./views/Referentials/DieselIndexesReferential/DieselIndexesReferentialView";
import { DispatchersReferentialView } from "./views/Referentials/DispatchersReferential/DispatchersReferentialView";
import GenericPurchasePricesReferentialView from "./views/Referentials/GenericPurchasePricesReferential/GenericPurchasePricesReferentialView";
import { InternalContactsReferentialView } from "./views/Referentials/InternalContactsReferential/InternalContactsReferentialView";
import { InternalDriversReferentialView } from "./views/Referentials/InternalDriversReferential/InternalDriversReferentialView";
import { InternalVehiclesReferentialView } from "./views/Referentials/InternalVehiclesReferential/InternalVehiclesReferentialView";
import { Level2ActivitiesReferentialView } from "./views/Referentials/Level2ActivitiesReferential/Level2ActivitiesReferentialView";
import { Level3SegmentationsReferentialView } from "./views/Referentials/Level3SegmentationsReferential/Level3SegmentationsReferentialView";
import { LogisticsUnitLogisticiansReferentialView } from "./views/Referentials/LogisticsUnitLogisticiansReferential/LogisticsUnitLogisticiansReferentialView";
import { NegotiatedPurchasePricesReferentialView } from "./views/Referentials/NegotiatedPurchasePricesReferential/NegotiatedPurchasePricesReferentialView";
import { NegotiatedSellPricesReferentialView } from "./views/Referentials/NegotiatedSellPricesReferential/NegotiatedSellPricesReferentialView";
import { OverheadExpensesReferentialView } from "./views/Referentials/OverheadExpensesReferential/OverheadExpensesReferentialView";
import { ProductionSitesReferentialView } from "./views/Referentials/ProductionSitesReferential/ProductionSitesReferentialView";
import { QuotationPricesReferentialView } from "./views/Referentials/QuotationPricesReferential/QuotationPricesReferentialView";
import { TransportPurchasePricesReferentialView } from "./views/Referentials/TransportPurchasePricesReferential/TransportPurchasePricesReferentialView";
import { WorkAgenciesReferentialView } from "./views/Referentials/WorkAgenciesReferential/WorkAgenciesReferentialView";
import { ReservationView } from "./views/Reservation/ReservationView";
import { SMSManagement } from "./views/Sms/SMSManagement";
import ThirdPartyBeneficiaryView from "./views/ThirdParty/ThirdPartyBeneficiary/ThirdPartyBeneficiaryView";
import ThirdPartyBeneficiaryInvoicesView from "./views/ThirdParty/ThirdPartyBeneficiaryInvoices/ThirdPartyBeneficiaryInvoicesView";
import { ThirdPartyProductionView } from "./views/ThirdParty/ThirdPartyProduction/ThirdPartyProductionView";
import ThirdPartyTransporterView from "./views/ThirdParty/ThirdPartyTransporter/ThirdPartyTransporterView";
import { ThirdPartyTransporterPrintView } from "./views/ThirdParty/ThirdPartyTransporterPrint/ThirdPartyTransporterPrintView";
import ThirdPartyTransporterPublishedCostsView from "./views/ThirdParty/ThirdPartyTransporterPublishedCosts/ThirdPartyTransporterPublishedCostsView";
import TransportPlanView from "./views/TransportPlan/TransportPlanView";
import { TransportPurchasesView } from "./views/TransportPurchases/TransportPurchasesView";
import { TransportSalesView } from "./views/TransportSales/TransportSalesView";

interface AppState {
    role: string,
    userName: string,
    userInitials: string,
    userDisplayName: string,
    userPhoneNumber: string,
    userEmail: string,
    loadingLayout: boolean,
    logisticsUnits: Array<LogisticsUnitChoice>,
    userHash: string
}

class AppHOC extends React.Component<WithMsalProps, AppState> {
    _isMounted: boolean;

    static displayName: string = AppHOC.name;

    constructor(props: WithMsalProps) {
        super(props);
        this.state = {
            role: '',
            userName: '',
            userInitials: '',
            userDisplayName: '',
            userPhoneNumber: '',
            userEmail: '',
            loadingLayout: true,
            logisticsUnits: null,
            userHash: ''
        };
    }

    componentDidMount() {
        this._isMounted = true;
        this.ensureUserAuthentication();

        if (SettingsProvider.IsReady()) {
            moment.locale('fr');
            this.purgeLocalStorage();
            this.getUserProfile();
        }
    }

    componentDidUpdate() {
        this.ensureUserAuthentication();

        if (SettingsProvider.IsReady() && this.state.loadingLayout) {
            moment.locale('fr');
            this.purgeLocalStorage();
            this.getUserProfile();
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    ensureUserAuthentication() {
        const isAuthenticated = this.props.msalContext.accounts.length > 0;
        const msalInstance = this.props.msalContext.instance;

        // If a user is not logged in and authentication is not already in progress, invoke login
        if (!isAuthenticated && this.props.msalContext.inProgress === InteractionStatus.None) {
            msalInstance.loginRedirect({ scopes: MsalConfig.GetLoginRequestScopes() });
        }
    }

    getMatomoInstance = (userName: string, userHash: string) => {
        if (SettingsProvider.IsReady() && userName && userHash) {
            const setting = SettingsProvider.Get();
            if (setting.matomo && setting.matomo.urlBase && setting.matomo.siteId != 0) {
                return createInstance({
                    urlBase: setting.matomo.urlBase,
                    siteId: setting.matomo.siteId,
                    userId: userHash
                });
            }
            return null;
        }
        return null;
    }

    getUserProfile = (): void => {
        UserApiClient.GetUserProfile()
            .then(res => {
                if (this._isMounted) {
                    const json = res.data;
                    let role = '';
                    let userName = '';
                    let userInitials = '';
                    let userDisplayName = '';
                    let userPhoneNumber = '';
                    let userEmail = '';
                    let listLogisticsUnits: LogisticsUnitChoice[] = [];
                    let userHash = '';

                    const savedLogisticsUnits: LogisticsUnitChoice[] = LocalStorage.LogisticsUnitsChoices;
                    const list: LogisticsUnitChoice[] = [];
                    json.logisticsUnits.forEach((element: LogisticsUnitLightModel) => {
                        if (savedLogisticsUnits !== null) {
                            const item: LogisticsUnitChoice = savedLogisticsUnits.find(x => x.logisticsUnitId === element.logisticsUnitId);
                            if (item !== null && item !== undefined) {
                                list.push({
                                    logisticsUnitId: element.logisticsUnitId,
                                    label: element.label,
                                    checked: item.checked,
                                    isQuotationAdministrator: element.isQuotationAdministrator
                                });
                            } else {
                                list.push({
                                    logisticsUnitId: element.logisticsUnitId,
                                    label: element.label,
                                    checked: true,
                                    isQuotationAdministrator: element.isQuotationAdministrator
                                });
                            }
                        } else {
                            list.push({
                                logisticsUnitId: element.logisticsUnitId,
                                label: element.label,
                                checked: true,
                                isQuotationAdministrator: element.isQuotationAdministrator
                            });
                        }
                    });
                    LocalStorage.LogisticsUnitsChoices = list;

                    listLogisticsUnits = list;

                    role = !json.isEnabled ? '' : json.role;
                    userName = !json.isEnabled ? '' : json.userName;
                    userInitials = !json.isEnabled ? '' : (`${json.firstName && json.firstName.length > 0 ? json.firstName[0] : ''}${json.lastName && json.lastName.length > 0 ? json.lastName[0] : ''}`);
                    userDisplayName = !json.isEnabled ? '' : `${json.firstName} ${json.lastName}`
                    userPhoneNumber = !json.isEnabled ? '' : json.phoneNumber;
                    userEmail = !json.isEnabled ? '' : json.email;
                    userHash = !json.isEnabled ? '' : json.userHash;

                    this.setState({
                        loadingLayout: false,
                        role: role,
                        userName: userName,
                        userInitials: userInitials,
                        userDisplayName: userDisplayName,
                        userPhoneNumber: userPhoneNumber,
                        userEmail: userEmail,
                        logisticsUnits: role === "ADM" || role === "LOG" ? listLogisticsUnits : null,
                        userHash: userHash
                    });
                }
            });
    }

    handleLogisticsUnitsChanges = (logisticsUnits: Array<LogisticsUnitChoice>): void => {
        this.setState({
            logisticsUnits: logisticsUnits
        });
    }

    closeFloatingPopups = (): void => {
        //supprimer les anciens popup créés et non néttoyées
        each(document.getElementsByClassName("scheduler-popup-w"), e => {
            e.remove();
        });
    }

    purgeLocalStorage = (): void => {

        const daysToStoreColapsedSectionsSinceToday: number = SettingsProvider.Get().daysToStoreColapsedSectionsSinceToday;
        const currentNumericDate: Date = new Date(new Date().stripTime());
        const sinceDateNumeric: number = new Date(currentNumericDate).addDays(-1 * daysToStoreColapsedSectionsSinceToday).toNumericDate();

        PrefixesOfDatedLocalStorageKeys.forEach(keyPrefix => {
            const keysToRemoved: number[] = Object.keys(localStorage)
                .filter(key => key.startsWith(keyPrefix))
                .map(key => parseInt(key.substring(keyPrefix.length)))
                .filter(numericDate => numericDate < sinceDateNumeric);

            keysToRemoved?.forEach(item => localStorage.removeItem(`${keyPrefix}${item}`));
        });

        if (["9.99", "0.88", "0.89", "0.90"].indexOf(buildinfo.versionMajorMinor) != -1) {
            this.fixZeroValuedGridColumnWidths();
        }
    }

    fixZeroValuedGridColumnWidths = () => {
        const keysToRemoved = Object.keys(localStorage)
            .filter(key => key.contains("/grid_") && key.contains("/columnWidth_") && localStorage.getItem(key) === "0")

        keysToRemoved?.forEach(item => localStorage.removeItem(item));
    }

    render() {
        const isAuthenticated = this.props.msalContext.accounts.length > 0;
        const settingsReady = SettingsProvider.IsReady();

        const MatomoProviderTest = MatomoProvider as any;

        if (isAuthenticated && settingsReady) {
            const { role, userName, userDisplayName, logisticsUnits, userPhoneNumber, userEmail, userInitials, userHash } = this.state;
            const chosenLogisticsUnits: Array<LogisticsUnitChoice> = logisticsUnits ? logisticsUnits.filter(l => l.checked) : null;
            const chosenQuotationLogisticsUnits: Array<LogisticsUnitChoice> = chosenLogisticsUnits ? chosenLogisticsUnits.filter(l => l.isQuotationAdministrator) : null;
            const chosenLogisticsUnitIds: Array<string> = logisticsUnits ? UserApiClient.GetLogisticsUnitIds(chosenLogisticsUnits) : null;
            const isQuotationAdministrator = chosenLogisticsUnits?.some(x => x.checked && x.isQuotationAdministrator);

            this.closeFloatingPopups();

            return (
                <MatomoProviderTest value={this.getMatomoInstance(userName, userHash)}>
                    <>
                        <ToastContainer className="generic-error-toast" />
                        {window.location.pathname.endsWith('-Print') ?
                            <Routes>
                                {(role === "ADM" || role === "LOG") && <Route path='ThirdPartyInternalTransporter-Print' Component={() => <ThirdPartyTransporterPrintView />} />}
                                {(role === "ADM" || role === "LOG") && <Route path='ThirdPartyExternalTransporter-Print' Component={() => <ThirdPartyTransporterPrintView />} />}
                                {role === "DIS" && <Route path='InternalTransporterOrder-Print' Component={() => <ThirdPartyTransporterPrintView />} />}
                            </Routes>
                            :
                            <Layout
                                role={role}
                                userName={userName}
                                userInitials={userInitials}
                                userDisplayName={userDisplayName}
                                userPhoneNumber={userPhoneNumber}
                                userEmail={userEmail}
                                loadingLayout={this.state.loadingLayout}
                                logisticsUnits={this.state.logisticsUnits} logisticsUnitIds={chosenLogisticsUnitIds} handleLogisticsUnitsChanges={this.handleLogisticsUnitsChanges}>
                                <Routes>
                                    {(role === "ADM" || role === "LOG" || role === "PRD" || role === "DIS") && <Route path='' Component={() => <OperationalMonitoringView role={role} userName={userName} userDisplayName={userDisplayName} userPhoneNumber={userPhoneNumber} logisticsUnitIds={chosenLogisticsUnitIds} logisticsUnits={chosenLogisticsUnits} />} />}
                                    {(role === "ADM" || role == "LOG") && <Route path='InternalTransporterOrder' element={<Navigate to="ThirdPartyInternalTransporter" replace />} />}
                                    {role == "DIS" && <Route path='InternalTransporterOrder' Component={() => <ThirdPartyTransporterView userName={userName} role={role} isForInternalTransporters={true} logisticsUnitIds={chosenLogisticsUnitIds} />} />}
                                    {role == "DIS" && <Route path='InternalTransporterPublishedCosts' Component={() => <ThirdPartyTransporterPublishedCostsView role={this.state.role} isForInternalTransporters={true} />} />}
                                    {(role === "ADM" || role === "LOG") && <Route path='TransportPlan' Component={() => <TransportPlanView logisticsUnits={chosenLogisticsUnits} logisticsUnitIds={chosenLogisticsUnitIds} />} />}
                                    {(role === "ADM" || role === "LOG") && <Route path='TransportPurchases' Component={() => <TransportPurchasesView logisticsUnitIds={chosenLogisticsUnitIds} logisticsUnits={chosenLogisticsUnits} />} />}
                                    {role === "PRD" && <Route path='Production' Component={() => <ThirdPartyProductionView role={role} logisticsUnitIds={chosenLogisticsUnitIds} />} />}
                                    {(role === "ADM" || role === "LOG") && <Route path='SMSManagement' Component={SMSManagement} />}
                                    {(role === "ADM" || role === "LOG") && <Route path='Reservation' Component={() => <ReservationView logisticsUnitIds={chosenLogisticsUnitIds} />} />}
                                    {(role === "ADM" || role === "LOG" || role === "CCH" || role === "CTX") && <Route path='ThirdPartyBeneficiary' Component={() => <ThirdPartyBeneficiaryView role={role} logisticsUnitIds={chosenLogisticsUnitIds} />} />}
                                    {(role === "ADM" || role === "LOG") && <Route path='ThirdPartyExternalTransporter' Component={() => <ThirdPartyTransporterView userName={userName} role={role} isForInternalTransporters={false} logisticsUnitIds={chosenLogisticsUnitIds} />} />}
                                    {(role === "ADM" || role === "LOG") && <Route path='ThirdPartyInternalTransporter' Component={() => <ThirdPartyTransporterView userName={userName} role={role} isForInternalTransporters={true} logisticsUnitIds={chosenLogisticsUnitIds} />} />}
                                    {(role === "ADM" || role === "LOG") && <Route path='ThirdPartyProduction' Component={() => <ThirdPartyProductionView logisticsUnitIds={chosenLogisticsUnitIds} role={role} />} />}
                                    {(role === "ADM" || role === "LOG") && <Route path='InternalVehiclesReferential' Component={() => <InternalVehiclesReferentialView />} />}
                                    {(role === "ADM" || role === "LOG") && <Route path='InternalDriversReferential' Component={() => <InternalDriversReferentialView isForTemporaryDrivers={false} />} />}
                                    {(role === "ADM" || role === "LOG") && <Route path='TemporaryDriversReferential' Component={() => <InternalDriversReferentialView isForTemporaryDrivers={true} />} />}
                                    {role === "ADM" && <Route path='LogisticsUnitLogisticiansReferential' Component={() => <LogisticsUnitLogisticiansReferentialView />} />}
                                    {(role === "ADM" || role === "LOG") && <Route path='Level3SegmentationsReferential' Component={() => <Level3SegmentationsReferentialView />} />}
                                    {(role === "ADM" || role === "LOG") && <Route path='GenericPurchasePricesReferential' Component={() => <GenericPurchasePricesReferentialView logisticsUnits={chosenLogisticsUnits} />} />}
                                    {(role === "ADM" || role === "LOG") && <Route path='ExternalTransportPurchasePricesReferential' Component={() => <TransportPurchasePricesReferentialView logisticsUnits={this.state.logisticsUnits} isForInternalTransporters={false} />} />}
                                    {(role === "ADM" || role === "LOG") && <Route path='InternalTransportPurchasePricesReferential' Component={() => <TransportPurchasePricesReferentialView logisticsUnits={this.state.logisticsUnits} isForInternalTransporters={true} />} />}
                                    {(role === "ADM" || role === "LOG") && <Route path='CustomerContactsReferential' Component={() => <CustomerContactsReferentialView />} />}
                                    {(role === "ADM" || role === "LOG") && <Route path='InternalContactsReferential' Component={() => <InternalContactsReferentialView />} />}
                                    {(role === "ADM" || role === "LOG") && <Route path='WorkAgenciesReferential' Component={() => <WorkAgenciesReferentialView />} />}
                                    {(role === "ADM" || role === "LOG") && <Route path='ProductionSitesReferential' Component={() => <ProductionSitesReferentialView handleLogisticsUnitsChanges={this.handleLogisticsUnitsChanges} role={role} />} />}
                                    {(role === "ADM" || role === "LOG") && <Route path='Level2ActivitiesReferential' Component={() => <Level2ActivitiesReferentialView />} />}
                                    {(role === "ADM" || role === "LOG") && <Route path='DieselIndexesReferential' Component={() => <DieselIndexesReferentialView logisticsUnits={chosenLogisticsUnits} role={role} />} />}
                                    {(role === "ADM" || role === "LOG") && <Route path='OverheadExpensesReferential' Component={() => <OverheadExpensesReferentialView logisticsUnits={chosenLogisticsUnits} logisticsUnitIds={chosenLogisticsUnitIds} />} />}
                                    {(role === "ADM" || role === "LOG") && <Route path='TransportSales' Component={() => <TransportSalesView role={role} logisticsUnits={chosenLogisticsUnits} logisticsUnitIds={chosenLogisticsUnitIds} />} />}
                                    {(role === "ADM" || role === "LOG") && <Route path='ThirdPartyInternalTransporterPublishedCosts' Component={() => <ThirdPartyTransporterPublishedCostsView role={this.state.role} isForInternalTransporters={true} />} />}
                                    {(role === "ADM" || role === "LOG") && <Route path='ThirdPartyExternalTransporterPublishedCosts' Component={() => <ThirdPartyTransporterPublishedCostsView role={this.state.role} isForInternalTransporters={false} />} />}
                                    {(role === "ADM" || role === "LOG") && <Route path='ThirdPartyBeneficiaryInvoices' Component={() => <ThirdPartyBeneficiaryInvoicesView role={this.state.role} />} />}
                                    {(role === "ADM" || role === "LOG") && <Route path='PurchaseProvisionings' Component={() => <PurchaseProvisioningsView logisticsUnits={chosenLogisticsUnits} />} />}
                                    {(role === "ADM" || role === "LOG") && <Route path='NegotiatedPurchasePricesReferential' Component={() => <NegotiatedPurchasePricesReferentialView logisticsUnits={chosenLogisticsUnits} />} />}
                                    {isQuotationAdministrator && <Route path='QuotationPricesReferential' Component={() => <QuotationPricesReferentialView logisticsUnits={chosenQuotationLogisticsUnits} />} />}
                                    {(role === "ADM" || role === "LOG") && <Route path='NegotiatedSellPricesReferential' Component={() => <NegotiatedSellPricesReferentialView logisticsUnits={chosenLogisticsUnits} />} />}
                                    {(role === "ADM" || role === "LOG") && <Route path='DispatchersReferential' Component={() => <DispatchersReferentialView logisticsUnits={chosenLogisticsUnits} />} />}
                                    {(role === "ADM" || role === "LOG") && <Route path='Cartography' Component={() => <CartographyView logisticsUnitIds={chosenLogisticsUnitIds} />} />}
                                </Routes>
                            </Layout>
                        }
                    </>
                </MatomoProviderTest>
            );
        }

        return (
            <MatomoProviderTest value={this.getMatomoInstance(this.state.userName, this.state.userHash)}>
                <Layout
                    role={this.state.role}
                    userName={this.state.userName}
                    userInitials={this.state.userInitials}
                    userDisplayName={this.state.userDisplayName}
                    userPhoneNumber={this.state.userPhoneNumber}
                    userEmail={this.state.userEmail}
                    loadingLayout={this.state.loadingLayout}
                    logisticsUnits={null}
                    logisticsUnitIds={null}
                    handleLogisticsUnitsChanges={null} />
            </MatomoProviderTest>
        );
    }
}

//Since you can't use the useMsal hook to access the MSAL React context in a class component you have 2 other options.
//You can either use the raw context directly or you can use the withMsal higher order component to inject the context into your component's props.
const App = withMsal(withAITracking(appInsightsReactPlugin, AppHOC));
export default App;