import { Box } from '@mui/material';
import { orderBy, SortDescriptor } from '@progress/kendo-data-query';
import { Grid, GridCellProps, GridColumn, GridColumnReorderEvent, GridColumnResizeEvent, GridHeaderCellProps, GridPageChangeEvent, GridSortChangeEvent } from '@progress/kendo-react-grid';
import React, { useEffect, useState } from 'react';
import { AppModule, LocalStorage } from '../../../../utils/Storage';
import { CustomCheckboxCell } from '../../Common/CustomCheckboxCell';
import { CustomHeaderCellComponent } from '../../Common/CustomHeaderCellComponent';
import { SizingUtilities } from '../../SizingUtilities';
import { ProductionSiteLightModelExtended } from '../models/ProductionSiteLightModelExtended';
import { LogisticsUnitChoiceOfProductionSitesLightModel } from '../services/dataContracts/queryStack/LogisticsUnitChoiceOfProductionSitesLightModel';
import { ProductionSitesReferentialApiClient } from '../services/ProductionSitesReferentialApiClient';

interface ProductionSitesReferentialComponentProps {
    productionSites: Array<ProductionSiteLightModelExtended>,
    productionSiteChoicesLogisticsUnit: Array<LogisticsUnitChoiceOfProductionSitesLightModel>,
    sort: SortDescriptor[],
    handleSortChange: (e: GridSortChangeEvent) => void,
    handleSortColumnChange: (sortItems: SortDescriptor[]) => void,
    productionSiteIsLogisticZone: boolean,
    handleSelectedLogisticZone: (logisticZoneId: string) => void,
}

function useForceUpdate() {
    const [, setTick] = React.useState(0);
    const update = React.useCallback(() => {
        setTick(tick => tick + 1);
    }, [])
    return update;
}

const ProductionSitesReferentialGridName = 'productionSitesReferential';
const ModuleKey = AppModule.ProductionSitesReferential;

export const ProductionSitesReferentialComponent = (props: ProductionSitesReferentialComponentProps) => {

    const [skip, setSkip] = useState<number>(0);
    const [productionSiteChoicesDataMap, setProductionSiteChoicesDataMap] = useState<Map<string, ProductionSiteLightModelExtended>>(new Map<string, ProductionSiteLightModelExtended>());
    const [productionSiteChoicesDataArray, setProductionSiteChoicesDataArray] = useState<Array<ProductionSiteLightModelExtended>>([]);
    const [selectedRowId, setSelectedRowId] = useState(null);

    useEffect(() => {
        setProductionSiteChoicesData();
    }, []);

    useEffect(() => {
        props.handleSelectedLogisticZone(null);
        setSelectedRowId(null);
    }, [props.productionSiteIsLogisticZone]);

    const forceUpdate = useForceUpdate();

    const setProductionSiteChoicesData = (): void => {
        const dataMap: Map<string, ProductionSiteLightModelExtended> = new Map<string, ProductionSiteLightModelExtended>();
        const dataArray: Array<ProductionSiteLightModelExtended> = [];

        props.productionSites.forEach((logisticsUnit: ProductionSiteLightModelExtended) => {
            const element: ProductionSiteLightModelExtended = { ...logisticsUnit };

            let checked = true;
            let _indeterminateAll = true;
            let numberOfChecked = 0;
            let numberOfUnchecked = 0;

            props.productionSiteChoicesLogisticsUnit.forEach((productionSite: LogisticsUnitChoiceOfProductionSitesLightModel) => {
                element[productionSite.logisticsUnitId] = productionSite.chosenProductionSites.includes(element.productionSiteId);
                if (!productionSite.chosenProductionSites.includes(element.productionSiteId)) {
                    checked = false;
                    numberOfUnchecked += 1;
                } else {
                    numberOfChecked += 1;
                }
            });

            if (props.productionSiteChoicesLogisticsUnit.length === numberOfUnchecked || props.productionSiteChoicesLogisticsUnit.length === numberOfChecked) {
                _indeterminateAll = false;
            }
            element.isIndeterminateAll = _indeterminateAll;
            element.isSelectedAll = checked ? true : (_indeterminateAll ? null : false);

            dataMap.set(element.productionSiteId, element);
            dataArray.push(element);
        });

        setProductionSiteChoicesDataMap(dataMap);
        setProductionSiteChoicesDataArray(dataArray);
    }

    const getProductionSitesWidth = (fieldName: string, defaultWidth: number): number => {
        return LocalStorage.GetGridColumnWidth(ModuleKey, ProductionSitesReferentialGridName, fieldName, defaultWidth);
    }

    const getLogisticsUnitGridOrderIndexColumn = (fieldName: string, defaultIndex: number): number => {
        return LocalStorage.GetGridColumnOrderIndex(ModuleKey, ProductionSitesReferentialGridName, fieldName, defaultIndex);
    }

    const onResizeHandler = (event: GridColumnResizeEvent): void => {
        const currentColumn = event.columns.find(c => c.id == event.targetColumnId);

        LocalStorage.SetGridColumnWidth(
            ModuleKey,
            ProductionSitesReferentialGridName,
            currentColumn.field,
            currentColumn.width);
    }

    const onReorderHandler = (event: GridColumnReorderEvent): void => {
        LocalStorage.SetGridColumnsOrderIndexes(ModuleKey, ProductionSitesReferentialGridName, event.columns);
        forceUpdate();
    }

    const updateChoicesProductionSitesLogisticsUnit = (checked: boolean, logisticsUnitId: string, productionSiteId: string): void => {
        ProductionSitesReferentialApiClient.AddOrRemoveProductionSiteChoice({
            productionSiteId: productionSiteId,
            logisticsUnitId: logisticsUnitId,
            shouldAdd: checked
        })
            .then(() => {
                const item = productionSiteChoicesDataMap.get(productionSiteId);
                item[logisticsUnitId] = checked;

                let checkedSelect = true;
                let _indeterminateAll = true;
                let numberOfChecked = 0;
                let numberOfUnchecked = 0;
                props.productionSiteChoicesLogisticsUnit.forEach((productionSite: LogisticsUnitChoiceOfProductionSitesLightModel) => {
                    if (!item[productionSite.logisticsUnitId]) {
                        checkedSelect = false;
                        numberOfUnchecked += 1;
                    } else {
                        numberOfChecked += 1;
                    }
                });
                if (props.productionSiteChoicesLogisticsUnit.length === numberOfUnchecked || props.productionSiteChoicesLogisticsUnit.length === numberOfChecked) {
                    _indeterminateAll = false;
                }

                item.isSelectedAll = checkedSelect ? true : (_indeterminateAll ? null : false);
                item.isIndeterminateAll = _indeterminateAll;

                forceUpdate();
            });
    }

    const updateChoicesProductionSitesAllLogisticsUnits = (checked: boolean, productionSiteId: string): void => {
        const logisticsUnitIds: Array<string> = [];
        props.productionSiteChoicesLogisticsUnit.forEach((element: LogisticsUnitChoiceOfProductionSitesLightModel) => {
            logisticsUnitIds.push(element.logisticsUnitId);
        });

        ProductionSitesReferentialApiClient.AddOrRemoveProductionSiteChoices({
            productionSiteId: productionSiteId,
            shouldAdd: checked,
            logisticsUnitIds: logisticsUnitIds
        })
            .then(() => {
                const item = productionSiteChoicesDataMap.get(productionSiteId);
                logisticsUnitIds.forEach((logisticsUnitId: string) => {
                    item[logisticsUnitId] = checked;
                });
                item.isSelectedAll = checked ? true : false;
                item.isIndeterminateAll = false;

                forceUpdate();
            });
    }

    const pageChange = (event: GridPageChangeEvent): void => {
        setSkip(event.page.skip);
    }

    const handleRowClick = (productionSite): void => {
        const dataItem = productionSite.data as ProductionSiteLightModelExtended;
        if (dataItem.selected) {
            props.handleSelectedLogisticZone(null);
            setSelectedRowId(null);
        }
        else {
            props.handleSelectedLogisticZone(dataItem.productionSiteId);
            setSelectedRowId(dataItem.productionSiteId);
        }
    }

    const rowRender = (trElement, row) => {
        const isSelected = selectedRowId === row.dataItem.productionSiteId;
        const data = row.dataItem;
        const className = isSelected ? 'k-table-row k-master-row k-selected' : 'k-table-row k-master-row';
        const onClickHandler = () => handleRowClick({ data });

        // Clone the element with the new props
        return React.cloneElement(trElement, { onClick: onClickHandler, className: className });
    };

    const _gridOffsetFromWindowTop: number = SizingUtilities.gridOffsetFromWindowTop();
    let gridHeight: number = SizingUtilities.computeGridHeight(_gridOffsetFromWindowTop);
    const rowHeight: number = SizingUtilities.rowHeight;
    let gridPageSize: number = SizingUtilities.computeGridPageSize(gridHeight, rowHeight);
    const totalGrid: number = props.productionSites.length;
    const gridStyle: React.CSSProperties = { height: gridHeight };
    const resize = (): void => {
        gridHeight = window.innerHeight - _gridOffsetFromWindowTop;
        gridPageSize = Number((gridHeight / rowHeight).toFixed(0));
        forceUpdate();
    }
    window.onresize = resize;

    const gridData: Array<ProductionSiteLightModelExtended> = orderBy(productionSiteChoicesDataArray, props.sort).slice(skip, skip + gridPageSize);

    return (
        <Box display="flex" flexDirection="row" flex="wrap" className="internal-production-sites-grid">
            <Grid className='production-sites-grid'
                data={gridData}
                selectedField="selected"
                sortable
                reorderable
                resizable
                sort={props.sort}
                onRowClick={e => handleRowClick(e)}
                onColumnResize={onResizeHandler}
                onColumnReorder={(e) => onReorderHandler(e)}
                onSortChange={props.handleSortChange}
                editField="inEdit"
                rowHeight={rowHeight}
                scrollable="virtual"
                rowRender={rowRender}
                skip={skip}
                total={totalGrid}
                pageSize={gridPageSize}
                onPageChange={pageChange}
                style={gridStyle}
            >
                {!props.productionSiteIsLogisticZone && <GridColumn field="isSelectedAll" orderIndex={getLogisticsUnitGridOrderIndexColumn("isSelectedAll", 0)} width={getProductionSitesWidth("isSelectedAll", 100)} title="Sélection"
                    cell={(props: GridCellProps) =>
                        <CustomCheckboxCell
                            {...props}
                            keyName="productionSiteId"
                            updateChoicesAllLogisticsUnits={updateChoicesProductionSitesAllLogisticsUnits}
                        />}
                    headerCell={(_props: GridHeaderCellProps) =>
                        <CustomHeaderCellComponent tooltip="Activer/Désactiver un site de production travaux pour toutes les zones logistiques" title={_props.title} field="isSelectedAll" sort={props.sort}
                            handleSortColumnChange={props.handleSortColumnChange} />
                    }
                />}
                <GridColumn field="label" orderIndex={getLogisticsUnitGridOrderIndexColumn("label", 1)} width={getProductionSitesWidth("label", 120)} title="Nom" />
                <GridColumn field="productionSiteId" orderIndex={getLogisticsUnitGridOrderIndexColumn("productionSiteId", 2)} width={getProductionSitesWidth("productionSiteId", 120)} title="Id" />
                <GridColumn field="freeFormAddress" sortable={false} orderIndex={getLogisticsUnitGridOrderIndexColumn("freeFormAddress", 3)} width={getProductionSitesWidth("freeFormAddress", 120)} title="Adresse" />
                {props.productionSiteChoicesLogisticsUnit.map((logisticsUnit: LogisticsUnitChoiceOfProductionSitesLightModel, i: number) =>
                    !props.productionSiteIsLogisticZone && <GridColumn key={i} field={logisticsUnit.logisticsUnitId} orderIndex={getLogisticsUnitGridOrderIndexColumn(logisticsUnit.logisticsUnitId, 4 + i)} width={getProductionSitesWidth(logisticsUnit.logisticsUnitId, 150)} title={logisticsUnit.label}
                        cell={(props: GridCellProps) =>
                            <CustomCheckboxCell
                                {...props}
                                keyName="productionSiteId"
                                updateChoicesLogisticsUnit={updateChoicesProductionSitesLogisticsUnit}
                            />}
                        headerCell={(_props: GridHeaderCellProps) =>
                            <CustomHeaderCellComponent tooltip="Activer/Désactiver un site de production travaux pour la zone logistique" title={_props.title} field={logisticsUnit.logisticsUnitId} sort={props.sort}
                                handleSortColumnChange={props.handleSortColumnChange} />
                        }
                    />
                )}
            </Grid>
        </Box>
    );
}
