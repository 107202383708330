import { Box, Button, CircularProgress, debounce, Dialog, DialogActions, DialogContent, DialogTitle, Drawer, Paper, PaperProps } from '@mui/material';
import { GridCellProps } from '@progress/kendo-react-grid';
import { SwitchChangeEvent } from '@progress/kendo-react-inputs';
import { Splitter, SplitterOnChangeEvent, SplitterPaneProps, TabStrip, TabStripSelectEventArguments, TabStripTab } from '@progress/kendo-react-layout';
import React, { useEffect, useMemo, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Draggable from 'react-draggable';
import { ScaleLoader } from 'react-spinners';
import { SettingsProvider } from 'src/SettingsProvider';
import { FieldDescriptor } from 'src/shared/components/TextSearchFieldsSelector/models/FieldDescriptor';
import ToastService from 'src/ToastService';
import BusinessErrors from 'src/utils/BusinessErrors';
import BusinessWarnings from 'src/utils/BusinessWarnings';
import Utilities from 'src/utils/Utilities';
import { PlanningVehicleStatusComponent } from '../../shared/components/PlanningVehicleStatus/PlanningVehicleStatusComponent';
import { DateRange } from '../../shared/models/DateRange';
import { LogisticsUnitChoice } from '../../shared/models/LogisticsUnitChoice';
import { LogisticsUnitSelectModel } from '../../shared/models/LogisticsUnitSelectModel';
import { SessionStorage } from '../../utils/Storage';
import { ContentHeaderComponent } from './components/ContentHeaderComponent';
import { NewProvisioningComponent } from './components/NewProvisioningComponent';
import { PlanningVehiclesSelectorComponent } from './components/PlanningVehiclesSelectorComponent';
import { ProvisioningsComponent } from './components/ProvisioningsComponent';
import { PurchaseProvisioningVehiclesSelectorComponent } from './components/PurchaseProvisioningVehiclesSelectorComponent';
import { RegularizationTemplateComponent } from './components/RegularizationTemplateComponent';
import { TransportersComponent } from './components/TransportersComponent';
import { PlanningVehicleLightModelExtended } from './models/PlanningVehicleLightModelExtended';
import { PublishedCostsStatus } from './models/PublishedCostsStatus';
import { PurchaseProvisioningDetailLightModelExtended } from './models/PurchaseProvisioningDetailLightModelExtended';
import { PurchaseProvisioningLightModelExtended } from './models/PurchaseProvisioningLightModelExtended';
import { PurchaseProvisionningCosts } from './models/PurchaseProvisionningCosts';
import { RegularizationPurchaseProvisioningDetailExtended } from './models/RegularizationPurchaseProvisioningDetailExtended';
import { RegularizationPurchaseProvisioningExtended } from './models/RegularizationPurchaseProvisioningExtended';
import { SimpleDialogModel } from './models/SimpleDialogModel';
import './PurchaseProvisioningsStyles.scss';
import { BaseOfPurchaseCostIndexableRequestArgs } from './services/dataContracts/controller/BaseOfPurchaseCostIndexableRequestArgs';
import { ProvisioningGenerationDetailRequestArgs } from './services/dataContracts/controller/ProvisioningGenerationDetailRequestArgs';
import { ProvisioningGenerationRequestArgs } from './services/dataContracts/controller/ProvisioningGenerationRequestArgs';
import { PublishedCostsSearchOption } from './services/dataContracts/controller/PublishedCostsSearchOption';
import { RegularizationPurchaseProvisioningRequestArgs } from './services/dataContracts/controller/RegularizationPurchaseProvisioningRequestArgs';
import { SendPurchaseProvisioningRequestArgs } from './services/dataContracts/controller/SendPurchaseProvisioningRequestArgs';
import { PurchaseProvisioningsApiClient } from './services/dataContracts/PurchaseProvisioningsApiClient';
import { PurchaseProvisioningLightModel } from './services/dataContracts/queryStack/PurchaseProvisioningLightModel';
import { TransporterLightModel } from './services/dataContracts/queryStack/TransporterLightModel';

interface PurchaseProvisioningsProperties {
    logisticsUnits: Array<LogisticsUnitChoice>
}

const filterOptionsPublishedCosts: Array<FieldDescriptor<PublishedCostsSearchOption>> = [
    { label: 'En cours', value: PublishedCostsSearchOption.pending },
    { label: 'OK', value: PublishedCostsSearchOption.agreed },
    { label: 'KO', value: PublishedCostsSearchOption.disagreed },
    { label: 'Aucun', value: PublishedCostsSearchOption.notPublished }
];

enum KnownProperties {
    CostsRemarksOfLogistician = 'costsRemarksOfLogistician',
    PlanningVehicleRemarks = 'planningVehicleRemarks',
    BaseOfPurchaseCostIndexableWithGasoilIndex = 'baseOfPurchaseCostIndexableWithGasoilIndex'
}

const PaperComponent = (props: PaperProps) => {
    return (
        <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'} bounds="parent">
            <Paper {...props} />
        </Draggable>
    );
}

interface GeneratedRegularizationModel {
    loading: boolean,
    nbElemAll: number,
    nbGeneratedRegularizationsElems: number,
    toastLstMessageError: Array<string>
}

interface PurchaseProvisioningDetailBaseOfPurchaseCostIndexableModel {
    purchaseProvisioningDetailId: number,
    baseOfPurchaseCostIndexable: number,
    openModal: boolean
}

export const PurchaseProvisioningsView = (props: PurchaseProvisioningsProperties) => {
    let preventExit: boolean;
    let preventExitTimeout: ReturnType<typeof setTimeout>;
    let blurTimeout: ReturnType<typeof setTimeout>;
    const config = SettingsProvider.Get();
    const purchaseProvisioningMaxAllowedCostLimit: number = config.purchaseProvisioningMaxAllowedCostLimit;
    const purchaseProvisioningRegularizationBatchSize = config.purchaseProvisioningRegularizationBatchSize;

    const inputSearchVehiclesRef: React.RefObject<HTMLInputElement> = React.useRef(null);
    const inputSearchProvisioningToSendRef: React.RefObject<HTMLInputElement> = React.useRef(null);
    const inputSearchIntegratedProvisioningRef: React.RefObject<HTMLInputElement> = React.useRef(null);

    const [editField, setEditField] = useState<string>(null);
    const [fieldValueBeforeEntering, setFieldValueBeforeEntering] = useState<string>(null);
    const [vehicleTypeGroups, setVehicleTypeGroups] = useState<Array<string>>([]);
    const [isTransporterDrawerOpened, setIsTransporterDrawerOpened] = useState<boolean>(false);
    const [loadingPlanningVehicles, setLoadingPlanningVehicles] = useState<boolean>(false);
    const [loadingPurchaseProvisioningVehicles, setLoadingPurchaseProvisioningVehicles] = useState<boolean>(false);
    const [loadingPurchaseProvisionings, setLoadingPurchaseProvisionings] = useState<boolean>(false);
    const [planningVehicles, setPlanningVehicles] = useState<Array<PlanningVehicleLightModelExtended>>([]);
    const [filteredPlanningVehicles, setFilteredPlanningVehicles] = useState<Array<PlanningVehicleLightModelExtended>>([]);
    const [purchaseProvisioningVehicles, setPurchaseProvisioningVehicles] = useState<Array<PurchaseProvisioningDetailLightModelExtended>>([]);
    const [planningVehiclesSelected, setPlanningVehiclesSelected] = useState<Array<PlanningVehicleLightModelExtended>>([]);
    const [transporters, setTransporters] = useState<Array<TransporterLightModel>>([]);
    const [selectedTransporters, setSelectedTransporters] = useState<Array<string>>([]);
    const [isHeaderBtnPreInvoicesEnabled, setIsHeaderBtnPreInvoicesEnabled] = useState<boolean>(false);
    const [date, setDate] = useState<DateRange>({ start: SessionStorage.ActiveStartDate, end: SessionStorage.ActiveEndDate });
    const [selectedLogisticsUnit, setSelectedLogisticsUnit] = useState<LogisticsUnitSelectModel>(props.logisticsUnits.length == 1 ? { value: props.logisticsUnits[0].logisticsUnitId, label: props.logisticsUnits[0].label } : null);
    const [searchText, setSearchText] = useState<string>("");
    const [searchTextIntegratedProvisionings, setSearchTextIntegratedProvisionings] = useState<string>("");
    const [searchTextProvisioningsToIntegrate, setSearchTextProvisioningsToIntegrate] = useState<string>("");
    const [filtersPublishedCostsSelected, setFiltersPublishedCostsSelected] = useState<Array<PublishedCostsSearchOption>>(filterOptionsPublishedCosts.map(x => x.value));
    const [lastTimeStampGetPlanningVehicles, setLastTimeStampGetPlanningVehicles] = useState<number>(Date.now());
    const [lastTimeStampGetProvisionings, setLastTimeStampGetProvisionings] = useState<number>(Date.now());
    const [lastTimeStampGetPurchaseProvisioningVehicles, setLastTimeStampGetPurchaseProvisioningVehicles] = useState<number>(Date.now());
    const [lastTimeStampGetPlanningVehiclesWithTransporters, setLastTimeStampGetPlanningVehiclesWithTransporters] = useState<number>(Date.now());
    const [firstLoad, setFirstLoad] = useState<boolean>(true);
    const [selectedTab, setSelectedTab] = useState<number>(0);
    const [purchaseProvisioningsToIntegrate, setPurchaseProvisioningsToIntegrate] = useState<Array<PurchaseProvisioningLightModelExtended>>([]);
    const [filteredPurchaseProvisioningsToIntegrate, setFilteredPurchaseProvisioningsToIntegrate] = useState<Array<PurchaseProvisioningLightModelExtended>>([]);
    const [integratedProvisionings, setIntegratedProvisionings] = useState<Array<PurchaseProvisioningLightModelExtended>>([]);
    const [filteredIntegratedProvisionings, setFilteredIntegratedProvisionings] = useState<Array<PurchaseProvisioningLightModelExtended>>([]);
    const [selectedPurchaseProvisioning, setSelectedPurchaseProvisioning] = useState<string>(null);
    const [sendingPurchaseProvisioningId, setSendingPurchaseProvisioningId] = useState<string>(null);
    const [showOnlyCostAndFeesWithoutProvisioning, setShowOnlyCostAndFeesWithoutProvisioning] = useState<boolean>(false);
    const [showOnlyProvisioningsWithDifferences, setShowOnlyProvisioningsWithDifferences] = useState<boolean>(false);
    const [showConfirmationClearOverridenGasoilIndexModal, setShowConfirmationClearOverridenGasoilIndexModal] = React.useState<PurchaseProvisioningDetailBaseOfPurchaseCostIndexableModel>({
        purchaseProvisioningDetailId: 0,
        baseOfPurchaseCostIndexable: null,
        openModal: false
    });

    const [purchaseProvisionningCosts, setPurchaseProvisionningCosts] = useState<PurchaseProvisionningCosts>({
        costsToIntegrateWithProvisioning: null,
        feesToIntegrateWithProvisioning: null,
        costsToIntegrateWithoutProvisioning: null,
        feesToIntegrateWithoutProvisioning: null,
        integratedCostsWithProvisioning: null,
        integratedFeesWithProvisioning: null
    });

    const [simpleDialog, setSimpleDialog] = useState<SimpleDialogModel>({
        isDialogOpened: false,
        popupContentComponent: <></>
    });

    const [regularizations, setRegularizations] = useState<Array<RegularizationPurchaseProvisioningExtended>>([]);
    const [generatedRegularizationModel, setGeneratedRegularizationModel] = useState<GeneratedRegularizationModel>({
        loading: false,
        nbElemAll: 0,
        nbGeneratedRegularizationsElems: 0,
        toastLstMessageError: []
    });

    useEffect(() => {
        if (selectedLogisticsUnit)
            searchPlanningVehiclesTripWithTransportersAndVehicleTypeGroupsAndProvisionings(date, searchText, filtersPublishedCostsSelected, selectedLogisticsUnit?.value);
        else
            getVehicleTypeGroups();
    }, []);

    useEffect(() => {
        if (!firstLoad) {
            if (filtersPublishedCostsSelected.length == 0) {
                ToastService.showErrorToast("Veuillez séléctionner au moins un filtre.");
            }
            else {
                searchPlanningVehicles(date, selectedTransporters, selectedLogisticsUnit?.value, searchText, filtersPublishedCostsSelected);
            }
        }
        else {
            setFirstLoad(false);
        }
    }, [filtersPublishedCostsSelected]);

    //#region Methods
    const handleOpenCloseTransporterDrawer = (): void => {
        setIsTransporterDrawerOpened(!isTransporterDrawerOpened);
    }

    const handleChangeDateRange = (range: DateRange): void => {
        if (range.end != null && range.start != null) {
            const fromTime = range.start.getDayStart();
            const toTime = range.end.getDayEnd();

            const dateRange = { start: fromTime, end: toTime };
            setDate(dateRange);
            setSelectedTransporters([]);
            searchPlanningVehiclesTripWithTransportersAndProvisionings(dateRange, searchText, filtersPublishedCostsSelected, selectedLogisticsUnit?.value, []);
        }
    }

    const clearSearchText = (): void => {
        inputSearchVehiclesRef.current.value = "";
        setSearchText("");
        searchPlanningVehiclesWithTransporters(date, "", filtersPublishedCostsSelected, selectedLogisticsUnit?.value);
    }

    const handleSearchTextKeyPressed = debounce((text: string): void => {
        if (text.length >= 3 || text.length === 0) {
            searchTextKeyPressed(text);
        }
    }, 500);

    const searchTextKeyPressed = (text: string): void => {
        setSearchText(text);
        searchPlanningVehiclesWithTransporters(date, text, filtersPublishedCostsSelected, selectedLogisticsUnit?.value);
    }

    const clearProvisioningSearchText = (integrated: boolean): void => {
        if (selectedPurchaseProvisioning) {
            handleDeselectPurchaseProvisioning();
        }
        if (integrated) {
            if (inputSearchIntegratedProvisioningRef?.current) {
                inputSearchIntegratedProvisioningRef.current.value = "";
            }
            setSearchTextIntegratedProvisionings("");
            searchIntegratedProvisioning(date, "", selectedLogisticsUnit?.value);
        }
        else {
            if (inputSearchProvisioningToSendRef?.current) {
                inputSearchProvisioningToSendRef.current.value = "";
            }
            setSearchTextProvisioningsToIntegrate("");
            searchProvisioningToIntegrate(date, "", selectedLogisticsUnit?.value);
        }
    }

    const handleSearchProvisioningTextKeyPressed = debounce((integrated: boolean, text: string): void => {
        if (text.length >= 3 || text.length === 0) {
            searchProvisioningTextKeyPressed(integrated, text);
        }
    }, 500);

    const searchProvisioningTextKeyPressed = (integrated: boolean, text: string): void => {
        if (selectedPurchaseProvisioning) {
            handleDeselectPurchaseProvisioning();
        }
        if (integrated) {
            setSearchTextIntegratedProvisionings(text);
            searchIntegratedProvisioning(date, text, selectedLogisticsUnit?.value);
        }
        else {
            setSearchTextProvisioningsToIntegrate(text);
            searchProvisioningToIntegrate(date, text, selectedLogisticsUnit?.value);
        }
    }

    const toggleCheckbox = debounce((transporter: TransporterLightModel): void => {
        const selectedTransportersArray = selectedTransporters;
        if (selectedTransportersArray.indexOf(transporter.transporterId == null ? null : transporter.transporterId) != -1) {
            selectedTransportersArray.splice(selectedTransportersArray.indexOf(transporter.transporterId == null ? null : transporter.transporterId), 1);

            setSelectedTransporters(selectedTransportersArray);
        } else {
            selectedTransportersArray.push(transporter.transporterId == null ? null : transporter.transporterId);

            setSelectedTransporters(selectedTransportersArray);
        }
        searchPlanningVehicles(date, selectedTransportersArray, selectedLogisticsUnit?.value, searchText, filtersPublishedCostsSelected, true);
    }, 500);

    const handleLogisticsUnitChange = (event: LogisticsUnitSelectModel): void => {
        setSelectedLogisticsUnit(event);
        setSelectedTransporters([]);

        if (event) {
            searchPlanningVehiclesTripWithTransportersAndProvisionings(date, searchText, filtersPublishedCostsSelected, event.value, []);
        }
        else {
            setPlanningVehiclesSelected([]);
            setPurchaseProvisioningVehicles([]);
            setPurchaseProvisioningsToIntegrate([]);
            setFilteredPurchaseProvisioningsToIntegrate([]);
            setIntegratedProvisionings([]);
            setFilteredIntegratedProvisionings([]);
            setSelectedPurchaseProvisioning(null);
        }
    }

    const selectionChange = (checked: boolean, dataItem: PlanningVehicleLightModelExtended): void => {
        const dataArray = [...planningVehicles];

        const data = dataArray.map((item: PlanningVehicleLightModelExtended) => {
            if (item.planningVehicleId === dataItem.planningVehicleId) {
                item.selected = checked;
                if (checked) {
                    item.purchaseCostEnabled = dataItem.purchaseCost && !dataItem.costPurchaseProvisioningBusinessId ? true : false;
                    item.feesEnabled = dataItem.fees && !dataItem.feesPurchaseProvisioningBusinessId ? true : false;
                }
                else {
                    item.purchaseCostEnabled = false;
                    item.feesEnabled = false;
                }
            }
            return item;
        });

        const dataItemsSelected = data.filter(x => x.selected);
        if (dataItemsSelected && dataItemsSelected.length == 0) {
            refreshPurchaseProvisioning();
        }

        setPlanningVehiclesSelected(dataItemsSelected);
        setPlanningVehicles(data);
        setFilteredPlanningVehicles(getFilteredPlanningVehicles(showOnlyCostAndFeesWithoutProvisioning, showOnlyProvisioningsWithDifferences, data));
    }

    const headerSelectAllChanged = (vehicleProvisioningsData: Array<PlanningVehicleLightModelExtended>, selectAllChecked: boolean, onlyProvisionableCanBeSelected?: boolean): void => {
        vehicleProvisioningsData = vehicleProvisioningsData ?? [...planningVehicles];

        const data = vehicleProvisioningsData.map((item: PlanningVehicleLightModelExtended) => {
            item.isIndeterminate = false;

            const hasUnprovisionedFees: boolean = (item.fees && !item.feesPurchaseProvisioningBusinessId) ? true : false;
            const hasUnprovisionedCost: boolean = (item.purchaseCost && !item.costPurchaseProvisioningBusinessId) ? true : false;
            let itemSelected = selectAllChecked && item.isEnabled;

            if (onlyProvisionableCanBeSelected) {
                itemSelected = itemSelected && (hasUnprovisionedFees || hasUnprovisionedCost);
            }

            if (showOnlyCostAndFeesWithoutProvisioning) {
                itemSelected = itemSelected && (hasUnprovisionedCost || hasUnprovisionedFees);
            }

            if (showOnlyProvisioningsWithDifferences) {
                itemSelected = itemSelected && (item.hasDiffFromPurchaseProvisioningCost || item.hasDiffFromPurchaseProvisioningFees);
            }

            item.selected = itemSelected;

            if (selectAllChecked) {
                item.purchaseCostEnabled = hasUnprovisionedCost;
                item.feesEnabled = hasUnprovisionedFees;
            }
            else {
                item.purchaseCostEnabled = false;
                item.feesEnabled = false;
            }
            return item;
        });

        const dataItemsSelected = data.filter(x => x.selected);
        if (dataItemsSelected && dataItemsSelected.length == 0) {
            refreshPurchaseProvisioning();
        }

        setPlanningVehiclesSelected(dataItemsSelected);
        setPlanningVehicles(data);
        setFilteredPlanningVehicles(getFilteredPlanningVehicles(showOnlyCostAndFeesWithoutProvisioning, showOnlyProvisioningsWithDifferences, data));
    }

    const refreshPurchaseProvisioning = () => {
        if (selectedTab == 0 && searchTextProvisioningsToIntegrate.length > 0) {
            clearProvisioningSearchText(false);
        }
        else if (searchTextIntegratedProvisionings.length > 0) {
            clearProvisioningSearchText(true);
        }
    }

    const rowRenderPlanningVehicles = (trElement: React.ReactElement<HTMLTableRowElement>): React.ReactElement<HTMLTableRowElement> => {
        const trProps = {
            ...trElement.props,

            onMouseDown: () => {
                preventExit = true;
                clearTimeout(preventExitTimeout);
                preventExitTimeout = setTimeout(() => { preventExit = undefined; });
            },
            onBlur: () => {
                clearTimeout(blurTimeout);
                if (!preventExit) {
                    blurTimeout = setTimeout(() => { exitEditPlanningVehicles(); });
                }
            },
            onFocus: () => { clearTimeout(blurTimeout); }
        };
        return React.cloneElement(trElement, { ...trProps }, trElement.props.children as any);
    }

    const cellRenderPlanningVehicles = (tdElement: React.ReactElement<HTMLTableCellElement>, cellProps: GridCellProps): React.ReactElement => {
        const dataItem: PlanningVehicleLightModelExtended = cellProps.dataItem;
        const field = cellProps.field;
        const additionalProps = (dataItem.inEdit && (field === dataItem.inEdit)) ?
            {
                ref: (td) => {
                    const input = td && td.querySelector('input');
                    if (!input || (input === document.activeElement)) { return; }
                    if (input.type === 'checkbox') {
                        input.focus();
                    } else {
                        input.select();
                    }
                }
            } : {
                onClick: () => { enterEditPlanningVehicles(dataItem, field); }
            };
        return React.cloneElement(tdElement, { ...tdElement.props, ...additionalProps }, tdElement.props.children as any);
    }

    const enterEditPlanningVehicles = (dataItem: PlanningVehicleLightModelExtended, field: string): void => {
        if (dataItem.inEdit && field === editField) {
            return;
        }

        exitEditPlanningVehicles(field);
        dataItem.inEdit = field;
        setFieldValueBeforeEntering(dataItem[field]);
    }

    const exitEditPlanningVehicles = (field?: string): void => {
        let wasEditing = false;
        let propertyName = "";
        let propertyValue: any;
        let planningVehicleIdToUpdate = null;
        let planningVehicleRemarksToUpdate: string = null;
        let costsRemarksOfLogisticianToUpdate: string = null;

        const vehicleProvisioningsArray = [...planningVehicles];

        vehicleProvisioningsArray.forEach((dataItem: PlanningVehicleLightModelExtended) => {
            if (dataItem.inEdit !== null && dataItem.inEdit !== "selected") {
                propertyName = dataItem.inEdit;
                propertyValue = dataItem[propertyName];
                planningVehicleIdToUpdate = dataItem.planningVehicleId;
                planningVehicleRemarksToUpdate = dataItem.planningVehicleRemarks;
                costsRemarksOfLogisticianToUpdate = dataItem.costsRemarksOfLogistician;
                if (fieldValueBeforeEntering !== propertyValue)
                    wasEditing = true;
            }
            dataItem.inEdit = null;
        });

        setPlanningVehicles(vehicleProvisioningsArray);
        setFilteredPlanningVehicles(getFilteredPlanningVehicles(showOnlyCostAndFeesWithoutProvisioning, showOnlyProvisioningsWithDifferences, vehicleProvisioningsArray));

        if (!wasEditing || (propertyName !== KnownProperties.PlanningVehicleRemarks && propertyName !== KnownProperties.CostsRemarksOfLogistician)) {
            setEditField(null);
            return;
        }
        setEditField(field);

        switch (propertyName) {
            case KnownProperties.PlanningVehicleRemarks:
                {
                    PurchaseProvisioningsApiClient.UpdatePlanningVehicleRemarks(planningVehicleIdToUpdate, planningVehicleRemarksToUpdate)
                        .then(res => {
                            const errors = BusinessErrors.Get(res.data);

                            if (errors.length > 0) {
                                ToastService.showErrorToast("Une ou plusieurs erreur(s) rencontrée(s) lors de la mise à jour du commentaire de camion", errors);
                            }
                        });
                    break;
                }
            case KnownProperties.CostsRemarksOfLogistician:
                {
                    PurchaseProvisioningsApiClient.UpdatePlanningVehicleCostsRemarks(planningVehicleIdToUpdate, costsRemarksOfLogisticianToUpdate)
                        .then(res => {
                            const errors = BusinessErrors.Get(res.data);

                            if (errors.length > 0) {
                                ToastService.showErrorToast("Une ou plusieurs erreur(s) rencontrée(s) lors de la mise à jour du commentaire de facturation", errors);
                            }
                        });
                    break;
                }
            default:
                break;
        }
    }

    const rowRenderPurchaseProvisionings = (trElement: React.ReactElement<HTMLTableRowElement>): React.ReactElement<HTMLTableRowElement> => {
        const trProps = {
            ...trElement.props,

            onMouseDown: () => {
                preventExit = true;
                clearTimeout(preventExitTimeout);
                preventExitTimeout = setTimeout(() => { preventExit = undefined; });
            },
            onBlur: () => {
                clearTimeout(blurTimeout);
                if (!preventExit) {
                    blurTimeout = setTimeout(() => { exitEditPurchaseProvisionings(); });
                }
            },
            onFocus: () => { clearTimeout(blurTimeout); }
        };
        return React.cloneElement(trElement, { ...trProps }, trElement.props.children as any);
    }

    const cellRenderPurchaseProvisionings = (tdElement: React.ReactElement<HTMLTableCellElement>, cellProps: GridCellProps): React.ReactElement => {
        const dataItem: PurchaseProvisioningDetailLightModelExtended = cellProps.dataItem;
        const field = cellProps.field;
        const additionalProps = (dataItem.inEdit && (field === dataItem.inEdit)) ?
            {
                ref: (td) => {
                    const input = td && td.querySelector('input');
                    if (!input || (input === document.activeElement)) { return; }
                    if (input.type === 'checkbox') {
                        input.focus();
                    } else {
                        input.select();
                    }
                }
            } : {
                onClick: () => { enterEditPurchaseProvisionings(dataItem, field); }
            };
        return React.cloneElement(tdElement, { ...tdElement.props, ...additionalProps }, tdElement.props.children as any);
    }

    const enterEditPurchaseProvisionings = (dataItem: PurchaseProvisioningDetailLightModelExtended, field: string): void => {
        if (dataItem.inEdit && field === editField) {
            return;
        }

        exitEditPurchaseProvisionings(field);
        dataItem.inEdit = field;
        setFieldValueBeforeEntering(dataItem[field]);
    }

    const exitEditPurchaseProvisionings = (field?: string): void => {
        let wasEditing = false;
        let propertyName = "";
        let propertyValue: any;
        let purchaseProvisioningDetailIdToUpdate = null;
        let baseOfPurchaseCostIndexableWithGasoilIndexToUpdate: number = null;

        const purchaseProvisioningVehiclesArray = [...purchaseProvisioningVehicles];

        purchaseProvisioningVehiclesArray.forEach((dataItem: PurchaseProvisioningDetailLightModelExtended) => {
            if (dataItem.inEdit !== null && dataItem.inEdit !== "selected") {
                propertyName = dataItem.inEdit;
                propertyValue = dataItem[propertyName];
                purchaseProvisioningDetailIdToUpdate = dataItem.purchaseProvisioningDetailId;
                baseOfPurchaseCostIndexableWithGasoilIndexToUpdate = dataItem.baseOfPurchaseCostIndexableWithGasoilIndex;
                if (fieldValueBeforeEntering !== propertyValue)
                    wasEditing = true;
            }
            dataItem.inEdit = null;
        });

        setPurchaseProvisioningVehicles(purchaseProvisioningVehiclesArray);

        if (!wasEditing || propertyName !== KnownProperties.BaseOfPurchaseCostIndexableWithGasoilIndex) {
            setEditField(null);
            return;
        }
        setEditField(field);

        let purchaseProvisioningOverridenGasoilIndex;
        if (selectedTab === 0) {
            purchaseProvisioningOverridenGasoilIndex = purchaseProvisioningsToIntegrate.find(x => x.businessId === selectedPurchaseProvisioning).overridenGasoilIndexCost;
        }
        else {
            purchaseProvisioningOverridenGasoilIndex = integratedProvisionings.find(x => x.businessId === selectedPurchaseProvisioning).overridenGasoilIndexCost;
        }

        if (!!purchaseProvisioningOverridenGasoilIndex) {
            setShowConfirmationClearOverridenGasoilIndexModal({
                purchaseProvisioningDetailId: purchaseProvisioningDetailIdToUpdate,
                baseOfPurchaseCostIndexable: baseOfPurchaseCostIndexableWithGasoilIndexToUpdate,
                openModal: true
            });
        }
        else {
            handleConfirmUpdatePurchaseProvisioningDetailsBaseOfPurchaseCostIndexableWithGasoilIndex(
                purchaseProvisioningDetailIdToUpdate,
                baseOfPurchaseCostIndexableWithGasoilIndexToUpdate,
                false)
        }
    }

    const handleConfirmUpdatePurchaseProvisioningDetailsBaseOfPurchaseCostIndexableWithGasoilIndex = (
        purchaseProvisioningDetailId: number,
        baseOfPurchaseCostIndexable: number,
        shouldRemoveOverridenGasoilIndex: boolean) => {

        let purchaseProvisioningId = "";
        if (selectedTab === 0) {
            purchaseProvisioningId = purchaseProvisioningsToIntegrate.find(x => x.businessId === selectedPurchaseProvisioning).purchaseProvisioningId;
        }
        else {
            purchaseProvisioningId = integratedProvisionings.find(x => x.businessId === selectedPurchaseProvisioning).purchaseProvisioningId;
        }

        const requestArgs: BaseOfPurchaseCostIndexableRequestArgs = {
            purchaseProvisioningId: purchaseProvisioningId,
            purchaseProvisioningDetailId: purchaseProvisioningDetailId,
            baseOfPurchaseCostIndexableWithGasoilIndex: baseOfPurchaseCostIndexable,
            shouldRemoveOverridenGasoilIndex: shouldRemoveOverridenGasoilIndex
        };
        PurchaseProvisioningsApiClient.UpdateBaseOfPurchaseCostIndexableWithGasoilIndex(requestArgs)
            .then(res => {
                const errors = BusinessErrors.Get(res.data);

                if (errors.length > 0) {
                    searchPurchaseProvisioningDetails(purchaseProvisioningId);
                    ToastService.showErrorToast("Une ou plusieurs erreur(s) rencontrée(s) lors de la mise à jour du coût à indexer", errors);
                }
                else {
                    PurchaseProvisioningsApiClient.SearchPurchaseProvisioningById(purchaseProvisioningId)
                        .then(res => {
                            if (selectedTab === 0) {
                                const purchaseProvisioningsToIntegrateArray = refreshPurchaseProvisioningData(purchaseProvisioningsToIntegrate, purchaseProvisioningId, res.data);
                                setPurchaseProvisioningsToIntegrate(purchaseProvisioningsToIntegrateArray);

                                const filteredPurchaseProvisioningsToIntegrateArray = refreshPurchaseProvisioningData(filteredPurchaseProvisioningsToIntegrate, purchaseProvisioningId, res.data);
                                setFilteredPurchaseProvisioningsToIntegrate(filteredPurchaseProvisioningsToIntegrateArray);
                            }
                            else {
                                const purchaseProvisioningsIntegratedArray = refreshPurchaseProvisioningData(integratedProvisionings, purchaseProvisioningId, res.data);
                                setIntegratedProvisionings(purchaseProvisioningsIntegratedArray);

                                const filteredPurchaseProvisioningsIntegratedArray = refreshPurchaseProvisioningData(filteredIntegratedProvisionings, purchaseProvisioningId, res.data);
                                setFilteredIntegratedProvisionings(filteredPurchaseProvisioningsIntegratedArray);
                            }
                        });
                }
            })
            .catch(e => console.log(e))
            .finally(() => setShowConfirmationClearOverridenGasoilIndexModal({
                openModal: false,
                baseOfPurchaseCostIndexable: null,
                purchaseProvisioningDetailId: 0
            }));
    }

    const refreshPurchaseProvisioningData = (purchasedProvisioningsArray: Array<PurchaseProvisioningLightModelExtended>, purchaseProvisioningId: string, newPurchaseProvisioning: PurchaseProvisioningLightModel) => {
        const newPurchaseProvisioningsArray = [...purchasedProvisioningsArray];
        let purchaseProvisioning = newPurchaseProvisioningsArray.find(x => x.purchaseProvisioningId === purchaseProvisioningId);
        if (purchaseProvisioning) {
            purchaseProvisioning = Object.assign(purchaseProvisioning, newPurchaseProvisioning);
            purchaseProvisioning.overridenGasoilIndexToUpdate = purchaseProvisioning.overridenGasoilIndexCost;
            purchaseProvisioning.currentGasoilIndex = purchaseProvisioning.overridenGasoilIndexCost !== null ? purchaseProvisioning.overridenGasoilIndexCost : purchaseProvisioning.gasoilIndexCost;
        }
        return newPurchaseProvisioningsArray;
    }

    const onChangePublishedCostsSearchOption = (filtersSelected: Array<PublishedCostsSearchOption>): void => {
        //vérifier s'il y'a eu du changement sur la liste des filtres
        const difference: Array<PublishedCostsSearchOption> = filtersPublishedCostsSelected
            .filter(x => !filtersSelected.includes(x))
            .concat(filtersSelected.filter(x => !filtersPublishedCostsSelected.includes(x)));

        if (difference.length > 0) {
            setFiltersPublishedCostsSelected(filtersSelected);
        }
    }

    const handleChangeFeesEnabled = (event: SwitchChangeEvent, planningVehicleId: string): void => {
        const dataArray = [...planningVehicles];

        const currentItem = dataArray.find(x => x.planningVehicleId == planningVehicleId);
        currentItem.feesEnabled = event.target.value;

        setPlanningVehiclesSelected(dataArray.filter(x => x.selected));
        setPlanningVehicles(dataArray);
        setFilteredPlanningVehicles(getFilteredPlanningVehicles(showOnlyCostAndFeesWithoutProvisioning, showOnlyProvisioningsWithDifferences, dataArray));
    };

    const getFilteredPlanningVehicles = (onlyCostAndFeesWithoutProvisioning: boolean, onlyProvisioningsWithDifferences, planningVehiclesArray: Array<PlanningVehicleLightModelExtended>): Array<PlanningVehicleLightModelExtended> => {
        if (onlyCostAndFeesWithoutProvisioning) {
            planningVehiclesArray = planningVehiclesArray.filter(p => (!p.costPurchaseProvisioningBusinessId && p.purchaseCost) || (!p.feesPurchaseProvisioningBusinessId && p.fees));
        }

        if (onlyProvisioningsWithDifferences) {
            planningVehiclesArray = planningVehiclesArray.filter(p => p.hasDiffFromPurchaseProvisioningCost || p.hasDiffFromPurchaseProvisioningFees);
        }

        return planningVehiclesArray;
    }

    const handleChangePurchaseCostsEnabled = (event: SwitchChangeEvent, planningVehicleId: string): void => {
        const dataArray = [...planningVehicles];

        const currentItem = dataArray.find(x => x.planningVehicleId == planningVehicleId);
        currentItem.purchaseCostEnabled = event.target.value;

        setPlanningVehiclesSelected(dataArray.filter(x => x.selected));
        setPlanningVehicles(dataArray);
        setFilteredPlanningVehicles(getFilteredPlanningVehicles(showOnlyCostAndFeesWithoutProvisioning, showOnlyProvisioningsWithDifferences, dataArray));

    };

    const handleChangeHeaderFeesEnabled = (checked: boolean): void => {
        const dataArray = [...planningVehicles];

        dataArray.filter(x => x.selected).map((dataItem: PlanningVehicleLightModelExtended) => {
            dataItem.feesEnabled = (dataItem.fees && !dataItem.feesPurchaseProvisioningBusinessId) ? checked : false;
        });

        setPlanningVehiclesSelected(dataArray.filter(x => x.selected));
        setPlanningVehicles(dataArray);
        setFilteredPlanningVehicles(getFilteredPlanningVehicles(showOnlyCostAndFeesWithoutProvisioning, showOnlyProvisioningsWithDifferences, dataArray));
    }

    const handleChangeHeaderPurchaseCostsEnabled = (checked: boolean): void => {
        const dataArray = [...planningVehicles];

        dataArray.filter(x => x.selected).map((dataItem: PlanningVehicleLightModelExtended) => {
            dataItem.purchaseCostEnabled = (dataItem.purchaseCost && !dataItem.costPurchaseProvisioningBusinessId) ? checked : false;
        });

        setPlanningVehiclesSelected(dataArray.filter(x => x.selected));
        setPlanningVehicles(dataArray);
        setFilteredPlanningVehicles(getFilteredPlanningVehicles(showOnlyCostAndFeesWithoutProvisioning, showOnlyProvisioningsWithDifferences, dataArray));
    }

    const translatePlanningVehicle = (dataItem: PlanningVehicleLightModelExtended): void => {
        dataItem.statusOrderValue = PlanningVehicleStatusComponent.getOrderValue(dataItem.isCanceled, dataItem.cancellationOrderSentDate, dataItem.cancellationOrderConfirmationDate, dataItem.orderConfirmationDate, dataItem.transporterOrderId, dataItem.isInternal);
        dataItem.publishedCostsStatus = dataItem.costsArePublished === false
            ?
            PublishedCostsStatus.None
            : (dataItem.costsAreAgreed === false ?
                PublishedCostsStatus.Disagreed
                : dataItem.costsAreAgreed === true ?
                    PublishedCostsStatus.Agreed
                    : PublishedCostsStatus.Pending);

        dataItem.totalCosts = dataItem.purchaseCost + dataItem.fees;
        dataItem.selected = false;
        dataItem.isEnabled = dataItem.fees != 0 || dataItem.purchaseCost != 0 || dataItem.costPurchaseProvisioningSentSuccessfully || dataItem.feesPurchaseProvisioningSentSuccessfully;

        if (dataItem.costPurchaseProvisioningBusinessId
            && ((dataItem.purchaseCost && dataItem.totalProvisionedCost && dataItem.purchaseCost.toFixed(2) != dataItem.totalProvisionedCost.toFixed(2))
                || (dataItem.purchaseCost && !dataItem.totalProvisionedCost)
                || (!dataItem.purchaseCost && dataItem.totalProvisionedCost))) {
            dataItem.hasDiffFromPurchaseProvisioningCost = true;
            dataItem.costDifference = dataItem.purchaseCost - (dataItem.totalProvisionedCost ?? 0);
        }
        else
            dataItem.hasDiffFromPurchaseProvisioningCost = false;

        if (dataItem.feesPurchaseProvisioningBusinessId
            && ((dataItem.fees && dataItem.totalProvisionedFees && dataItem.fees.toFixed(2) != dataItem.totalProvisionedFees.toFixed(2))
                || (dataItem.fees && !dataItem.totalProvisionedFees)
                || (!dataItem.fees && dataItem.totalProvisionedFees))) {
            dataItem.hasDiffFromPurchaseProvisioningFees = true;
            dataItem.feesDifference = dataItem.fees - (dataItem.totalProvisionedFees ?? 0);
        }
        else
            dataItem.hasDiffFromPurchaseProvisioningFees = false;
    }

    const translatePurchaseProvisioning = (dataItem: PurchaseProvisioningLightModelExtended, filteredPlanningVehicles: PlanningVehicleLightModelExtended[]): void => {
        dataItem.diffFromPurchaseProvisioningCount = 0;
        const planningVehiclesIds = filteredPlanningVehicles.filter(x => x.costPurchaseProvisioningBusinessId === dataItem.businessId || x.feesPurchaseProvisioningBusinessId === dataItem.businessId).map(x => x.planningVehicleId);
        planningVehiclesIds.forEach((planningVehicleId: string) => {
            const planningVehicle = filteredPlanningVehicles.find(x => x.planningVehicleId === planningVehicleId);
            if ((planningVehicle?.hasDiffFromPurchaseProvisioningCost && dataItem.purchaseCost) || (planningVehicle?.hasDiffFromPurchaseProvisioningFees && dataItem.fees)) {
                dataItem.diffFromPurchaseProvisioningCount += 1;
            }
        });

        dataItem.remarksToUpdate = dataItem.remarks ?? "";
        dataItem.overridenGasoilIndexToUpdate = dataItem.overridenGasoilIndexCost;
        dataItem.totalCosts = (dataItem.purchaseCost ?? 0) + (dataItem.fees ?? 0);
        dataItem.currentGasoilIndex = dataItem.overridenGasoilIndexCost !== null ? dataItem.overridenGasoilIndexCost : dataItem.gasoilIndexCost;
    }

    const handleSelectTab = (e: TabStripSelectEventArguments) => {
        if (selectedTab != e.selected) {
            if (selectedPurchaseProvisioning) {
                handleDeselectPurchaseProvisioning();
            }
            refreshPurchaseProvisioning();
            setSelectedTab(e.selected);
        }
    }

    const handleDeselectPurchaseProvisioning = (): void => {
        const toIntegrate = selectedTab == 0 ? true : false;
        const dataArray = toIntegrate ? [...filteredPurchaseProvisioningsToIntegrate] : [...filteredIntegratedProvisionings];
        const currentSelectedPurchaseProvisioning = dataArray.find(x => x.businessId == selectedPurchaseProvisioning);
        if (currentSelectedPurchaseProvisioning !== undefined && currentSelectedPurchaseProvisioning.selected) {
            currentSelectedPurchaseProvisioning.selected = false;
            if (toIntegrate)
                setFilteredPurchaseProvisioningsToIntegrate(dataArray);
            else
                setFilteredIntegratedProvisionings(dataArray);
        }

        setSelectedPurchaseProvisioning(null);
    }

    const handleSelectedPurchaseProvisioning = (purchaseProvisioningId: string, selected: boolean, toIntegrate: boolean): void => {
        const dataArray = toIntegrate ? [...filteredPurchaseProvisioningsToIntegrate] : [...filteredIntegratedProvisionings];

        const currentPurchaseProvisioning = dataArray.find(x => x.purchaseProvisioningId === purchaseProvisioningId);
        if (currentPurchaseProvisioning !== undefined) {
            if (currentPurchaseProvisioning.selected) {
                currentPurchaseProvisioning.selected = false;
                setSelectedPurchaseProvisioning(null);
            }
            else {
                const purchaseProvisioningSelected = dataArray.find(x => x.selected === true);
                if (purchaseProvisioningSelected !== undefined) {
                    purchaseProvisioningSelected.selected = false;
                    setSelectedPurchaseProvisioning(null);
                }
                currentPurchaseProvisioning.selected = selected;
                if (selected) {
                    setSelectedPurchaseProvisioning(currentPurchaseProvisioning.businessId);
                    searchPurchaseProvisioningDetails(currentPurchaseProvisioning.purchaseProvisioningId);
                }
            }
        }
        else {
            setSelectedPurchaseProvisioning(null);
        }

        if (toIntegrate)
            setFilteredPurchaseProvisioningsToIntegrate(dataArray);
        else
            setFilteredIntegratedProvisionings(dataArray);
    }

    const handleEditGasoilIndex = (purchaseProvisioningId: string, toEdit = true): void => {
        const dataArray = [...filteredPurchaseProvisioningsToIntegrate];
        const currentPurchaseProvisioning = dataArray.find(x => x.purchaseProvisioningId === purchaseProvisioningId);
        if (currentPurchaseProvisioning !== undefined) {
            currentPurchaseProvisioning.editGasoilIndex = toEdit;
        }

        setFilteredPurchaseProvisioningsToIntegrate(dataArray);
    }

    const handleEditRemarks = (purchaseProvisioningId: string, toIntegrated: boolean, toEdit = true): void => {
        const dataArray = toIntegrated ? [...filteredPurchaseProvisioningsToIntegrate] : [...filteredIntegratedProvisionings];
        const currentPurchaseProvisioning = dataArray.find(x => x.purchaseProvisioningId === purchaseProvisioningId);
        if (currentPurchaseProvisioning !== undefined) {
            currentPurchaseProvisioning.editRemarks = toEdit;
        }

        if (toIntegrated)
            setFilteredPurchaseProvisioningsToIntegrate(dataArray);
        else
            setFilteredIntegratedProvisionings(dataArray);
    }

    const handleChangeGasoilIndex = (purchaseProvisioningId: string, overridenGasoilIndexToUpdate?: number): void => {
        overridenGasoilIndexToUpdate = overridenGasoilIndexToUpdate === null ? 0 : overridenGasoilIndexToUpdate;

        const purchaseProvisioningsToIntegrateArray = changePurchaseProvisioningGasoilIndex([...purchaseProvisioningsToIntegrate], purchaseProvisioningId, overridenGasoilIndexToUpdate);
        setPurchaseProvisioningsToIntegrate(purchaseProvisioningsToIntegrateArray);

        const filteredPurchaseProvisioningsToIntegrateArray = changePurchaseProvisioningGasoilIndex([...filteredPurchaseProvisioningsToIntegrate], purchaseProvisioningId, overridenGasoilIndexToUpdate);
        setFilteredPurchaseProvisioningsToIntegrate(filteredPurchaseProvisioningsToIntegrateArray);
    }

    const changePurchaseProvisioningGasoilIndex = (dataArray: PurchaseProvisioningLightModelExtended[], purchaseProvisioningId: string, overridenGasoilIndexToUpdate?: number): PurchaseProvisioningLightModelExtended[] => {
        const currentPurchaseProvisioning = dataArray.find(x => x.purchaseProvisioningId === purchaseProvisioningId);

        if (currentPurchaseProvisioning !== undefined) {
            currentPurchaseProvisioning.overridenGasoilIndexToUpdate = overridenGasoilIndexToUpdate;
        }

        return dataArray;
    }

    const handleChangeRemarks = (purchaseProvisioningId: string, remarksToUpdate: string, toIntegrated: boolean): void => {
        if (toIntegrated) {
            const purchaseProvisioningsToIntegrateArray = changePurchaseProvisioningRemarks([...purchaseProvisioningsToIntegrate], purchaseProvisioningId, remarksToUpdate);
            setPurchaseProvisioningsToIntegrate(purchaseProvisioningsToIntegrateArray);

            const filteredPurchaseProvisioningsToIntegrateArray = changePurchaseProvisioningRemarks([...filteredPurchaseProvisioningsToIntegrate], purchaseProvisioningId, remarksToUpdate);
            setFilteredPurchaseProvisioningsToIntegrate(filteredPurchaseProvisioningsToIntegrateArray);
        }
        else {
            const integratedProvisioningsArray = changePurchaseProvisioningRemarks([...integratedProvisionings], purchaseProvisioningId, remarksToUpdate);
            setIntegratedProvisionings(integratedProvisioningsArray);

            const filteredIntegratedProvisioningsArray = changePurchaseProvisioningRemarks([...filteredIntegratedProvisionings], purchaseProvisioningId, remarksToUpdate);
            setFilteredIntegratedProvisionings(filteredIntegratedProvisioningsArray);
        }
    }

    const changePurchaseProvisioningRemarks = (dataArray: PurchaseProvisioningLightModelExtended[], purchaseProvisioningId: string, remarksToUpdate: string): PurchaseProvisioningLightModelExtended[] => {
        const currentPurchaseProvisioning = dataArray.find(x => x.purchaseProvisioningId === purchaseProvisioningId);

        if (currentPurchaseProvisioning !== undefined && currentPurchaseProvisioning.remarksToUpdate !== remarksToUpdate) {
            currentPurchaseProvisioning.remarksToUpdate = remarksToUpdate;
        }

        return dataArray;
    }

    const onVerticalLayoutChange = (event: SplitterOnChangeEvent): void => {
        setVerticalPanes(event.newState);
    }

    const handleGenerateRegularizations = (): void => {
        const regularizationsLines = planningVehiclesSelected.filter(p =>
            (!p.isInternal
                && ((p.costPurchaseProvisioningBusinessId && p.costPurchaseProvisioningSentSuccessfully)
                    || (p.feesPurchaseProvisioningBusinessId && p.feesPurchaseProvisioningSentSuccessfully)))
            || (p.isInternal && (p.costPurchaseProvisioningBusinessId || p.feesPurchaseProvisioningBusinessId)));

        const regularizations: Array<RegularizationPurchaseProvisioningExtended> = [];

        const planningVehiclesSorted = regularizationsLines.sort((p1, p2) => p1.transporterName.localeCompare(p2.transporterName));
        const planningVehiclesGroupedByTransporter = Utilities.groupBy(planningVehiclesSorted, "transporterId");

        planningVehiclesGroupedByTransporter?.forEach(vehicles => {
            const details: Array<RegularizationPurchaseProvisioningDetailExtended> = [];
            const vehiclesSorted = vehicles.sort((v1, v2) => (v1.planningId - v2.planningId) || v1.expectedVehicleTypeLabel.localeCompare(v2.expectedVehicleTypeLabel));

            vehiclesSorted.forEach((v: PlanningVehicleLightModelExtended) => {
                const purchaseCostCanBeRegularized = v.costPurchaseProvisioningBusinessId && (v.isInternal || (!v.isInternal && v.costPurchaseProvisioningSentSuccessfully));
                const feesCanBeRegularized = v.feesPurchaseProvisioningBusinessId && (v.isInternal || (!v.isInternal && v.feesPurchaseProvisioningSentSuccessfully));

                details.push({
                    planningVehicleNumber: v.planningVehicleNumber,
                    planningDate: v.planningDate,
                    planningDate_AsNumeric: v.planningId,
                    vehicleId: v.vehicleId,
                    expectedVehicleTypeGroupId: v.expectedVehicleTypeGroupId,
                    vehicleEquipmentId: v.vehicleEquipmentId,
                    isNightWork: v.isNightWork,
                    transporterOrderId: v.transporterOrderId,
                    transporterOrderReference: v.transporterOrderReference,
                    orderConfirmationDate: v.orderConfirmationDate,
                    orderSentDate: v.orderSentDate,
                    costsArePublished: v.costsArePublished,
                    costsDisagreementReason: v.costsDisagreementReason,
                    costsRemarksOfLogistician: v.costsRemarksOfLogistician,
                    vehicleLicencePlate: v.vehicleLicencePlate,
                    costsAreAgreed: v.costsAreAgreed,
                    planningVehicleId: v.planningVehicleId,
                    expectedVehicleTypeId: v.expectedVehicleTypeId,
                    expectedVehicleTypeLabel: v.expectedVehicleTypeLabel,
                    gasoilIndexCost: (v.costDifference && purchaseCostCanBeRegularized) ? (v.costDifference * (v.applicableGasoilIndexValue ?? 0)) : null,
                    baseOfPurchaseCostIndexableWithGasoilIndex: purchaseCostCanBeRegularized ? v.costDifference : null,
                    applicableGasoilIndexValue: v.applicableGasoilIndexValue,
                    purchaseCost: purchaseCostCanBeRegularized ? v.costDifference : null,
                    fees: feesCanBeRegularized ? v.feesDifference : null,
                    purchaseCostCanBeRegularized: purchaseCostCanBeRegularized,
                    feesCanBeRegularized: feesCanBeRegularized,
                    planningVehicleRemarks: v.planningVehicleRemarks,
                    regularizationRemarks: '',
                    contractualPurchasePriceId: v.contractualPurchasePriceId,
                    contractualPurchasePriceContractNumber: v.contractualPurchasePriceContractNumber
                })
            });

            const sumPurchaseCost = Utilities.sum(details, 'purchaseCost');
            const sumFees = Utilities.sum(details, 'fees');
            const sumGasoilIndexCost = Utilities.sum(details.filter(x => x.purchaseCost), 'gasoilIndexCost');

            regularizations.push({
                transporterId: vehicles[0].transporterId,
                transporterName: vehicles[0].transporterName,
                buyerLogisticsAgencyId: vehicles[0].buyerLogisticsAgencyId,
                buyerLogisticsBusinessUnitId: vehicles[0].buyerLogisticsBusinessUnitId,
                supplierMdmIdentifier: vehicles[0].supplierMdmIdentifier,
                buyerLogisticsUnitId: selectedLogisticsUnit?.value,
                purchaseCost: sumPurchaseCost,
                fees: sumFees,
                gasoilIndexCost: sumGasoilIndexCost,
                total: (sumPurchaseCost ?? 0) + (sumFees ?? 0) + (sumGasoilIndexCost ?? 0),
                details: details
            });
        })

        setRegularizations(regularizations);

        setSimpleDialog({
            isDialogOpened: true,
            dialogFirstPartTitle: 'Régularisation',
            dialogLastPartTitle: `Lot(s) à créer: ${regularizations.length}`,
            popupContentComponent: <RegularizationTemplateComponent selectedLogisticsUnitId={selectedLogisticsUnit?.value} purchaseProvisioningMaxAllowedCostLimit={purchaseProvisioningMaxAllowedCostLimit} regularizations={regularizations}
                handleRefreshRegularizations={handleRefreshRegularizations} />
        });
    }

    const handleClickCloseDialog = (reason?: string) => {
        if (reason !== "backdropClick") {
            setSimpleDialog({
                isDialogOpened: false,
                dialogLastPartTitle: '',
                dialogFirstPartTitle: '',
                popupContentComponent: <></>
            });
        }
    }

    const handleRefreshRegularizations = (newRegularizations: Array<RegularizationPurchaseProvisioningExtended>): void => {
        setRegularizations(newRegularizations);
    }

    //#endregion

    //#region Call Api services
    const searchPlanningVehiclesWithTransporters = (date: DateRange, searchText: string, publishedCostsSearchOption: Array<PublishedCostsSearchOption>, logisticsUnitId: string): void => {
        const fromTime = date.start;
        const untilTime = date.end;
        if (logisticsUnitId !== '') {
            const currentTimeStamp = Date.now();
            setLoadingPlanningVehicles(true);
            setSelectedPurchaseProvisioning(null);

            PurchaseProvisioningsApiClient.SearchPlanningVehiclesWithTransporters(fromTime, untilTime, searchText, publishedCostsSearchOption, selectedTransporters, logisticsUnitId)
                .then(res => {
                    if (currentTimeStamp < lastTimeStampGetPlanningVehiclesWithTransporters)
                        return;

                    if (res.length > 0) {
                        const transporters: Array<TransporterLightModel> = res[0].data.pageItems;
                        const vehicleProvisioningsArray: Array<PlanningVehicleLightModelExtended> = res[1].data as Array<PlanningVehicleLightModelExtended>;

                        vehicleProvisioningsArray.map((dataItem: PlanningVehicleLightModelExtended) => {
                            translatePlanningVehicle(dataItem);
                        });

                        const filteredPlanningVehiclesArray = getFilteredPlanningVehicles(showOnlyCostAndFeesWithoutProvisioning, showOnlyProvisioningsWithDifferences, vehicleProvisioningsArray);
                        setPlanningVehicles(vehicleProvisioningsArray);
                        setFilteredPlanningVehicles(filteredPlanningVehiclesArray);
                        setPurchaseProvisionningCosts(buildPurchaseProvisionningCosts(filteredPlanningVehiclesArray));
                        setTransporters(transporters);
                        setPlanningVehiclesSelected([]);
                        setIsHeaderBtnPreInvoicesEnabled(false);
                    }
                })
                .finally(() => {
                    setLoadingPlanningVehicles(false);
                    setLastTimeStampGetPlanningVehiclesWithTransporters(currentTimeStamp);
                });
        }
    }

    const searchPlanningVehiclesTripWithTransportersAndProvisionings = (date: DateRange, searchText: string, publishedCostsSearchOption: Array<PublishedCostsSearchOption>, logisticsUnitId: string, selectedTransportersArray: string[]): void => {
        const fromTime = date.start;
        const untilTime = date.end;
        if (logisticsUnitId !== '') {
            const currentTimeStamp = Date.now();
            setLoadingPlanningVehicles(true);
            setLoadingPurchaseProvisionings(true);
            setPlanningVehiclesSelected([]);
            setPurchaseProvisioningVehicles([]);
            setSelectedPurchaseProvisioning(null);

            PurchaseProvisioningsApiClient.SearchPlanningVehiclesWithTransportersAndProvisionings(fromTime, untilTime, searchText, searchTextIntegratedProvisionings, searchTextProvisioningsToIntegrate, publishedCostsSearchOption, selectedTransportersArray, logisticsUnitId)
                .then(res => {
                    if (currentTimeStamp < lastTimeStampGetPlanningVehiclesWithTransporters)
                        return;

                    if (res.length > 0) {
                        const transporters: Array<TransporterLightModel> = res[0].data.pageItems;
                        const vehicleProvisioningsArray: Array<PlanningVehicleLightModelExtended> = res[1].data as Array<PlanningVehicleLightModelExtended>;
                        const purchaseProvisioningsToIntegrateArray: Array<PurchaseProvisioningLightModelExtended> = res[2].data as Array<PurchaseProvisioningLightModelExtended>;
                        const integratedProvisioningsArray: Array<PurchaseProvisioningLightModelExtended> = res[3].data as Array<PurchaseProvisioningLightModelExtended>;

                        vehicleProvisioningsArray.map((dataItem: PlanningVehicleLightModelExtended) => {
                            translatePlanningVehicle(dataItem);
                        });

                        const filteredPlanningVehiclesArray = getFilteredPlanningVehicles(showOnlyCostAndFeesWithoutProvisioning, showOnlyProvisioningsWithDifferences, vehicleProvisioningsArray);
                        setPlanningVehicles(vehicleProvisioningsArray);
                        setFilteredPlanningVehicles(filteredPlanningVehiclesArray);
                        setPurchaseProvisionningCosts(buildPurchaseProvisionningCosts(filteredPlanningVehiclesArray));
                        setTransporters(transporters);

                        purchaseProvisioningsToIntegrateArray.map((dataItem: PurchaseProvisioningLightModelExtended) => {
                            translatePurchaseProvisioning(dataItem, filteredPlanningVehiclesArray);
                        });

                        integratedProvisioningsArray.map((dataItem: PurchaseProvisioningLightModelExtended) => {
                            translatePurchaseProvisioning(dataItem, filteredPlanningVehiclesArray);
                        });

                        setPurchaseProvisioningsToIntegrate(purchaseProvisioningsToIntegrateArray);
                        setIntegratedProvisionings(integratedProvisioningsArray);
                        setIsHeaderBtnPreInvoicesEnabled(false);
                        setFilteredProvisionings(filteredPlanningVehiclesArray, purchaseProvisioningsToIntegrateArray, integratedProvisioningsArray, showOnlyProvisioningsWithDifferences);
                    }
                })
                .finally(() => {
                    setLoadingPlanningVehicles(false);
                    setLoadingPurchaseProvisionings(false);
                    setLastTimeStampGetPlanningVehiclesWithTransporters(currentTimeStamp);
                    setLastTimeStampGetProvisionings(currentTimeStamp);
                });
        }
    }

    const getVehicleTypeGroups = () => {
        PurchaseProvisioningsApiClient.GetVehicleTypeGroups()
            .then(res => {
                const vehicleTypeGroupsArray = res.data;
                setVehicleTypeGroups(vehicleTypeGroupsArray);
            });
    }

    const searchPlanningVehiclesTripWithTransportersAndVehicleTypeGroupsAndProvisionings = (date: DateRange, searchText: string, publishedCostsSearchOption: Array<PublishedCostsSearchOption>, logisticsUnitId: string): void => {
        const fromTime = date.start;
        const untilTime = date.end;
        if (logisticsUnitId !== '') {

            const currentTimeStamp = Date.now();
            setLoadingPlanningVehicles(true);
            setLoadingPurchaseProvisionings(true);
            setPurchaseProvisioningVehicles([]);
            setSelectedPurchaseProvisioning(null);

            PurchaseProvisioningsApiClient.SearchPlanningVehiclesWithTransportersAndVehicleTypeGroupsAndProvisionings(fromTime, untilTime, searchText, searchTextIntegratedProvisionings, searchTextProvisioningsToIntegrate, publishedCostsSearchOption, selectedTransporters, logisticsUnitId)
                .then(res => {
                    if (currentTimeStamp < lastTimeStampGetPlanningVehiclesWithTransporters)
                        return;

                    if (res.length > 0) {
                        const transporters: Array<TransporterLightModel> = res[0].data.pageItems;
                        const vehicleProvisioningsArray: Array<PlanningVehicleLightModelExtended> = res[1].data as Array<PlanningVehicleLightModelExtended>;
                        const vehicleTypeGroupsArray: Array<string> = res[2].data;
                        const purchaseProvisioningsToIntegrateArray: Array<PurchaseProvisioningLightModelExtended> = res[3].data as Array<PurchaseProvisioningLightModelExtended>;
                        const integratedProvisioningsArray: Array<PurchaseProvisioningLightModelExtended> = res[4].data as Array<PurchaseProvisioningLightModelExtended>;

                        setVehicleTypeGroups(vehicleTypeGroupsArray);

                        vehicleProvisioningsArray.map((dataItem: PlanningVehicleLightModelExtended) => {
                            translatePlanningVehicle(dataItem);
                        });

                        const filteredPlanningVehiclesArray = getFilteredPlanningVehicles(showOnlyCostAndFeesWithoutProvisioning, showOnlyProvisioningsWithDifferences, vehicleProvisioningsArray);
                        setPlanningVehicles(vehicleProvisioningsArray);
                        setFilteredPlanningVehicles(filteredPlanningVehiclesArray);
                        setPurchaseProvisionningCosts(buildPurchaseProvisionningCosts(filteredPlanningVehiclesArray));
                        setTransporters(transporters);

                        purchaseProvisioningsToIntegrateArray.map((dataItem: PurchaseProvisioningLightModelExtended) => {
                            translatePurchaseProvisioning(dataItem, filteredPlanningVehiclesArray);
                        });

                        integratedProvisioningsArray.map((dataItem: PurchaseProvisioningLightModelExtended) => {
                            translatePurchaseProvisioning(dataItem, filteredPlanningVehiclesArray);
                        });

                        setPurchaseProvisioningsToIntegrate(purchaseProvisioningsToIntegrateArray);
                        setIntegratedProvisionings(integratedProvisioningsArray);
                        setPlanningVehiclesSelected([]);
                        setIsHeaderBtnPreInvoicesEnabled(false);
                        setFilteredProvisionings(filteredPlanningVehiclesArray, purchaseProvisioningsToIntegrateArray, integratedProvisioningsArray, showOnlyProvisioningsWithDifferences);
                    }
                })
                .finally(() => {
                    setLoadingPlanningVehicles(false);
                    setLoadingPurchaseProvisionings(false);
                    setLastTimeStampGetPlanningVehiclesWithTransporters(currentTimeStamp);
                    setLastTimeStampGetProvisionings(currentTimeStamp);
                });
        }
    }

    const searchPlanningVehicles = (date: DateRange, selectedTransporters: Array<string>, logisticsUnitId: string, searchText: string, publishedCostsSearchOption: Array<PublishedCostsSearchOption>, needSelectedAll?: boolean): void => {
        const fromTime = date.start;
        const untilTime = date.end;

        if (logisticsUnitId !== '') {
            setLoadingPlanningVehicles(true);
            setPlanningVehiclesSelected([]);

            const currentTimeStamp = Date.now();

            PurchaseProvisioningsApiClient.SearchPlanningVehicles(fromTime, untilTime, searchText, publishedCostsSearchOption, selectedTransporters, logisticsUnitId)
                .then(res => {
                    if (currentTimeStamp < lastTimeStampGetPlanningVehicles)
                        return;

                    const vehicleProvisioningsArray: Array<PlanningVehicleLightModelExtended> = res.data as Array<PlanningVehicleLightModelExtended>;

                    vehicleProvisioningsArray.map((dataItem: PlanningVehicleLightModelExtended) => {
                        translatePlanningVehicle(dataItem);
                    });

                    //sélectionner tous les camions lotissables dans le cas d'une sélection d'un transporteur
                    if (needSelectedAll && selectedTransporters.length >= 1) {
                        headerSelectAllChanged(vehicleProvisioningsArray, true, true);
                    }

                    if (currentTimeStamp < lastTimeStampGetPlanningVehicles)
                        return;

                    const filteredPlanningVehiclesArray = getFilteredPlanningVehicles(showOnlyCostAndFeesWithoutProvisioning, showOnlyProvisioningsWithDifferences, vehicleProvisioningsArray);
                    setPlanningVehicles(vehicleProvisioningsArray);
                    setFilteredPlanningVehicles(filteredPlanningVehiclesArray);
                    setPurchaseProvisionningCosts(buildPurchaseProvisionningCosts(filteredPlanningVehiclesArray));
                    setIsHeaderBtnPreInvoicesEnabled(false);
                })
                .finally(() => {
                    setLoadingPlanningVehicles(false);
                    setLastTimeStampGetPlanningVehicles(currentTimeStamp);
                    if (selectedPurchaseProvisioning) {
                        handleDeselectPurchaseProvisioning();
                    }
                });
        }
    }

    const buildPurchaseProvisionningCosts = (planningVehicles: Array<PlanningVehicleLightModelExtended>): PurchaseProvisionningCosts => {
        let integratedCostsWithProvisioning = 0;
        let integratedFeesWithProvisioning = 0;
        let costsToIntegrateWithProvisioning = 0;
        let feesToIntegrateWithProvisioning = 0;
        let costsToIntegrateWithoutProvisioning = 0;
        let feesToIntegrateWithoutProvisioning = 0;

        for (let i = 0; i < planningVehicles.length; i++) {
            const pv = planningVehicles[i];

            if (pv.costPurchaseProvisioningBusinessId) {
                integratedCostsWithProvisioning += pv.integratedProvisionedCost;
                costsToIntegrateWithProvisioning += pv.provisionedCostToIntegrate;
            }
            else {
                costsToIntegrateWithoutProvisioning += pv.purchaseCost;
            }

            if (pv.feesPurchaseProvisioningBusinessId) {
                integratedFeesWithProvisioning += pv.integratedProvisionedFees;
                feesToIntegrateWithProvisioning += pv.provisionedFeesToIntegrate;
            }
            else {
                feesToIntegrateWithoutProvisioning += pv.fees;
            }
        }

        return {
            costsToIntegrateWithProvisioning: costsToIntegrateWithProvisioning,
            feesToIntegrateWithProvisioning: feesToIntegrateWithProvisioning,
            costsToIntegrateWithoutProvisioning: costsToIntegrateWithoutProvisioning,
            feesToIntegrateWithoutProvisioning: feesToIntegrateWithoutProvisioning,
            integratedCostsWithProvisioning: integratedCostsWithProvisioning,
            integratedFeesWithProvisioning: integratedFeesWithProvisioning
        };
    }

    const searchPurchaseProvisioningDetails = (purchaseProvisioningId: string): void => {
        setLoadingPurchaseProvisioningVehicles(true);
        setPurchaseProvisioningVehicles([]);
        const currentTimeStamp = Date.now();

        PurchaseProvisioningsApiClient.SearchPurchaseProvisioningDetails(purchaseProvisioningId)
            .then(res => {
                if (currentTimeStamp < lastTimeStampGetPurchaseProvisioningVehicles)
                    return;

                const vehiclesDataArray = res.data as PurchaseProvisioningDetailLightModelExtended[];

                vehiclesDataArray.forEach((dataItem: PurchaseProvisioningDetailLightModelExtended) => {
                    dataItem.publishedCostsStatus = dataItem.costsArePublished === false
                        ?
                        PublishedCostsStatus.None
                        : (dataItem.costsAreAgreed === false ?
                            PublishedCostsStatus.Disagreed
                            : dataItem.costsAreAgreed === true ?
                                PublishedCostsStatus.Agreed
                                : PublishedCostsStatus.Pending);

                    if ((dataItem.actualPurchaseCost && dataItem.provisionedPurchaseCost && dataItem.actualPurchaseCost.toFixed(2) != dataItem.provisionedPurchaseCost.toFixed(2)))
                        dataItem.hasDiffFromPurchaseProvisioningCost = true;
                    else
                        dataItem.hasDiffFromPurchaseProvisioningCost = false;

                    if ((dataItem.actualFees && dataItem.provisionedFees && dataItem.actualFees.toFixed(2) != dataItem.provisionedFees.toFixed(2)))
                        dataItem.hasDiffFromPurchaseProvisioningFees = true;
                    else
                        dataItem.hasDiffFromPurchaseProvisioningFees = false;
                });

                setPurchaseProvisioningVehicles(vehiclesDataArray);
            })
            .finally(() => {
                setLoadingPurchaseProvisioningVehicles(false);
                setLastTimeStampGetPurchaseProvisioningVehicles(currentTimeStamp);
            });
    }

    const searchPlanningVehiclesWithProvisionings = (date: DateRange, selectedTransporters: Array<string>, logisticsUnitId: string, searchText: string, searchTextIntegratedProvisionings: string, searchTextProvisioningsToIntegrate: string, showOnlyProvisioningsWithDifferences: boolean, publishedCostsSearchOption: Array<PublishedCostsSearchOption>, needSelectedAll?: boolean): void => {
        const fromTime = date.start;
        const untilTime = date.end;

        if (logisticsUnitId !== '') {
            setLoadingPlanningVehicles(true);
            setLoadingPurchaseProvisionings(true);
            setPlanningVehiclesSelected([]);
            setPurchaseProvisioningVehicles([]);
            setSelectedPurchaseProvisioning(null);

            const currentTimeStamp = Date.now();

            PurchaseProvisioningsApiClient.SearchPlanningVehiclesWithProvisionings(fromTime, untilTime, searchText, searchTextIntegratedProvisionings, searchTextProvisioningsToIntegrate, publishedCostsSearchOption, selectedTransporters, logisticsUnitId)
                .then(res => {
                    if (currentTimeStamp < lastTimeStampGetPlanningVehicles)
                        return;

                    const vehicleProvisioningsArray: Array<PlanningVehicleLightModelExtended> = res[0].data as Array<PlanningVehicleLightModelExtended>;
                    const purchaseProvisioningsToIntegrateArray: Array<PurchaseProvisioningLightModelExtended> = res[1].data as Array<PurchaseProvisioningLightModelExtended>;
                    const integratedProvisioningsArray: Array<PurchaseProvisioningLightModelExtended> = res[2].data as Array<PurchaseProvisioningLightModelExtended>;

                    vehicleProvisioningsArray.map((dataItem: PlanningVehicleLightModelExtended) => {
                        translatePlanningVehicle(dataItem);
                    });

                    //sélectionner tous les camions dans le cas d'une sélection d'un camion
                    if (needSelectedAll && selectedTransporters.length >= 1) {
                        headerSelectAllChanged(vehicleProvisioningsArray, true);
                    }

                    const filteredPlanningVehiclesArray = getFilteredPlanningVehicles(showOnlyCostAndFeesWithoutProvisioning, showOnlyProvisioningsWithDifferences, vehicleProvisioningsArray);
                    setPlanningVehicles(vehicleProvisioningsArray);
                    setFilteredPlanningVehicles(filteredPlanningVehiclesArray);
                    setPurchaseProvisionningCosts(buildPurchaseProvisionningCosts(filteredPlanningVehiclesArray));

                    purchaseProvisioningsToIntegrateArray.map((dataItem: PurchaseProvisioningLightModelExtended) => {
                        translatePurchaseProvisioning(dataItem, filteredPlanningVehiclesArray);
                    });

                    integratedProvisioningsArray.map((dataItem: PurchaseProvisioningLightModelExtended) => {
                        translatePurchaseProvisioning(dataItem, filteredPlanningVehiclesArray);
                    });

                    setPurchaseProvisioningsToIntegrate(purchaseProvisioningsToIntegrateArray);
                    setIntegratedProvisionings(integratedProvisioningsArray);

                    setIsHeaderBtnPreInvoicesEnabled(false);
                    setFilteredProvisionings(filteredPlanningVehiclesArray, purchaseProvisioningsToIntegrateArray, integratedProvisioningsArray, showOnlyProvisioningsWithDifferences);
                })
                .finally(() => {
                    setLoadingPlanningVehicles(false);
                    setLoadingPurchaseProvisionings(false);
                    setLastTimeStampGetPlanningVehicles(currentTimeStamp);
                    setLastTimeStampGetProvisionings(currentTimeStamp);
                });
        }
    }

    const searchIntegratedProvisioning = (date: DateRange, searchText: string, logisticsUnitId: string): void => {
        const fromTime = date.start;
        const untilTime = date.end;

        const currentTimeStamp = Date.now();
        setLoadingPurchaseProvisionings(true);
        PurchaseProvisioningsApiClient.SearchIntegratedProvisionings(fromTime, untilTime, searchText, logisticsUnitId)
            .then(res => {
                if (currentTimeStamp < lastTimeStampGetProvisionings)
                    return;

                const integratedProvisioningsArray: Array<PurchaseProvisioningLightModelExtended> = res.data as Array<PurchaseProvisioningLightModelExtended>;
                integratedProvisioningsArray.map((dataItem: PurchaseProvisioningLightModelExtended) => {
                    translatePurchaseProvisioning(dataItem, filteredPlanningVehicles);
                });
                setIntegratedProvisionings(integratedProvisioningsArray);
                setFilteredProvisionings(filteredPlanningVehicles, purchaseProvisioningsToIntegrate, integratedProvisioningsArray, showOnlyProvisioningsWithDifferences);
            })
            .finally(() => {
                setLoadingPurchaseProvisionings(false);
                setLastTimeStampGetProvisionings(currentTimeStamp);
            });
    }

    const searchProvisioningToIntegrate = (date: DateRange, searchText: string, logisticsUnitId: string): void => {
        const fromTime = date.start;
        const untilTime = date.end;

        const currentTimeStamp = Date.now();
        setLoadingPurchaseProvisionings(true);
        PurchaseProvisioningsApiClient.SearchPurchaseProvisioningsToIntegrate(fromTime, untilTime, searchText, logisticsUnitId)
            .then(res => {
                if (currentTimeStamp < lastTimeStampGetProvisionings)
                    return;

                const purchaseProvisioningsToIntegrateArray: Array<PurchaseProvisioningLightModelExtended> = res.data as Array<PurchaseProvisioningLightModelExtended>;
                purchaseProvisioningsToIntegrateArray.map((dataItem: PurchaseProvisioningLightModelExtended) => {
                    translatePurchaseProvisioning(dataItem, filteredPlanningVehicles);
                });
                setPurchaseProvisioningsToIntegrate(purchaseProvisioningsToIntegrateArray);
                setFilteredProvisionings(filteredPlanningVehicles, purchaseProvisioningsToIntegrateArray, integratedProvisionings, showOnlyProvisioningsWithDifferences);
            })
            .finally(() => {
                setLoadingPurchaseProvisionings(false);
                setLastTimeStampGetProvisionings(currentTimeStamp);
            });
    }

    const handleGenerateProvisioning = (transporterId: string, transporterName: string, buyerLogisticsAgencyId: string, buyerLogisticsBusinessUnitId: string,
        supplierMdmIdentifier: string, details: Array<ProvisioningGenerationDetailRequestArgs>, purchaseCost?: number, fees?: number, gasoilIndexCost?: number): void => {
        const requestArgs: ProvisioningGenerationRequestArgs = {
            buyerLogisticsUnitId: selectedLogisticsUnit?.value,
            buyerLogisticsAgencyId: buyerLogisticsAgencyId,
            buyerLogisticsBusinessUnitId: buyerLogisticsBusinessUnitId,
            supplierMdmIdentifier: supplierMdmIdentifier,
            transporterId: transporterId,
            transporterName: transporterName,
            purchaseCost: purchaseCost ? purchaseCost : null,
            fees: fees ? fees : null,
            gasoilIndexCost: gasoilIndexCost ? gasoilIndexCost : null,
            details: details
        };

        PurchaseProvisioningsApiClient.ProvisioningGeneration(requestArgs)
            .then(res => {
                const data = res?.data;

                const errors = BusinessErrors.Get(data);

                if (errors.length > 0) {
                    ToastService.showErrorToast("Erreur lors de la création du lot", errors);
                }
                else {
                    ToastService.showSuccessToast("Création du lot réalisée avec succès");
                    setSearchTextIntegratedProvisionings("");
                    setSearchTextProvisioningsToIntegrate("");

                    //Se mettre sur l'onglet "Lot à intégrer" lors de la génération du lot dans le cas où l'utilisateur est sur l'historique
                    if (selectedTab != 0)
                        setSelectedTab(0);

                    if (showOnlyProvisioningsWithDifferences)
                        setShowOnlyProvisioningsWithDifferences(false);

                    searchPlanningVehiclesWithProvisionings(date, selectedTransporters, selectedLogisticsUnit?.value, searchText, "", "", false, filtersPublishedCostsSelected);
                }
            })
            .finally(() => {
                setPlanningVehiclesSelected([]);
            });
    }

    const handleUpdateRemarks = (purchaseProvisioningId: string, toIntegrate: boolean, remarks?: string, remarksToUpdate?: string): void => {
        if (remarksToUpdate != remarks) {
            PurchaseProvisioningsApiClient.UpdatePurchaseProvisioningRemarks(purchaseProvisioningId, remarksToUpdate)
                .then(res => {
                    const errors = BusinessErrors.Get(res.data);

                    if (errors.length > 0) {
                        ToastService.showErrorToast("Une ou plusieurs erreur(s) rencontrée(s) lors de la mise à jour du commentaire", errors);
                    }
                    else {
                        if (toIntegrate) {
                            const purchaseProvisioningsToIntegrateArray = updatePurchaseProvisioningRemarks([...purchaseProvisioningsToIntegrate], purchaseProvisioningId, remarksToUpdate);
                            setPurchaseProvisioningsToIntegrate(purchaseProvisioningsToIntegrateArray);

                            const filteredPurchaseProvisioningsToIntegrateArray = updatePurchaseProvisioningRemarks([...filteredPurchaseProvisioningsToIntegrate], purchaseProvisioningId, remarksToUpdate);
                            setFilteredPurchaseProvisioningsToIntegrate(filteredPurchaseProvisioningsToIntegrateArray);
                        }
                        else {
                            const integratedProvisioningsArray = updatePurchaseProvisioningRemarks([...integratedProvisionings], purchaseProvisioningId, remarksToUpdate);
                            setIntegratedProvisionings(integratedProvisioningsArray);

                            const filteredIntegratedProvisioningsArray = updatePurchaseProvisioningRemarks([...filteredIntegratedProvisionings], purchaseProvisioningId, remarksToUpdate);
                            setFilteredIntegratedProvisionings(filteredIntegratedProvisioningsArray);
                        }
                    }
                })
                .catch(() => {
                    handleEditRemarks(purchaseProvisioningId, toIntegrate, false);
                });
        }
        else {
            handleEditRemarks(purchaseProvisioningId, toIntegrate, false);
        }
    }

    const updatePurchaseProvisioningRemarks = (dataArray: PurchaseProvisioningLightModelExtended[], purchaseProvisioningId: string, remarksToUpdate?: string): PurchaseProvisioningLightModelExtended[] => {
        const currentPurchaseProvisioning = dataArray.find(x => x.purchaseProvisioningId === purchaseProvisioningId);
        if (currentPurchaseProvisioning !== undefined) {
            currentPurchaseProvisioning.remarks = remarksToUpdate;
        }
        currentPurchaseProvisioning.editRemarks = false;

        return dataArray;
    }

    const reInitializeGasoilIndex = (purchaseProvisioningId: string): void => {
        PurchaseProvisioningsApiClient.UpdatePurchaseProvisioningOverridenGasoilIndex(purchaseProvisioningId, null)
            .then(res => {
                const errors = BusinessErrors.Get(res.data);

                if (errors.length > 0) {
                    ToastService.showErrorToast("Une ou plusieurs erreur(s) rencontrée(s) lors de la surcharge d'indice gasoil", errors);
                }
                else {
                    const warnings = BusinessWarnings.Get(res.data);
                    if (warnings.length > 0) {
                        ToastService.showWarningToast("", warnings);
                    }

                    const dataArray = [...purchaseProvisioningsToIntegrate];
                    const currentPurchaseProvisioning = dataArray.find(x => x.purchaseProvisioningId === purchaseProvisioningId);
                    currentPurchaseProvisioning.overridenGasoilIndexCost = null;
                    currentPurchaseProvisioning.overridenGasoilIndexToUpdate = null;
                    currentPurchaseProvisioning.currentGasoilIndex = currentPurchaseProvisioning.gasoilIndexCost;

                    setPurchaseProvisioningsToIntegrate(dataArray);

                    const filteredDataArray = [...filteredPurchaseProvisioningsToIntegrate];
                    const currentFilteredPurchaseProvisioning = filteredDataArray.find(x => x.purchaseProvisioningId === purchaseProvisioningId);
                    currentFilteredPurchaseProvisioning.overridenGasoilIndexCost = null;
                    currentFilteredPurchaseProvisioning.overridenGasoilIndexToUpdate = null;
                    currentFilteredPurchaseProvisioning.currentGasoilIndex = currentFilteredPurchaseProvisioning.gasoilIndexCost;

                    setFilteredPurchaseProvisioningsToIntegrate(filteredDataArray);
                }
            })
            .catch(() => {
                handleEditGasoilIndex(purchaseProvisioningId, false);
            });
    }

    //Le debounce permet de temporiser la sortie du champs gasole car le clique sur l'envoi Zephyr peut s'enclencher même s'il est grisé à l'écran 
    //dans le cas où la mise à jour du state du champs gasole passe avant l'événement du clique
    const handleUpdateOverridenGasoilIndex = debounce((purchaseProvisioningId: string, isInternal: boolean, overridenGasoilIndexCost?: number, overridenGasoilIndexCostToUpdate?: number): void => {
        const dataArray = [...purchaseProvisioningsToIntegrate];
        const currentPurchaseProvisioning = dataArray.find(x => x.purchaseProvisioningId === purchaseProvisioningId);
        const sumOfPurchaseCostAndGasoilExceedsTheMaximumAllowedLimit: boolean = (currentPurchaseProvisioning.purchaseCost + currentPurchaseProvisioning.fees + overridenGasoilIndexCostToUpdate) >= purchaseProvisioningMaxAllowedCostLimit;

        if (overridenGasoilIndexCost !== overridenGasoilIndexCostToUpdate) {
            if (isInternal || !sumOfPurchaseCostAndGasoilExceedsTheMaximumAllowedLimit) {
                PurchaseProvisioningsApiClient.UpdatePurchaseProvisioningOverridenGasoilIndex(purchaseProvisioningId, overridenGasoilIndexCostToUpdate)
                    .then(res => {
                        const errors = BusinessErrors.Get(res.data);

                        if (errors.length > 0) {
                            ToastService.showErrorToast("Une ou plusieurs erreur(s) rencontrée(s) lors de la surcharge d'indice gasoil", errors);
                        }
                        else {
                            const warnings = BusinessWarnings.Get(res.data);
                            if (warnings.length > 0) {
                                ToastService.showWarningToast("", warnings);
                            }

                            UpdatePurchaseProvisioningOverridenGasoilIndex(currentPurchaseProvisioning, overridenGasoilIndexCostToUpdate);
                            setPurchaseProvisioningsToIntegrate(dataArray);

                            const filteredDataArray = [...filteredPurchaseProvisioningsToIntegrate];
                            const currentFilteredPurchaseProvisioning = filteredDataArray.find(x => x.purchaseProvisioningId === purchaseProvisioningId);
                            UpdatePurchaseProvisioningOverridenGasoilIndex(currentFilteredPurchaseProvisioning, overridenGasoilIndexCostToUpdate);
                            setFilteredPurchaseProvisioningsToIntegrate(filteredDataArray);
                        }
                    })
                    .catch(() => {
                        handleEditGasoilIndex(purchaseProvisioningId, false);
                    });
            }
            else {
                if (currentPurchaseProvisioning !== undefined) {
                    currentPurchaseProvisioning.overridenGasoilIndexCost = overridenGasoilIndexCostToUpdate;
                }
                currentPurchaseProvisioning.editGasoilIndex = false;
                setPurchaseProvisioningsToIntegrate(dataArray);
            }
        }
        else {
            handleEditGasoilIndex(purchaseProvisioningId, false);
        }
    }, 500);

    const UpdatePurchaseProvisioningOverridenGasoilIndex = (purchaseProvisioning: PurchaseProvisioningLightModelExtended, overridenGasoilIndexCostToUpdate?: number): PurchaseProvisioningLightModelExtended => {
        purchaseProvisioning.overridenGasoilIndexCost = overridenGasoilIndexCostToUpdate;
        purchaseProvisioning.editGasoilIndex = false;
        purchaseProvisioning.currentGasoilIndex = overridenGasoilIndexCostToUpdate !== null ? overridenGasoilIndexCostToUpdate : purchaseProvisioning.gasoilIndexCost;
        return purchaseProvisioning;
    }

    const updatePurchaseProvisioningVisibilityInBI = (purchaseProvisioningId: string, toIntegrate: boolean): void => {
        PurchaseProvisioningsApiClient.UpdatePurchaseProvisioningVisibilityInBI(purchaseProvisioningId, toIntegrate)
            .then(() => {
                searchPlanningVehiclesWithProvisionings(date, selectedTransporters, selectedLogisticsUnit?.value, searchText, searchTextIntegratedProvisionings, searchTextProvisioningsToIntegrate, showOnlyProvisioningsWithDifferences, filtersPublishedCostsSelected);
            });
    }

    const sendPurchaseProvisioning = (purchaseProvisioning: PurchaseProvisioningLightModelExtended, emailSendRequested: boolean): void => {
        const requestArgs: SendPurchaseProvisioningRequestArgs = {
            purchaseProvisioningId: purchaseProvisioning.purchaseProvisioningId,
            purchaseProvisioningBusinessId: purchaseProvisioning.businessId,
            transporterId: purchaseProvisioning.transporterId,
            buyerLogisticsAgencyId: purchaseProvisioning.buyerLogisticsAgencyId,
            buyerLogisticsBusinessUnitId: purchaseProvisioning.buyerLogisticsBusinessUnitId,
            buyerLogisticsUnitId: purchaseProvisioning.buyerLogisticsUnitId,
            supplierMdmIdentifier: purchaseProvisioning.supplierMdmIdentifier,
            firstPlanningDate: purchaseProvisioning.firstPlanningDate,
            lastPlanningDate: purchaseProvisioning.lastPlanningDate,
            purchaseCost: purchaseProvisioning.purchaseCost,
            fees: purchaseProvisioning.fees,
            gasoilIndexCost: purchaseProvisioning.overridenGasoilIndexCost != null ? purchaseProvisioning.overridenGasoilIndexCost : purchaseProvisioning.gasoilIndexCost,
            emailSendRequested: emailSendRequested,
            isRegularization: purchaseProvisioning.isRegularization,
            remarks: purchaseProvisioning.remarks
        };

        setSendingPurchaseProvisioningId(purchaseProvisioning.purchaseProvisioningId);

        PurchaseProvisioningsApiClient.SendPurshaseProvisioning(requestArgs)
            .then(res => {
                const errors = BusinessErrors.Get(res.data);

                if (errors.length > 0) {
                    ToastService.showErrorToast("Une ou plusieurs erreur(s) rencontrée(s) lors de l'intégration Zephyr", errors);
                }

                searchPlanningVehiclesWithProvisionings(date, selectedTransporters, selectedLogisticsUnit?.value, searchText, searchTextIntegratedProvisionings, searchTextProvisioningsToIntegrate, showOnlyProvisioningsWithDifferences, filtersPublishedCostsSelected);
            })
            .finally(() => {
                setSendingPurchaseProvisioningId(null);
            });
    }

    const removePurchaseProvisioning = (purchaseProvisioningId: string): void => {
        PurchaseProvisioningsApiClient.RemovePurchaseProvisioning(purchaseProvisioningId)
            .then(res => {
                const errors = BusinessErrors.Get(res.data);

                if (errors.length > 0) {
                    ToastService.showErrorToast("Une ou plusieurs erreur(s) rencontrée(s) lors de la suppression du lot", errors);
                }
                else {
                    ToastService.showSuccessToast("Suppression du lot réalisée avec succès");
                }
            })
            .finally(() => {
                searchPlanningVehiclesWithProvisionings(date, selectedTransporters, selectedLogisticsUnit?.value, searchText, searchTextIntegratedProvisionings, searchTextProvisioningsToIntegrate, showOnlyProvisioningsWithDifferences, filtersPublishedCostsSelected);
            });
    }

    const GenerateRegularizationPurchaseProvisionings = async (): Promise<void> => {
        const filteredRegularizations = regularizations.filter(r => r.details.some(v => v.purchaseCost || v.fees));
        const nbOfProvisionings = filteredRegularizations.length;

        setSimpleDialog({
            isDialogOpened: false,
            popupContentComponent: <></>
        });

        setGeneratedRegularizationModel({
            loading: true,
            nbGeneratedRegularizationsElems: 0,
            nbElemAll: nbOfProvisionings,
            toastLstMessageError: []
        });

        let finished = false;
        let indexStart = 0;

        let indexEnd: number = nbOfProvisionings > purchaseProvisioningRegularizationBatchSize ? purchaseProvisioningRegularizationBatchSize : nbOfProvisionings;

        while (!finished) {
            if (indexStart >= nbOfProvisionings) {
                finished = true;
            }
            else {
                const reguls: Array<RegularizationPurchaseProvisioningRequestArgs> = filteredRegularizations.slice(indexStart, indexEnd);
                reguls.forEach(r => {
                    r.purchaseCost = r.purchaseCost ? r.purchaseCost : null;
                    r.fees = r.fees ? r.fees : null;
                    r.gasoilIndexCost = r.gasoilIndexCost ? r.gasoilIndexCost : null;
                    r.details = r.details.filter(v => v.purchaseCost || v.fees).map(d => {
                        return {
                            ...d,
                            purchaseCost: d.purchaseCost ? d.purchaseCost : null,
                            fees: d.fees ? d.fees : null,
                            gasoilIndexCost: d.gasoilIndexCost ? d.gasoilIndexCost : null
                        };
                    })
                });

                const toastLstMessageError = generatedRegularizationModel.toastLstMessageError;

                let newLstMessageErrors: string[] = [];

                await PurchaseProvisioningsApiClient.GenerateRegularizationPurchaseProvisionings(reguls)
                    .then(res => {
                        const errors = BusinessErrors.Get(res.data);

                        if (errors.length > 0) {
                            newLstMessageErrors = errors;
                        }
                    })
                    .finally(() => {
                        const nbElemDone = indexEnd;
                        indexStart = indexStart + purchaseProvisioningRegularizationBatchSize;
                        indexEnd = indexStart + purchaseProvisioningRegularizationBatchSize;
                        toastLstMessageError.push(...newLstMessageErrors);
                        setGeneratedRegularizationModel({
                            ...generatedRegularizationModel,
                            toastLstMessageError: toastLstMessageError,
                            nbGeneratedRegularizationsElems: nbElemDone
                        });
                    });
            }
        }

        let toastLstMessageError = generatedRegularizationModel.toastLstMessageError;

        if (toastLstMessageError.length > 0) {
            toastLstMessageError = toastLstMessageError.filter(Utilities.distinct);
            ToastService.showErrorToast("Un ou plusieurs lots n'ont pas été créés", toastLstMessageError);
        }

        setGeneratedRegularizationModel({
            ...generatedRegularizationModel,
            loading: false
        });

        setPlanningVehiclesSelected([]);
        searchPlanningVehiclesWithProvisionings(date, selectedTransporters, selectedLogisticsUnit?.value, searchText, "", "", showOnlyProvisioningsWithDifferences, filtersPublishedCostsSelected);
    }

    const handleShowOnlyCostAndFeesWithoutProvisioning = () => {
        const newValue = !showOnlyCostAndFeesWithoutProvisioning;
        const planningVehiclesArray = [...planningVehicles];

        planningVehiclesArray.map(p => {
            p.selected = false;
        });

        const filteredPlanningVehiclesArray = getFilteredPlanningVehicles(newValue, showOnlyProvisioningsWithDifferences, planningVehiclesArray);
        setPlanningVehicles(planningVehiclesArray);
        setFilteredPlanningVehicles(filteredPlanningVehiclesArray);
        setPurchaseProvisionningCosts(buildPurchaseProvisionningCosts(filteredPlanningVehiclesArray));
        setPlanningVehiclesSelected([]);
        setShowOnlyCostAndFeesWithoutProvisioning(newValue);
    }

    const handleShowOnlyProvisioningsWithDifferences = () => {
        const newValue = !showOnlyProvisioningsWithDifferences;
        const planningVehiclesArray = [...planningVehicles];

        planningVehiclesArray.map(p => {
            p.selected = false;
        });

        const filteredPlanningVehiclesArray = getFilteredPlanningVehicles(showOnlyCostAndFeesWithoutProvisioning, newValue, planningVehiclesArray);
        setPlanningVehicles(planningVehiclesArray);
        setFilteredPlanningVehicles(filteredPlanningVehiclesArray);
        setPurchaseProvisionningCosts(buildPurchaseProvisionningCosts(filteredPlanningVehiclesArray));
        setPlanningVehiclesSelected([]);
        setShowOnlyProvisioningsWithDifferences(newValue);
        setFilteredProvisionings(filteredPlanningVehiclesArray, purchaseProvisioningsToIntegrate, integratedProvisionings, newValue);
    }

    const setFilteredProvisionings = (filteredPlanningVehiclesArray: PlanningVehicleLightModelExtended[], purchaseProvisioningsToIntegrate: PurchaseProvisioningLightModelExtended[], integratedProvisionings: PurchaseProvisioningLightModelExtended[], showOnlyProvisioningsWithDifferences: boolean): void => {
        const costPurchaseProvisioningBusinessIds = filteredPlanningVehiclesArray.map(x => x.costPurchaseProvisioningBusinessId);
        const feesPurchaseProvisioningBusinessIds = filteredPlanningVehiclesArray.map(x => x.feesPurchaseProvisioningBusinessId);
        const regularizationProvisioningBusinessIds = filteredPlanningVehiclesArray.flatMap(x => x.regularizationReferences.map(y => y.businessId));

        const allBusinessIds = [...costPurchaseProvisioningBusinessIds, ...feesPurchaseProvisioningBusinessIds, ...regularizationProvisioningBusinessIds].filter(x => x).filter(Utilities.distinct);

        const filteredPurchaseProvisioningsToIntegrate = getFilteredPurchaseProvisioningsToIntegrate(showOnlyProvisioningsWithDifferences, purchaseProvisioningsToIntegrate, allBusinessIds);
        const filteredIntegratedProvisioningsArray = getFilteredIntegratedProvisioningsArray(showOnlyProvisioningsWithDifferences, integratedProvisionings, allBusinessIds);

        setFilteredPurchaseProvisioningsToIntegrate(filteredPurchaseProvisioningsToIntegrate);
        setFilteredIntegratedProvisionings(filteredIntegratedProvisioningsArray);
    }

    const getFilteredPurchaseProvisioningsToIntegrate = (showOnlyProvisioningsWithDifferences: boolean, purchaseProvisioningsToIntegrate: PurchaseProvisioningLightModelExtended[]
        , businessIds: string[]): PurchaseProvisioningLightModelExtended[] => {

        if (showOnlyProvisioningsWithDifferences) {
            let filteredPurchaseProvisioningsToIntegrateArray = [...purchaseProvisioningsToIntegrate];
            filteredPurchaseProvisioningsToIntegrateArray = filteredPurchaseProvisioningsToIntegrateArray.filter(x => businessIds.includes(x.businessId));
            return filteredPurchaseProvisioningsToIntegrateArray;
        }

        return purchaseProvisioningsToIntegrate;
    }

    const getFilteredIntegratedProvisioningsArray = (showOnlyProvisioningsWithDifferences: boolean, integratedProvisionings: PurchaseProvisioningLightModelExtended[]
        , businessIds: string[]): PurchaseProvisioningLightModelExtended[] => {

        if (showOnlyProvisioningsWithDifferences) {
            let filteredIntegratedProvisioningsArray = [...integratedProvisionings];
            filteredIntegratedProvisioningsArray = filteredIntegratedProvisioningsArray.filter(x => businessIds.includes(x.businessId));
            return filteredIntegratedProvisioningsArray;
        }

        return integratedProvisionings;
    }

    //#endregion

    const inputSearchVehiclesValue = inputSearchVehiclesRef.current === null || inputSearchVehiclesRef.current === undefined ? '' : inputSearchVehiclesRef.current.value;

    const [verticalPanes, setVerticalPanes] = useState<SplitterPaneProps[]>([
        { size: '64%', scrollable: false, min: '50px' },
        { min: '50px', scrollable: false }
    ]);

    const percentHeight = parseFloat(verticalPanes[0].size) / 100;

    const purchaseProvisioningsComponent: JSX.Element = useMemo(() =>
        <PlanningVehiclesSelectorComponent
            percentHeight={percentHeight}
            planningVehiclesList={filteredPlanningVehicles}
            planningVehiclesSelected={planningVehiclesSelected}
            rowRender={rowRenderPlanningVehicles}
            cellRender={cellRenderPlanningVehicles}
            selectionChange={selectionChange}
            headerSelectAllChanged={headerSelectAllChanged}
            handleChangeFeesEnabled={handleChangeFeesEnabled}
            handleChangePurchaseCostsEnabled={handleChangePurchaseCostsEnabled}
            handleChangeHeaderFeesEnabled={handleChangeHeaderFeesEnabled}
            handleChangeHeaderPurchaseCostsEnabled={handleChangeHeaderPurchaseCostsEnabled}
        />, [filteredPlanningVehicles, planningVehiclesSelected, percentHeight]);

    const isRegularization = selectedPurchaseProvisioning
        ?
        (selectedTab == 0
            ? [...filteredPurchaseProvisioningsToIntegrate]
            : [...filteredIntegratedProvisionings]).find(x => x.businessId === selectedPurchaseProvisioning).isRegularization
        : false;
    const planningVehiclesByPurchaseProvisionings: JSX.Element = useMemo(() =>
        <PurchaseProvisioningVehiclesSelectorComponent
            planningVehiclesList={purchaseProvisioningVehicles}
            isRegularization={isRegularization}
            purchaseProvisioningIsFinished={selectedTab === 1}
            cellRender={cellRenderPurchaseProvisionings}
            rowRender={rowRenderPurchaseProvisionings}
        />, [purchaseProvisioningVehicles]);

    const provisioningToSendComponent: JSX.Element = useMemo(() =>
        <ProvisioningsComponent purchaseProvisionings={filteredPurchaseProvisioningsToIntegrate} toIntegrate={true} sendingPurchaseProvisioningId={sendingPurchaseProvisioningId} loadingPurchaseProvisioning={loadingPurchaseProvisionings}
            handleSelectedPurchaseProvisioning={handleSelectedPurchaseProvisioning} handleEditGasoilIndex={handleEditGasoilIndex} handleEditRemarks={handleEditRemarks}
            handleProvisioningKeyPress={handleSearchProvisioningTextKeyPressed} clearSearchText={clearProvisioningSearchText}
            inputSearchProvisioningRef={inputSearchProvisioningToSendRef} purchaseProvisioningMaxAllowedCostLimit={purchaseProvisioningMaxAllowedCostLimit}
            handleChangeGasoilIndex={handleChangeGasoilIndex} reInitializeGasoilIndex={reInitializeGasoilIndex} handleChangeRemarks={handleChangeRemarks} sendPurchaseProvisioning={sendPurchaseProvisioning}
            handleUpdateOverridenGasoilIndex={handleUpdateOverridenGasoilIndex} handleUpdateRemarks={handleUpdateRemarks} removePurchaseProvisioning={removePurchaseProvisioning}
            updatePurchaseProvisioningVisibilityInBI={updatePurchaseProvisioningVisibilityInBI}
        />, [filteredPurchaseProvisioningsToIntegrate, sendingPurchaseProvisioningId, loadingPurchaseProvisionings]);

    const provisioningIntegratedComponent: JSX.Element = useMemo(() =>
        <ProvisioningsComponent purchaseProvisionings={filteredIntegratedProvisionings} toIntegrate={false} loadingPurchaseProvisioning={loadingPurchaseProvisionings}
            handleSelectedPurchaseProvisioning={handleSelectedPurchaseProvisioning} handleEditRemarks={handleEditRemarks}
            handleProvisioningKeyPress={handleSearchProvisioningTextKeyPressed} clearSearchText={clearProvisioningSearchText}
            inputSearchProvisioningRef={inputSearchIntegratedProvisioningRef}
            handleChangeRemarks={handleChangeRemarks} handleUpdateRemarks={handleUpdateRemarks} removePurchaseProvisioning={removePurchaseProvisioning}
            updatePurchaseProvisioningVisibilityInBI={updatePurchaseProvisioningVisibilityInBI}
        />, [filteredIntegratedProvisionings, sendingPurchaseProvisioningId, loadingPurchaseProvisionings]);

    const provisioningsWithDifferencesCount = filteredPlanningVehicles.filter(p => p.hasDiffFromPurchaseProvisioningCost || p.hasDiffFromPurchaseProvisioningFees).length;

    const btnDisabledRegularizationConfirmed = regularizations.some(r => r.supplierMdmIdentifier && r.total >= purchaseProvisioningMaxAllowedCostLimit)
        || !regularizations.some(r => r.purchaseCost || r.fees);

    return (
        <>
            <Box display="flex" flexDirection="column" minHeight="450px" height="100%" className={`${isTransporterDrawerOpened ? "purchase-shiftLeft " : ""} purchase-provisionings-view`}>
                <Box display="flex" flexWrap="nowrap" flexDirection="column" className="purchase-content">
                    <ContentHeaderComponent
                        logisticsUnits={props.logisticsUnits}
                        selectedLogisticsUnit={selectedLogisticsUnit}
                        handleLogisticsUnitChange={handleLogisticsUnitChange}
                        date={date}
                        purchaseProvisionningCosts={purchaseProvisionningCosts}
                        isTransporterDrawerOpened={isTransporterDrawerOpened}
                        inputSearchVehiclesValue={inputSearchVehiclesValue}
                        inputSearchVehiclesRef={inputSearchVehiclesRef}
                        isHeaderBtnPreInvoicesEnabled={isHeaderBtnPreInvoicesEnabled}
                        showOnlyCostAndFeesWithoutProvisioning={showOnlyCostAndFeesWithoutProvisioning}
                        showOnlyProvisioningsWithDifferences={showOnlyProvisioningsWithDifferences}
                        provisioningsWithDifferencesCount={provisioningsWithDifferencesCount}
                        handleShowOnlyCostAndFeesWithoutProvisioning={handleShowOnlyCostAndFeesWithoutProvisioning}
                        handleShowOnlyProvisioningsWithDifferences={handleShowOnlyProvisioningsWithDifferences}
                        handleOpenCloseTransporterDrawer={handleOpenCloseTransporterDrawer}
                        handleChangeDateRange={handleChangeDateRange}
                        handleVehiclesTripsKeyPress={handleSearchTextKeyPressed}
                        clearSearchText={clearSearchText}
                        filterOptionsPreinvoiceStatus={filterOptionsPublishedCosts}
                        onChangePublishedCostsSearchOption={onChangePublishedCostsSearchOption}
                    />
                    <Box pb={1} height="100%" className="parent-pp">
                        {loadingPlanningVehicles
                            ? <div className='sweet-loading spinnerClass'>
                                <ScaleLoader
                                    width={5}
                                    height={20}
                                    radius={50}
                                    color={'#000000'}
                                    loading={loadingPlanningVehicles}
                                />
                            </div>
                            :
                            <Splitter className='pp-splitter' panes={verticalPanes} orientation={'vertical'} onChange={onVerticalLayoutChange}>
                                <Box pb={1} height="100%">
                                    {purchaseProvisioningsComponent}
                                </Box>
                                <Box height="100%">
                                    {selectedPurchaseProvisioning ?
                                        <>
                                            <div className="title-grid">
                                                {`Camions du lot ${selectedPurchaseProvisioning}`}
                                            </div>
                                            {loadingPlanningVehicles
                                                ? <div className='sweet-loading spinnerClass'>
                                                    <ScaleLoader
                                                        width={5}
                                                        height={20}
                                                        radius={50}
                                                        color={'#000000'}
                                                        loading={loadingPurchaseProvisioningVehicles}
                                                    />
                                                </div>
                                                :
                                                <>
                                                    {planningVehiclesByPurchaseProvisionings}
                                                </>
                                            }
                                        </>
                                        : <></>
                                    }
                                </Box>
                            </Splitter>}
                    </Box>
                </Box>

            </Box>
            <TransportersComponent
                isTransporterDrawerOpened={isTransporterDrawerOpened}
                loading={loadingPlanningVehicles}
                transporters={transporters}
                selectedTransporters={selectedTransporters}
                handleOpenCloseTransporterDrawer={handleOpenCloseTransporterDrawer}
                toggleCheckbox={toggleCheckbox}
            />
            <Drawer
                className='drawer-generation-historical-lot'
                variant="persistent"
                anchor="right"
                open={true}
                classes={{
                    paper: 'drawer-paper'
                }}>
                {planningVehiclesSelected.length > 0 ?
                    <TabStrip selected={0} className="tab-strip">
                        <TabStripTab title="Création d'un lot" contentClassName="new-provisioning-content">
                            <NewProvisioningComponent planningVehiclesSelected={planningVehiclesSelected} vehicleTypeGroups={vehicleTypeGroups} purchaseProvisioningMaxAllowedCostLimit={purchaseProvisioningMaxAllowedCostLimit}
                                handleGenerateProvisioning={handleGenerateProvisioning} handleGenerateRegularizations={handleGenerateRegularizations} />
                        </TabStripTab>
                    </TabStrip>
                    :
                    <TabStrip selected={selectedTab} className="tab-strip" onSelect={handleSelectTab}>
                        <TabStripTab title="à traiter">
                            {provisioningToSendComponent}
                        </TabStripTab>
                        <TabStripTab title="Terminés">
                            {provisioningIntegratedComponent}
                        </TabStripTab>
                    </TabStrip>
                }
            </Drawer>
            <Dialog
                disableEscapeKeyDown
                aria-modal="false"
                open={simpleDialog.isDialogOpened}
                onClose={(_event, reason) => handleClickCloseDialog(reason)}
                scroll="paper"
                className="simple-dialog-regularization-purchase-provisioning"
                PaperComponent={PaperComponent}
                aria-labelledby="draggable-dialog-title"
            >
                <DialogTitle id="draggable-dialog-title">
                    <Box display="flex" flexDirection="row">
                        <Box width="50%">
                            {simpleDialog.dialogFirstPartTitle}
                        </Box>
                        <Box width="50%" textAlign="end">
                            {simpleDialog.dialogLastPartTitle}
                        </Box>
                    </Box>
                </DialogTitle>
                <DialogContent dividers>
                    {simpleDialog.popupContentComponent}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleClickCloseDialog()} color="primary">
                        Annuler
                    </Button>
                    <Button color="primary" onClick={GenerateRegularizationPurchaseProvisionings} disabled={btnDisabledRegularizationConfirmed}>
                        Confirmer
                    </Button>
                </DialogActions>
            </Dialog>
            <Modal show={generatedRegularizationModel.loading} className='mt-5'
                backdrop="static"
                keyboard={false}>
                <Modal.Body>
                    <Box display="flex" alignItems='center' flexDirection="column">
                        <Box p={1}>
                            <CircularProgress />
                        </Box>
                        <div>
                            Génération des régularisations en cours:
                        </div>
                        <div>
                            <b>{`${generatedRegularizationModel.nbGeneratedRegularizationsElems}/${generatedRegularizationModel.nbElemAll}`}</b>&nbsp; régularisations de lot générés
                        </div>
                    </Box>
                </Modal.Body>
            </Modal>
            <Modal show={showConfirmationClearOverridenGasoilIndexModal.openModal} className='delete-signature-modal' backdropClassName="delete-signature-modal-backdrop">
                <Modal.Header>
                    <Modal.Title>ATTENTION</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>Vous avez modifié le "cout à indexer" d'une ligne alors qu'une surcharge existe sur le montant gazole de la provision</div>
                    <br />
                    Souhaitez-vous recalculer le montant et supprimer la surcharge ?
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => handleConfirmUpdatePurchaseProvisioningDetailsBaseOfPurchaseCostIndexableWithGasoilIndex(
                        showConfirmationClearOverridenGasoilIndexModal.purchaseProvisioningDetailId,
                        showConfirmationClearOverridenGasoilIndexModal.baseOfPurchaseCostIndexable,
                        false)}>
                        NON
                    </Button>
                    <Button onClick={() => handleConfirmUpdatePurchaseProvisioningDetailsBaseOfPurchaseCostIndexableWithGasoilIndex(
                        showConfirmationClearOverridenGasoilIndexModal.purchaseProvisioningDetailId,
                        showConfirmationClearOverridenGasoilIndexModal.baseOfPurchaseCostIndexable,
                        true)}>
                        OUI
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}