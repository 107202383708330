import { useDroppable } from '@progress/kendo-react-common';
import * as React from 'react';
import { List, ListProps } from './List';

export interface DroppableListProps extends ListProps {
    onDrop?: ()=>void
}

export const DroppableList = (props: DroppableListProps) => {
    const list = React.useRef<HTMLUListElement>(null);
    const dropInstance = React.useRef<{ element: React.RefObject<HTMLElement | null> }>(null);
    React.useImperativeHandle(dropInstance, () => ({ element: list }));

    const handleDrop = () => {
        if (props.onDrop) {
            props.onDrop();
        }
    }

    useDroppable(list, {
        onDrop: handleDrop
    })

    return (
        <List ref={list} {...props}>
            {props.children && React.Children.count(props.children) && props.children}
        </List>)
}
