import { NormalizedDragEvent, useDraggable, useDroppable } from '@progress/kendo-react-common';
import * as React from 'react';
import { UserSignatureField } from '../models/UserSignatureField';
import { ListItem, ListItemProps } from './ListItem';
import { DirectionContext, DragContext, DropContext } from './UserSignatureFieldsComponent';

export interface DraggableListItemProps extends ListItemProps {
    onDrop: ()=>void;
}

export const DraggableListItem = (props: DraggableListItemProps) => {
    const item = React.useRef<HTMLLIElement>(null);
    const hint = React.useRef<HTMLLIElement>(null);
    const dropHint = React.useRef<HTMLLIElement>(null);
    const dragInstance = React.useRef<{ element: React.RefObject<HTMLElement | null>, data: UserSignatureField }>(null);
    const dropInstance = React.useRef<{ element: React.RefObject<HTMLElement | null>, data: UserSignatureField }>(null);

    React.useImperativeHandle(dragInstance, () => ({ element: hint, data: props.data }));
    React.useImperativeHandle(dropInstance, () => ({ element: item, data: props.data }));

    const [drag, setDrag] = React.useContext(DragContext);
    const [drop, setDrop] = React.useContext(DropContext);
    const [direction, setDirection] = React.useContext(DirectionContext);

    const [dragged, setDragged] = React.useState<boolean>(false);
    const [offset, setOffset] = React.useState<{ x: number; y: number }>({ x: 0, y: 0 });
    const [position, setPosition] = React.useState<{ x: number, y: number } | null>(null);
    const [width, setWidth] = React.useState<number | null>(null);

    const handlePress = React.useCallback(
        (event: NormalizedDragEvent) => {
            setOffset({
                x: event.offsetX,
                y: event.offsetY
            });
            if (!item.current) { return; }
            setWidth(item.current.offsetWidth);
        },
        []
    )

    const handleDragStart = React.useCallback(
        () => {
            setDragged(true);
            setDrag(dragInstance.current)
        },
        [setDrag]
    )

    const handleDrag = React.useCallback(
        (event: NormalizedDragEvent) => {
            if (drag && drag.element.current
                && drop && drop.element.current) {
                const newDirection = calculateDirection(drag.element.current, drop.element.current)
                setDirection(newDirection);
            }
            setPosition({
                x: event.clientX - offset.x,
                y: event.clientY - offset.y
            })
        },
        [drag, drop, offset.x, offset.y, setDirection]
    )

    const handleDragEnd = React.useCallback(
        () => {
            setDragged(false);
            setOffset({ x: 0, y: 0 });
            setDrag(null);
        },
        [setDrag]
    )

    const handleRelease = React.useCallback(
        () => {
            setDrop(null);
        },
        [setDrop]
    )

    const handleDragEnter = React.useCallback(
        () => {
            setDrop(dropInstance.current);
        },
        [setDrop]
    )

    const handleDragLeave = React.useCallback(
        () => {
            setDrop(null);
        },
        [setDrop]
    )

    const handleDrop = React.useCallback(
        () => {
            if (props.onDrop) {
                props.onDrop();
            }
            setDrop(null);
        },
        [props, setDrop]
    )

    useDraggable(
        item,
        {
            onPress: handlePress,
            onDragStart: handleDragStart,
            onDrag: handleDrag,
            onDragEnd: handleDragEnd,
            onRelease: handleRelease
        },
        {
            hint,
            autoScroll: false
        }
    );

    useDroppable(item, {
        onDragEnter: handleDragEnter,
        onDragLeave: handleDragLeave,
        onDrop: handleDrop
    });
    useDroppable(dropHint, {
        onDragEnter: handleDragEnter,
        onDrop: handleDrop
    });

    return (
        <React.Fragment>
            {(drag && drop && drop.data.id === props.data.id && direction === 'before') && <ListItem ref={dropHint} style={{ opacity: '0.7' }}>{drag.data.content}</ListItem>}
            <ListItem
                ref={item}
                {...props}
                style={dragged
                    ? { ...props.style, display: 'none' }
                    : { ...props.style }
                }
            />
            {(drag && drop && drop.data.id === props.data.id && direction === 'after') && <ListItem ref={dropHint} style={{ opacity: '0.7' }}>{drag.data.content}</ListItem>}
            {(dragged && position) && <ListItem
                ref={hint}
                {...props}
                style={{
                    position: 'absolute',
                    width: width,
                    left: position.x,
                    top: position.y
                } as React.CSSProperties}
            />}
        </React.Fragment>
    )
}

const calculateDirection = (drag: HTMLElement, drop: HTMLElement) => {
    const dragRect = drag.getBoundingClientRect();
    const dropRect = drop.getBoundingClientRect();

    return (dragRect.top + (dragRect.height / 2)) < (dropRect.top + (dropRect.height / 2))
        ? 'before'
        : 'after'
}
