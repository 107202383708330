import { faCalculator } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, TextField } from '@mui/material';
import { DatePicker, DatePickerChangeEvent } from '@progress/kendo-react-dateinputs';
import { load, loadMessages } from '@progress/kendo-react-intl';
import { UploadFileInfo, UploadOnAddEvent, UploadOnBeforeUploadEvent } from '@progress/kendo-react-upload';
import { AxiosResponse } from 'axios';
import { AuthenticationType, data, layer, Map, MapMouseEvent, Shape, source } from 'azure-maps-control';
import { debounce, groupBy, isEqual, orderBy, size } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { ScaleLoader } from 'react-spinners';
import { Address, GpsCoordinates, Leg, MapsService, TripEstimation } from 'src/shared/MapsService/MapsService';
import ToastService from 'src/ToastService';
import { SettingsProvider } from '../../SettingsProvider';
import { LogisticsUnitChoice } from '../../shared/models/LogisticsUnitChoice';
import { BusinessErrorLight, CommandResultLight } from '../../shared/models/WebAppActionResult';
import { priceUnavailabilityReasons } from '../../shared/Quotation/Global.QuotationErrorMessages';
import { TransportQuotationRequest } from '../../shared/Quotation/services/dataContracts/TransportQuotationRequest';
import { TransportQuotationResult } from '../../shared/Quotation/services/dataContracts/TransportQuotationResult';
import { TransportQuotationApiClient } from '../../shared/Quotation/services/TransportQuotationApiClient';
import BusinessErrors from '../../utils/BusinessErrors';
import BusinessWarnings from '../../utils/BusinessWarnings';
import '../../utils/Date';
import '../../utils/String';
import { fromMinutes, toMinutes } from '../../utils/TimeSpanString';
import Utilities from '../../utils/Utilities';
import { AddressesContent } from './components/AddressesContent';
import { AttachmentsComponent } from './components/AttachmentsComponent';
import { ConfirmValidationDialogComponent } from './components/ConfirmValidationDialogComponent';
import { ContactsContent } from './components/ContactsContent';
import { FlowDatesAndPrestationContent } from './components/FlowDatesAndPrestationContent';
import { JobSiteAndProductContent } from './components/JobSiteAndProductContent';
import { QuotationSettingsComponent } from './components/QuotationSettingsComponent';
import { VehicleAndPriceContent } from './components/VehicleAndPriceContent';
import { FixedLists } from './FixedLists';
import ModelMappings from './ModelMappings';
import { AddressModel } from './models/AddressModel';
import { BilledPriceModel } from './models/BilledPriceModel';
import { CoatingApplicationModeEnum } from './models/CoatingApplicationModeEnum';
import { ContactModel } from './models/ContactModel';
import { DateConfigModel } from './models/DateConfigModel';
import { Level3SegmentationModel } from './models/Level3SegmentationModel';
import { LogisticsUnitsModel } from './models/LogisticsUnitsModel';
import { PayerSiteModel } from './models/PayerSiteModel';
import { PayerSiteOriginEnum } from './models/PayerSiteOriginEnum';
import { PriceGridKindEnum } from './models/PriceGridKindEnum';
import { PurchasePriceKindEnum } from './models/PurchasePriceGridEnum';
import { QuotationCallModel } from './models/QuotationCallModel';
import { QuotationDataModel } from './models/QuotationDataModel';
import { SelectInputItemModel } from './models/SelectInputItemModel';
import { ServiceKindModel } from './models/ServiceKindModel';
import { SiteEntityTypeEnum } from './models/SiteEntityTypeEnum';
import { SiteModel } from './models/SiteModel';
import { TransportBillingModeEnum } from './models/TransportBillingModeEnum';
import { TransportPriorityEnum } from './models/TransportPriorityEnum';
import { TransportRequestModel } from './models/TransportRequestModel';
import { TransportServiceKindEnum } from './models/TransportServiceKindEnum';
import { TripsDetailsModel } from './models/TripsDetailsModel';
import { VehicleTypeModel } from './models/VehicleTypeModel';
import { AddedAttachmentResult } from './services/dataContracts/controller/AddedAttachmentResult';
import { AttachmentArgs } from './services/dataContracts/controller/AttachmentArgs';
import { AttachmentStatus } from './services/dataContracts/controller/AttachmentStatus';
import { ContactRequestArgs } from './services/dataContracts/controller/ContactRequestArgs';
import { DeleteAttachmentArgs } from './services/dataContracts/controller/DeleteAttachmentArgs';
import { JobContactInfo } from './services/dataContracts/controller/JobContactInfo';
import { JobSiteInfo } from './services/dataContracts/controller/JobSiteInfo';
import { NegotiatedPurchasePricesRequestArgs } from './services/dataContracts/controller/NegotiatedPurchasePricesRequestArgs';
import { TransportFlowPayerZephyrJobCodeRequestArgs } from './services/dataContracts/controller/TransportFlowPayerZephyrJobCodeRequestArgs';
import { TransportFlowRequestArgs } from './services/dataContracts/controller/TransportFlowRequestArgs';
import { AgencyLightModel } from './services/dataContracts/queryStack/AgencyLightModel';
import { ContactLightModel } from './services/dataContracts/queryStack/ContactLightModel';
import { Level2ActivityLightModel } from './services/dataContracts/queryStack/Level2ActivityLightModel';
import { Level3SegmentationLightModel } from './services/dataContracts/queryStack/Level3SegmentationLightModel';
import { NegotiatedPurchasePriceLightModel } from './services/dataContracts/queryStack/NegotiatedPurchasePriceLightModel';
import { ProductionSiteLightModel } from './services/dataContracts/queryStack/ProductionSiteLightModel';
import { ReferencedCustomerLightModel } from './services/dataContracts/queryStack/ReferencedCustomerLightModel';
import { TransportFlowLightModel } from './services/dataContracts/queryStack/TransportFlowLightModel';
import { TransportFlowTripsLightModel } from './services/dataContracts/queryStack/TransportFlowTripsLightModel';
import { TransportRequestLightModel } from './services/dataContracts/queryStack/TransportRequestLightModel';
import { VehicleTypeEstimatedLoadCapacityLightModel } from './services/dataContracts/queryStack/VehicleTypeEstimatedLoadCapacityLightModel';
import { TransportFlowApiClient } from './services/TransportFlowApiClient';
import './TransportFlowStyles.scss';

const weekData = require('cldr-core/supplemental/weekData.json');
const caGregorian = require('cldr-dates-full/main/fr/ca-gregorian.json');
const dateFields = require('cldr-dates-full/main/fr/dateFields.json');
const timeZoneNames = require('cldr-dates-full/main/fr/timeZoneNames.json');
const esMessages = require('../../config/fr.json');
loadMessages(esMessages, 'fr-FR');
load(
    weekData,
    caGregorian,
    dateFields,
    timeZoneNames
);

export const pickupPinId = "pickupPinId";
export const deliveryPinId = "deliveryPinId";
export const routeId = "routeId";
export const mapDataSourceId = "mapDataSourceId";

export const creationMode = "creation";
export const updateMode = "update";
export const duplicateMode = "duplicate";

export const jobsiteVehicle = "jobsiteVehicle";
export const perishable = "perishable";
export const notPerishable = "notPerishable";
export const removal = "removal";

export const productionSite = "productionSite";
export const jobSite = "jobSite";
export const referencedCustomer = "referencedCustomer";
export const unreferencedCustomer = "unreferencedCustomer";

export const internal = "internal";
export const externalReferenced = "externalReferenced";
export const externalNotReferenced = "externalNotReferenced";

export const sourceApplicationLorie = "Lorie";

interface TransportFlowProps {
    logisticsUnits: Array<LogisticsUnitChoice>,
    chosenLogisticsUnitIds: Array<string>,
    onClose: () => void,
    onCloseAfterValidation: (hasBusinessErrors: boolean, hasBusinessWarnings: boolean, warnings: string[], businessId: string, mode: string) => void,
    mode: string,
    transportFlowId?: string,
    flowBusinessId?: string
}

interface LogisticsUnitData {
    productionSites: Array<ProductionSiteLightModel>,
    agencies: Array<AgencyLightModel>,
    referencedCustomers: Array<ReferencedCustomerLightModel>,
    internalBeneficiaries: Array<ContactLightModel>,
    internalRequesters: Array<ContactLightModel>
}

export const TransportFlowForm = (props: TransportFlowProps): JSX.Element => {
    //Par défault pour les flux modulables et journés
    const DefaultArrivalSlotDurationWhenCreatingWithLowOrMediumPriority = 600;
    const DefaultArrivalSlotDurationWhenArrivalTimeAtReceiverChanged = 120;
    const [priority, setPriority] = useState<TransportPriorityEnum>(null);
    const [isNightWork, setNightWork] = useState<boolean>(false);
    const [coatingApplicationMode, setCoatingApplicationMode] = useState<CoatingApplicationModeEnum>(null);
    const [sourceApplicationIsPlaneo, setSourceApplicationIsPlaneo] = useState<boolean>(null);
    const [initiallyRequestedTransportationLineId, setInitiallyRequestedTransportationLineId] = useState<number>(null);
    const [serviceKind, setServiceKind] = useState<ServiceKindModel>({ serviceKindList: [], selectedServiceKind: null });
    const [transportRequest, setTransportRequest] = useState<TransportRequestModel>({ transportRequestList: [], selectedTransportRequest: null });
    const [level3Segmentation, setLevel3Segmentation] = useState<Level3SegmentationModel>({ level3SegList: [], level3Seg: null });
    const [logisticsUnit, setLogisticsUnit] = useState<LogisticsUnitsModel>({ userLogisticsUnitList: [], selectedLogisticsUnit: null });
    const [dateConfig, setDateConfig] = useState<DateConfigModel>({ startDateValue: null, endDateValue: null, minEndDateValue: null, equalDates: null });
    const [productLabel, setProductLabel] = useState<string>("");
    const [zephyrOrderNumber, setZephyrOrderNumber] = useState<string>("");
    const [quantity, setQuantity] = useState<number>(null);
    const [isUnloadingContinuityRequired, setIsUnloadingContinuityRequired] = useState<boolean>(null);
    const [vehicleType, setVehicleType] = useState<VehicleTypeModel>({ vehicleTypeList: [], selectedVehicleType: null });
    const [agenciesList, setAgenciesList] = useState<AgencyLightModel[]>([]);
    const [productionSiteList, setProductionSiteList] = useState<ProductionSiteLightModel[]>([]);
    const [referencedCustomerList, setReferencedCustomerList] = useState<ReferencedCustomerLightModel[]>([]);
    const [internalBeneficiariesList, setInternalBeneficiariesList] = useState<ContactLightModel[]>([]);
    const [internalRequestersList, setInternalRequestersList] = useState<ContactLightModel[]>([]);
    const [negotiatedPurchasePrices, setNegotiatedPurchasePrices] = useState<NegotiatedPurchasePriceLightModel[]>([]);
    const [negotiatedPurchasePriceAppliesToExternalTransporters, setNegotiatedPurchasePriceAppliesToExternalTransporters] = useState<boolean>(true);
    const [negotiatedPurchasePriceAppliesToInternalTransporters, setNegotiatedPurchasePriceAppliesToInternalTransporters] = useState<boolean>(true);

    const [requestedBy, setRequestedBy] = useState<ContactModel>({ contactType: null, contactList: [], contact: null, undefinedContact: false });

    const [beneficiary, setBeneficiary] = useState<ContactModel>({ contactType: null, contactList: [], contact: null, contactPhoneNumber: "", invalidPhoneNumber: false, undefinedContact: false });

    const [arrivalTimeAtSenderSiteIsFixed, setArrivalTimeAtSenderSiteIsFixed] = useState<boolean>(false);
    const [arrivalTimeAtSenderSite, setArrivalTimeAtSenderSite] = useState<Date>(null);
    const [arrivalTimeAtReceiverSite, setArrivalTimeAtReceiverSite] = useState<Date>(null);
    const [arrivalEndTimeAtReceiverSite, setArrivalEndTimeAtReceiverSite] = useState<Date>(null);
    const [arrivalSlotDuration, setArrivalSlotDuration] = useState<number>(null);
    const [jobSiteVehicleUnloadingDuration, setJobSiteVehicleUnloadingDuration] = useState<Date>(null);
    const [unloadingDuration, setUnloadingDuration] = useState<number>(null);
    const [transportersInstructions, setTransportersInstructions] = useState<string>(null);

    const [senderSite, setSenderSite] = useState<SiteModel>({
        siteKind: null,
        siteKindList: FixedLists.siteKindList,
        siteList: [],
        productionSite: null,
        productionSiteActivities: [],
        productionSiteActivity: null,
        unReferencedSiteCustomer: null,
        unReferencedSiteCustomerSite: null,
        referencedSiteCustomerList: [],
        referencedSiteCustomer: null,
        siteAgencyList: [],
        selectedSiteAgency: null,
        jobSiteIdmdm: null,
        jobSiteLabel: null
    });
    const [pickupAddress, setPickupAddress] = useState<AddressModel>({ freeFormAddress: null, line1: null, zipCode: null, city: null });

    const [receiverSite, setReceiverSite] = useState<SiteModel>({
        siteKind: null,
        siteKindList: FixedLists.siteKindList,
        siteList: [],
        productionSite: null,
        productionSiteActivities: [],
        productionSiteActivity: null,
        unReferencedSiteCustomer: null,
        unReferencedSiteCustomerSite: null,
        referencedSiteCustomerList: [],
        referencedSiteCustomer: null,
        siteAgencyList: [],
        selectedSiteAgency: null,
        jobSiteIdmdm: null,
        jobSiteLabel: null
    });
    const [deliveryAddress, setDeliveryAddress] = useState<AddressModel>({ freeFormAddress: null, line1: null, zipCode: null, city: null });
    const [payerSiteList, setPayerSiteList] = useState<PayerSiteModel[]>([]);
    const [payerSite, setPayerSite] = useState<PayerSiteModel>(null);
    const [payerLevel2Activity, setPayerLevel2Activity] = useState<SelectInputItemModel>(null);
    const [payerLevel2ActivityList, setPayerLevel2ActivityList] = useState<SelectInputItemModel[]>([]);
    const [transportBillingMode, setTransportBillingMode] = useState<SelectInputItemModel>();

    const [quotationCall, setQuotationCall] = useState<QuotationCallModel>({
        isQuotationCallSuccess: false,
        isQuotationDetailsExpanded: props.mode !== creationMode ? true : false,
        arrivalTimeAtSenderSiteIsFixed: null
    });

    const [quotationResult, setQuotationResult] = useState<TransportQuotationResult>();
    const [quotationRequest, setQuotationRequest] = useState<QuotationDataModel>({
        coatingApplicationMode: null,
        level3Segmentation: null,
        quantity: null,
        tripDuration: null,
        arrivalTimeAtSenderSite: null,
        arrivalTimeAtReceiverSite: null,
        numberOfVehicles: null,
        isUnloadingContinuityRequired: null,
        unloadingDuration: null,
        senderSite: {
            agencyId: null,
            customerMdmId: null,
            jobSiteMdmId: null,
            productionSiteId: null,
            unreferencedCustomer: null,
            productionSiteActivity: null
        },
        pickupAddress: { line1: null, zipCode: null, city: null },
        receiverSite: {
            agencyId: null,
            customerMdmId: null,
            jobSiteMdmId: null,
            productionSiteId: null,
            unreferencedCustomer: null,
            productionSiteActivity: null
        },
        deliveryAddress: { line1: null, zipCode: null, city: null },
        priceGridKind: null,
        payerSite: null,
        payerActivity: null,
        payerJobSiteMdmId: null,
        transportBillingMode: null
    });

    const [tripDuration, setTripDuration] = useState<number>(null);
    const [transportFlowData, setTransportFlowData] = useState<TransportFlowLightModel>(null);

    const [loadingContent, setLoadingContent] = useState<boolean>(false);
    const [loadingPopup, setLoadingPopup] = useState<boolean>(false);
    const [recalculatePlanning, setRecalculatePlanning] = useState<boolean>(false);
    const [tripsData, setTripsData] = useState<TripsDetailsModel>({
        tripsCount: 0,
        planningVehicleCount: 0,
        tripsNeededCount: 0,
        tripsPlannedCount: 0,
        tripsConfirmedCount: 0,
        tripsInProgressCount: 0,
        tripsFinishedCount: 0,
        tripsCanceledCount: 0
    });
    const [isConfirmValidationDialogOpened, setIsConfirmValidationDialogOpened] = useState<boolean>(false);
    const [isConfirmValidationExistingSpecificOrNegotiatedPriceDialogOpened, setIsConfirmValidationExistingSpecificOrNegotiatedPriceDialogOpened] = useState<boolean>(false);
    const [isConfirmValidationExistingSpecificOrNegotiatedPriceOnInternalExternalDialogOpened, setIsConfirmValidationExistingSpecificOrNegotiatedPriceOnInternalExternalDialogOpened] = useState<boolean>(false);
    const [payerLabel, setPayerLabel] = useState<string>(null);
    const [startDateValid, setStartDateValid] = useState<boolean>(false);
    const [endDateValid, setEndDateValid] = useState<boolean>(false);

    const [myMap, setMyMap] = useState<Map>(null);
    const [pickupCoordinates, setPickupCoordinates] = useState<GpsCoordinates>(null);
    const [deliveryCoordinates, setDeliveryCoordinates] = useState<GpsCoordinates>(null);
    const [failedPickupAddress, setFailedPickupAddress] = useState<boolean>(false);
    const [failedDeliveryAddress, setFailedDeliveryAddress] = useState<boolean>(false);
    const [estimateDistance, setEstimateDistance] = useState<number>(null);
    const [estimateDuration, setEstimateDuration] = useState<number>(null);
    const [isPickupMapMarkerActive, setIsPickupMapMarkerActive] = useState<boolean>(false);
    const [isDeliveryMapMarkerActive, setIsDeliveryMapMarkerActive] = useState<boolean>(false);
    const [clickedMapPosition, setClickedMapPosition] = useState<GpsCoordinates>(null);
    const [billedSellPrice, setBilledSellPrice] = useState<BilledPriceModel>({
        billedPriceValue: null,
        billedUnitPriceIsLocked: false,
        billedUnitPriceValue: null
    });
    const [estimatedPrice, setEstimatedPrice] = useState<number>(null);
    const [sellPriceKindData, setSellPriceKindData] = useState<SelectInputItemModel>(null);
    const [negotiatedPurchasePriceKindData, setNegotiatedPurchasePriceKindData] = useState<SelectInputItemModel>(null);
    const [negotiatedPurchasePriceValue, setNegotiatedPurchasePriceValue] = useState<number>(null);
    const [isZephyrJobCodeDisabled, setIsZephyrJobCodeDisabled] = useState<boolean>(true);
    const [payerZephyrJobCode, setPayerZephyrJobCode] = useState<string>(null);
    const [isNegotiatedSellPrice, setIsNegotiatedSellPrice] = useState<boolean>(false);
    const [payerOverridenJobSiteIdmdm, setPayerOverridenjobSiteIdmdm] = useState<string>(null);
    const [payerOverridenBeneficiary, setPayerOverridenBeneficiary] = useState<SelectInputItemModel>(null);
    const [payerOverridenBeneficiaryList, setPayerOverridenBeneficiaryList] = useState<SelectInputItemModel[]>([]);
    const [attachmentFiles, setAttachmentFiles] = useState<AttachmentArgs[]>([]);
    const [isLoadingAttachment, setIsLoadingAttachment] = useState<boolean>(false);
    const [senderJobSiteAgencyInfo, setSenderJobSiteAgencyInfo] = useState<JobSiteInfo>(null);
    const [receiverJobSiteAgencyInfo, setReceiverJobSiteAgencyInfo] = useState<JobSiteInfo>(null);
    const startDateRef = useRef<DatePicker>();
    const endDateRef = useRef<DatePicker>();

    useEffect(() => {
        if (props.mode !== creationMode) {
            setFlowData();
        } else {
            const logisticsUnitId = props.logisticsUnits.length === 1 ? props.logisticsUnits[0].logisticsUnitId : null;
            if (logisticsUnitId) {
                setLoadingPopup(true);
                searchProductionSitesWithAgenciesAndReferencedCustomersAndInternalBeneficiariesAndRequesters(logisticsUnitId).then(() => {
                    loadCreationFlowData();
                });
            }
            else {
                loadCreationFlowData();
            }
        }
    }, []);

    useEffect(() => {
        const coordinates: GpsCoordinates = { latitude: clickedMapPosition?.latitude, longitude: clickedMapPosition?.longitude };

        if (isPickupMapMarkerActive) {
            setMapPin(coordinates, pickupPinId);
            setIsPickupMapMarkerActive(false);

            MapsService.GetAddressFromCoordinates(coordinates)
                .then((res: Address) => {
                    setPickupAddress({ freeFormAddress: res.freeformAddress, line1: res.line1, line2: null, zipCode: res.zipCode, city: res.city });
                    if (failedPickupAddress)
                        setFailedPickupAddress(false);
                });
        } else if (isDeliveryMapMarkerActive) {
            setMapPin(coordinates, deliveryPinId);
            setIsDeliveryMapMarkerActive(false);

            MapsService.GetAddressFromCoordinates(coordinates)
                .then((res: Address) => {
                    setDeliveryAddress({ freeFormAddress: res.freeformAddress, line1: res.line1, line2: null, zipCode: res.zipCode, city: res.city });
                    if (failedDeliveryAddress)
                        setFailedDeliveryAddress(false);
                });
        }

        if (pickupCoordinates !== null && isDeliveryMapMarkerActive && (arrivalTimeAtSenderSite !== null || arrivalTimeAtReceiverSite !== null)) {
            setMapRoute(pickupCoordinates, coordinates);
        } else if (deliveryCoordinates !== null && isPickupMapMarkerActive && (arrivalTimeAtSenderSite !== null || arrivalTimeAtReceiverSite !== null)) {
            setMapRoute(coordinates, deliveryCoordinates);
        }

    }, [clickedMapPosition]);

    useEffect(() => {
        //si arrivalTimeAtSenderSite ou arrivalTimeAtReceiverSite changent suite à un appel au service de quotation, pas besoin de faire appel au service azure maps pour calcul de route et temps estimé

        //NOTE HGA Actuellement les eventHandlers basés sur les maps n'accède pas au state du fonctional composant. 
        //"arrivalTimesChangedAfterQuotationCall" est contournement qui indique si "ArrivalTimeAtReceiverSite" ou "ArrivalTimeAtSenderSite" ont été mis à jours suite à un calcul de service de quotation.
        const arrivalTimesChangedAfterQuotationCall = document.getElementById("arrivalTimesChangedAfterQuotationCall") as HTMLInputElement;
        if (arrivalTimesChangedAfterQuotationCall && arrivalTimesChangedAfterQuotationCall.value === 'true') {
            arrivalTimesChangedAfterQuotationCall.value = 'false';
            return;
        }

        //if pickup and delivery coordinates are active => calculate route
        if (pickupCoordinates && deliveryCoordinates && (props.mode === creationMode ||
            (props.mode !== creationMode && transportFlowData &&
                ((arrivalTimeAtSenderSiteIsFixed === true && !Date.equals(arrivalTimeAtSenderSite, transportFlowData?.arrivalTimeAtSenderSite)) ||
                    (arrivalTimeAtSenderSiteIsFixed === false && !Date.equals(arrivalTimeAtReceiverSite, transportFlowData?.arrivalTimeAtReceiverSite)))))) {
            setMapRoute(pickupCoordinates, deliveryCoordinates);
        }
    }, [arrivalTimeAtSenderSite, arrivalTimeAtReceiverSite]);

    useEffect(() => {
        //Cas de modification, dès qu'on valorise les coordonnées => créer la map
        if (props.mode !== creationMode && (pickupCoordinates || deliveryCoordinates) && !myMap)
            setMap(pickupCoordinates, deliveryCoordinates);

        const arrivalTime = arrivalTimeAtSenderSiteIsFixed === true ? arrivalTimeAtSenderSite : arrivalTimeAtReceiverSite;
        updateRoute(pickupCoordinates, deliveryCoordinates, arrivalTime, myMap);
    }, [deliveryCoordinates]);

    useEffect(() => {
        const arrivalTime = arrivalTimeAtSenderSiteIsFixed === true ? arrivalTimeAtSenderSite : arrivalTimeAtReceiverSite;
        updateRoute(pickupCoordinates, deliveryCoordinates, arrivalTime, myMap);
    }, [pickupCoordinates]);

    useEffect(() => {
        setStartDateValid(isValidDate(startDateRef));
    }, [dateConfig.startDateValue]);

    useEffect(() => {
        setEndDateValid(isValidDate(endDateRef));
    }, [dateConfig.endDateValue]);

    useEffect(() => {
        const isStartDateValid: boolean = isValidDate(startDateRef);
        const isEndDateValid: boolean = isValidDate(endDateRef);
        if (logisticsUnit.selectedLogisticsUnit?.value !== "" && dateConfig.startDateValue !== null && dateConfig.endDateValue !== null && isStartDateValid && isEndDateValid && props.mode !== updateMode) {
            const transportRequestId: string = loadingPopup && props.mode === duplicateMode ? transportFlowData.transportRequestId : null;
            getTransportRequestList(logisticsUnit.selectedLogisticsUnit?.value, dateConfig.startDateValue, dateConfig.endDateValue, transportRequestId);
        }
    }, [dateConfig.startDateValue, dateConfig.endDateValue]);

    const loadCreationFlowData = (): void => {
        getUserLogisticsUnits();
        getVehicleTypes();
        initialiseMap();
        setServiceKind({
            ...serviceKind,
            serviceKindList: initialiseServiceKindList(FixedLists.serviceKindList)
        });
    }

    const searchProductionSitesWithAgenciesAndReferencedCustomersAndInternalBeneficiariesAndRequesters = (logisticsUnitId?: string)
        : Promise<LogisticsUnitData> => {
        let data: LogisticsUnitData = {
            agencies: [],
            productionSites: [],
            referencedCustomers: [],
            internalBeneficiaries: [],
            internalRequesters: []
        };

        return new Promise(resolve => {
            TransportFlowApiClient.SearchProductionSitesWithAgenciesAndReferencedCustomersAndInternalBeneficiariesAndRequesters(logisticsUnitId).then(res => {
                const productionSitesData = res[0].data;
                const agenciesData = res[1].data;
                const referencedCustomersData = res[2].data;
                const internalBeneficiariesData = res[3].data;
                const internalRequestersData = res[4].data;

                setProductionSiteList(productionSitesData);
                setAgenciesList(agenciesData);
                setReferencedCustomerList(referencedCustomersData);
                setInternalBeneficiariesList(internalBeneficiariesData);
                setInternalRequestersList(internalRequestersData);

                const beneficiaryList: Array<SelectInputItemModel> = [];
                internalBeneficiariesData.forEach((element: ContactLightModel) => {
                    beneficiaryList.push({ label: getFullName(element), value: element.contactId });
                });

                setPayerOverridenBeneficiaryList(beneficiaryList);

                data = {
                    productionSites: productionSitesData,
                    agencies: agenciesData,
                    referencedCustomers: referencedCustomersData,
                    internalBeneficiaries: internalBeneficiariesData,
                    internalRequesters: internalRequestersData
                };
            })
                .finally(() => {
                    return resolve(data);
                });
        });
    }

    const updateRoute = (pickupCoordinates: GpsCoordinates, deliveryCoordinates: GpsCoordinates, arrivalTime: Date, myMap: Map): void => {
        if (props.mode === creationMode && pickupCoordinates && deliveryCoordinates && arrivalTime && myMap) {
            const dataSource = myMap.sources.getById(mapDataSourceId) as source.DataSource;
            const existingRoute: Shape = dataSource.getShapeById(routeId);

            if (existingRoute)
                getTripEstimation(pickupCoordinates, deliveryCoordinates, dataSource);
        }
    }

    const setFlowData = (): void => {
        setLoadingPopup(true);
        TransportFlowApiClient.GetTransportFlowById(props.transportFlowId)
            .then((res) => {
                const tf: TransportFlowLightModel = res.data;
                if (tf.serviceKind !== TransportServiceKindEnum.jobsiteVehicle) {
                    setNegotiatedPurchasePricesFlow(tf.logisticsUnitId, tf.vehicleTypeId, tf.zephyrOrderNumber, tf.deliveryStartDate, tf.deliveryEndDate
                        , tf.senderSiteAgencyId, tf.senderSiteCustomerMdmId, tf.senderSiteJobSiteMdmId, tf.senderSiteProductionSiteId, tf.senderSiteLevel2ActivityId
                        , tf.receiverSiteAgencyId, tf.receiverSiteCustomerMdmId, tf.receiverSiteJobSiteMdmId, tf.receiverSiteProductionSiteId, tf.receiverSiteLevel2ActivityId);
                }

                searchProductionSitesWithAgenciesAndReferencedCustomersAndInternalBeneficiariesAndRequesters(tf.logisticsUnitId)
                    .then(data => {

                        //NOTE HGA Actuellement les eventHandlers basés sur les maps n'accède pas au state du fonctional composant.
                        //"initialiseForm" est contournement qui indique si on est entrains d'initialiser le formulaire ou pas.
                        const initialiseForm = document.getElementById("initialiseForm") as HTMLInputElement;
                        if (initialiseForm) {
                            initialiseForm.value = 'true';
                        }

                        setTransportFlowData(tf);
                        setFlowLogisticsUnitAndListDependedOnLogisticsUnit(tf.logisticsUnitId, tf.productLevel3SegmentationId);
                        setFLowPriorityAndDeliveryDatesAndServiceKind(tf.priority, tf.deliveryStartDate, tf.deliveryEndDate, tf.serviceKind, tf.isPerishableProduct);

                        if (props.mode === updateMode) {
                            setTransportRequest({
                                transportRequestList: [],
                                selectedTransportRequest: { label: !tf.isHidden ? tf.transportRequestBusinessId + " / " + tf.productCode + " / " + tf.transportRequestRequestedQuantity + "T" : "", value: tf.transportRequestId }
                            });
                        }

                        const coatingApplicationMode = CoatingApplicationModeEnum[tf.coatingApplicationMode];
                        setCoatingApplicationMode(coatingApplicationMode);

                        setSourceApplicationIsPlaneo(tf.sourceApplication === 'Planeo');
                        setInitiallyRequestedTransportationLineId(tf.initiallyRequestedTransportationLineId);
                        setProductLabel(tf.productCode);
                        setQuantity(tf.requestedQuantity);
                        setNightWork(tf.isNightWork);
                        setTransportersInstructions(tf.transportersInstructions);
                        setArrivalTimeAtSenderSite(new Date(tf.arrivalTimeAtSenderSite));
                        setArrivalTimeAtReceiverSite(new Date(tf.arrivalTimeAtReceiverSite));
                        setArrivalTimeAtSenderSiteIsFixed(tf.arrivalTimeAtSenderSiteIsFixed);
                        setZephyrOrderNumber(tf.zephyrOrderNumber);
                        setPayerOverridenjobSiteIdmdm(tf.payerOverridenJobSiteMdmId);
                        if (tf.payerOverridenBeneficiaryContactId) {
                            const overridenBeneficiaryContact: ContactLightModel = data.internalBeneficiaries.find(x => x.contactId === tf.payerOverridenBeneficiaryContactId);
                            setPayerOverridenBeneficiary(overridenBeneficiaryContact ? { label: getFullName(overridenBeneficiaryContact), value: overridenBeneficiaryContact.contactId } : null);
                        }

                        setFlowSenderSite(tf.logisticsUnitId,
                            tf.senderSiteResolutionKind,
                            tf.senderSiteProductionSiteId,
                            tf.senderSiteAgencyId,
                            tf.senderSiteJobSiteMdmId,
                            tf.senderSiteJobSiteLabel,
                            tf.senderSiteCustomerMdmId,
                            tf.senderSiteUnreferencedCustomer,
                            tf.senderSiteUnreferencedCustomerSite,
                            tf.senderSiteLevel2ActivityId,
                            tf.pickupFreeFormAddress,
                            tf.pickupAddressLine1,
                            tf.pickupAddressLine2,
                            tf.pickupAddressZipCode,
                            tf.pickupAddressCity,
                            tf.receiverSiteResolutionKind,
                            tf.receiverSiteProductionSiteId,
                            data.productionSites,
                            data.agencies,
                            data.referencedCustomers);

                        setFlowReceiverSite(tf.logisticsUnitId,
                            tf.receiverSiteResolutionKind,
                            tf.receiverSiteProductionSiteId,
                            tf.receiverSiteAgencyId,
                            tf.receiverSiteJobSiteMdmId,
                            tf.receiverSiteJobSiteLabel,
                            tf.receiverSiteCustomerMdmId,
                            tf.receiverSiteUnreferencedCustomer,
                            tf.receiverSiteUnreferencedCustomerSite,
                            tf.receiverSiteLevel2ActivityId,
                            tf.deliveryFreeFormAddress,
                            tf.deliveryAddressLine1,
                            tf.deliveryAddressLine2,
                            tf.deliveryAddressZipCode,
                            tf.deliveryAddressCity,
                            tf.senderSiteResolutionKind,
                            tf.senderSiteProductionSiteId,
                            data.productionSites,
                            data.agencies,
                            data.referencedCustomers);

                        if (myMap) {
                            myMap.dispose();
                            setMyMap(null);
                            setPickupCoordinates(null);
                            setDeliveryCoordinates(null);
                        }

                        setMapWithCoordinates(
                            tf.pickupCoordinatesLatitude,
                            tf.pickupCoordinatesLongitude,
                            tf.deliveryCoordinatesLatitude,
                            tf.deliveryCoordinatesLongitude,
                            tf.pickupFreeFormAddress,
                            tf.deliveryFreeFormAddress);

                        setFlowRequester(tf.requestedByIsExternal, tf.requestedByContactId, tf.requestedByName, tf.logisticsUnitId, tf.senderSiteCustomerMdmId, tf.receiverSiteCustomerMdmId);
                        setFlowBeneficiary(tf.beneficiaryIsExternal, tf.beneficiaryContactId, tf.beneficiaryName, tf.beneficiaryPhoneNumber, tf.logisticsUnitId, tf.senderSiteCustomerMdmId, tf.receiverSiteCustomerMdmId, data.internalBeneficiaries);

                        setQuotationResult({
                            ...JSON.parse(tf.quotationResultJson),
                            numberOfVehicles: tf.numberOfVehicles,
                            totalNumberOfTurns: tf.numberOfTurns,
                            estimatedPrice: tf.estimatedTransportCost,
                            missionStartTime: tf.arrivalTimeAtSenderSite,
                            firstDeliveryTime: tf.arrivalTimeAtReceiverSite,
                            transportBillingMode: tf.transportBillingMode ? ModelMappings.mapTransportBillingMode(TransportBillingModeEnum[tf.transportBillingMode]) : null,
                            payerIdentifier: tf.payerIdentifier,
                            payerOrigin: PayerSiteOriginEnum[tf.payerOrigin],
                            payerEntity: tf.payerEntity ? SiteEntityTypeEnum[tf.payerEntity] : null,
                            priceGridKind: tf.transportSellPriceKind ? ModelMappings.mapPriceGridKind(PriceGridKindEnum[tf.transportSellPriceKind]) : null
                        });

                        if (tf.payerIdentifier && tf.payerEntity) {
                            getPayerLabel(tf.payerIdentifier, tf.payerEntity);
                            setIsZephyrJobCodeDisabled(false);
                        }

                        //ODA Actuellement les eventHandlers basés sur les maps n'accède pas au state du fonctional composant. 
                        //Le contournement trouvé est de créer un champ caché qu'on synchronise à chaque modif du tripDuration
                        const tripDurationById = document.getElementById("tripDurationId") as HTMLInputElement;
                        const tripDurationValue = toMinutes(tf.estimatedTripDuration);

                        if (tripDurationById)
                            tripDurationById.value = tripDurationValue + '';

                        setTripDuration(tripDurationValue);

                        if (tf.arrivalSlotDuration) {
                            const slotDuration = toMinutes(tf.arrivalSlotDuration);
                            setArrivalSlotDuration(slotDuration);
                            setArrivalEndTimeAtReceiverSite(new Date(tf.arrivalTimeAtReceiverSite).addMinutes(slotDuration));
                        }

                        const unloadingDuration: Date = new Date();
                        if (tf.serviceKind === TransportServiceKindEnum.jobsiteVehicle) {
                            const time: string[] = tf.unloadingDuration.split(':');
                            const h = Number(time[0]);
                            const m = Number(time[1]);
                            unloadingDuration.setHours(h, m);
                            setJobSiteVehicleUnloadingDuration(new Date(unloadingDuration));
                        } else {
                            setUnloadingDuration(toMinutes(tf.unloadingDuration));
                        }

                        setIsUnloadingContinuityRequired(tf.isUnloadingContinuityRequired);
                        getVehicleTypes(tf.vehicleTypeId, tf.vehicleTypeLabel, tf.vehicleTypeGroupId);
                        setFlowTripsData(tf.trips);
                        setEstimatedPrice(tf.estimatedTransportCost);
                        setQuotationRequest({
                            coatingApplicationMode: coatingApplicationMode,
                            level3Segmentation: tf.productLevel3SegmentationId,
                            quantity: tf.requestedQuantity,
                            tripDuration: toMinutes(tf.estimatedTripDuration),
                            arrivalTimeAtSenderSite: tf.arrivalTimeAtSenderSiteIsFixed === true ? new Date(tf.arrivalTimeAtSenderSite) : null,
                            arrivalTimeAtReceiverSite: tf.arrivalTimeAtSenderSiteIsFixed === true ? null : new Date(tf.arrivalTimeAtReceiverSite),
                            numberOfVehicles: tf.numberOfVehicles,
                            isUnloadingContinuityRequired: tf.isUnloadingContinuityRequired,
                            unloadingDuration: tf.serviceKind !== TransportServiceKindEnum.jobsiteVehicle ? toMinutes(tf.unloadingDuration) : new Date(unloadingDuration),
                            senderSite: {
                                agencyId: tf.senderSiteResolutionKind === SiteEntityTypeEnum.JobSite ? tf.senderSiteAgencyId : null,
                                customerMdmId: tf.senderSiteResolutionKind === SiteEntityTypeEnum.Customer ? tf.senderSiteCustomerMdmId : null,
                                jobSiteMdmId: tf.senderSiteResolutionKind === SiteEntityTypeEnum.JobSite ? tf.senderSiteJobSiteMdmId : null,
                                productionSiteId: tf.senderSiteResolutionKind === SiteEntityTypeEnum.ProductionSite ? tf.senderSiteProductionSiteId : null,
                                unreferencedCustomer: tf.senderSiteResolutionKind === SiteEntityTypeEnum.UnreferencedCustomer ? tf.senderSiteUnreferencedCustomer : null,
                                productionSiteActivity: tf.senderSiteResolutionKind === SiteEntityTypeEnum.ProductionSite ? tf.senderSiteLevel2ActivityId : null
                            },
                            pickupAddress: {
                                line1: tf.pickupAddressLine1,
                                zipCode: tf.pickupAddressZipCode,
                                city: tf.pickupAddressCity
                            },
                            receiverSite: {
                                agencyId: tf.receiverSiteResolutionKind === SiteEntityTypeEnum.JobSite ? tf.receiverSiteAgencyId : null,
                                customerMdmId: tf.receiverSiteResolutionKind === SiteEntityTypeEnum.Customer ? tf.receiverSiteCustomerMdmId : null,
                                jobSiteMdmId: tf.receiverSiteResolutionKind === SiteEntityTypeEnum.JobSite ? tf.receiverSiteJobSiteMdmId : null,
                                productionSiteId: tf.receiverSiteResolutionKind === SiteEntityTypeEnum.ProductionSite ? tf.receiverSiteProductionSiteId : null,
                                unreferencedCustomer: tf.receiverSiteResolutionKind === SiteEntityTypeEnum.UnreferencedCustomer ? tf.receiverSiteUnreferencedCustomer : null,
                                productionSiteActivity: tf.receiverSiteResolutionKind === SiteEntityTypeEnum.ProductionSite ? tf.receiverSiteLevel2ActivityId : null
                            },
                            deliveryAddress: {
                                line1: tf.deliveryAddressLine1,
                                zipCode: tf.deliveryAddressZipCode,
                                city: tf.deliveryAddressCity
                            },
                            priceGridKind: tf.transportSellPriceKind ? PriceGridKindEnum[tf.transportSellPriceKind] : null,
                            payerSite: tf.payerOverriden ? tf.payerIdentifier : null,
                            payerActivity: tf.payerOverriden ? tf.payerLevel2ActivityId : null,
                            payerJobSiteMdmId: tf.payerOverriden ? tf.payerOverridenJobSiteMdmId : null,
                            transportBillingMode: tf.payerOverriden ? tf.transportBillingMode : null
                        });

                        setQuotationCall({
                            ...quotationCall,
                            arrivalTimeAtSenderSiteIsFixed: tf.serviceKind !== TransportServiceKindEnum.jobsiteVehicle ? tf.arrivalTimeAtSenderSiteIsFixed : null
                        });

                        const priceKindData: SelectInputItemModel = getPriceKindDataValue(tf.transportSellPriceKind);
                        setSellPriceKindData(priceKindData);
                        setBilledSellPrice({
                            billedPriceValue: tf.billedTransportPrice,
                            billedUnitPriceValue: tf.billedTransportUnitPrice,
                            billedUnitPriceIsLocked: tf.billedTransportUnitPriceIsLocked
                        });

                        setNegotiatedPurchasePriceValue(tf.negotiatedPurchaseUnitPrice);
                        setNegotiatedPurchasePriceAppliesToExternalTransporters(tf.negotiatedPurchasePriceAppliesToExternalTransporters);
                        setNegotiatedPurchasePriceAppliesToInternalTransporters(tf.negotiatedPurchasePriceAppliesToInternalTransporters);
                        const negotiatedPurchasePriceKindData: SelectInputItemModel = getPurchasePriceKindDataValue(tf.negotiatedPurchasePriceKind);
                        setNegotiatedPurchasePriceKindData(negotiatedPurchasePriceKindData);

                        const hasInternalSite: boolean = tf.senderSiteResolutionKind === SiteEntityTypeEnum.ProductionSite || tf.senderSiteResolutionKind === SiteEntityTypeEnum.JobSite || tf.receiverSiteResolutionKind === SiteEntityTypeEnum.ProductionSite || tf.receiverSiteResolutionKind === SiteEntityTypeEnum.JobSite;
                        setPayerSection(tf.payerOverriden, tf.payerIdentifier, tf.payerLevel2ActivityId, tf.transportBillingMode, hasInternalSite, tf.logisticsUnitId, tf.serviceKind, data.productionSites, data.agencies);
                        setPayerZephyrJobCode(tf.payerZephyrJobCode);
                        setLoadingPopup(false);

                        if (tf.attachments.length > 0) {
                            const attachments = tf.attachments.map(x => { return { attachmentId: x.attachmentId, name: x.name, status: props.mode === updateMode ? AttachmentStatus.unchanged : AttachmentStatus.duplicate } as AttachmentArgs });
                            setAttachmentFiles(attachments);
                        }
                    });
            });
    }

    const setNegotiatedPurchasePricesFlow = (logisticsUnitId: string, vehicleTypeId: string, zephyrOrderNumber: string, startDate: Date, endDate: Date
        , senderSiteAgencyId: string, senderSiteCustomerMdmId: string, senderSiteJobSiteMdmId: string, senderSiteProductionSiteId: string, senderSiteLevel2ActivityId: string
        , receiverSiteAgencyId: string, receiverSiteCustomerMdmId: string, receiverSiteJobSiteMdmId: string, receiverSiteProductionSiteId: string, receiverSiteLevel2ActivityId: string) => {
        const negotiatedPurchasePricesRequestArgs: NegotiatedPurchasePricesRequestArgs = {
            logisticsUnitId: logisticsUnitId,
            vehicleTypeId: vehicleTypeId,
            zephyrOrderNumber: zephyrOrderNumber,
            startDate: startDate,
            endDate: endDate,
            senderSite: {
                agencyId: senderSiteAgencyId ? senderSiteAgencyId : null,
                customerMdmId: senderSiteCustomerMdmId ? senderSiteCustomerMdmId : null,
                jobSiteMdmId: senderSiteJobSiteMdmId ? senderSiteJobSiteMdmId : null,
                productionSiteId: senderSiteProductionSiteId ? senderSiteProductionSiteId : null,
                level2ActivityId: senderSiteLevel2ActivityId ? senderSiteLevel2ActivityId : null
            },
            receiverSite: {
                agencyId: receiverSiteAgencyId ? receiverSiteAgencyId : null,
                customerMdmId: receiverSiteCustomerMdmId ? receiverSiteCustomerMdmId : null,
                jobSiteMdmId: receiverSiteJobSiteMdmId ? receiverSiteJobSiteMdmId : null,
                productionSiteId: receiverSiteProductionSiteId ? receiverSiteProductionSiteId : null,
                level2ActivityId: receiverSiteLevel2ActivityId ? receiverSiteLevel2ActivityId : null
            }
        };

        TransportFlowApiClient.GetNegotiatedPurchasePrices(negotiatedPurchasePricesRequestArgs)
            .then(res => {
                setNegotiatedPurchasePrices(res.data);
            })
    }
    const setPayerSection = (payerOverriden: boolean, payerIdentifier: string, payerLevel2ActivityId: string, transportBillingMode: string, hasInternalSite: boolean, logisticsUnitId: string, serviceKind: string
        , productionSites: Array<ProductionSiteLightModel>, agencies: Array<AgencyLightModel>): void => {
        if (serviceKind !== TransportServiceKindEnum.jobsiteVehicle) {
            const payerSiteArray: PayerSiteModel[] = [];

            productionSites.forEach((element: ProductionSiteLightModel) => {
                payerSiteArray.push({
                    payerType: SiteEntityTypeEnum.ProductionSite,
                    label: element.label,
                    value: element.productionSiteId
                });
            });

            agencies.forEach((element: AgencyLightModel) => {
                payerSiteArray.push({
                    payerType: SiteEntityTypeEnum.Agency,
                    label: element.label,
                    value: element.agencyId
                });
            });

            const payerSiteArraySorted = payerSiteArray.sort((a, b) => a.label.localeCompare(b.label));

            if (hasInternalSite) {
                payerSiteArraySorted.unshift({ label: "Automatique", value: "Automatic" });
            }

            setPayerSiteList(payerSiteArraySorted);

            if (payerOverriden) {
                const payerSite = payerSiteArray.find(x => x.value === payerIdentifier);
                setPayerSite(payerSite);

                TransportFlowApiClient.SearchLevel2ActivityList(payerIdentifier, logisticsUnitId)
                    .then((res) => {
                        const productionSiteActivityList: SelectInputItemModel[] = [];
                        res.data.forEach((element: Level2ActivityLightModel) => {
                            productionSiteActivityList.push({ label: element.label, value: element.id });
                        });

                        setPayerLevel2ActivityList(productionSiteActivityList);
                        const payerActivity = productionSiteActivityList.find(x => x.value === payerLevel2ActivityId)
                        setPayerLevel2Activity(payerActivity ?? null);

                        const tBillingMode = transportBillingMode ? TransportBillingModeEnum[transportBillingMode] : null;
                        setTransportBillingMode(FixedLists.transportBillingModeList.find(x => x.value === tBillingMode));
                    });
            } else {
                setPayerSite({ label: "Automatique", value: "Automatic" });
            }
        }
    }

    const setFLowPriorityAndDeliveryDatesAndServiceKind = (flowPriority: string, flowStartDate: Date, flowEndDate: Date, flowServiceKind: string, flowIsPerishableProduct: boolean): void => {
        flowPriority === "High" ? setPriority(TransportPriorityEnum.high) : (flowPriority === "Low" ? setPriority(TransportPriorityEnum.low) : (flowPriority === "Medium" ? setPriority(TransportPriorityEnum.medium) : null));

        const serviceKindList = FixedLists.serviceKindList;

        switch (flowPriority) {
            case TransportPriorityEnum.low:
                serviceKindList.find(x => x.value === perishable).disabled = true;
                serviceKindList.find(x => x.value === notPerishable).disabled = false;
                serviceKindList.find(x => x.value === removal).disabled = false;
                serviceKindList.find(x => x.value === jobsiteVehicle).disabled = true;
                break;

            case TransportPriorityEnum.medium:
                serviceKindList.find(x => x.value === perishable).disabled = false;
                serviceKindList.find(x => x.value === notPerishable).disabled = false;
                serviceKindList.find(x => x.value === removal).disabled = false;
                serviceKindList.find(x => x.value === jobsiteVehicle).disabled = true;
                break;

            default:
                serviceKindList.find(x => x.value === perishable).disabled = false;
                serviceKindList.find(x => x.value === notPerishable).disabled = false;
                serviceKindList.find(x => x.value === removal).disabled = false;
                serviceKindList.find(x => x.value === jobsiteVehicle).disabled = false;
        }

        switch (flowServiceKind) {
            case TransportServiceKindEnum.jobsiteVehicle:
                setServiceKind({
                    selectedServiceKind: serviceKindList.find(x => x.value === jobsiteVehicle),
                    serviceKindList: serviceKindList
                });
                break;

            case TransportServiceKindEnum.removal:
                setServiceKind({
                    selectedServiceKind: serviceKindList.find(x => x.value === removal),
                    serviceKindList: serviceKindList
                });
                break;

            case TransportServiceKindEnum.delivery:
                if (flowIsPerishableProduct) {
                    setServiceKind({
                        selectedServiceKind: serviceKindList.find(x => x.value === perishable),
                        serviceKindList: serviceKindList
                    });
                } else {
                    setServiceKind({
                        selectedServiceKind: serviceKindList.find(x => x.value === notPerishable),
                        serviceKindList: serviceKindList
                    });
                }
                break;

            default:
                setServiceKind({ selectedServiceKind: null, serviceKindList: serviceKindList });
        }

        const startDate: Date = new Date(flowStartDate);
        const endDate: Date = new Date(flowEndDate);

        setDateConfig({
            ...dateConfig,
            startDateValue: startDate,
            endDateValue: endDate,
            minEndDateValue: flowPriority === TransportPriorityEnum.low ? endDate : startDate,
        });
    }
    const setFlowLogisticsUnitAndListDependedOnLogisticsUnit = (logisticsUnitId: string, productLevel3SegmentationId: string): void => {
        getUserLogisticsUnits(logisticsUnitId);
        getLevel3SegList(logisticsUnitId, productLevel3SegmentationId);
    }
    const setFlowSenderSite = (logisticsUnitId: string,
        senderSiteResolutionKind: string,
        senderSiteProductionSiteId: string,
        senderSiteAgencyId: string,
        senderSiteJobSiteMdmId: string,
        senderSiteJobSiteLabel: string,
        senderSiteCustomerMdmId: string,
        senderSiteUnreferencedCustomer: string,
        senderSiteUnreferencedCustomerSite: string,
        senderLevel2ActivityId: string,
        pickupFreeFormAddress: string,
        pickupAddressLine1: string,
        pickupAddressLine2: string,
        pickupAddressZipCode: string,
        pickupAddressCity: string,
        receiverSiteKind: string,
        receiverSiteProductionSiteId: string,
        productionSites: Array<ProductionSiteLightModel>,
        agencies: Array<AgencyLightModel>,
        referencedCustomers: Array<ReferencedCustomerLightModel>): void => {

        const pickupAddress: AddressModel = { freeFormAddress: pickupFreeFormAddress, line1: pickupAddressLine1, line2: pickupAddressLine2, zipCode: pickupAddressZipCode, city: pickupAddressCity };

        switch (senderSiteResolutionKind) {
            case SiteEntityTypeEnum.ProductionSite:
                TransportFlowApiClient.SearchLevel2ActivityList(senderSiteProductionSiteId, logisticsUnitId)
                    .then((res) => {
                        const senderSiteArray: SelectInputItemModel[] = [];
                        productionSites.forEach((element: ProductionSiteLightModel) => {
                            let disabled = false;
                            if (receiverSiteKind === SiteEntityTypeEnum.ProductionSite)
                                if (element.productionSiteId === receiverSiteProductionSiteId)
                                    disabled = true;

                            senderSiteArray.push({
                                label: element.label,
                                value: element.productionSiteId,
                                freeFormAddress: element.freeFormAddress,
                                line1: element.line1,
                                line2: element.line2,
                                city: element.city,
                                zipCode: element.zipCode,
                                agencyId: element.agencyId,
                                latitude: element.latitude,
                                longitude: element.longitude,
                                disabled: disabled
                            });
                        });
                        let senderProductionSite: SelectInputItemModel = senderSiteArray.find(x => x.value === senderSiteProductionSiteId);

                        const senderLevel2ActivityArray: SelectInputItemModel[] = [];
                        res.data.forEach((element: Level2ActivityLightModel) => {
                            senderLevel2ActivityArray.push({ label: element.label, value: element.id });
                        });
                        let senderLevel2Activity: SelectInputItemModel = senderLevel2ActivityArray.find(x => x.value === senderLevel2ActivityId);

                        if (senderProductionSite && senderLevel2Activity) {
                            setSenderSite({
                                ...senderSite,
                                productionSite: senderProductionSite,
                                siteList: senderSiteArray,
                                productionSiteActivity: senderLevel2Activity,
                                productionSiteActivities: senderLevel2ActivityArray,
                                siteKind: FixedLists.siteKindList.find(x => x.value === productionSite)
                            });
                            setPickupAddress(pickupAddress);
                            if (failedPickupAddress)
                                setFailedPickupAddress(false);

                        } else if (!senderProductionSite && senderLevel2Activity) {
                            TransportFlowApiClient.GetProductionSiteById(senderSiteProductionSiteId)
                                .then((res) => {
                                    senderProductionSite = {
                                        label: res.data.label,
                                        value: res.data.productionSiteId,
                                        freeFormAddress: res.data.freeFormAddress,
                                        line1: res.data.line1,
                                        city: res.data.city,
                                        zipCode: res.data.zipCode,
                                        agencyId: res.data.agencyId
                                    };
                                    senderSiteArray.push(senderProductionSite);
                                    setSenderSite({
                                        ...senderSite,
                                        productionSite: senderProductionSite,
                                        siteList: senderSiteArray,
                                        productionSiteActivity: senderLevel2Activity,
                                        productionSiteActivities: senderLevel2ActivityArray,
                                        siteKind: FixedLists.siteKindList.find(x => x.value === productionSite)
                                    });
                                    setPickupAddress(pickupAddress);
                                    if (failedPickupAddress)
                                        setFailedPickupAddress(false);
                                });
                        } else if (senderProductionSite && !senderLevel2Activity) {
                            TransportFlowApiClient.GetLevel2ActivityById(senderLevel2ActivityId)
                                .then((res) => {
                                    senderLevel2Activity = {
                                        label: res.data.label === undefined ? "" : res.data.label,
                                        value: res.data.id === undefined ? "" : res.data.id
                                    }
                                    senderLevel2ActivityArray.push(senderLevel2Activity);
                                    setSenderSite({
                                        ...senderSite,
                                        productionSite: senderProductionSite,
                                        siteList: senderSiteArray,
                                        productionSiteActivity: senderLevel2Activity,
                                        productionSiteActivities: senderLevel2ActivityArray,
                                        siteKind: FixedLists.siteKindList.find(x => x.value === productionSite)
                                    });
                                    setPickupAddress(pickupAddress);
                                    if (failedPickupAddress)
                                        setFailedPickupAddress(false);
                                });
                        } else {
                            TransportFlowApiClient.GetProductionSiteByIdAndLevel2ActivityById(senderSiteProductionSiteId, senderLevel2ActivityId)
                                .then((res) => {
                                    senderProductionSite = {
                                        label: res[0].data.label,
                                        value: res[0].data.productionSiteId,
                                        freeFormAddress: res[0].data.freeFormAddress,
                                        line1: res[0].data.line1,
                                        city: res[0].data.city,
                                        zipCode: res[0].data.zipCode,
                                        agencyId: res[0].data.agencyId
                                    };
                                    senderSiteArray.push(senderProductionSite);
                                    senderLevel2Activity = {
                                        label: res[1].data.label === undefined ? "" : res[1].data.label,
                                        value: res[1].data.id === undefined ? "" : res[1].data.id
                                    }
                                    senderLevel2ActivityArray.push(senderLevel2Activity);
                                    setSenderSite({
                                        ...senderSite,
                                        productionSite: senderProductionSite,
                                        siteList: senderSiteArray,
                                        productionSiteActivity: senderLevel2Activity,
                                        productionSiteActivities: senderLevel2ActivityArray,
                                        siteKind: FixedLists.siteKindList.find(x => x.value === productionSite)
                                    });
                                    setPickupAddress(pickupAddress);
                                    if (failedPickupAddress)
                                        setFailedPickupAddress(false);
                                });
                        }
                    });

                break;

            case SiteEntityTypeEnum.JobSite:
                {
                    const senderSiteAgencyArray: SelectInputItemModel[] = [];
                    agencies.forEach((element: AgencyLightModel) => {
                        senderSiteAgencyArray.push({ label: element.label, value: element.agencyId });
                    });
                    let agency: SelectInputItemModel = senderSiteAgencyArray.find(x => x.value === senderSiteAgencyId);
                    if (!agency) {
                        TransportFlowApiClient.GetAgencyById(senderSiteAgencyId)
                            .then((res) => {
                                agency = { label: res.data.label, value: res.data.agencyId };
                                senderSiteAgencyArray.push(agency);
                                setSenderSite({
                                    ...senderSite,
                                    siteAgencyList: senderSiteAgencyArray,
                                    selectedSiteAgency: agency,
                                    jobSiteIdmdm: senderSiteJobSiteMdmId,
                                    jobSiteLabel: senderSiteJobSiteLabel,
                                    siteKind: FixedLists.siteKindList.find(x => x.value === jobSite)
                                });
                                setPickupAddress(pickupAddress);
                                if (failedPickupAddress)
                                    setFailedPickupAddress(false);
                            });
                    } else {
                        setSenderSite({
                            ...senderSite,
                            siteAgencyList: senderSiteAgencyArray,
                            selectedSiteAgency: agency,
                            jobSiteIdmdm: senderSiteJobSiteMdmId,
                            jobSiteLabel: senderSiteJobSiteLabel,
                            siteKind: FixedLists.siteKindList.find(x => x.value === jobSite)
                        });
                        setPickupAddress(pickupAddress);
                        if (failedPickupAddress)
                            setFailedPickupAddress(false);
                    }
                }

                break;

            case SiteEntityTypeEnum.Customer:
                {
                    const senderSiteReferencedCustomerArray: SelectInputItemModel[] = [];
                    referencedCustomers.forEach((element: ReferencedCustomerLightModel) => {
                        senderSiteReferencedCustomerArray.push(createReferencedCustomerItem(element));
                    });
                    let senderCustomer: SelectInputItemModel = senderSiteReferencedCustomerArray.find(x => x.value === senderSiteCustomerMdmId);
                    if (senderCustomer) {
                        setSenderSite({
                            ...senderSite,
                            siteKind: FixedLists.siteKindList.find(x => x.value === referencedCustomer),
                            referencedSiteCustomerList: senderSiteReferencedCustomerArray,
                            referencedSiteCustomer: senderCustomer
                        });
                        setPickupAddress(pickupAddress);
                        if (failedPickupAddress)
                            setFailedPickupAddress(false);
                    } else {
                        TransportFlowApiClient.GetCustomerById(senderSiteCustomerMdmId)
                            .then((res) => {
                                senderCustomer = createReferencedCustomerItem(res.data);
                                senderSiteReferencedCustomerArray.push(senderCustomer);
                                setSenderSite({
                                    ...senderSite,
                                    siteKind: FixedLists.siteKindList.find(x => x.value === referencedCustomer),
                                    referencedSiteCustomerList: senderSiteReferencedCustomerArray,
                                    referencedSiteCustomer: senderCustomer
                                });
                                setPickupAddress(pickupAddress);
                                if (failedPickupAddress)
                                    setFailedPickupAddress(false);
                            });
                    }
                }

                break;

            case SiteEntityTypeEnum.UnreferencedCustomer:
                setSenderSite({
                    ...senderSite,
                    unReferencedSiteCustomer: senderSiteUnreferencedCustomer,
                    unReferencedSiteCustomerSite: senderSiteUnreferencedCustomerSite,
                    siteKind: FixedLists.siteKindList.find(x => x.value === unreferencedCustomer)
                });
                setPickupAddress(pickupAddress);
                if (failedPickupAddress)
                    setFailedPickupAddress(false);

                break;

            default:
                setSenderSite({
                    siteKind: null,
                    siteKindList: FixedLists.siteKindList,
                    siteList: [],
                    productionSite: null,
                    productionSiteActivities: [],
                    productionSiteActivity: null,
                    unReferencedSiteCustomer: null,
                    unReferencedSiteCustomerSite: null,
                    referencedSiteCustomerList: [],
                    referencedSiteCustomer: null,
                    siteAgencyList: [],
                    selectedSiteAgency: null,
                    jobSiteIdmdm: null,
                    jobSiteLabel: null
                });
                setPickupAddress(pickupAddress);
                if (failedPickupAddress)
                    setFailedPickupAddress(false);
        }
    }
    const setFlowReceiverSite = (logisticsUnitId: string,
        receiverSiteResolutionKind: string,
        receiverSiteProductionSiteId: string,
        receiverSiteAgencyId: string,
        receiverSiteJobSiteMdmId: string,
        receiverSiteJobSiteLabel: string,
        receiverSiteCustomerMdmId: string,
        receiverSiteUnreferencedCustomer: string,
        receiverSiteUnreferencedCustomerSite: string,
        receiverLevel2ActivityId: string,
        deliveryFreeFormAddress: string,
        deliveryAddressLine1: string,
        deliveryAddressLine2: string,
        deliveryAddressZipCode: string,
        deliveryAddressCity: string,
        senderSiteKind: string,
        senderSiteProductionSiteId: string,
        productionSites: Array<ProductionSiteLightModel>,
        agencies: Array<AgencyLightModel>,
        referencedCustomers: Array<ReferencedCustomerLightModel>): void => {

        const deliveryAddress: AddressModel = { freeFormAddress: deliveryFreeFormAddress, line1: deliveryAddressLine1, line2: deliveryAddressLine2, zipCode: deliveryAddressZipCode, city: deliveryAddressCity };

        switch (receiverSiteResolutionKind) {
            case SiteEntityTypeEnum.ProductionSite:
                TransportFlowApiClient.SearchLevel2ActivityList(receiverSiteProductionSiteId, logisticsUnitId)
                    .then((res) => {
                        const receiverSiteArray: SelectInputItemModel[] = [];
                        productionSites.forEach((element: ProductionSiteLightModel) => {
                            let disabled = false;
                            if (senderSiteKind === SiteEntityTypeEnum.ProductionSite)
                                if (element.productionSiteId === senderSiteProductionSiteId)
                                    disabled = true;

                            receiverSiteArray.push({
                                label: element.label,
                                value: element.productionSiteId,
                                freeFormAddress: element.freeFormAddress,
                                line1: element.line1,
                                line2: element.line2,
                                city: element.city,
                                zipCode: element.zipCode,
                                agencyId: element.agencyId,
                                latitude: element.latitude,
                                longitude: element.longitude,
                                disabled: disabled
                            });
                        });
                        let receiverProductionSite: SelectInputItemModel = receiverSiteArray.find(x => x.value === receiverSiteProductionSiteId);

                        const receiverLevel2ActivityArray: SelectInputItemModel[] = [];
                        res.data.forEach((element: Level2ActivityLightModel) => {
                            receiverLevel2ActivityArray.push({ label: element.label, value: element.id });
                        });
                        let receiverLevel2Activity: SelectInputItemModel = receiverLevel2ActivityArray.find(x => x.value === receiverLevel2ActivityId);

                        if (receiverProductionSite && receiverLevel2Activity) {
                            setReceiverSite({
                                ...receiverSite,
                                productionSite: receiverProductionSite,
                                siteList: receiverSiteArray,
                                productionSiteActivity: receiverLevel2Activity,
                                productionSiteActivities: receiverLevel2ActivityArray,
                                siteKind: FixedLists.siteKindList.find(x => x.value === productionSite)
                            });
                            setDeliveryAddress(deliveryAddress);

                            if (failedDeliveryAddress)
                                setFailedDeliveryAddress(false);
                        } else if (!receiverProductionSite && receiverLevel2Activity) {
                            TransportFlowApiClient.GetProductionSiteById(receiverSiteProductionSiteId)
                                .then((res) => {
                                    receiverProductionSite = {
                                        label: res.data.label,
                                        value: res.data.productionSiteId,
                                        freeFormAddress: res.data.freeFormAddress,
                                        line1: res.data.line1,
                                        city: res.data.city,
                                        zipCode: res.data.zipCode,
                                        agencyId: res.data.agencyId
                                    };
                                    receiverSiteArray.push(receiverProductionSite);
                                    setReceiverSite({
                                        ...receiverSite,
                                        productionSite: receiverProductionSite,
                                        siteList: receiverSiteArray,
                                        productionSiteActivity: receiverLevel2Activity,
                                        productionSiteActivities: receiverLevel2ActivityArray,
                                        siteKind: FixedLists.siteKindList.find(x => x.value === productionSite)
                                    });
                                    setDeliveryAddress(deliveryAddress);
                                    if (failedDeliveryAddress)
                                        setFailedDeliveryAddress(false);
                                });
                        } else if (receiverProductionSite && !receiverLevel2Activity) {
                            TransportFlowApiClient.GetLevel2ActivityById(receiverLevel2ActivityId)
                                .then((res) => {
                                    receiverLevel2Activity = {
                                        label: res.data.label === undefined ? "" : res.data.label,
                                        value: res.data.id === undefined ? "" : res.data.id
                                    }
                                    receiverLevel2ActivityArray.push(receiverLevel2Activity);
                                    setReceiverSite({
                                        ...receiverSite,
                                        productionSite: receiverProductionSite,
                                        siteList: receiverSiteArray,
                                        productionSiteActivity: receiverLevel2Activity,
                                        productionSiteActivities: receiverLevel2ActivityArray,
                                        siteKind: FixedLists.siteKindList.find(x => x.value === productionSite)
                                    });
                                    setDeliveryAddress(deliveryAddress);
                                    if (failedDeliveryAddress)
                                        setFailedDeliveryAddress(false);
                                });
                        } else {
                            TransportFlowApiClient.GetProductionSiteByIdAndLevel2ActivityById(receiverSiteProductionSiteId, receiverLevel2ActivityId)
                                .then(() => {
                                    TransportFlowApiClient.GetProductionSiteByIdAndLevel2ActivityById(receiverSiteProductionSiteId, receiverLevel2ActivityId)
                                        .then((res) => {
                                            receiverProductionSite = {
                                                label: res[0].data.label,
                                                value: res[0].data.productionSiteId,
                                                freeFormAddress: res[0].data.freeFormAddress,
                                                line1: res[0].data.line1,
                                                city: res[0].data.city,
                                                zipCode: res[0].data.zipCode,
                                                agencyId: res[0].data.agencyId
                                            };
                                            receiverSiteArray.push(receiverProductionSite);
                                            receiverLevel2Activity = {
                                                label: res[1].data.label === undefined ? "" : res[1].data.label,
                                                value: res[1].data.id === undefined ? "" : res[1].data.id
                                            }
                                            receiverLevel2ActivityArray.push(receiverLevel2Activity);
                                            setReceiverSite({
                                                ...receiverSite,
                                                productionSite: receiverProductionSite,
                                                siteList: receiverSiteArray,
                                                productionSiteActivity: receiverLevel2Activity,
                                                productionSiteActivities: receiverLevel2ActivityArray,
                                                siteKind: FixedLists.siteKindList.find(x => x.value === productionSite)
                                            });
                                            setDeliveryAddress(deliveryAddress);
                                            if (failedDeliveryAddress)
                                                setFailedDeliveryAddress(false);
                                        });
                                });
                        }
                    });

                break;

            case SiteEntityTypeEnum.JobSite:
                {
                    const receiverSiteAgencyArray: SelectInputItemModel[] = [];
                    agencies.forEach((element: AgencyLightModel) => {
                        receiverSiteAgencyArray.push({ label: element.label, value: element.agencyId });
                    });
                    let agency: SelectInputItemModel = receiverSiteAgencyArray.find(x => x.value === receiverSiteAgencyId);
                    if (!agency) {
                        TransportFlowApiClient.GetAgencyById(receiverSiteAgencyId)
                            .then((res) => {
                                agency = { label: res.data.label, value: res.data.agencyId };
                                receiverSiteAgencyArray.push(agency);
                                setReceiverSite({
                                    ...receiverSite,
                                    siteAgencyList: receiverSiteAgencyArray,
                                    selectedSiteAgency: agency,
                                    jobSiteIdmdm: receiverSiteJobSiteMdmId,
                                    jobSiteLabel: receiverSiteJobSiteLabel,
                                    siteKind: FixedLists.siteKindList.find(x => x.value === jobSite)
                                });
                                setDeliveryAddress(deliveryAddress);
                                if (failedDeliveryAddress)
                                    setFailedDeliveryAddress(false);
                            });
                    } else {
                        setReceiverSite({
                            ...receiverSite,
                            siteAgencyList: receiverSiteAgencyArray,
                            selectedSiteAgency: agency,
                            jobSiteIdmdm: receiverSiteJobSiteMdmId,
                            jobSiteLabel: receiverSiteJobSiteLabel,
                            siteKind: FixedLists.siteKindList.find(x => x.value === jobSite)
                        });
                        setDeliveryAddress(deliveryAddress);
                        if (failedDeliveryAddress)
                            setFailedDeliveryAddress(false);
                    }
                }

                break;

            case SiteEntityTypeEnum.Customer:
                {
                    const receiverSiteReferencedCustomerArray: SelectInputItemModel[] = [];
                    referencedCustomers.forEach((element: ReferencedCustomerLightModel) => { receiverSiteReferencedCustomerArray.push(createReferencedCustomerItem(element)) });
                    let receiverCustomer = receiverSiteReferencedCustomerArray.find(x => x.value === receiverSiteCustomerMdmId);
                    if (receiverCustomer) {
                        setReceiverSite({
                            ...receiverSite,
                            siteKind: FixedLists.siteKindList.find(x => x.value === referencedCustomer),
                            referencedSiteCustomerList: receiverSiteReferencedCustomerArray,
                            referencedSiteCustomer: receiverCustomer
                        });
                        setDeliveryAddress(deliveryAddress);
                        if (failedDeliveryAddress)
                            setFailedDeliveryAddress(false);
                    } else {
                        TransportFlowApiClient.GetCustomerById(receiverSiteCustomerMdmId)
                            .then((res) => {
                                receiverCustomer = createReferencedCustomerItem(res.data);
                                receiverSiteReferencedCustomerArray.push(receiverCustomer);
                                setReceiverSite({
                                    ...receiverSite,
                                    siteKind: FixedLists.siteKindList.find(x => x.value === referencedCustomer),
                                    referencedSiteCustomerList: receiverSiteReferencedCustomerArray,
                                    referencedSiteCustomer: receiverCustomer
                                });
                                setDeliveryAddress(deliveryAddress);
                                if (failedDeliveryAddress)
                                    setFailedDeliveryAddress(false);
                            });
                    }
                }

                break;

            case SiteEntityTypeEnum.UnreferencedCustomer:
                setReceiverSite({
                    ...receiverSite,
                    unReferencedSiteCustomer: receiverSiteUnreferencedCustomer,
                    unReferencedSiteCustomerSite: receiverSiteUnreferencedCustomerSite,
                    siteKind: FixedLists.siteKindList.find(x => x.value === unreferencedCustomer)
                });
                setDeliveryAddress(deliveryAddress);
                if (failedDeliveryAddress)
                    setFailedDeliveryAddress(false);

                break;

            default:
                setReceiverSite({
                    siteKind: null,
                    siteKindList: FixedLists.siteKindList,
                    siteList: [],
                    productionSite: null,
                    productionSiteActivities: [],
                    productionSiteActivity: null,
                    unReferencedSiteCustomer: null,
                    unReferencedSiteCustomerSite: null,
                    referencedSiteCustomerList: [],
                    referencedSiteCustomer: null,
                    siteAgencyList: [],
                    selectedSiteAgency: null,
                    jobSiteIdmdm: null,
                    jobSiteLabel: null
                });
                setDeliveryAddress(deliveryAddress);
                if (failedDeliveryAddress)
                    setFailedDeliveryAddress(false);
        }
    }
    const setFlowRequester = (requestedByIsExternal: boolean, requestedByContactId: string, requestedByName: string, logisticsUnitId: string, senderSiteCustomerMdmId: string, receiverSiteCustomerMdmId: string): void => {
        if (!requestedByIsExternal) {
            const contactRequestArgs: ContactRequestArgs = {
                logisticsUnitId: logisticsUnitId,
                isInternal: true,
                referencedCustomerMdmId: null
            };
            TransportFlowApiClient.SearchRequestedByList(contactRequestArgs)
                .then((res) => {
                    const requestedByList: SelectInputItemModel[] = [];
                    res.data.forEach((element: ContactLightModel) => {
                        requestedByList.push({
                            label: getFullName(element),
                            value: element.contactId
                        });
                    });
                    let requestedByContact: SelectInputItemModel = requestedByList.find(x => x.label === requestedByName);
                    if (requestedByContact) {
                        setRequestedBy({
                            ...requestedBy,
                            contactType: { label: "Interne", value: internal },
                            contact: requestedByContact,
                            contactList: requestedByList
                        });
                    } else {
                        if (requestedByName) {
                            const requesterName: string[] = requestedByName.split(' ');
                            const lastName: string = requesterName[0];
                            const firstName: string = requesterName.length > 1 ? requesterName[1] : "";
                            TransportFlowApiClient.GetContactByFullName(lastName, firstName, true)
                                .then((res) => {
                                    let undefinedContact = false;
                                    if (res.data)
                                        requestedByContact = { label: res.data.lastName + ' ' + res.data.firstName, value: res.data.contactId };
                                    else {
                                        requestedByContact = { label: requestedByName, value: requestedByName };
                                        undefinedContact = true;
                                    }
                                    if (res.data.isEnabled == true)
                                        requestedByList.push(requestedByContact);

                                    setRequestedBy({
                                        contactType: { label: "Interne", value: internal },
                                        contact: requestedByContact,
                                        contactList: requestedByList,
                                        undefinedContact: undefinedContact
                                    });
                                });
                        } else {
                            setRequestedBy({
                                ...requestedBy,
                                contactType: { label: "Interne", value: internal },
                                contact: null,
                                contactList: requestedByList
                            });
                        }
                    }
                });
        } else {
            if (requestedByContactId) {
                let referencedCustomerMdmIdValue: string = null;
                if (senderSiteCustomerMdmId && !receiverSiteCustomerMdmId) {
                    referencedCustomerMdmIdValue = senderSiteCustomerMdmId;
                } else if ((!senderSiteCustomerMdmId && receiverSiteCustomerMdmId)) {
                    referencedCustomerMdmIdValue = receiverSiteCustomerMdmId;
                }

                const contactRequestArgs: ContactRequestArgs = {
                    logisticsUnitId: logisticsUnitId,
                    isInternal: false,
                    referencedCustomerMdmId: referencedCustomerMdmIdValue
                };
                TransportFlowApiClient.SearchRequestedByList(contactRequestArgs)
                    .then((res) => {
                        const requestedByList: SelectInputItemModel[] = [];
                        res.data.forEach((element: ContactLightModel) => {
                            requestedByList.push({
                                label: getFullName(element),
                                value: element.contactId
                            });
                        });
                        let requestedByContact: SelectInputItemModel = requestedByList.find(x => x.label === requestedByName);
                        if (requestedByContact) {
                            setRequestedBy({
                                ...requestedBy,
                                contactType: { label: "Externe référencé", value: externalReferenced },
                                contact: requestedByContact,
                                contactList: requestedByList
                            });
                        } else {
                            if (requestedByName) {
                                const requesterName: string[] = requestedByName.split(' ');
                                const lastName: string = requesterName[0];
                                const firstName: string = requesterName.length > 1 ? requesterName[1] : "";
                                TransportFlowApiClient.GetContactByFullName(lastName, firstName, false)
                                    .then((res) => {
                                        let undefinedContact = false;
                                        if (res.data) {
                                            requestedByContact = { label: getFullName(res.data), value: res.data.contactId };
                                        } else {
                                            requestedByContact = { label: requestedByName, value: requestedByName };
                                            undefinedContact = true;
                                        }
                                        if (res.data.isEnabled == true)
                                            requestedByList.push(requestedByContact);
                                        setRequestedBy({
                                            contactType: { label: "Externe référencé", value: externalReferenced },
                                            contact: requestedByContact,
                                            contactList: requestedByList,
                                            undefinedContact: undefinedContact
                                        });
                                    });
                            } else {
                                setRequestedBy({
                                    ...requestedBy,
                                    contactType: { label: "Externe référencé", value: externalReferenced },
                                    contact: null,
                                    contactList: requestedByList
                                });
                            }
                        }
                    });
            } else {
                setRequestedBy({
                    ...requestedBy,
                    contactType: { label: "Externe non référencé", value: externalNotReferenced },
                    contact: { label: requestedByName, value: null }
                });
            }
        }
    }
    const setFlowBeneficiary = (beneficiaryIsExternal: boolean, beneficiaryContactId: string, beneficiaryName: string, beneficiaryPhoneNumber: string, logisticsUnitId: string, senderSiteCustomerMdmId: string, receiverSiteCustomerMdmId: string, internalBeneficiariesData: Array<ContactLightModel>): void => {
        if (!beneficiaryIsExternal) {
            const beneficiaryList: SelectInputItemModel[] = [];
            internalBeneficiariesData.forEach((element: ContactLightModel) => {
                beneficiaryList.push({
                    label: getFullName(element),
                    value: element.contactId,
                    phoneNumber: element.phoneNumber
                });
            });
            let beneficiaryContact: SelectInputItemModel = beneficiaryList.find(x => x.label === beneficiaryName);
            if (beneficiaryContact) {
                setBeneficiary({
                    ...beneficiary,
                    contactType: { label: "Interne", value: internal },
                    contact: beneficiaryContact,
                    contactList: beneficiaryList,
                    contactPhoneNumber: beneficiaryPhoneNumber
                });
            } else {
                if (beneficiaryName) {
                    const _beneficiaryName: string[] = beneficiaryName.split(' ');
                    const lastName: string = _beneficiaryName[0];
                    const firstName: string = _beneficiaryName.length > 1 ? _beneficiaryName[1] : "";
                    TransportFlowApiClient.GetContactByFullName(lastName, firstName, true)
                        .then((res) => {
                            let undefinedContact = false;
                            if (res.data)
                                beneficiaryContact = { label: res.data.lastName + ' ' + res.data.firstName, value: res.data.contactId };
                            else {
                                beneficiaryContact = { label: beneficiaryName, value: beneficiaryName };
                                undefinedContact = true;
                            }
                            if (res.data.isEnabled == true)
                                beneficiaryList.push(beneficiaryContact);
                            setBeneficiary({
                                ...beneficiary,
                                contactType: { label: "Interne", value: internal },
                                contact: beneficiaryContact,
                                contactList: beneficiaryList,
                                contactPhoneNumber: beneficiaryPhoneNumber,
                                undefinedContact: undefinedContact
                            });
                        });
                } else {
                    setBeneficiary({
                        ...beneficiary,
                        contactType: { label: "Interne", value: internal },
                        contact: null,
                        contactList: beneficiaryList,
                        contactPhoneNumber: beneficiaryPhoneNumber
                    });
                }
            }
        } else {
            if (beneficiaryContactId) {
                let referencedCustomerMdmIdValue: string = null;
                if (senderSiteCustomerMdmId && !receiverSiteCustomerMdmId) {
                    referencedCustomerMdmIdValue = senderSiteCustomerMdmId;
                } else if ((!senderSiteCustomerMdmId && receiverSiteCustomerMdmId)) {
                    referencedCustomerMdmIdValue = receiverSiteCustomerMdmId;
                }

                const contactRequestArgs: ContactRequestArgs = {
                    logisticsUnitId: logisticsUnitId,
                    isInternal: false,
                    referencedCustomerMdmId: referencedCustomerMdmIdValue
                };
                TransportFlowApiClient.SearchBeneficiaryList(contactRequestArgs)
                    .then((res) => {
                        const beneficiaryList: SelectInputItemModel[] = [];
                        res.data.forEach((element: ContactLightModel) => {
                            beneficiaryList.push({
                                label: getFullName(element),
                                value: element.contactId,
                                phoneNumber: element.phoneNumber
                            });
                        });
                        let beneficiaryContact: SelectInputItemModel = beneficiaryList.find(x => x.label === beneficiaryName);
                        if (beneficiaryContact) {
                            setBeneficiary({
                                ...beneficiary,
                                contactType: { label: "Externe référencé", value: externalReferenced },
                                contact: beneficiaryContact,
                                contactList: beneficiaryList,
                                contactPhoneNumber: beneficiaryPhoneNumber
                            });
                        } else {
                            if (beneficiaryName) {
                                const _beneficiaryName: string[] = beneficiaryName.split(' ');
                                const lastName: string = _beneficiaryName[0];
                                const firstName: string = _beneficiaryName.length > 1 ? _beneficiaryName[1] : "";
                                TransportFlowApiClient.GetContactByFullName(lastName, firstName, false)
                                    .then((res) => {
                                        let undefinedContact = false;
                                        if (res.data)
                                            beneficiaryContact = { label: getFullName(res.data), value: res.data.contactId };
                                        else {
                                            beneficiaryContact = { label: beneficiaryName, value: beneficiaryName };
                                            undefinedContact = true;
                                        }
                                        if (res.data.isEnabled == true)
                                            beneficiaryList.push(beneficiaryContact);
                                        setBeneficiary({
                                            ...beneficiary,
                                            contactType: { label: "Externe référencé", value: externalReferenced },
                                            contact: beneficiaryContact,
                                            contactList: beneficiaryList,
                                            contactPhoneNumber: beneficiaryPhoneNumber,
                                            undefinedContact: undefinedContact
                                        });
                                    });
                            } else {
                                setBeneficiary({
                                    ...beneficiary,
                                    contactType: { label: "Externe référencé", value: externalReferenced },
                                    contact: beneficiaryContact,
                                    contactList: beneficiaryList,
                                    contactPhoneNumber: beneficiaryPhoneNumber
                                });
                            }
                        }
                    });
            } else {
                setBeneficiary({
                    ...beneficiary,
                    contactType: { label: "Externe non référencé", value: externalNotReferenced },
                    contact: { label: beneficiaryName, value: null },
                    contactPhoneNumber: beneficiaryPhoneNumber
                });
            }
        }
    }

    const resetForm = (): void => {
        deleteNewAttachmentFiles(attachmentFiles);
        setFlowData();
    }

    const getTransportRequestList = (logisticsUnitId: string, startDate: Date, endDate: Date, transportRequestId?: string): void => {
        TransportFlowApiClient.SearchTransportRequestsByDates(startDate, endDate.getDayEnd(), logisticsUnitId)
            .then((res) => {
                const transportRequestList: SelectInputItemModel[] = [];
                res.data.forEach((element: TransportRequestLightModel) => {
                    transportRequestList.push({
                        label: element.businessId + " / " + element.productCode + " / " + element.requestedQuantity + "T",
                        value: element.transportRequestId,
                        logisticsUnitId: element.logisticsUnitId
                    });
                });

                const selectedTransportRequest = transportRequestList.find(x => x.value === transportRequestId);
                setTransportRequest({
                    transportRequestList: transportRequestList,
                    selectedTransportRequest: props.mode === duplicateMode && transportRequestId && selectedTransportRequest ? selectedTransportRequest : null
                });
            });
    }

    const handleTransportRequestChange = (e: SelectInputItemModel): void => {
        if (!e) {
            setTransportRequest({ ...transportRequest, selectedTransportRequest: null });
            setTransportersInstructions(null);
            return;
        }

        setTransportRequest({ ...transportRequest, selectedTransportRequest: { label: e.label, value: e.value } });

        TransportFlowApiClient.GetTransportRequestDeliveryRemarks(e.value)
            .then((res) => {
                if (res.data)
                    setTransportersInstructions(res.data);
            });
    }

    const getUserLogisticsUnits = (logisticsUnitId?: string): void => {
        const userLogisticsUnitList: SelectInputItemModel[] = props.logisticsUnits.map((x): SelectInputItemModel => { return { label: x.label, value: x.logisticsUnitId } });

        if (props.mode !== creationMode && logisticsUnitId) {
            const logisticsUnit: SelectInputItemModel = userLogisticsUnitList.find(x => x.value === logisticsUnitId);
            if (logisticsUnit) {
                setLogisticsUnit({
                    userLogisticsUnitList: userLogisticsUnitList,
                    selectedLogisticsUnit: logisticsUnit
                });
            } else {
                TransportFlowApiClient.GetLogisticsUnitById(logisticsUnitId)
                    .then((res) => {
                        const ta: SelectInputItemModel = { label: res.data.label, value: res.data.logisticsUnitId };
                        userLogisticsUnitList.push(ta);
                        setLogisticsUnit({
                            userLogisticsUnitList: userLogisticsUnitList,
                            selectedLogisticsUnit: ta
                        });
                    })
            }
        } else {
            if (userLogisticsUnitList.length === 1) {
                setLogisticsUnit({ userLogisticsUnitList: userLogisticsUnitList, selectedLogisticsUnit: userLogisticsUnitList[0] });
                if (props.mode === creationMode) {
                    getLevel3SegList(userLogisticsUnitList[0].value);
                }
            } else {
                setLogisticsUnit({ ...logisticsUnit, userLogisticsUnitList: userLogisticsUnitList, selectedLogisticsUnit: null });
            }
        }
    }

    const getLevel3SegList = (logisticsUnitId: string, productLevel3SegmentationId?: string): void => {
        TransportFlowApiClient.SearchLevel3Seg(logisticsUnitId)
            .then((res) => {
                const level3SegList: SelectInputItemModel[] = [];
                res.data.forEach((element: Level3SegmentationLightModel) => {
                    level3SegList.push({ label: element.label, value: element.id });
                });
                let lev3SegValue: SelectInputItemModel = null;
                if (props.mode !== creationMode && productLevel3SegmentationId) {
                    lev3SegValue = level3SegList.find(x => x.value === productLevel3SegmentationId);
                    if (!lev3SegValue) {
                        TransportFlowApiClient.GetLevel3SegmentationById(productLevel3SegmentationId)
                            .then((res) => {
                                lev3SegValue = { label: res.data.label, value: res.data.id };
                                level3SegList.push(lev3SegValue);
                                setLevel3Segmentation({ level3SegList: level3SegList, level3Seg: lev3SegValue });
                            });
                    } else {
                        setLevel3Segmentation({ level3SegList: level3SegList, level3Seg: lev3SegValue });
                    }
                } else {
                    setLevel3Segmentation({ level3SegList: level3SegList, level3Seg: lev3SegValue });
                }
            });
    }

    const handleLevel3SegmentationChanged = (e: SelectInputItemModel): void => {
        setLevel3Segmentation({ ...level3Segmentation, level3Seg: e ? { label: e.label, value: e.value } : null });
    }

    const getVehicleTypes = (vehicleTypeId?: string, vehicleTypeLabel?: string, vehicleTypeGroupId?: string): void => {
        TransportFlowApiClient.GetVehicleTypes()
            .then((res) => {
                const vehicleTypeList = res.data;
                let selectedVehicleType = null;
                if (props.mode !== creationMode && vehicleTypeId) {
                    const currentVehicleType = vehicleTypeList.find(x => x.vehicleTypeId === vehicleTypeId);
                    if (currentVehicleType) {
                        selectedVehicleType = { label: currentVehicleType.label, value: currentVehicleType.vehicleTypeId };
                    }
                    else if (props.mode == updateMode) {
                        const vehicleType: VehicleTypeEstimatedLoadCapacityLightModel = {
                            vehicleTypeId: vehicleTypeId,
                            label: vehicleTypeLabel,
                            vehicleTypeGroupId: vehicleTypeGroupId
                        };
                        vehicleTypeList.push(vehicleType);
                        selectedVehicleType = { label: vehicleType.label, value: vehicleType.vehicleTypeId };
                    }
                }
                setVehicleType({ ...vehicleType, vehicleTypeList: vehicleTypeList, selectedVehicleType: selectedVehicleType });

                if (!vehicleTypeId)
                    setLoadingPopup(false);
            });
    }

    const handleVehicleTypeChanged = (e: SelectInputItemModel): void => {
        if (e?.value !== vehicleType.selectedVehicleType?.value) {
            setVehicleType({ ...vehicleType, selectedVehicleType: e ? { label: e.label, value: e.value } : null });
            clearFieldNumberOfVehicles();
        }
    }

    const handleLogisticsUnitChange = (e: SelectInputItemModel): void => {
        if (!e) {
            setLogisticsUnit({ ...logisticsUnit, selectedLogisticsUnit: null });
            setProductionSiteList([]);
            setAgenciesList([]);
            setReferencedCustomerList([]);
            setInternalBeneficiariesList([]);
            setLevel3Segmentation({ level3SegList: [], level3Seg: null });
            return;
        }

        if (e.value !== logisticsUnit.selectedLogisticsUnit?.value) {
            setLogisticsUnit({ ...logisticsUnit, selectedLogisticsUnit: { label: e.label, value: e.value } });

            searchProductionSitesWithAgenciesAndReferencedCustomersAndInternalBeneficiariesAndRequesters(e.value)
                .then((res) => {
                    if (dateConfig.startDateValue !== null && dateConfig.endDateValue !== null && startDateValid && endDateValid) {
                        getTransportRequestList(e.value, dateConfig.startDateValue, dateConfig.endDateValue);
                    }

                    checkAfterLogisticsUnitChanged(e.value, res.productionSites, res.agencies, res.referencedCustomers);
                });
        }
    }

    const checkAfterLogisticsUnitChanged = (logisticsUnitId: string, productionSites: Array<ProductionSiteLightModel>, agencies: Array<AgencyLightModel>, referencedCustomers: Array<ReferencedCustomerLightModel>): void => {
        getLevel3SegList(logisticsUnitId);
        if (senderSite.siteKind?.value === referencedCustomer) {
            const senderSiteReferencedCustomerArray: SelectInputItemModel[] = [];
            referencedCustomers.forEach((element: ReferencedCustomerLightModel) => {
                senderSiteReferencedCustomerArray.push(createReferencedCustomerItem(element));
            });
            setSenderSite({
                ...senderSite,
                referencedSiteCustomerList: senderSiteReferencedCustomerArray,
                productionSiteActivities: [],
                productionSiteActivity: null,
                referencedSiteCustomer: null
            });
        }
        if (receiverSite.siteKind?.value === referencedCustomer) {
            const receiverSiteReferencedCustomerArray: SelectInputItemModel[] = [];
            referencedCustomers.forEach((element: ReferencedCustomerLightModel) => {
                receiverSiteReferencedCustomerArray.push(createReferencedCustomerItem(element));
            });
            setReceiverSite({
                ...receiverSite,
                productionSiteActivities: [],
                productionSiteActivity: null,
                referencedSiteCustomerList: receiverSiteReferencedCustomerArray,
                referencedSiteCustomer: null
            });
        }
        if (senderSite.siteKind?.value === jobSite) {
            const senderSiteAgencyArray: SelectInputItemModel[] = [];
            agencies.forEach((element: AgencyLightModel) => {
                senderSiteAgencyArray.push({ label: element.label, value: element.agencyId });
            });
            setSenderSite({
                ...senderSite,
                productionSiteActivities: [],
                productionSiteActivity: null,
                siteAgencyList: senderSiteAgencyArray,
                selectedSiteAgency: null
            });
        }
        if (receiverSite.siteKind?.value === jobSite) {
            const receiverSiteAgencyArray: SelectInputItemModel[] = [];
            agencies.forEach((element: AgencyLightModel) => {
                receiverSiteAgencyArray.push({ label: element.label, value: element.agencyId });
            });
            setReceiverSite({
                ...receiverSite,
                productionSiteActivities: [],
                productionSiteActivity: null,
                siteAgencyList: receiverSiteAgencyArray,
                selectedSiteAgency: null
            });
        }
        if (senderSite.siteKind?.value === productionSite) {
            const senderSiteArray: SelectInputItemModel[] = [];
            productionSites.forEach((element: ProductionSiteLightModel) => {
                senderSiteArray.push({
                    label: element.label,
                    value: element.productionSiteId,
                    freeFormAddress: element.freeFormAddress,
                    line1: element.line1,
                    line2: element.line2,
                    city: element.city,
                    zipCode: element.zipCode,
                    agencyId: element.agencyId,
                    latitude: element.latitude,
                    longitude: element.longitude
                });
            });
            setSenderSite({
                ...senderSite,
                productionSiteActivities: [],
                productionSiteActivity: null,
                productionSite: null,
                siteList: senderSiteArray
            });
        }
        if (receiverSite.siteKind?.value === productionSite) {
            const receiverSiteArray: SelectInputItemModel[] = [];
            productionSites.forEach((element: ProductionSiteLightModel) => {
                receiverSiteArray.push({
                    label: element.label,
                    value: element.productionSiteId,
                    freeFormAddress: element.freeFormAddress,
                    line1: element.line1,
                    line2: element.line2,
                    city: element.city,
                    zipCode: element.zipCode,
                    agencyId: element.agencyId,
                    latitude: element.latitude,
                    longitude: element.longitude
                });
            });
            setReceiverSite({
                ...receiverSite,
                siteList: receiverSiteArray,
                productionSiteActivities: [],
                productionSiteActivity: null,
                productionSite: null
            });
        }
    }

    const handleStartDateChange = (event: DatePickerChangeEvent): void => {
        const selectedDate: Date = event.target.value;

        if (event.target.state.show) {
            (startDateRef.current as any).shouldFocusDateInput = false;
        }

        switch (priority) {
            case TransportPriorityEnum.low:
                setDateConfig({
                    ...dateConfig,
                    minEndDateValue: new Date(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate() + 1),
                    startDateValue: selectedDate,
                    endDateValue: (dateConfig.endDateValue !== null && selectedDate >= dateConfig.endDateValue) ? null : dateConfig.endDateValue
                });
                if (props.mode === creationMode) {
                    setArrivalSlotDuration(DefaultArrivalSlotDurationWhenCreatingWithLowOrMediumPriority);
                    setDefaultArrivalStartTimeEndTime(selectedDate);
                    setArrivalTimeAtSenderSiteIsFixed(false);
                }

                break;

            case TransportPriorityEnum.high:
            case TransportPriorityEnum.medium:
                setDateConfig({
                    ...dateConfig,
                    endDateValue: selectedDate,
                    minEndDateValue: selectedDate,
                    startDateValue: selectedDate
                });

                if (priority === TransportPriorityEnum.medium && props.mode === creationMode) {
                    setArrivalSlotDuration(DefaultArrivalSlotDurationWhenCreatingWithLowOrMediumPriority);
                    setDefaultArrivalStartTimeEndTime(selectedDate);
                }

                break;

            case null:
                setDateConfig({
                    ...dateConfig,
                    minEndDateValue: selectedDate,
                    startDateValue: selectedDate,
                    equalDates: Date.equals(event.target.value, dateConfig.endDateValue)
                });

                break;

            default:
                return;
        }

        if (arrivalTimeAtSenderSite !== null) {
            const h: number = arrivalTimeAtSenderSite.getHours();
            const m: number = arrivalTimeAtSenderSite.getMinutes();
            const inputDate: Date = event.target.value;
            const arrivalTime: Date = new Date(inputDate.getFullYear(), inputDate.getMonth(), inputDate.getDate(), h, m, 0, 0);
            setArrivalTimeAtSenderSite(isValidDate(startDateRef) ? arrivalTime : null);
        }

        if (arrivalTimeAtReceiverSite !== null) {
            const h: number = arrivalTimeAtReceiverSite.getHours();
            const m: number = arrivalTimeAtReceiverSite.getMinutes();
            const inputDate: Date = event.target.value;
            const arrivalTime: Date = new Date(inputDate.getFullYear(), inputDate.getMonth(), inputDate.getDate(), h, m, 0, 0);
            setArrivalTimeAtReceiverSite(isValidDate(startDateRef) ? arrivalTime : null);
        }
    }

    const handleEndDateChange = (event: DatePickerChangeEvent): void => {
        if (event.target.state.show) {
            (endDateRef.current as any).shouldFocusDateInput = false;
        }

        if (Date.equals(dateConfig.startDateValue, event.target.value)) {
            setDateConfig({
                ...dateConfig,
                endDateValue: event.target.value,
                equalDates: priority === null ? true : null
            });
        } else {
            setDateConfig({
                ...dateConfig,
                endDateValue: event.target.value,
                equalDates: priority === null ? false : null
            });
        }
    }

    const handleCLickPriority = (newPriority: TransportPriorityEnum): void => {
        if (priority !== newPriority) {
            const oldPriority = priority;
            setPriority(newPriority);

            if (newPriority === TransportPriorityEnum.low && startDateValid) {
                setDateConfig({
                    ...dateConfig,
                    minEndDateValue: new Date(dateConfig.startDateValue.getFullYear(), dateConfig.startDateValue.getMonth(), dateConfig.startDateValue.getDate() + 1),
                    endDateValue: dateConfig.endDateValue !== null ? (Date.equals(dateConfig.startDateValue, dateConfig.endDateValue) ? null : dateConfig.endDateValue) : null
                });
            } else {
                setDateConfig({
                    ...dateConfig,
                    endDateValue: startDateValid ? dateConfig.startDateValue : null,
                    minEndDateValue: startDateValid ? dateConfig.startDateValue : null,
                });
            }

            let serviceKindList = [...serviceKind.serviceKindList];
            serviceKindList = initialiseServiceKindList(serviceKindList);

            switch (newPriority) {
                case TransportPriorityEnum.low:
                    serviceKindList.find(x => x.value === perishable).disabled = true;
                    serviceKindList.find(x => x.value === jobsiteVehicle).disabled = true;

                    setServiceKind({
                        ...serviceKind,
                        selectedServiceKind: (serviceKind.selectedServiceKind?.value === perishable || serviceKind.selectedServiceKind?.value === jobsiteVehicle) ? null : serviceKind.selectedServiceKind,
                        serviceKindList: serviceKindList
                    });
                    if (dateConfig.startDateValue) {
                        if (oldPriority !== TransportPriorityEnum.medium) {
                            setArrivalSlotDuration(DefaultArrivalSlotDurationWhenCreatingWithLowOrMediumPriority);
                            setDefaultArrivalStartTimeEndTime(dateConfig.startDateValue);
                            setArrivalTimeAtSenderSite(null);
                            setArrivalTimeAtSenderSiteIsFixed(false);
                        }
                    }

                    break;

                case TransportPriorityEnum.medium:
                    serviceKindList.find(x => x.value === jobsiteVehicle).disabled = true;
                    setServiceKind({ ...serviceKind, serviceKindList: serviceKindList });
                    if (dateConfig.startDateValue) {
                        if (oldPriority !== TransportPriorityEnum.low) {
                            setArrivalSlotDuration(DefaultArrivalSlotDurationWhenCreatingWithLowOrMediumPriority);
                            setDefaultArrivalStartTimeEndTime(dateConfig.startDateValue);
                            setArrivalTimeAtSenderSite(null);
                            setArrivalTimeAtSenderSiteIsFixed(false);
                        }
                    }

                    break;

                case TransportPriorityEnum.high:
                    setServiceKind({ ...serviceKind, serviceKindList: serviceKindList });
                    setArrivalSlotDuration(null);
                    setArrivalEndTimeAtReceiverSite(null);
                    break;

                default:
                    return;
            }
        }
    }

    const setDefaultArrivalStartTimeEndTime = (date: Date) => {
        const startTime = initializeArrivalTime(date, 7, 0);
        setArrivalTimeAtReceiverSite(startTime);
        const endTime = new Date(startTime).addMinutes(DefaultArrivalSlotDurationWhenCreatingWithLowOrMediumPriority);
        setArrivalEndTimeAtReceiverSite(endTime);
    }

    const initializeArrivalTime = (date: Date, hour: number, minute: number): Date => {
        return new Date(date.getFullYear(), date.getMonth(), date.getDate(), hour, minute, 0, 0);
    }

    const initialiseServiceKindList = (serviceKindList: SelectInputItemModel[]): SelectInputItemModel[] => {
        serviceKindList.forEach((element: SelectInputItemModel) => {
            element.disabled = false;
        });

        return serviceKindList
    }

    const handleServiceKindChange = (event: SelectInputItemModel): void => {
        if (serviceKind.selectedServiceKind?.value === jobsiteVehicle) {
            setPriority(null);
        }

        event ? setServiceKind({ ...serviceKind, selectedServiceKind: { label: event.label, value: event.value } }) : setServiceKind({ ...serviceKind, selectedServiceKind: null });

        setSellPriceKindData(null);

        if (event?.value === perishable) {
            setIsUnloadingContinuityRequired(true);
            setUnloadingDurationFromCoatingApplicationMode(coatingApplicationMode);
            setJobSiteVehicleUnloadingDuration(null);
        } else if (event?.value === jobsiteVehicle) {
            setArrivalSlotDuration(null);
            setArrivalEndTimeAtReceiverSite(null);
            setArrivalTimeAtSenderSite(null);
            setArrivalTimeAtSenderSiteIsFixed(false);
            setIsUnloadingContinuityRequired(false);
            setProductLabel("");
            setQuantity(null);
            setUnloadingDuration(null);
            setSellPriceKindData({ label: "Forfait", value: PriceGridKindEnum.PerTimePeriod });
            setPriority(TransportPriorityEnum.high);
            setCoatingApplicationMode(null);
            reInitialisePayerContent();

            const d = new Date();
            d.setHours(1);
            d.setMinutes(0);
            setJobSiteVehicleUnloadingDuration(d);
            setNegotiatedPurchasePriceValue(null);
            setNegotiatedPurchasePriceKindData(null);
        } else {
            setIsUnloadingContinuityRequired(false);
            setUnloadingDurationFromCoatingApplicationMode(coatingApplicationMode);
            setJobSiteVehicleUnloadingDuration(null);
        }
    }

    const getInternalContactList = (contactList: ContactLightModel[]): SelectInputItemModel[] => {
        const list: SelectInputItemModel[] = [];
        contactList.forEach((element: ContactLightModel) => {
            list.push({
                label: getFullName(element),
                value: element.contactId,
                phoneNumber: element.phoneNumber
            });
        });

        return list;
    }

    const handleRequestedByTypeChange = (e: SelectInputItemModel): void => {
        if (!e) {
            const requestedBeneficiaryList = updateInternalContactsFromJobSitesInfo([], getInternalContactList(internalBeneficiariesList), senderJobSiteAgencyInfo, receiverJobSiteAgencyInfo);

            setRequestedBy({
                ...requestedBy,
                contactList: requestedBeneficiaryList.requestedList,
                contact: null,
                contactType: null
            });

            setBeneficiary({
                ...beneficiary,
                contactList: requestedBeneficiaryList.beneficiaryList
            });

            return;
        }

        if (e.value !== requestedBy.contactType?.value) {
            if (e.value === internal) {
                const requesterList: SelectInputItemModel[] = [];
                internalRequestersList.forEach((element: ContactLightModel) => {
                    requesterList.push({ label: getFullName(element), value: element.contactId, phoneNumber: element.phoneNumber });
                });

                const requestedBeneficiaryList = updateInternalContactsFromJobSitesInfo(requesterList, getInternalContactList(internalBeneficiariesList), senderJobSiteAgencyInfo, receiverJobSiteAgencyInfo);

                setRequestedBy({
                    ...requestedBy,
                    contactList: requestedBeneficiaryList.requestedList,
                    contact: null,
                    contactType: { label: e.label, value: e.value }
                });

                setBeneficiary({
                    ...beneficiary,
                    contactList: requestedBeneficiaryList.beneficiaryList
                });

                return;
            } else if (e.value === externalReferenced) {
                let referencedCustomerMdmIdValue = null;

                if (senderSite.referencedSiteCustomer && !receiverSite.referencedSiteCustomer) {
                    referencedCustomerMdmIdValue = senderSite.referencedSiteCustomer.value;
                } else if ((!senderSite.referencedSiteCustomer && receiverSite.referencedSiteCustomer)) {
                    referencedCustomerMdmIdValue = receiverSite.referencedSiteCustomer.value;
                }

                const contactRequestArgs: ContactRequestArgs = {
                    logisticsUnitId: logisticsUnit.selectedLogisticsUnit?.value,
                    isInternal: false,
                    referencedCustomerMdmId: referencedCustomerMdmIdValue
                };
                TransportFlowApiClient.SearchRequestedByList(contactRequestArgs)
                    .then((res) => {
                        const requestedByList: SelectInputItemModel[] = [];
                        res.data.forEach((element: ContactLightModel) => {
                            requestedByList.push({ label: getFullName(element), value: element.contactId });
                        });
                        setRequestedBy({
                            ...requestedBy,
                            contactList: requestedByList,
                            contact: null,
                            contactType: { label: e.label, value: e.value }
                        });
                    });
            } else if (e.value === externalNotReferenced) {
                setRequestedBy({
                    ...requestedBy,
                    contact: null,
                    contactType: { label: e.label, value: e.value }
                });
            }
        }
    }

    const handleRequestedByContactLabel = (label: string): void => {
        setRequestedBy({ ...requestedBy, contact: label.trim() !== "" ? { label: label, value: null } : null });
    }

    const handleRequestedByContactChanged = (e: SelectInputItemModel): void => {
        if (!e) {
            setRequestedBy({ ...requestedBy, contact: null, contactPhoneNumber: "", undefinedContact: false });
            return;
        }

        if (e.value !== requestedBy.contact?.value) {
            const undefinedContact: boolean = props.mode !== creationMode ? (e.value !== transportFlowData.requestedByName ? false : true) : false;
            const value: string = requestedBy.contactType?.value === externalReferenced || requestedBy.contactType?.value === internal ? e.value : "";

            setRequestedBy({ ...requestedBy, contact: { label: e.label, value: value }, undefinedContact: undefinedContact });
        }
    }

    const handleBeneficiairyTypeChange = (e: SelectInputItemModel): void => {
        if (!e) {
            const requestedBeneficiaryList = updateInternalContactsFromJobSitesInfo(getInternalContactList(internalRequestersList), [], senderJobSiteAgencyInfo, receiverJobSiteAgencyInfo);
            setBeneficiary({ ...beneficiary, contact: null, contactList: requestedBeneficiaryList.beneficiaryList, contactType: null, contactPhoneNumber: "", invalidPhoneNumber: false });

            setRequestedBy({
                ...requestedBy,
                contactList: requestedBeneficiaryList.requestedList,
            });

            return;
        }

        if (e.value !== beneficiary.contactType?.value) {
            if (e.value === internal) {
                const beneficiaryList: SelectInputItemModel[] = [];
                internalBeneficiariesList.forEach((element: ContactLightModel) => {
                    beneficiaryList.push({ label: getFullName(element), value: element.contactId, phoneNumber: element.phoneNumber });
                });

                const requestedBeneficiaryList = updateInternalContactsFromJobSitesInfo(getInternalContactList(internalRequestersList), beneficiaryList, senderJobSiteAgencyInfo, receiverJobSiteAgencyInfo);

                setBeneficiary({
                    ...beneficiary,
                    contactList: requestedBeneficiaryList.beneficiaryList,
                    contact: null,
                    contactType: { label: e.label, value: e.value },
                    contactPhoneNumber: "",
                    invalidPhoneNumber: false
                });
                setRequestedBy({
                    ...requestedBy,
                    contactList: requestedBeneficiaryList.requestedList,
                });

                return;
            } else if (e.value === externalReferenced) {
                let referencedCustomerMdmIdValue: string = null;

                if (senderSite.referencedSiteCustomer && !receiverSite.referencedSiteCustomer) {
                    referencedCustomerMdmIdValue = senderSite.referencedSiteCustomer.value;
                } else if ((!senderSite.referencedSiteCustomer && receiverSite.referencedSiteCustomer)) {
                    referencedCustomerMdmIdValue = receiverSite.referencedSiteCustomer.value;
                }

                const contactRequestArgs: ContactRequestArgs = {
                    logisticsUnitId: logisticsUnit.selectedLogisticsUnit?.value,
                    isInternal: false,
                    referencedCustomerMdmId: referencedCustomerMdmIdValue
                };
                TransportFlowApiClient.SearchBeneficiaryList(contactRequestArgs)
                    .then((res) => {
                        const beneficiairyList: SelectInputItemModel[] = [];
                        res.data.forEach((element: ContactLightModel) => {
                            beneficiairyList.push({
                                label: getFullName(element),
                                value: element.contactId,
                                phoneNumber: element.phoneNumber
                            });
                        });
                        setBeneficiary({
                            ...beneficiary,
                            contactList: beneficiairyList,
                            contact: null,
                            contactType: { label: e.label, value: e.value },
                            contactPhoneNumber: "",
                            invalidPhoneNumber: false
                        });

                    });
            } else if (e.value === externalNotReferenced) {
                setBeneficiary({
                    ...beneficiary,
                    contact: null,
                    contactType: { label: e.label, value: e.value },
                    contactPhoneNumber: "",
                    invalidPhoneNumber: false
                });
            }
        }
    }

    const handleBeneficiairyContactChanged = (e: SelectInputItemModel): void => {
        if (!e) {
            setBeneficiary({ ...beneficiary, contact: null, contactPhoneNumber: "", invalidPhoneNumber: false, undefinedContact: false });
            return;
        }

        if (e.value !== beneficiary.contact?.value) {
            const undefinedContact: boolean = props.mode !== creationMode ? (e.value !== transportFlowData.beneficiaryName ? false : true) : false;
            const beneficiairy: SelectInputItemModel = beneficiary.contactList.find(x => x.value === e.value);
            const value: string = beneficiary.contactType?.value === externalReferenced || beneficiary.contactType?.value === internal ? e.value : "";

            setBeneficiary({
                ...beneficiary,
                contact: { label: e.label, value: value },
                contactPhoneNumber: beneficiairy.phoneNumber,
                invalidPhoneNumber: false,
                undefinedContact: undefinedContact
            });
        }
    }

    const handleBeneficiairyContactLabel = (label: string): void => {
        setBeneficiary({ ...beneficiary, contact: label.trim() !== "" ? { label: label, value: null } : null });
    }

    const handleChangeBeneficiairyPhoneNumber = (phoneNumber: string): void => {
        setBeneficiary({ ...beneficiary, contactPhoneNumber: phoneNumber, invalidPhoneNumber: false });
    }

    const handleBlurBeneficiairyPhoneNumber = (event: React.FocusEvent<HTMLInputElement>): void => {
        const validPhoneNumber = event.target.value?.trim() === ''
            || Utilities.isValidPhoneNumber(event.target.value, true);
        if (!validPhoneNumber) {
            ToastService.showErrorToast("Numéro de téléphone invalide");

            setBeneficiary({ ...beneficiary, invalidPhoneNumber: true });
            event.target.focus();
        } else {
            setBeneficiary({ ...beneficiary, invalidPhoneNumber: false });
        }
    }

    const handleSenderSiteKindChanged = (e: SelectInputItemModel): void => {
        reInitialisePayerSiteList(e?.value, receiverSite.siteKind?.value);

        setSenderJobSiteAgencyInfo(null);

        if (e?.value === productionSite) {
            const senderSiteArray: SelectInputItemModel[] = [];
            productionSiteList.forEach((element: ProductionSiteLightModel) => {
                let disabled = false;
                if (receiverSite.siteKind?.value === productionSite)
                    if (element.productionSiteId === receiverSite.productionSite?.value)
                        disabled = true;

                senderSiteArray.push({
                    label: element.label,
                    value: element.productionSiteId,
                    freeFormAddress: element.freeFormAddress,
                    line1: element.line1,
                    line2: element.line2,
                    city: element.city,
                    zipCode: element.zipCode,
                    agencyId: element.agencyId,
                    latitude: element.latitude,
                    longitude: element.longitude,
                    disabled: disabled
                });
            });

            setSitesInfoAfterSenderSiteKindChanged({ label: e.label, value: e.value }, senderSiteArray, [], []);
        } else if (e?.value === jobSite) {
            const senderSiteAgencyArray: SelectInputItemModel[] = [];
            agenciesList.forEach((element: AgencyLightModel) => {
                senderSiteAgencyArray.push({ label: element.label, value: element.agencyId });
            });
            setSitesInfoAfterSenderSiteKindChanged({ label: e.label, value: e.value }, [], [], senderSiteAgencyArray);
        } else if (e?.value === referencedCustomer) {
            const senderSiteReferencedCustomerArray: SelectInputItemModel[] = [];
            referencedCustomerList.forEach((element: ReferencedCustomerLightModel) => {
                senderSiteReferencedCustomerArray.push(createReferencedCustomerItem(element));
            });
            setSitesInfoAfterSenderSiteKindChanged({ label: e.label, value: e.value }, [], senderSiteReferencedCustomerArray, []);
        } else if (e?.value === unreferencedCustomer) {
            setSitesInfoAfterSenderSiteKindChanged({ label: e.label, value: e.value }, [], [], []);
        } else {
            setSitesInfoAfterSenderSiteKindChanged(null, [], [], []);
        }

        if (requestedBy.contactType?.value === externalReferenced) {
            let contactRequestArgs: ContactRequestArgs;

            if (receiverSite.siteKind?.value === referencedCustomer && e?.value !== referencedCustomer) {
                contactRequestArgs = {
                    logisticsUnitId: logisticsUnit.selectedLogisticsUnit?.value,
                    isInternal: false,
                    referencedCustomerMdmId: receiverSite.referencedSiteCustomer?.value
                };
            } else {
                contactRequestArgs = {
                    logisticsUnitId: logisticsUnit.selectedLogisticsUnit?.value,
                    isInternal: false,
                    referencedCustomerMdmId: null
                };
            }

            TransportFlowApiClient.SearchRequestedByList(contactRequestArgs)
                .then((res) => {
                    const requestedByList: SelectInputItemModel[] = [];
                    res.data.forEach((element: ContactLightModel) => {
                        requestedByList.push({ label: getFullName(element), value: element.contactId });
                    });

                    const requestedBeneficiaryList = updateInternalContactsFromJobSitesInfo([...requestedByList], getInternalContactList(internalBeneficiariesList), null, receiverJobSiteAgencyInfo);
                    setRequestedBy({ ...requestedBy, contactList: requestedBeneficiaryList.requestedList, contact: null });
                });
        }
        else {
            const requestedBeneficiaryList = updateInternalContactsFromJobSitesInfo(getInternalContactList(internalRequestersList), getInternalContactList(internalBeneficiariesList), null, receiverJobSiteAgencyInfo);
            setRequestedBy({ ...requestedBy, contactList: requestedBeneficiaryList.requestedList });
        }

        if (beneficiary.contactType?.value === externalReferenced) {
            let contactRequestArgs: ContactRequestArgs;

            if (receiverSite.siteKind?.value === referencedCustomer && e?.value !== referencedCustomer) {
                contactRequestArgs = {
                    logisticsUnitId: logisticsUnit.selectedLogisticsUnit?.value,
                    isInternal: false,
                    referencedCustomerMdmId: receiverSite.referencedSiteCustomer?.value
                };
            } else {
                contactRequestArgs = {
                    logisticsUnitId: logisticsUnit.selectedLogisticsUnit?.value,
                    isInternal: false,
                    referencedCustomerMdmId: null
                };
            }

            TransportFlowApiClient.SearchBeneficiaryList(contactRequestArgs)
                .then((res) => {
                    const beneficiaryList: SelectInputItemModel[] = [];
                    res.data.forEach((element: ContactLightModel) => {
                        beneficiaryList.push({
                            label: getFullName(element),
                            value: element.contactId,
                            phoneNumber: element.phoneNumber
                        });
                    });
                    const requestedBeneficiaryList = updateInternalContactsFromJobSitesInfo(getInternalContactList(internalRequestersList), beneficiaryList, null, receiverJobSiteAgencyInfo);
                    setBeneficiary({ ...beneficiary, contactList: requestedBeneficiaryList.beneficiaryList, contact: null, contactPhoneNumber: "", invalidPhoneNumber: false });
                });
        }
        else {
            const requestedBeneficiaryList = updateInternalContactsFromJobSitesInfo(getInternalContactList(internalRequestersList), getInternalContactList(internalBeneficiariesList), null, receiverJobSiteAgencyInfo);
            setBeneficiary({ ...beneficiary, contactList: requestedBeneficiaryList.beneficiaryList });
        }
    }

    const setSitesInfoAfterSenderSiteKindChanged = (senderSiteKind: SelectInputItemModel,
        senderSiteList: SelectInputItemModel[],
        referencedSenderSiteCustomerList: SelectInputItemModel[],
        senderSiteAgencyList: SelectInputItemModel[]): void => {
        setSenderSite({
            ...senderSite,
            siteKind: senderSiteKind,
            siteList: senderSiteList,
            productionSite: null,
            productionSiteActivities: [],
            productionSiteActivity: null,
            unReferencedSiteCustomer: null,
            unReferencedSiteCustomerSite: null,
            referencedSiteCustomerList: referencedSenderSiteCustomerList,
            referencedSiteCustomer: null,
            siteAgencyList: senderSiteAgencyList,
            selectedSiteAgency: null,
            jobSiteIdmdm: null,
            jobSiteLabel: null
        });

        setPickupAddress({ freeFormAddress: null, city: null, line1: null, line2: null, zipCode: null });
        if (failedPickupAddress)
            setFailedPickupAddress(false);
        setPickupCoordinates(null);
        removePinFromMaps(pickupPinId);
    }

    const handleReceiverSiteKindChanged = (e: SelectInputItemModel): void => {
        reInitialisePayerSiteList(senderSite.siteKind?.value, e?.value);

        setReceiverJobSiteAgencyInfo(null);

        if (e?.value === productionSite) {
            const receiverSiteArray: SelectInputItemModel[] = [];
            productionSiteList.forEach((element: ProductionSiteLightModel) => {
                let disabled = false;
                if (senderSite.siteKind?.value === productionSite)
                    if (element.productionSiteId === senderSite.productionSite?.value)
                        disabled = true;

                receiverSiteArray.push({
                    label: element.label,
                    value: element.productionSiteId,
                    freeFormAddress: element.freeFormAddress,
                    line1: element.line1,
                    line2: element.line2,
                    city: element.city,
                    zipCode: element.zipCode,
                    agencyId: element.agencyId,
                    latitude: element.latitude,
                    longitude: element.longitude,
                    disabled: disabled
                });
            });
            setSitesInfoAfterReceiverSiteKindChanged({ label: e.label, value: e.value }, receiverSiteArray, [], []);
        } else if (e?.value === jobSite) {
            const receiverSiteAgencyArray: SelectInputItemModel[] = [];
            agenciesList.forEach((element: AgencyLightModel) => {
                receiverSiteAgencyArray.push({ label: element.label, value: element.agencyId });
            });
            setSitesInfoAfterReceiverSiteKindChanged({ label: e.label, value: e.value }, [], [], receiverSiteAgencyArray);
        } else if (e?.value === referencedCustomer) {
            const receiverSiteReferencedCustomerArray: SelectInputItemModel[] = [];
            referencedCustomerList.forEach((element: ReferencedCustomerLightModel) => {
                receiverSiteReferencedCustomerArray.push(createReferencedCustomerItem(element));
            });
            setSitesInfoAfterReceiverSiteKindChanged({ label: e.label, value: e.value }, [], receiverSiteReferencedCustomerArray, []);
        } else if (e?.value === unreferencedCustomer) {
            setSitesInfoAfterReceiverSiteKindChanged({ label: e.label, value: e.value }, [], [], []);
        } else {
            setSitesInfoAfterReceiverSiteKindChanged(null, [], [], []);
        }

        if (requestedBy.contactType?.value === externalReferenced) {
            let contactRequestArgs: ContactRequestArgs;

            if (senderSite.siteKind?.value === referencedCustomer && e.value !== referencedCustomer) {
                contactRequestArgs = {
                    logisticsUnitId: logisticsUnit.selectedLogisticsUnit?.value,
                    isInternal: false,
                    referencedCustomerMdmId: senderSite.referencedSiteCustomer?.value
                };
            } else {
                contactRequestArgs = {
                    logisticsUnitId: logisticsUnit.selectedLogisticsUnit?.value,
                    isInternal: false,
                    referencedCustomerMdmId: null
                };
            }

            TransportFlowApiClient.SearchRequestedByList(contactRequestArgs)
                .then((res) => {
                    const requestedByList: SelectInputItemModel[] = [];
                    res.data.forEach((element: ContactLightModel) => {
                        requestedByList.push({ label: getFullName(element), value: element.contactId });
                    });

                    const requestedBeneficiaryList = updateInternalContactsFromJobSitesInfo([...requestedByList], getInternalContactList(internalBeneficiariesList), senderJobSiteAgencyInfo, null);
                    setRequestedBy({ ...requestedBy, contactList: requestedBeneficiaryList.requestedList, contact: null });
                });
        }
        else {
            const requestedBeneficiaryList = updateInternalContactsFromJobSitesInfo(getInternalContactList(internalRequestersList), getInternalContactList(internalBeneficiariesList), senderJobSiteAgencyInfo, null);
            setRequestedBy({ ...requestedBy, contactList: requestedBeneficiaryList.requestedList });
        }

        if (beneficiary.contactType?.value === externalReferenced) {
            let contactRequestArgs: ContactRequestArgs;

            if (senderSite.siteKind?.value === referencedCustomer && e?.value !== referencedCustomer) {
                contactRequestArgs = {
                    logisticsUnitId: logisticsUnit.selectedLogisticsUnit?.value,
                    isInternal: false,
                    referencedCustomerMdmId: senderSite.referencedSiteCustomer?.value
                };
            } else {
                contactRequestArgs = {
                    logisticsUnitId: logisticsUnit.selectedLogisticsUnit?.value,
                    isInternal: false,
                    referencedCustomerMdmId: null
                };
            }

            TransportFlowApiClient.SearchBeneficiaryList(contactRequestArgs)
                .then((res) => {
                    const beneficiaryList: SelectInputItemModel[] = [];
                    res.data.forEach((element: ContactLightModel) => {
                        beneficiaryList.push({ label: getFullName(element), value: element.contactId, phoneNumber: element.phoneNumber });
                    });
                    const requestedBeneficiaryList = updateInternalContactsFromJobSitesInfo([...requestedBy?.contactList], beneficiaryList, senderJobSiteAgencyInfo, null);

                    setBeneficiary({ ...beneficiary, contactList: requestedBeneficiaryList.beneficiaryList, contact: null, contactPhoneNumber: "", invalidPhoneNumber: false });
                });
        }
        else {
            const requestedBeneficiaryList = updateInternalContactsFromJobSitesInfo([...requestedBy?.contactList], [...beneficiary?.contactList], senderJobSiteAgencyInfo, null);
            setBeneficiary({ ...beneficiary, contactList: requestedBeneficiaryList.beneficiaryList });
        }
    }

    const setSitesInfoAfterReceiverSiteKindChanged = (receiverSiteKind: SelectInputItemModel,
        receiverSiteList: SelectInputItemModel[],
        referencedReceiverSiteCustomerList: SelectInputItemModel[],
        receiverSiteAgencyList: SelectInputItemModel[]): void => {
        setReceiverSite({
            ...receiverSite,
            siteKind: receiverSiteKind,
            siteList: receiverSiteList,
            productionSite: null,
            productionSiteActivities: [],
            productionSiteActivity: null,
            unReferencedSiteCustomer: null,
            unReferencedSiteCustomerSite: null,
            referencedSiteCustomerList: referencedReceiverSiteCustomerList,
            referencedSiteCustomer: null,
            siteAgencyList: receiverSiteAgencyList,
            selectedSiteAgency: null,
            jobSiteIdmdm: null,
            jobSiteLabel: null
        });

        setDeliveryAddress({ freeFormAddress: null, city: null, line1: null, line2: null, zipCode: null });
        if (failedDeliveryAddress)
            setFailedDeliveryAddress(false);
        setDeliveryCoordinates(null);
        removePinFromMaps(deliveryPinId);
    }

    const handleSenderProductionSiteChanged = (e: SelectInputItemModel): void => {
        reInitialisePayerSiteList(senderSite.siteKind?.value, receiverSite.siteKind?.value);

        if (!e) {
            setSitesInfoAfterSenderSiteKindChanged(senderSite.siteKind, senderSite.siteList, [], []);
            return;
        }

        if (e.value !== senderSite.productionSite?.value) {
            const senderSiteInfo: SelectInputItemModel = senderSite.siteList.find(x => x.value === e.value);
            TransportFlowApiClient.SearchLevel2ActivityList(e.value, logisticsUnit.selectedLogisticsUnit?.value)
                .then((res) => {
                    const senderProductionSiteActivityList: SelectInputItemModel[] = [];
                    res.data.forEach((element: Level2ActivityLightModel) => {
                        senderProductionSiteActivityList.push({ label: element.label, value: element.id });
                    });
                    setSenderSite({
                        ...senderSite,
                        productionSiteActivities: senderProductionSiteActivityList,
                        productionSiteActivity: senderProductionSiteActivityList.length === 1 ? { value: senderProductionSiteActivityList[0].value, label: senderProductionSiteActivityList[0].label } : null,
                        productionSite: { value: e.value, label: e.label, agencyId: e.agencyId }
                    });
                    setPickupAddress({ freeFormAddress: senderSiteInfo.freeFormAddress, line1: senderSiteInfo.line1, line2: senderSiteInfo.line2, zipCode: senderSiteInfo.zipCode, city: senderSiteInfo.city });
                    if (failedPickupAddress)
                        setFailedPickupAddress(false);

                    if (senderSiteInfo.latitude && senderSiteInfo.longitude) {
                        setPickupPin({ latitude: senderSiteInfo.latitude, longitude: senderSiteInfo.longitude });
                    } else {
                        getPickupAddressCoordinates(senderSiteInfo.freeFormAddress);
                    }

                    if (receiverSite.siteKind?.value === productionSite)
                        receiverSite.siteList.forEach(element => {
                            if (element.value === e.value)
                                element.disabled = true;
                            else
                                element.disabled = false;
                        });
                });
        }
    }

    const handleReceiverProductionSiteChanged = (e: SelectInputItemModel): void => {
        reInitialisePayerSiteList(senderSite.siteKind?.value, receiverSite.siteKind?.value);

        if (!e) {
            setSitesInfoAfterReceiverSiteKindChanged(receiverSite.siteKind, receiverSite.siteList, [], []);
            return;
        }

        if (e.value !== receiverSite.productionSite?.value) {
            const receiverSiteInfo: SelectInputItemModel = receiverSite.siteList.find(x => x.value === e.value);
            TransportFlowApiClient.SearchLevel2ActivityList(e.value, logisticsUnit.selectedLogisticsUnit?.value)
                .then((res) => {
                    const receiverProductionSiteActivityList: SelectInputItemModel[] = [];
                    res.data.forEach((element: Level2ActivityLightModel) => {
                        receiverProductionSiteActivityList.push({ label: element.label, value: element.id });
                    });
                    setReceiverSite({
                        ...receiverSite,
                        productionSiteActivities: receiverProductionSiteActivityList,
                        productionSiteActivity: receiverProductionSiteActivityList.length === 1 ? { value: receiverProductionSiteActivityList[0].value, label: receiverProductionSiteActivityList[0].label } : null,
                        productionSite: { value: e.value, label: e.label, agencyId: e.agencyId }
                    });
                    setDeliveryAddress({ freeFormAddress: receiverSiteInfo.freeFormAddress, line1: receiverSiteInfo.line1, line2: receiverSiteInfo.line2, zipCode: receiverSiteInfo.zipCode, city: receiverSiteInfo.city });
                    if (failedDeliveryAddress)
                        setFailedDeliveryAddress(false);

                    if (receiverSiteInfo.latitude && receiverSiteInfo.longitude) {
                        setDeliveryPin({ latitude: receiverSiteInfo.latitude, longitude: receiverSiteInfo.longitude });
                    } else {
                        getDeliveryAddressCoordinates(receiverSiteInfo.freeFormAddress);
                    }

                    if (senderSite.siteKind?.value === productionSite)
                        senderSite.siteList.forEach((element) => {
                            if (element.value === e.value)
                                element.disabled = true;
                            else
                                element.disabled = false;
                        });
                });
        }
    }
    const handleSenderProductionSiteActivityChanged = (e: SelectInputItemModel): void => {
        setSenderSite({ ...senderSite, productionSiteActivity: e ? { value: e.value, label: e.label } : null });
    }

    const handleReceiverProductionSiteActivityChanged = (e: SelectInputItemModel): void => {
        setReceiverSite({ ...receiverSite, productionSiteActivity: e ? { value: e.value, label: e.label } : null });
    }

    const handleUnReferencedSenderSiteCustomerChange = (value: string): void => {
        reInitialisePayerSiteList(senderSite.siteKind?.value, receiverSite.siteKind?.value);

        const val: string = value ? value : null;
        setSenderSite({ ...senderSite, unReferencedSiteCustomer: val });
    }
    const handleUnReferencedReceiverSiteCustomerChange = (value: string): void => {
        reInitialisePayerSiteList(senderSite.siteKind?.value, receiverSite.siteKind?.value);

        const val: string = value ? value : null;
        setReceiverSite({ ...receiverSite, unReferencedSiteCustomer: val });
    }

    const handleUnReferencedSenderSiteCustomerSiteChange = (value: string): void => {
        reInitialisePayerSiteList(senderSite.siteKind?.value, receiverSite.siteKind?.value);

        const val: string = value ? value : null;
        setSenderSite({ ...senderSite, unReferencedSiteCustomerSite: val });
    }
    const handleUnReferencedReceiverSiteCustomerSiteChange = (value: string): void => {
        reInitialisePayerSiteList(senderSite.siteKind?.value, receiverSite.siteKind?.value);

        const val: string = value ? value : null;
        setReceiverSite({ ...receiverSite, unReferencedSiteCustomerSite: val });
    }

    const handleReferencedSenderSiteCustomerChanged = (e: SelectInputItemModel): void => {
        reInitialisePayerSiteList(senderSite.siteKind?.value, receiverSite.siteKind?.value);

        if (!e) {
            setSitesInfoAfterSenderSiteKindChanged(senderSite.siteKind, [], senderSite.referencedSiteCustomerList, []);
            return;
        }

        if (e.value !== senderSite.referencedSiteCustomer?.value) {
            const senderSiteInfo: SelectInputItemModel = senderSite.referencedSiteCustomerList.find(x => x.value === e.value);
            setSenderSite({ ...senderSite, referencedSiteCustomer: { label: e.label, value: e.value }, });
            setPickupAddress({ freeFormAddress: senderSiteInfo.freeFormAddress, line1: senderSiteInfo.line1, line2: senderSiteInfo.line2, zipCode: senderSiteInfo.zipCode, city: senderSiteInfo.city });
            if (failedPickupAddress)
                setFailedPickupAddress(false);

            getPickupAddressCoordinates(senderSiteInfo.freeFormAddress);

            if (requestedBy.contactType?.value === externalReferenced && receiverSite.siteKind?.value !== referencedCustomer) {
                const contactRequestArgs: ContactRequestArgs = {
                    logisticsUnitId: logisticsUnit.selectedLogisticsUnit?.value,
                    isInternal: false,
                    referencedCustomerMdmId: e.value
                };
                TransportFlowApiClient.SearchRequestedByList(contactRequestArgs)
                    .then((res) => {
                        const requestedByList: SelectInputItemModel[] = [];
                        res.data.forEach((element: ContactLightModel) => {
                            requestedByList.push({ label: getFullName(element), value: element.contactId });
                        });
                        setRequestedBy({ ...requestedBy, contactList: requestedByList, contact: null });
                    });
            }

            if (beneficiary.contactType?.value === externalReferenced && receiverSite.siteKind?.value !== referencedCustomer) {
                const contactArgs: ContactRequestArgs = {
                    logisticsUnitId: logisticsUnit.selectedLogisticsUnit?.value,
                    isInternal: false,
                    referencedCustomerMdmId: e.value
                };
                TransportFlowApiClient.SearchBeneficiaryList(contactArgs)
                    .then((res) => {
                        const beneficiairyList: SelectInputItemModel[] = [];
                        res.data.forEach((element: ContactLightModel) => {
                            beneficiairyList.push({
                                label: getFullName(element),
                                value: element.contactId,
                                phoneNumber: element.phoneNumber
                            });
                        });
                        setBeneficiary({ ...beneficiary, contactList: beneficiairyList, contact: null, contactPhoneNumber: "", invalidPhoneNumber: false });
                    });
            }
        }
    }

    const handleReferencedReceiverSiteCustomerChanged = (e: SelectInputItemModel): void => {
        reInitialisePayerSiteList(senderSite.siteKind?.value, receiverSite.siteKind?.value);

        if (!e) {
            setSitesInfoAfterReceiverSiteKindChanged(receiverSite.siteKind, [], receiverSite.referencedSiteCustomerList, []);
            return;
        }

        if (e.value !== receiverSite.referencedSiteCustomer?.value) {
            const receiverSiteInfo: SelectInputItemModel = receiverSite.referencedSiteCustomerList.find(x => x.value === e.value);
            setReceiverSite({ ...receiverSite, referencedSiteCustomer: { label: e.label, value: e.value } });
            setDeliveryAddress({ freeFormAddress: receiverSiteInfo.freeFormAddress, line1: receiverSiteInfo.line1, line2: receiverSiteInfo.line2, zipCode: receiverSiteInfo.zipCode, city: receiverSiteInfo.city });
            if (failedDeliveryAddress)
                setFailedDeliveryAddress(false);

            getDeliveryAddressCoordinates(receiverSiteInfo.freeFormAddress);

            if (requestedBy.contactType?.value === externalReferenced && senderSite.siteKind?.value !== referencedCustomer) {
                const contactRequestArgs: ContactRequestArgs = {
                    logisticsUnitId: logisticsUnit.selectedLogisticsUnit?.value,
                    isInternal: false,
                    referencedCustomerMdmId: e.value
                };
                TransportFlowApiClient.SearchRequestedByList(contactRequestArgs)
                    .then((res) => {
                        const requestedByList: SelectInputItemModel[] = [];
                        res.data.forEach((element: ContactLightModel) => {
                            requestedByList.push({ label: getFullName(element), value: element.contactId });
                        });
                        setRequestedBy({ ...requestedBy, contactList: requestedByList, contact: null });
                    });
            }

            if (beneficiary.contactType?.value === externalReferenced && senderSite.siteKind?.value !== referencedCustomer) {
                const contactRequestArgs: ContactRequestArgs = {
                    logisticsUnitId: logisticsUnit.selectedLogisticsUnit?.value,
                    isInternal: false,
                    referencedCustomerMdmId: e.value
                };
                TransportFlowApiClient.SearchBeneficiaryList(contactRequestArgs)
                    .then((res) => {
                        const beneficiairyList: SelectInputItemModel[] = [];
                        res.data.forEach((element: ContactLightModel) => {
                            beneficiairyList.push({
                                label: getFullName(element),
                                value: element.contactId,
                                phoneNumber: element.phoneNumber
                            });
                        });
                        setBeneficiary({ ...beneficiary, contactList: beneficiairyList, contact: null, contactPhoneNumber: "", invalidPhoneNumber: false });
                    });
            }
        }
    }

    const handleSenderSiteAgencyChanged = (e: SelectInputItemModel): void => {
        reInitialisePayerSiteList(senderSite.siteKind?.value, receiverSite.siteKind?.value);
        setSenderSite({ ...senderSite, selectedSiteAgency: e ? { label: e.label, value: e.value } : null });
    }
    const handleReceiverSiteAgencyChanged = (e: SelectInputItemModel): void => {
        reInitialisePayerSiteList(senderSite.siteKind?.value, receiverSite.siteKind?.value);
        setReceiverSite({ ...receiverSite, selectedSiteAgency: e ? { label: e.label, value: e.value } : null });
    }

    const handleSenderJobSiteIdmdmChanged = (value: string): void => {
        reInitialisePayerSiteList(senderSite.siteKind?.value, receiverSite.siteKind?.value);

        const val = value ? value : null;
        setSenderSite({ ...senderSite, jobSiteIdmdm: val });
    }
    const handleReceiverJobSiteIdmdmChanged = (value: string): void => {
        reInitialisePayerSiteList(senderSite.siteKind?.value, receiverSite.siteKind?.value);

        const val = value ? value : null;
        setReceiverSite({ ...receiverSite, jobSiteIdmdm: val });
    }

    const handleVerifyJobMdmId = (isSenderSite: boolean): void => {
        const jobMdmId = isSenderSite ? senderSite.jobSiteIdmdm : receiverSite.jobSiteIdmdm;
        TransportFlowApiClient.SearchJobSiteByMdmId(jobMdmId)
            .then(res => {
                let requestersList = [];
                let beneficiaryList = [];

                const currentJobSiteAgency = res.data;

                const requestersMissingMessage = "Demandeur du chantier non visible ou inconnu de LORIE";
                const beneficiariesMissingMessage = "Bénéficiaire du chantier non visible ou inconnu de LORIE";

                if (currentJobSiteAgency) {
                    const addressString = Utilities.formatAddress(currentJobSiteAgency.addressLine1, currentJobSiteAgency.addressLine2, currentJobSiteAgency.addressZipCode, currentJobSiteAgency.addressCity);

                    const address: AddressModel = { freeFormAddress: addressString, line1: currentJobSiteAgency.addressLine1, line2: currentJobSiteAgency.addressLine2, zipCode: currentJobSiteAgency.addressZipCode, city: currentJobSiteAgency.addressCity };

                    if (isSenderSite) {
                        const agency = senderSite.siteAgencyList?.find(x => x.value === currentJobSiteAgency.agencyId);
                        if (!agency) {
                            ToastService.showErrorToast(`L'agence ${currentJobSiteAgency.agencyId} correspondante au chantier n'est pas connue de LORIE. Veuillez la rajouter via le référentiel Lorie (Unité d'Exploitation Travaux Colas).`);
                        }

                        if (currentJobSiteAgency.latitude && currentJobSiteAgency.longitude) {
                            setPickupPin({ latitude: currentJobSiteAgency.latitude, longitude: currentJobSiteAgency.longitude });
                        }
                        else {
                            getPickupAddressCoordinates(addressString);
                        }
                        setSenderJobSiteAgencyInfo(currentJobSiteAgency);
                        setSenderSite({
                            ...senderSite,
                            selectedSiteAgency: agency,
                            jobSiteLabel: currentJobSiteAgency.label
                        });

                        setPickupAddress(address);

                        const lists = updateInternalContactsFromJobSitesInfo(getInternalContactList(internalRequestersList), getInternalContactList(internalBeneficiariesList), currentJobSiteAgency, receiverJobSiteAgencyInfo
                            , requestersMissingMessage, beneficiariesMissingMessage);

                        requestersList = lists.requestedList;
                        beneficiaryList = lists.beneficiaryList;
                    }
                    else {
                        const agency = receiverSite.siteAgencyList?.find(x => x.value === currentJobSiteAgency.agencyId);
                        if (!agency) {
                            ToastService.showErrorToast(`L'agence ${currentJobSiteAgency.agencyId} correspondante au chantier n'est pas connue de LORIE. Veuillez la rajouter via le référentiel Lorie (Unité d'Exploitation Travaux Colas).`);
                        }

                        if (currentJobSiteAgency.latitude && currentJobSiteAgency.longitude) {
                            setDeliveryPin({ latitude: currentJobSiteAgency.latitude, longitude: currentJobSiteAgency.longitude });
                        }
                        else {
                            getDeliveryAddressCoordinates(addressString);
                        }
                        setReceiverJobSiteAgencyInfo(currentJobSiteAgency);
                        setReceiverSite({
                            ...receiverSite,
                            selectedSiteAgency: agency,
                            jobSiteLabel: currentJobSiteAgency.label
                        });

                        setDeliveryAddress(address);

                        const lists = updateInternalContactsFromJobSitesInfo(getInternalContactList(internalRequestersList), getInternalContactList(internalBeneficiariesList), senderJobSiteAgencyInfo, currentJobSiteAgency
                            , requestersMissingMessage, beneficiariesMissingMessage);

                        requestersList = lists.requestedList;
                        beneficiaryList = lists.beneficiaryList;
                    }
                }
                else {
                    ToastService.showErrorToast("Chantier inactif ou non connu du référentiel chantier Colas.");
                    if (isSenderSite) {
                        setSenderJobSiteAgencyInfo(null);
                        setPickupAddress({ freeFormAddress: null, line1: null, zipCode: null, city: null });
                        setSenderSite({ ...senderSite, jobSiteLabel: "", selectedSiteAgency: null });
                    }
                    else {
                        setReceiverJobSiteAgencyInfo(null);
                        setDeliveryAddress({ freeFormAddress: null, line1: null, zipCode: null, city: null });
                        setReceiverSite({ ...receiverSite, jobSiteLabel: "", selectedSiteAgency: null });
                    }
                    const lists = updateInternalContactsFromJobSitesInfo(getInternalContactList(internalRequestersList), getInternalContactList(internalBeneficiariesList), null, null);

                    requestersList = lists.requestedList;
                    beneficiaryList = lists.beneficiaryList;
                }

                setRequestedBy({
                    ...requestedBy,
                    contactList: requestersList
                });

                setBeneficiary({
                    ...beneficiary,
                    contactList: beneficiaryList
                });
            });
    }

    const updateInternalContactsFromJobSitesInfo = (requestedByList: SelectInputItemModel[], beneficiaryList: SelectInputItemModel[], senderJobSite: JobSiteInfo, receiverJobSite: JobSiteInfo
        , missingRequestersWarning?: string, missingBeneficiariesWarning?: string) => {
        let foremans: JobContactInfo[];
        let contractManagers: JobContactInfo[];

        if (senderSite?.siteKind?.value === jobSite) {
            if (receiverSite?.siteKind?.value === jobSite) {
                foremans = (senderJobSite?.foremans ?? []).concat(receiverJobSite?.foremans ?? []);
                contractManagers = (senderJobSite?.contractManagers ?? []).concat(receiverJobSite?.contractManagers ?? []);
            }
            else {
                contractManagers = senderJobSite?.contractManagers ?? [];
                foremans = senderJobSite?.foremans ?? [];
            }
        }
        else {
            if (receiverSite?.siteKind?.value === jobSite) {
                contractManagers = receiverJobSite?.contractManagers ?? [];
                foremans = receiverJobSite?.foremans ?? [];
            }
            else {
                contractManagers = [];
                foremans = [];
            }
        }

        const primaryContractManagers = (contractManagers.filter(x => x.isMain) ?? []).map(x => x.contactId);
        const secondaryContractManagers = (contractManagers.filter(x => !x.isMain) ?? []).map(x => x.contactId);
        const primaryForemans = (foremans.filter(x => x.isMain) ?? []).map(x => x.contactId);
        const secondaryForemans = (foremans.filter(x => !x.isMain) ?? []).map(x => x.contactId);

        return {
            requestedList: refreshListFromJobContacts(requestedByList, new Set(primaryContractManagers), new Set(secondaryContractManagers), missingRequestersWarning),
            beneficiaryList: refreshListFromJobContacts(beneficiaryList, new Set(primaryForemans), new Set(secondaryForemans), missingBeneficiariesWarning),
        };
    }

    const refreshListFromJobContacts = (list: SelectInputItemModel[]
        , primaryIdentifiers: Set<string>, secondaryIdentifiers: Set<string>, missingWarningMessage?: string): SelectInputItemModel[] => {

        const listIdentifiers = new Set(list.map(x => x.value));
        if (missingWarningMessage) {
            let existsMissingContact = false;

            for (const identifier of primaryIdentifiers) {
                if (!listIdentifiers.has(identifier)) {
                    existsMissingContact = true;
                    break;
                }
            }

            if (!existsMissingContact) {
                for (const identifier of secondaryIdentifiers) {
                    if (!listIdentifiers.has(identifier)) {
                        existsMissingContact = true;
                        break;
                    }
                }
            }

            if (existsMissingContact) {
                ToastService.showWarningToast(missingWarningMessage);
            }
        }

        list.forEach(c => {
            c.isPrimary = primaryIdentifiers?.has(c.value);
            c.isSecondary = secondaryIdentifiers?.has(c.value);
        });

        list = orderBy(list, ["isPrimary", "isSecondary", "label"], ["desc", "desc", "asc"]);

        return list;
    }

    const handleSenderjobSiteLabelChanged = (value: string): void => {
        const val = value ? value : null;
        setSenderSite({ ...senderSite, jobSiteLabel: val });

    }
    const handleReceiverjobSiteLabelChanged = (value: string): void => {
        const val = value ? value : null;
        setReceiverSite({ ...receiverSite, jobSiteLabel: val });
    }

    const handlePayerSiteChanged = (e: PayerSiteModel): void => {
        if (!e) {
            setPayerSite(null);
            reinitialisePayerSectionContent();
            return;
        }

        setPayerSite({ label: e.label, value: e.value, payerType: e.payerType });
        reinitialisePayerSectionContent();
        if (e.payerType === SiteEntityTypeEnum.ProductionSite) {
            TransportFlowApiClient.SearchLevel2ActivityList(e.value, logisticsUnit.selectedLogisticsUnit?.value)
                .then((res) => {
                    const productionSiteActivityList: SelectInputItemModel[] = [];
                    res.data.forEach((element: Level2ActivityLightModel) => {
                        productionSiteActivityList.push({ label: element.label, value: element.id });
                    });

                    setPayerLevel2ActivityList(productionSiteActivityList);

                    if (e.value === senderSite.productionSite?.value)
                        setPayerLevel2Activity({ label: senderSite.productionSiteActivity.label, value: senderSite.productionSiteActivity?.value });

                    if (e.value === receiverSite.productionSite?.value)
                        setPayerLevel2Activity({ label: receiverSite.productionSiteActivity.label, value: receiverSite.productionSiteActivity?.value });

                });
        }
        else if (e.payerType === SiteEntityTypeEnum.Agency) {
            if (e.value === senderSite.selectedSiteAgency?.value)
                setPayerOverridenjobSiteIdmdm(senderSite.jobSiteIdmdm?.trim());

            if (e.value === receiverSite.selectedSiteAgency?.value)
                setPayerOverridenjobSiteIdmdm(receiverSite.jobSiteIdmdm?.trim());
        }
    }

    const handlePayerLevel2ActivityChanged = (e: SelectInputItemModel): void => {
        setPayerLevel2Activity(e ? { label: e.label, value: e.value } : null);
    }

    const handleOverridenPayerBeneficiaryChanged = (e: SelectInputItemModel): void => {
        setPayerOverridenBeneficiary(e ? { label: e.label, value: e.value } : null);
    }

    const handleOverridenPayerjobSiteIdmdmChanged = (e: React.ChangeEvent<HTMLInputElement>): void => {
        setPayerOverridenjobSiteIdmdm(e.target.value);
    }

    const handleTransportBillingModeChanged = (e: SelectInputItemModel): void => {
        setTransportBillingMode(e ? { label: e.label, value: e.value } : null);
    }

    const reInitialisePayerActivityContent = (): void => {
        setPayerLevel2Activity(null);
        setPayerLevel2ActivityList([]);
    }

    const reinitialisePayerSectionContent = (): void => {
        reInitialisePayerActivityContent();
        setPayerOverridenjobSiteIdmdm(null);
        setPayerOverridenBeneficiary(null);
        setTransportBillingMode(null);
    }

    const reInitialisePayerContent = (): void => {
        const hasInternalSite: boolean = senderSite.siteKind?.value === productionSite || senderSite.siteKind?.value === jobSite || receiverSite.siteKind?.value === productionSite || receiverSite.siteKind?.value === jobSite;
        hasInternalSite ? setPayerSite({ label: "Automatique", value: "Automatic" }) : setPayerSite(null);
        reinitialisePayerSectionContent();
        setTransportBillingMode(null);
    }

    const reInitialisePayerSiteList = (senderSiteKind: string, receiverSiteKind: string) => {
        const hasInternalSite: boolean = senderSiteKind === productionSite || senderSiteKind === jobSite || receiverSiteKind === productionSite || receiverSiteKind === jobSite;

        const productionSiteArray = [...payerSiteList];
        const automaticItemIndex = productionSiteArray.findIndex(x => x.value === "Automatic");

        if (hasInternalSite) {
            if (automaticItemIndex == -1)
                productionSiteArray.unshift({ label: "Automatique", value: "Automatic" });
        } else {
            if (automaticItemIndex != -1)
                productionSiteArray.splice(automaticItemIndex, 1);
        }

        setPayerSiteList(productionSiteArray);
        if (hasInternalSite) {
            setPayerSite({ label: "Automatique", value: "Automatic" });
        }
        reinitialisePayerSectionContent();
        setTransportBillingMode(null);
    }

    const handleNumberOfVehiclesChange = (value: number): void => {
        setQuotationResult({ ...quotationResult, numberOfVehicles: value });
    }

    const isJobSiteVehicleServiceKind: boolean = serviceKind.selectedServiceKind?.value === jobsiteVehicle;

    const handleTripDurationChanged = (value: number): void => {
        //ODA Actuellement les eventHandlers basés sur les maps n'accède pas au state du fonctional composant. 
        //Le contournement trouvé est de créer un champ caché qu'on synchronise à chaque modif du tripDuration
        const tripDurationById = document.getElementById("tripDurationId") as HTMLInputElement;
        tripDurationById.value = value ? (value + '') : null;
        setTripDuration(value);
        clearFieldNumberOfVehicles();
    }

    const clearFieldNumberOfVehicles = (): void => {
        const isJobSiteVehicleServiceKind: boolean = serviceKind.selectedServiceKind?.value === jobsiteVehicle;
        const sourceApplicationIsLorie: boolean = props.mode == creationMode || transportFlowData?.sourceApplication == sourceApplicationLorie;

        if (!isJobSiteVehicleServiceKind && sourceApplicationIsLorie) {
            handleNumberOfVehiclesChange(null);
        }
    }

    const handleUnloadingDurationChanged = (value: number): void => {
        setUnloadingDuration(value);
        clearFieldNumberOfVehicles();
    }

    const handleQuantityChanged = (value: number): void => {
        setQuantity(value);
        clearFieldNumberOfVehicles();
    }

    const handleCoatingApplicationModeChanged = (coatingApplicationMode: CoatingApplicationModeEnum): void => {
        setCoatingApplicationMode(coatingApplicationMode);
        if (!isJobSiteVehicleServiceKind) {
            setUnloadingDurationFromCoatingApplicationMode(coatingApplicationMode);
        }
        clearFieldNumberOfVehicles();
    }

    const handleIsUnloadingContinuityRequiredChanged = (value: boolean): void => {
        setIsUnloadingContinuityRequired(value);
        clearFieldNumberOfVehicles();
    }

    const handlePickupAddressChanged = (value: AddressModel): void => {
        setPickupAddress(value);
        clearFieldNumberOfVehicles();
    }

    const handleDeliveryAddressChanged = (value: AddressModel): void => {
        setDeliveryAddress(value);
        clearFieldNumberOfVehicles();
    }

    const handleReverseSites = (): void => {
        const oldPickupAddress = { ...pickupAddress };
        const oldPickupCoordinates = { ...pickupCoordinates };
        const oldIsPickupMapMarkerActive = isPickupMapMarkerActive;
        const oldSenderSite = { ...senderSite };
        const oldFailedPickupAddress = failedPickupAddress;

        setPickupAddress(deliveryAddress);
        setPickupCoordinates(deliveryCoordinates);
        setIsPickupMapMarkerActive(isDeliveryMapMarkerActive);
        setSenderSite(receiverSite);
        setFailedPickupAddress(failedDeliveryAddress);

        setDeliveryAddress(oldPickupAddress);
        setDeliveryCoordinates(oldPickupCoordinates);
        setIsDeliveryMapMarkerActive(oldIsPickupMapMarkerActive);
        setReceiverSite(oldSenderSite);
        setFailedDeliveryAddress(oldFailedPickupAddress);

        reversePinsAndCalculateMapRoute(deliveryCoordinates, oldPickupCoordinates);

        clearFieldNumberOfVehicles();
        reInitialisePayerContent();
    }

    const setUnloadingDurationFromCoatingApplicationMode = (coatingApplicationMode: CoatingApplicationModeEnum): void => {
        if (coatingApplicationMode !== null) {
            switch (coatingApplicationMode) {
                case CoatingApplicationModeEnum.Manual:
                    setUnloadingDuration(80);
                    break;

                case CoatingApplicationModeEnum.Mechanical:
                    setUnloadingDuration(15);
                    break;

                case CoatingApplicationModeEnum.Mixed:
                    setUnloadingDuration(40);
                    break;

                case CoatingApplicationModeEnum.NotApplicable:
                    setUnloadingDuration(null);
                    break;

                default:
                    setUnloadingDuration(null);
            }
        }
    }

    const checkBeneficiaryValidation = (): boolean => {
        if (beneficiary.contact && beneficiary.contact?.label?.trim() !== "") {
            return true;
        }
        else {
            return false;
        }
    }

    const checkReceiverSite = (): boolean => {
        if (receiverSite.siteKind?.value === unreferencedCustomer) {
            if (receiverSite.unReferencedSiteCustomer?.trim()) {
                return true;
            } else {
                return false;
            }
        }
        if (receiverSite.siteKind?.value === jobSite) {
            if (receiverSite.selectedSiteAgency && receiverSite.jobSiteIdmdm?.trim()) {
                return true;
            } else {
                return false;
            }
        }
        if (receiverSite.siteKind?.value === productionSite) {
            if (receiverSite.productionSite && receiverSite.productionSiteActivity?.value) {
                return true;
            } else {
                return false;
            }
        }
        if (receiverSite.siteKind?.value === referencedCustomer) {
            if (receiverSite.referencedSiteCustomer) {
                return true;
            } else {
                return false;
            }
        }
        return true;
    }

    const checkNegotiatedPurchasePrice = (): boolean => {
        return !(negotiatedPurchasePriceKindData?.value && negotiatedPurchasePriceValue && !negotiatedPurchasePriceAppliesToExternalTransporters && !negotiatedPurchasePriceAppliesToInternalTransporters);
    }

    const checkSenderSite = (): boolean => {
        if (!senderSite.siteKind) {
            if (isJobSiteVehicleServiceKind) {
                return true;
            } else {
                return false;
            }
        }
        if (senderSite.siteKind?.value === unreferencedCustomer) {
            if (senderSite.unReferencedSiteCustomer?.trim()) {
                return true;
            } else {
                return false;
            }
        }
        if (senderSite.siteKind?.value === jobSite) {
            if (senderSite.selectedSiteAgency && senderSite.jobSiteIdmdm?.trim()) {
                return true;
            } else {
                return false;
            }
        }
        if (senderSite.siteKind?.value === productionSite) {
            if (senderSite.productionSite && senderSite.productionSiteActivity?.value) {
                return true;
            } else {
                return false;
            }
        }
        if (senderSite.siteKind?.value === referencedCustomer) {
            if (senderSite.referencedSiteCustomer) {
                return true;
            } else {
                return false;
            }
        }
        return true;
    }

    const checkPickupAdress = (): boolean => {
        if ((isJobSiteVehicleServiceKind && senderSite.siteKind) || !isJobSiteVehicleServiceKind) {
            if (pickupAddress.freeFormAddress?.trim() && pickupAddress.zipCode?.trim() && pickupAddress.city?.trim()) {
                return true;
            } else {
                return false;
            }
        }
        return true;
    }

    const getFullName = (element: ContactLightModel): string => {
        const fullName: string = (element.lastName && element.firstName) ? element.lastName + " " + element.firstName :
            (element.lastName && !element.firstName) ? element.lastName :
                (element.firstName && !element.lastName) ? element.firstName : "";

        return fullName;
    }

    const createReferencedCustomerItem = (element: ReferencedCustomerLightModel): SelectInputItemModel => {
        return {
            label: element.name,
            value: element.customerMdmId,
            freeFormAddress: element.freeFormAddress,
            line1: element.addressLine1,
            line2: element.addressLine2,
            zipCode: element.addressZipCode,
            city: element.addressCity
        }
    }

    const checkArrivalEndTimeAtReceiverSite = () => {
        if (priority === TransportPriorityEnum.high)
            return true;

        return (priority === TransportPriorityEnum.low || priority === TransportPriorityEnum.medium) && arrivalEndTimeAtReceiverSite !== null;
    }

    const checkRequiredQuotationApi = (): boolean => {
        if (props.mode === creationMode) {
            if (quotationCall.isQuotationCallSuccess) {
                return true;
            } else {
                return false;
            }
        }
        return true;
    }

    const isValidDate = (refDate: React.MutableRefObject<DatePicker>): boolean => {
        return refDate.current?.validity.valid;
    }

    const validateClickHandler = (): void => {
        if (props.mode == updateMode && transportFlowData.hasOverridesOnNegotiatedPurchasePricedTrips && transportFlowData.negotiatedPurchasePriceKind !== (negotiatedPurchasePriceKindData?.value ?? null)) {
            setIsConfirmValidationExistingSpecificOrNegotiatedPriceDialogOpened(true);
            return;
        }

        if (props.mode == updateMode &&
            ((transportFlowData.hasOverridesOnNegotiatedPurchasePricedTripsOnExternal
                && (transportFlowData.negotiatedPurchasePriceAppliesToExternalTransporters != negotiatedPurchasePriceAppliesToExternalTransporters || (!negotiatedPurchasePriceValue && transportFlowData.negotiatedPurchaseUnitPrice)) ||
                (transportFlowData.hasOverridesOnNegotiatedPurchasePricedTripsOnInternal
                    && (transportFlowData.negotiatedPurchasePriceAppliesToInternalTransporters != negotiatedPurchasePriceAppliesToInternalTransporters || (!negotiatedPurchasePriceValue && transportFlowData.negotiatedPurchaseUnitPrice)))))) {
            setIsConfirmValidationExistingSpecificOrNegotiatedPriceOnInternalExternalDialogOpened(true);
            return;
        }

        if (requestedBy.undefinedContact || beneficiary.undefinedContact) {
            setIsConfirmValidationDialogOpened(true);
            return;
        }
        createUpdateFlow();
    }

    const confirmValidate = (): void => {
        setIsConfirmValidationDialogOpened(false);
        createUpdateFlow();
    }

    const handleConfirmValidationExistingSpecificOrNegotiatedPrice = (): void => {
        setIsConfirmValidationExistingSpecificOrNegotiatedPriceDialogOpened(false);
        createUpdateFlow();
    }

    const handleConfirmValidationExistingSpecificOrNegotiatedPriceOnInternalExternal = (): void => {
        setIsConfirmValidationExistingSpecificOrNegotiatedPriceOnInternalExternalDialogOpened(false);
        createUpdateFlow();
    }

    const handleCloseConfirmValidationDialog = (): void => {
        setIsConfirmValidationDialogOpened(false);
    }

    const createUpdateFlow = debounce((): void => {
        const transportRequestId: string = transportRequest.selectedTransportRequest ? transportRequest.selectedTransportRequest.value : null;
        const requestedByFullName: string = requestedBy.undefinedContact ? transportFlowData.requestedByName : (requestedBy.contact?.label?.trim() === "" ? null : requestedBy.contact?.label?.trim());
        const requestedByContactId: string = requestedBy.undefinedContact ? transportFlowData.requestedByContactId : (!requestedBy.contact ? null : requestedBy.contact.value);
        const beneficiaryName: string = beneficiary.undefinedContact ? transportFlowData.beneficiaryName : (beneficiary.contact ? (beneficiary.contact?.label?.trim() === "" ? null : beneficiary.contact?.label?.trim()) : null);
        const beneficiaryContactId: string = beneficiary.undefinedContact ? transportFlowData.beneficiaryContactId : (!beneficiary.contact ? null : beneficiary.contact.value);
        const isAutomaticPayer: boolean = payerSite?.value === "Automatic" || !payerSite;

        const negotiatedPurchasePriceVehicleTypeGroupId = vehicleType.selectedVehicleType?.value
            ? vehicleType.vehicleTypeList.find(x => x.vehicleTypeId == vehicleType.selectedVehicleType?.value)?.vehicleTypeGroupId
            : null;

        const requestArgs: TransportFlowRequestArgs = {
            transportRequestId: transportRequestId,
            isRequestless: transportRequest.selectedTransportRequest ? false : true,
            priority: priority,
            endDate: dateConfig.endDateValue,
            startDate: dateConfig.startDateValue,
            serviceKind: serviceKind.selectedServiceKind?.value,
            logisticsUnitId: logisticsUnit.selectedLogisticsUnit?.value,
            coatingApplicationMode: coatingApplicationMode,
            productLevel3SegmentationId: !level3Segmentation.level3Seg ? null : level3Segmentation.level3Seg.value,
            productCode: productLabel?.trim() === "" ? null : productLabel?.trim(),
            productQuantity: quantity,
            unitOfMesure: quantity === null ? null : 'T',
            isNightWork: isNightWork,
            senderSiteProductionSiteId: !senderSite.productionSite ? null : senderSite.productionSite.value,
            senderSiteAgencyId: senderSite.productionSite ? senderSite.productionSite.agencyId :
                (!senderSite.selectedSiteAgency ? null : senderSite.selectedSiteAgency.value),
            senderSiteJobSiteMdmId: senderSite.jobSiteIdmdm?.trim() ? senderSite.jobSiteIdmdm?.trim() : null,
            senderSiteJobSiteLabel: senderSite.jobSiteLabel?.trim() ? senderSite.jobSiteLabel?.trim() : null,
            senderSiteCustomerMdmId: !senderSite.referencedSiteCustomer ? null : senderSite.referencedSiteCustomer.value,
            senderSiteUnreferencedCustomer: senderSite.unReferencedSiteCustomer?.trim() ? senderSite.unReferencedSiteCustomer?.trim() : null,
            senderSiteUnreferencedCustomerSite: senderSite.unReferencedSiteCustomerSite?.trim() ? senderSite.unReferencedSiteCustomerSite?.trim() : null,
            pickupFreeFormAddress: pickupAddress.freeFormAddress?.trim() ? pickupAddress.freeFormAddress?.trim() : null,
            pickupAddressLine1: pickupAddress.line1?.trim() ? pickupAddress.line1?.trim() : null,
            pickupAddressLine2: pickupAddress.line2?.trim() ? pickupAddress.line2?.trim() : null,
            pickupAddressZipCode: pickupAddress.zipCode?.trim() ? pickupAddress.zipCode?.trim() : null,
            pickupAddressCity: pickupAddress.city?.trim() ? pickupAddress.city?.trim() : null,
            pickupCoordinatesLatitude: pickupCoordinates?.latitude,
            pickupCoordinatesLongitude: pickupCoordinates?.longitude,
            receiverSiteProductionSiteId: !receiverSite.productionSite ? null : receiverSite.productionSite.value,
            receiverSiteAgencyId: receiverSite.productionSite ? receiverSite.productionSite.agencyId :
                (!receiverSite.selectedSiteAgency ? null : receiverSite.selectedSiteAgency.value),
            receiverSiteJobSiteMdmId: receiverSite.jobSiteIdmdm?.trim() ? receiverSite.jobSiteIdmdm?.trim() : null,
            receiverSiteJobSiteLabel: receiverSite.jobSiteLabel?.trim() ? receiverSite.jobSiteLabel?.trim() : null,
            receiverSiteCustomerMdmId: !receiverSite.referencedSiteCustomer ? null : receiverSite.referencedSiteCustomer.value,
            receiverSiteUnreferencedCustomer: receiverSite.unReferencedSiteCustomer?.trim() ? receiverSite.unReferencedSiteCustomer?.trim() : null,
            receiverSiteUnreferencedCustomerSite: receiverSite.unReferencedSiteCustomerSite?.trim() ? receiverSite.unReferencedSiteCustomerSite?.trim() : null,
            deliveryFreeFormAddress: deliveryAddress.freeFormAddress?.trim() ? deliveryAddress.freeFormAddress?.trim() : null,
            deliveryAddressLine1: deliveryAddress.line1?.trim() ? deliveryAddress.line1?.trim() : null,
            deliveryAddressLine2: deliveryAddress.line2?.trim() ? deliveryAddress.line2?.trim() : null,
            deliveryAddressZipCode: deliveryAddress.zipCode?.trim() ? deliveryAddress.zipCode?.trim() : null,
            deliveryAddressCity: deliveryAddress.city?.trim() ? deliveryAddress.city?.trim() : null,
            deliveryCoordinatesLatitude: deliveryCoordinates?.latitude,
            deliveryCoordinatesLongitude: deliveryCoordinates?.longitude,
            requestedByIsExternal: requestedBy.contactType?.value !== internal,
            requestedByFullName: requestedByFullName,
            requestedByContactId: requestedByContactId,
            beneficiaryIsExternal: beneficiary.contactType?.value !== internal,
            beneficiaryName: beneficiaryName,
            beneficiaryContactId: beneficiaryContactId,
            beneficiaryPhoneNumber: beneficiary.contactPhoneNumber,
            transportersInstructions: transportersInstructions,
            arrivalTimeAtSenderSiteIsFixed: arrivalTimeAtSenderSiteIsFixed,
            arrivalTimeAtSenderSite: arrivalTimeAtSenderSite,
            arrivalTimeAtReceiverSite: arrivalTimeAtReceiverSite,
            unloadingDuration: isJobSiteVehicleServiceKind ? (jobSiteVehicleUnloadingDuration !== null ? jobSiteVehicleUnloadingDuration.toShortTimeString() : null) : fromMinutes(unloadingDuration),
            vehicleTypeId: vehicleType.selectedVehicleType?.value,
            numberOfVehicles: quotationResult.numberOfVehicles,
            isUnloadingContinuityRequired: isUnloadingContinuityRequired,
            numberOfTurns: quotationResult.totalNumberOfTurns === null ? 0 : quotationResult.totalNumberOfTurns,
            estimatedTransportCost: quotationResult.estimatedPrice,
            estimatedTripDuration: fromMinutes(tripDuration),
            transportFlowId: props.mode === updateMode ? props.transportFlowId : null,
            recalculatePlanning: props.mode === updateMode ? recalculatePlanning : false,
            receiverSiteLevel2ActivityId: (receiverSite.productionSiteActivity?.value && receiverSite.productionSiteActivity.value !== "") ? receiverSite.productionSiteActivity.value : null,
            senderSiteLevel2ActivityId: (senderSite.productionSiteActivity?.value && senderSite.productionSiteActivity.value !== "") ? senderSite.productionSiteActivity.value : null,
            transportBillingMode: isAutomaticPayer ? quotationResult.transportBillingMode?.toString() : transportBillingMode?.value,
            payerEntity: isAutomaticPayer ? quotationResult.payerEntity?.toString() : payerSite?.payerType,
            payerIdentifier: isAutomaticPayer ? quotationResult.payerIdentifier : payerSite?.value,
            payerLabel: isAutomaticPayer ? payerLabel : payerSite?.label,
            payerOrigin: isAutomaticPayer ? quotationResult.payerOrigin?.toString() : null,
            payerOverriden: isAutomaticPayer ? false : true,
            payerLevel2ActivityId: isAutomaticPayer
                ? payerSite?.payerType === SiteEntityTypeEnum.ProductionSite
                    ? getPayerLevel2ActivityId(ModelMappings.mapQuotationPayerSiteOrigin(quotationResult.payerOrigin), ModelMappings.mapQuotationSiteEntityType(quotationResult.payerEntity))
                    : null
                : !payerLevel2Activity ? null : payerLevel2Activity.value,
            payerZephyrJobCode: payerZephyrJobCode,
            payerOverridenBeneficiaryContactId: !isAutomaticPayer && payerSite?.payerType === SiteEntityTypeEnum.Agency ? payerOverridenBeneficiary?.value : null,
            payerOverridenBeneficiaryName: !isAutomaticPayer && payerSite?.payerType === SiteEntityTypeEnum.Agency ? payerOverridenBeneficiary?.label : null,
            payerOverridenJobSiteMdmId: !isAutomaticPayer && payerSite?.payerType === SiteEntityTypeEnum.Agency ? payerOverridenJobSiteIdmdm : null,
            billedTransportPrice: billedSellPrice.billedPriceValue,
            billedTransportUnitPrice: billedSellPrice.billedUnitPriceValue,
            billedTransportUnitPriceIsLocked: billedSellPrice.billedUnitPriceIsLocked,
            transportSellPriceKind: sellPriceKindData?.value,
            quotationResultJson: JSON.stringify(quotationResult),
            estimatedTripDistance: estimateDistance / 1000,
            arrivalSlotDuration: arrivalSlotDuration ? fromMinutes(arrivalSlotDuration) : null,
            zephyrOrderNumber: zephyrOrderNumber,
            negotiatedPurchaseUnitPrice: negotiatedPurchasePriceValue ? negotiatedPurchasePriceValue : null,
            negotiatedPurchasePriceKind: negotiatedPurchasePriceKindData?.value ? negotiatedPurchasePriceKindData?.value : null,
            negotiatedPurchaseVehicleTypeGroupId: negotiatedPurchasePriceVehicleTypeGroupId,
            negotiatedPurchasePriceAppliesToInternalTransporters: negotiatedPurchasePriceAppliesToInternalTransporters,
            negotiatedPurchasePriceAppliesToExternalTransporters: negotiatedPurchasePriceAppliesToExternalTransporters,
            attachments: attachmentFiles,
            isDuplicateMode: props.mode === duplicateMode,
            hasConfirmedExistenceOfDeliveryOverridesOnNegotiatedTrips: props.mode === updateMode ? transportFlowData.hasOverridesOnNegotiatedPurchasePricedTrips : false
        }

        setLoadingContent(true);
        if (props.mode === updateMode) {
            TransportFlowApiClient.UpdateFlow(requestArgs)
                .then((res) => {
                    const firstCommandResult = res.data.commandResults[0];
                    const hasBusinessWarnings = firstCommandResult.hasBusinessWarnings;
                    let warnings: string[] = [];
                    if (hasBusinessWarnings)
                        warnings = BusinessWarnings.Get(res.data);

                    props.onCloseAfterValidation(firstCommandResult.hasBusinessErrors, hasBusinessWarnings, warnings, props.flowBusinessId, updateMode);

                    if (firstCommandResult.hasBusinessErrors) {
                        setLoadingContent(false);
                    }
                })
                .catch(() => {
                    setLoadingContent(false);
                });
        } else {
            TransportFlowApiClient.CreateFlow(requestArgs)
                .then((res) => {
                    const firstCommandResult = res.data.commandResults[0];
                    const hasBusinessWarnings = firstCommandResult.hasBusinessWarnings;
                    let warnings: string[] = [];
                    if (hasBusinessWarnings)
                        warnings = BusinessWarnings.Get(res.data);

                    props.onCloseAfterValidation(firstCommandResult.hasBusinessErrors, hasBusinessWarnings, warnings, res.data.extraResult, creationMode);

                    if (firstCommandResult.hasBusinessErrors) {
                        setLoadingContent(false);
                    }
                })
                .catch(() => {
                    setLoadingContent(false);
                });
        }
    }, 500);

    const getQuotationAndNegociatedPurchasePrices = (quotationRequestArgs: TransportQuotationRequest
        , negotiatedPurchasePricesRequestArgs: NegotiatedPurchasePricesRequestArgs)
        : Promise<[AxiosResponse<TransportQuotationResult>, AxiosResponse<Array<NegotiatedPurchasePriceLightModel>>]> => {
        return Promise.all([
            TransportQuotationApiClient.GetQuotation(quotationRequestArgs),
            TransportFlowApiClient.GetNegotiatedPurchasePrices(negotiatedPurchasePricesRequestArgs)
        ])
    }

    const getQuotation = (): void => {
        const transportQuotationRequest: TransportQuotationRequest = {
            sourceApplication: sourceApplicationLorie,
            requestedLogisticsUnitId: logisticsUnit.selectedLogisticsUnit?.value,
            senderSite: !senderSite.siteKind ? null :
                {
                    agencyId: !senderSite.selectedSiteAgency ? null : senderSite.selectedSiteAgency.value,
                    customerMdmId: !senderSite.referencedSiteCustomer ? null : senderSite.referencedSiteCustomer.value,
                    jobSiteMdmId: senderSite.jobSiteIdmdm?.trim() ? senderSite.jobSiteIdmdm?.trim() : null,
                    productionSiteId: !senderSite.productionSite ? null : senderSite.productionSite.value,
                    level2ActivityId: senderSite.productionSiteActivity?.value ? senderSite.productionSiteActivity.value : null,
                    unreferencedCustomer: senderSite.unReferencedSiteCustomer?.trim() ? senderSite.unReferencedSiteCustomer?.trim() : null,
                    supplierMdmId: null,
                    businessUnit: null,
                    businessUnitSubPhase: null,
                    companyId: null
                },
            pickupAddress: {
                line1: pickupAddress?.line1?.trim(),
                line2: null,
                zipCode: pickupAddress?.zipCode?.trim(),
                statisticZipCode: null,
                city: pickupAddress?.city?.trim(),
                country: null
            },
            pickupCoordinates: {
                latitude: pickupCoordinates?.latitude,
                longitude: pickupCoordinates?.longitude
            },
            receiverSite: {
                agencyId: !receiverSite.selectedSiteAgency ? null : receiverSite.selectedSiteAgency.value,
                customerMdmId: !receiverSite.referencedSiteCustomer ? null : receiverSite.referencedSiteCustomer.value,
                jobSiteMdmId: receiverSite.jobSiteIdmdm?.trim() ? receiverSite.jobSiteIdmdm?.trim() : null,
                productionSiteId: !receiverSite.productionSite ? null : receiverSite.productionSite.value,
                level2ActivityId: receiverSite.productionSiteActivity?.value ? receiverSite.productionSiteActivity.value : null,
                unreferencedCustomer: receiverSite.unReferencedSiteCustomer?.trim() ? receiverSite.unReferencedSiteCustomer?.trim() : null,
                businessUnit: null,
                businessUnitSubPhase: null,
                companyId: null,
                supplierMdmId: null
            },
            deliveryAddress: {
                line1: deliveryAddress?.line1?.trim(),
                line2: null,
                zipCode: deliveryAddress?.zipCode?.trim(),
                statisticZipCode: null,
                city: deliveryAddress?.city?.trim(),
                country: null
            },
            deliveryCoordinates: {
                latitude: deliveryCoordinates?.latitude,
                longitude: deliveryCoordinates?.longitude
            },
            product: {
                code: productLabel?.trim(),
                level3Segmentation: level3Segmentation.level3Seg?.value,
                level4Segmentation: null
            },
            coatingApplicationMode: ModelMappings.mapCoatingApplicationMode(coatingApplicationMode),
            quantity: isJobSiteVehicleServiceKind ? 0 : quantity,
            unitOfMeasure: "T",
            vehicleTypeId: vehicleType.selectedVehicleType?.value,
            arrivalTimeAtSenderSite: arrivalTimeAtSenderSiteIsFixed === true ? arrivalTimeAtSenderSite : null,
            arrivalTimeAtReceiverSite: arrivalTimeAtSenderSiteIsFixed === true ? null : arrivalTimeAtReceiverSite,
            unloadingDuration: !isJobSiteVehicleServiceKind ? fromMinutes(unloadingDuration) : null,
            durationOnJobsite: isJobSiteVehicleServiceKind ? jobSiteVehicleUnloadingDuration.toShortTimeString() : null,
            tripDuration: fromMinutes(tripDuration),
            numberOfFinishers: 0,
            isUnloadingContinuityRequired: isUnloadingContinuityRequired,
            numberOfVehicles: quotationResult?.numberOfVehicles,
            transportPriority: ModelMappings.mapTransportPriority(priority),
            serviceKind: ModelMappings.mapTransportServiceKind(getTransportServiceKindValue(serviceKind.selectedServiceKind?.value)),
            priceGridKind: ModelMappings.mapPriceGridKind(PriceGridKindEnum[sellPriceKindData?.value]),
            isNightWork: isNightWork
        };

        const isAutomaticPayer: boolean = payerSite?.value === "Automatic" || !payerSite;

        setLoadingContent(true);

        const negotiatedPurchasePricesRequestArgs: NegotiatedPurchasePricesRequestArgs = {
            logisticsUnitId: logisticsUnit.selectedLogisticsUnit?.value,
            vehicleTypeId: vehicleType.selectedVehicleType?.value,
            zephyrOrderNumber: zephyrOrderNumber === "" ? null : zephyrOrderNumber,
            startDate: dateConfig.startDateValue,
            endDate: dateConfig.endDateValue,
            senderSite: {
                agencyId: senderSite.siteKind?.value === productionSite
                    ? senderSite.productionSite?.agencyId
                    : !senderSite.selectedSiteAgency
                        ? null
                        : senderSite.selectedSiteAgency.value,
                customerMdmId: !senderSite.referencedSiteCustomer ? null : senderSite.referencedSiteCustomer.value,
                jobSiteMdmId: senderSite.jobSiteIdmdm?.trim() ? senderSite.jobSiteIdmdm?.trim() : null,
                productionSiteId: !senderSite.productionSite ? null : senderSite.productionSite.value,
                level2ActivityId: senderSite.productionSiteActivity?.value ? senderSite.productionSiteActivity.value : null,
            },
            receiverSite: {
                agencyId: receiverSite.siteKind?.value === productionSite
                    ? receiverSite.productionSite?.agencyId
                    : !receiverSite.selectedSiteAgency
                        ? null
                        : receiverSite.selectedSiteAgency.value,
                customerMdmId: !receiverSite.referencedSiteCustomer ? null : receiverSite.referencedSiteCustomer.value,
                jobSiteMdmId: receiverSite.jobSiteIdmdm?.trim() ? receiverSite.jobSiteIdmdm?.trim() : null,
                productionSiteId: !receiverSite.productionSite ? null : receiverSite.productionSite.value,
                level2ActivityId: receiverSite.productionSiteActivity?.value ? receiverSite.productionSiteActivity.value : null,
            }
        };

        getQuotationAndNegociatedPurchasePrices(transportQuotationRequest, negotiatedPurchasePricesRequestArgs)
            .then(res => {
                if (res !== undefined) {
                    const resQuotation = res[0];
                    const resVehicleTypesData = res[1].data;
                    setNegotiatedPurchasePrices(resVehicleTypesData);

                    setQuotationRequest({
                        coatingApplicationMode: coatingApplicationMode,
                        level3Segmentation: !level3Segmentation.level3Seg ? null : level3Segmentation.level3Seg.value,
                        quantity: quantity,
                        tripDuration: tripDuration,
                        arrivalTimeAtSenderSite: arrivalTimeAtSenderSiteIsFixed === true ? arrivalTimeAtSenderSite : null,
                        arrivalTimeAtReceiverSite: arrivalTimeAtSenderSiteIsFixed === true ? null : arrivalTimeAtReceiverSite,
                        numberOfVehicles: resQuotation.data.numberOfVehicles,
                        isUnloadingContinuityRequired: isUnloadingContinuityRequired,
                        unloadingDuration: isJobSiteVehicleServiceKind ? jobSiteVehicleUnloadingDuration : unloadingDuration,
                        senderSite: {
                            agencyId: !senderSite.selectedSiteAgency ? null : senderSite.selectedSiteAgency.value,
                            customerMdmId: !senderSite.referencedSiteCustomer ? null : senderSite.referencedSiteCustomer.value,
                            jobSiteMdmId: senderSite.jobSiteIdmdm,
                            productionSiteId: !senderSite.productionSite ? null : senderSite.productionSite.value,
                            unreferencedCustomer: senderSite.unReferencedSiteCustomer,
                            productionSiteActivity: senderSite.productionSiteActivity?.value ? senderSite.productionSiteActivity.value : null
                        },
                        pickupAddress: {
                            line1: pickupAddress.line1,
                            zipCode: pickupAddress.zipCode,
                            city: pickupAddress.city
                        },
                        receiverSite: {
                            agencyId: !receiverSite.selectedSiteAgency ? null : receiverSite.selectedSiteAgency.value,
                            customerMdmId: !receiverSite.referencedSiteCustomer ? null : receiverSite.referencedSiteCustomer.value,
                            jobSiteMdmId: receiverSite.jobSiteIdmdm,
                            productionSiteId: !receiverSite.productionSite ? null : receiverSite.productionSite.value,
                            unreferencedCustomer: receiverSite.unReferencedSiteCustomer,
                            productionSiteActivity: receiverSite.productionSiteActivity?.value ? receiverSite.productionSiteActivity.value : null
                        },
                        deliveryAddress: {
                            line1: deliveryAddress.line1,
                            zipCode: deliveryAddress.zipCode,
                            city: deliveryAddress.city
                        },
                        priceGridKind: ModelMappings.mapQuotationPriceGridKind(resQuotation.data.priceGridKind),
                        payerActivity: !payerLevel2Activity ? null : payerLevel2Activity.value,
                        payerJobSiteMdmId: payerOverridenJobSiteIdmdm === "" ? null : payerOverridenJobSiteIdmdm,
                        payerSite: isAutomaticPayer ? null : payerSite?.value,
                        transportBillingMode: !transportBillingMode ? null : transportBillingMode.value
                    });

                    //NOTE HGA Actuellement les eventHandlers basés sur les maps n'accède pas au state du fonctional composant. 
                    //"arrivalTimesChangedAfterQuotationCall" est contournement qui indique si "ArrivalTimeAtReceiverSite" ou "ArrivalTimeAtSenderSite" ont été mis à jours suite à un calcul de service de quotation.
                    const arrivalTimesChangedAfterQuotationCall = document.getElementById("arrivalTimesChangedAfterQuotationCall") as HTMLInputElement;
                    if (arrivalTimesChangedAfterQuotationCall) {
                        arrivalTimesChangedAfterQuotationCall.value = 'true';
                    }

                    if (arrivalTimeAtSenderSiteIsFixed === true) {
                        const arrivalTime = resQuotation.data.firstDeliveryTime;
                        setArrivalTimeAtReceiverSite(arrivalTime);
                        if (!arrivalSlotDuration && priority !== TransportPriorityEnum.high) {
                            //Mettre 2 heures dans le créneau dès qu'on touche l'heure de début
                            const newSlotDuration = DefaultArrivalSlotDurationWhenArrivalTimeAtReceiverChanged;
                            const arrivalEndTime = new Date(arrivalTime).addMinutes(newSlotDuration);
                            setArrivalSlotDuration(newSlotDuration);
                            setArrivalEndTimeAtReceiverSite(arrivalEndTime);
                        }
                    }
                    else {
                        setArrivalTimeAtSenderSite(resQuotation.data.missionStartTime);
                    }

                    const priceKindData: SelectInputItemModel = getPriceKindDataValue(resQuotation.data.priceGridKind);
                    setSellPriceKindData(priceKindData);

                    if (resQuotation.data.negociatedPrice !== null) {
                        setBilledPriceData(priceKindData ? priceKindData.value : null, billedSellPrice, resQuotation.data.negociatedPrice, quantity, resQuotation.data.totalNumberOfTurns, resQuotation.data.numberOfVehicles);
                        setIsNegotiatedSellPrice(true);
                    } else {
                        setBilledPriceData(priceKindData ? priceKindData.value : null, billedSellPrice, resQuotation.data.estimatedPrice, quantity, resQuotation.data.totalNumberOfTurns, resQuotation.data.numberOfVehicles);
                        setIsNegotiatedSellPrice(false);
                    }

                    setEstimatedPrice(resQuotation.data.estimatedPrice);
                    setQuotationResult(resQuotation.data);

                    if (isAutomaticPayer) {
                        if (resQuotation.data?.payerIdentifier && resQuotation.data?.payerEntity) {
                            getPayerLabel(resQuotation.data.payerIdentifier, resQuotation.data.payerEntity);
                            setIsZephyrJobCodeDisabled(false);
                        } else {
                            setPayerLabel(null);
                            setIsZephyrJobCodeDisabled(true);
                        }
                    } else {
                        setPayerLabel(null);
                        setIsZephyrJobCodeDisabled(false);
                    }

                    setQuotationCall({
                        isQuotationCallSuccess: true,
                        isQuotationDetailsExpanded: true,
                        arrivalTimeAtSenderSiteIsFixed: !isJobSiteVehicleServiceKind ? arrivalTimeAtSenderSiteIsFixed : null
                    });

                    const payerEntity: string = isAutomaticPayer ? resQuotation.data.payerEntity?.toString() : SiteEntityTypeEnum.ProductionSite;
                    const payerIdentifier: string = isAutomaticPayer ? resQuotation.data.payerIdentifier : payerSite?.value;
                    const payerOrigin: string = isAutomaticPayer ? resQuotation.data.payerOrigin?.toString() : null;
                    const payerOverriden = !isAutomaticPayer;

                    GetJobCodeZephyr(payerEntity, payerIdentifier, payerOrigin, payerOverriden, false)
                        .then((result) => {
                            if (!result.data) {
                                ToastService.showErrorToast("Chantier non connu du référentiel chantier Colas.");
                            }
                            setPayerZephyrJobCode(result.data ? result.data : null);
                            setLoadingContent(false);
                        });
                } else {
                    setQuotationResult(null);
                    setPayerLabel(null);
                    setQuotationCall({
                        isQuotationCallSuccess: false,
                        isQuotationDetailsExpanded: false,
                        arrivalTimeAtSenderSiteIsFixed: null
                    });
                    setLoadingContent(false);
                }
            });
    }

    const setBilledPriceData = (priceKindData: string, billedPrice: BilledPriceModel, price: number, quantity: number, totalNumberOfTurns: number, totalNbOfVehicles: number): void => {
        let billedUnitPrice: number = null;
        let billedPriceValue: number = null;

        if (isJobSiteVehicleServiceKind)
            setBilledSellPrice({
                billedPriceValue: price,
                billedUnitPriceValue: null,
                billedUnitPriceIsLocked: true
            });
        else {
            switch (priceKindData) {
                case PriceGridKindEnum.PerTimePeriod:
                    billedUnitPrice = totalNbOfVehicles ? price / totalNbOfVehicles : null;
                    billedPriceValue = price;
                    break;

                case PriceGridKindEnum.PerTon:
                    billedUnitPrice = billedPrice.billedUnitPriceIsLocked ? billedPrice.billedUnitPriceValue : (quantity ? price / quantity : null);
                    billedPriceValue = billedPrice.billedUnitPriceIsLocked ? (billedUnitPrice * quantity) : price;
                    break;

                case PriceGridKindEnum.PerTurn:
                    billedUnitPrice = billedPrice.billedUnitPriceIsLocked ? billedPrice.billedUnitPriceValue : (totalNumberOfTurns ? price / totalNumberOfTurns : null);
                    billedPriceValue = billedPrice.billedUnitPriceIsLocked ? (billedUnitPrice * totalNumberOfTurns) : price;
                    break;

                default:
                    billedUnitPrice = null;
            }

            setBilledSellPrice({
                billedPriceValue: billedPriceValue,
                billedUnitPriceIsLocked: billedPrice.billedUnitPriceIsLocked,
                billedUnitPriceValue: billedUnitPrice
            });
        }
    }

    const handleQuotationDetailsExpandedChange = (): void => {
        setQuotationCall({
            ...quotationCall,
            isQuotationDetailsExpanded: !quotationCall.isQuotationDetailsExpanded
        });
    }

    const getPayerLabel = (payerIdentifier: string, payerEntity: string): void => {
        switch (payerEntity) {
            case SiteEntityTypeEnum.ProductionSite:
                const senderProductionSite: SelectInputItemModel = senderSite.siteList.find(x => x.value === payerIdentifier);
                if (senderProductionSite) {
                    setPayerLabel(senderProductionSite.label);
                    break;
                }
                const receiverProductionSite: SelectInputItemModel = receiverSite.siteList.find(x => x.value === payerIdentifier);
                if (receiverProductionSite) {
                    setPayerLabel(receiverProductionSite.label);
                    break;
                }
                getPayer(payerIdentifier, payerEntity);
                break;

            case SiteEntityTypeEnum.Agency:
                const senderAgency: SelectInputItemModel = senderSite.siteAgencyList.find(x => x.value === payerIdentifier);
                if (senderAgency) {
                    setPayerLabel(senderAgency.label);
                    break;
                }
                const receiverAgency: SelectInputItemModel = receiverSite.siteAgencyList.find(x => x.value === payerIdentifier);
                if (receiverAgency) {
                    setPayerLabel(receiverAgency.label);
                    break;
                }
                getPayer(payerIdentifier, payerEntity);
                break;

            case SiteEntityTypeEnum.Customer:
                const referencedSenderCustomer: SelectInputItemModel = senderSite.referencedSiteCustomerList.find(x => x.value === payerIdentifier);
                if (referencedSenderCustomer) {
                    setPayerLabel(referencedSenderCustomer.label);
                    break;
                }
                const referencedReceiverCustomer: SelectInputItemModel = receiverSite.referencedSiteCustomerList.find(x => x.value === payerIdentifier);
                if (referencedReceiverCustomer) {
                    setPayerLabel(referencedReceiverCustomer.label);
                    break;
                }
                getPayer(payerIdentifier, payerEntity);
                break;

            case SiteEntityTypeEnum.Supplier:
                getPayer(payerIdentifier, payerEntity);
                break;

            case SiteEntityTypeEnum.JobSite:
            case SiteEntityTypeEnum.UnreferencedCustomer:
                setPayerLabel(payerIdentifier);
                break;

            default:
                setPayerLabel(`${payerEntity}|${payerIdentifier}`);
        }
    }

    const getPayer = (payerIdentifier: string, payerEntity: string): void => {
        TransportFlowApiClient.GetPayerLabel(payerIdentifier, payerEntity)
            .then((res) => {
                setPayerLabel(res.data);
            });
    }

    const getPayerLevel2ActivityId = (payerOrigin: PayerSiteOriginEnum, payerEntity: SiteEntityTypeEnum): string => {
        if (payerOrigin && payerEntity === SiteEntityTypeEnum.ProductionSite) {
            switch (payerOrigin) {
                case PayerSiteOriginEnum.Sender:
                    return senderSite.productionSiteActivity?.value ? senderSite.productionSiteActivity.value : null;

                case PayerSiteOriginEnum.Receiver:
                    return receiverSite.productionSiteActivity?.value ? receiverSite.productionSiteActivity.value : null;
            }
        }

        return null;
    }

    const initialiseMap = (): void => {
        const map: Map = createMap(4, { latitude: 47.0853, longitude: 2.3945 });

        map.events.add('ready', function () {
            map.resize();
            const dataSource: source.DataSource = new source.DataSource(mapDataSourceId);
            map.sources.add(dataSource);

            map.events.add('click', handleMapClick);
        });

        setMyMap(map);
    }

    const getPickupAddressCoordinates = (freeformAddress: string): void => {
        if (freeformAddress?.trim()) {
            MapsService.ResolveAddress(freeformAddress)
                .then((position: GpsCoordinates) => {
                    setPickupPin(position);
                    if (position === null)
                        setFailedPickupAddress(true);
                    else
                        setFailedPickupAddress(false);
                });
            return;
        }

        if (!freeformAddress?.trim()) {
            setPickupCoordinates(null);
            removePinFromMaps(pickupPinId);
        }
    }

    const getDeliveryAddressCoordinates = (freeformAddress: string): void => {
        if (freeformAddress?.trim()) {
            MapsService.ResolveAddress(freeformAddress)
                .then((position: GpsCoordinates) => {
                    setDeliveryPin(position);
                    if (position === null)
                        setFailedDeliveryAddress(true);
                    else
                        setFailedDeliveryAddress(false);
                });
            return;
        }

        if (!freeformAddress?.trim()) {
            setDeliveryCoordinates(null);
            removePinFromMaps(deliveryPinId);
        }
    }

    const setPickupPin = (position: GpsCoordinates): void => {
        setMapPin(position, pickupPinId);
        if (deliveryCoordinates !== null &&
            ((arrivalTimeAtSenderSiteIsFixed === true && arrivalTimeAtSenderSite !== null) || (arrivalTimeAtSenderSiteIsFixed === false && arrivalTimeAtReceiverSite !== null)))
            setMapRoute(position, deliveryCoordinates);
    }
    const setDeliveryPin = (position: GpsCoordinates): void => {
        setMapPin(position, deliveryPinId);
        if (pickupCoordinates !== null &&
            ((arrivalTimeAtSenderSiteIsFixed === true && arrivalTimeAtSenderSite !== null) || (arrivalTimeAtSenderSiteIsFixed === false && arrivalTimeAtReceiverSite !== null)))
            setMapRoute(pickupCoordinates, position);
    }

    const reversePinsAndCalculateMapRoute = (pickupPosition: GpsCoordinates, deliveryPosition: GpsCoordinates): void => {
        if (pickupPosition?.latitude && pickupPosition?.longitude)
            setMapPin(pickupPosition, pickupPinId);

        if (deliveryPosition?.latitude && deliveryPosition?.longitude)
            setMapPin(deliveryPosition, deliveryPinId);

        if (pickupPosition?.latitude && pickupPosition?.longitude && deliveryPosition?.latitude && deliveryPosition?.longitude &&
            ((arrivalTimeAtSenderSiteIsFixed === true && arrivalTimeAtSenderSite !== null) || (arrivalTimeAtSenderSiteIsFixed === false && arrivalTimeAtReceiverSite !== null)))
            setMapRoute(pickupPosition, deliveryPosition);
    }

    const setMapPin = (position: GpsCoordinates, pinId: string): void => {
        pinId === pickupPinId ? setPickupCoordinates(position) : setDeliveryCoordinates(position);
        const dataSource = myMap.sources.getById(mapDataSourceId) as source.DataSource;
        const existingPin: Shape = dataSource.getShapeById(pinId);

        if (position) {
            if (!existingPin) {
                //add pin
                addPin(position, pinId, dataSource);
                addPinSymbol(dataSource);
            } else {
                //update pin position
                existingPin.setCoordinates([position.longitude, position.latitude]);
            }

            myMap.setCamera({
                zoom: 10,
                center: [position.longitude, position.latitude]
            });
        } else {
            if (existingPin) {
                dataSource.remove(existingPin);
            }
        }
    }

    const addPinSymbol = (dataSource: source.DataSource): void => {
        let layer: layer.Layer<layer.LayerEvents> = myMap.layers.getLayerById("layer");
        if (!layer) {
            //custom pin
            const symbolLayer: layer.SymbolLayer = createSymbolLayer(dataSource);
            myMap.layers.add(symbolLayer);
            layer = symbolLayer;
        }

        //Add related event
        myMap.events.add('mouseover', layer, () => myMap.getCanvasContainer().style.cursor = 'pointer');
        myMap.events.add('mouseout', layer, () => myMap.getCanvasContainer().style.cursor = 'default');
        myMap.events.add('mousedown', layer, mousedown);
        myMap.events.add('mousemove', mousemove);
        myMap.events.add('mouseup', mouseup);
    }

    const removePinFromMaps = (pinId: string): void => {
        const dataSource = myMap.sources.getById(mapDataSourceId) as source.DataSource;
        const existingPin: Shape = dataSource.getShapeById(pinId);

        if (existingPin) {
            dataSource.remove(existingPin);
        }

        const existingRoute: Shape = dataSource.getShapeById(routeId);
        if (existingRoute) {
            dataSource.remove(existingRoute);
            setEstimateDistance(null);
            setEstimateDuration(null);
        }
    }

    let selectedShape: Shape = null;

    const mousedown = (e: MapMouseEvent): void => {
        if (e.shapes && e.shapes.length > 0) {
            selectedShape = e.shapes[0] as Shape;
            myMap.setUserInteraction({
                dragPanInteraction: false
            });
        }
    }
    const mousemove = (e: MapMouseEvent) => {
        if (selectedShape) {
            selectedShape.setCoordinates(e.position);
        }
    }
    const mouseup = (): void => {
        if (selectedShape) {
            const dataSource = myMap.sources.getById(mapDataSourceId) as source.DataSource;
            const pickupShape: Shape = dataSource.getShapeById(pickupPinId);
            const deliveryShape: Shape = dataSource.getShapeById(deliveryPinId);
            const sourcesPickupCoordinates = pickupShape?.getCoordinates() as data.Position;
            const sourcesDeliveryCoordinates = deliveryShape?.getCoordinates() as data.Position;

            let pickupCoord = {} as GpsCoordinates;
            let deliveryCoord = {} as GpsCoordinates;

            //update coordinates
            if (sourcesPickupCoordinates) {
                pickupCoord = { latitude: sourcesPickupCoordinates[1], longitude: sourcesPickupCoordinates[0] };
                if (selectedShape.getId() === pickupPinId) {
                    MapsService.GetAddressFromCoordinates(pickupCoord)
                        .then((res: Address) => {
                            setPickupAddress({ freeFormAddress: res.freeformAddress, line1: res.line1, line2: null, zipCode: res.zipCode, city: res.city });
                            if (failedPickupAddress)
                                setFailedPickupAddress(false);
                            setPickupCoordinates(pickupCoord);
                        });
                }
            }
            if (sourcesDeliveryCoordinates) {
                deliveryCoord = { latitude: sourcesDeliveryCoordinates[1], longitude: sourcesDeliveryCoordinates[0] };
                if (selectedShape.getId() === deliveryPinId) {
                    MapsService.GetAddressFromCoordinates(deliveryCoord)
                        .then((res: Address) => {
                            setDeliveryAddress({ freeFormAddress: res.freeformAddress, line1: res.line1, line2: null, zipCode: res.zipCode, city: res.city });
                            if (failedDeliveryAddress)
                                setFailedDeliveryAddress(false);
                            setDeliveryCoordinates(deliveryCoord);
                        });
                }
            }
        }

        selectedShape = null;
        myMap.setUserInteraction({
            dragPanInteraction: true
        });
    }

    const setMapRoute = (pickupCoord: GpsCoordinates, deliveryCoord: GpsCoordinates): void => {
        const dataSource = myMap.sources.getById(mapDataSourceId) as source.DataSource;
        addLineLayer(myMap, dataSource);
        getTripEstimation(pickupCoord, deliveryCoord, dataSource);
    }

    const handleMapClick = (e): void => {
        setClickedMapPosition({ latitude: e.position[1], longitude: e.position[0] });
    }

    const handleBilledPriceChange = (value: number): void => {
        const billedUnitPriceValue: number = (!isJobSiteVehicleServiceKind && sellPriceKindData?.value !== PriceGridKindEnum.PerTimePeriod) ?
            (sellPriceKindData?.value === PriceGridKindEnum.PerTon ? (quantity ? value / quantity : null) :
                (quotationResult?.totalNumberOfTurns ? value / quotationResult?.totalNumberOfTurns : null)) : null;

        if (value == null || value == 0) {
            setBilledSellPrice({
                billedPriceValue: estimatedPrice,
                billedUnitPriceValue: billedUnitPriceValue,
                billedUnitPriceIsLocked: false
            });
        } else if (value < 0) {
            setBilledSellPrice({
                billedPriceValue: billedSellPrice.billedPriceValue,
                billedUnitPriceValue: billedUnitPriceValue,
                billedUnitPriceIsLocked: false
            });
        } else {
            setBilledSellPrice({
                billedPriceValue: value,
                billedUnitPriceValue: billedUnitPriceValue,
                billedUnitPriceIsLocked: false
            });
        }
    }

    const handleUnitBilledPriceChange = (value: number): void => {
        if (!isJobSiteVehicleServiceKind && sellPriceKindData?.value !== PriceGridKindEnum.PerTimePeriod) {
            switch (sellPriceKindData?.value) {
                case PriceGridKindEnum.PerTon:
                    setBilledSellPrice({
                        billedPriceValue: value * quantity,
                        billedUnitPriceValue: value,
                        billedUnitPriceIsLocked: false
                    });
                    break;

                case PriceGridKindEnum.PerTurn:
                    setBilledSellPrice({
                        billedPriceValue: value * quotationResult.totalNumberOfTurns,
                        billedUnitPriceValue: value,
                        billedUnitPriceIsLocked: false
                    });
                    break;

                default:
                    return;
            }
        }
    }

    const handleUnitPurchasePriceChange = (value: number): void => {
        setNegotiatedPurchasePriceValue(value);
    }

    const handleNegotiatedPurchasePriceAppliesToExternalTransportersChange = (): void => {
        setNegotiatedPurchasePriceAppliesToExternalTransporters(!negotiatedPurchasePriceAppliesToExternalTransporters);
    }

    const handleNegotiatedPurchasePriceAppliesToInternalTransportersChange = (): void => {
        setNegotiatedPurchasePriceAppliesToInternalTransporters(!negotiatedPurchasePriceAppliesToInternalTransporters);
    }

    const handleChangeBilledUnitPriceIsLocked = (): void => {
        setBilledSellPrice({ ...billedSellPrice, billedUnitPriceIsLocked: !billedSellPrice.billedUnitPriceIsLocked });
    }

    const getTripEstimation = (pickupCoord: GpsCoordinates, deliveryCoord: GpsCoordinates, dataSource: source.DataSource): void => {
        const existingRoute: Shape = dataSource.getShapeById(routeId);

        if (pickupCoord && deliveryCoord) {
            //calculate route
            getRouteAndEstimation(pickupCoord, deliveryCoord, dataSource, existingRoute, myMap);
        } else {
            if (existingRoute) {
                dataSource.remove(existingRoute);
                setEstimateDistance(null);
                setEstimateDuration(null);
            }
        }
    }

    const setMapWithCoordinates = (
        pickupCoordinatesLatitude: number,
        pickupCoordinatesLongitude: number,
        deliveryCoordinatesLatitude: number,
        deliveryCoordinatesLongitude: number,
        pickupAddress: string,
        deliveryAddress: string): void => {
        let pickupCoordinates = null as GpsCoordinates;
        let deliveryCoordinates = null as GpsCoordinates;

        if (pickupCoordinatesLatitude && pickupCoordinatesLongitude) {
            pickupCoordinates = { latitude: pickupCoordinatesLatitude, longitude: pickupCoordinatesLongitude };
            setPickupCoordinates(pickupCoordinates);
        } else if (pickupAddress) {
            MapsService.ResolveAddress(pickupAddress)
                .then((position: GpsCoordinates) => {
                    if (position === null)
                        setFailedPickupAddress(true);

                    pickupCoordinates = position ? { latitude: position.latitude, longitude: position.longitude } : null;
                    setPickupCoordinates(pickupCoordinates);
                });
        }

        if (deliveryCoordinatesLatitude && deliveryCoordinatesLongitude) {
            deliveryCoordinates = { latitude: deliveryCoordinatesLatitude, longitude: deliveryCoordinatesLongitude };
            setDeliveryCoordinates(deliveryCoordinates);
        } else if (deliveryAddress) {
            MapsService.ResolveAddress(deliveryAddress)
                .then((position: GpsCoordinates) => {
                    if (position === null)
                        setFailedDeliveryAddress(true);

                    deliveryCoordinates = position ? { latitude: position?.latitude, longitude: position?.longitude } : null;
                    setDeliveryCoordinates(deliveryCoordinates);
                });
        }

        if (pickupCoordinates === null && deliveryCoordinates === null) {
            setMap(null, null);
        }
    }

    const setMap = (pickupCoordinates: GpsCoordinates, deliveryCoordinates: GpsCoordinates): void => {
        const map = createMap(4);
        map.events.add('ready', function () {
            map.resize();

            const dataSource = new source.DataSource(mapDataSourceId);
            map.sources.add(dataSource);

            if (pickupCoordinates)
                addPin(pickupCoordinates, pickupPinId, dataSource);

            if (deliveryCoordinates)
                addPin(deliveryCoordinates, deliveryPinId, dataSource);

            const symbolLayer: layer.SymbolLayer = createSymbolLayer(dataSource);
            map.layers.add(symbolLayer);
            addLineLayer(map, dataSource);

            let selectedShape: Shape = null;
            map.events.add('mouseover', symbolLayer, () => map.getCanvasContainer().style.cursor = 'pointer');
            map.events.add('mouseout', symbolLayer, () => map.getCanvasContainer().style.cursor = 'default');
            map.events.add('mousedown', symbolLayer, (e: MapMouseEvent) => {
                if (e.shapes && e.shapes.length > 0) {
                    selectedShape = e.shapes[0] as Shape;
                    map.setUserInteraction({
                        dragPanInteraction: false
                    });
                }
            });
            map.events.add('mousemove', (e: MapMouseEvent) => {
                if (selectedShape) {
                    selectedShape.setCoordinates(e.position);
                }
            });
            map.events.add('mouseup', () => {
                if (selectedShape) {
                    const pickupShape: Shape = dataSource.getShapeById(pickupPinId);
                    const deliveryShape: Shape = dataSource.getShapeById(deliveryPinId);
                    const sourcesPickupCoordinates = pickupShape?.getCoordinates() as data.Position;
                    const sourcesDeliveryCoordinates = deliveryShape?.getCoordinates() as data.Position;
                    let pickupCoord = {} as GpsCoordinates;
                    let deliveryCoord = {} as GpsCoordinates;

                    //update coordinates
                    if (sourcesPickupCoordinates) {
                        pickupCoord = { latitude: sourcesPickupCoordinates[1], longitude: sourcesPickupCoordinates[0] };
                        if (selectedShape.getId() === pickupPinId) {
                            MapsService.GetAddressFromCoordinates(pickupCoord)
                                .then((res: Address) => {
                                    setPickupAddress({ freeFormAddress: res.freeformAddress, line1: res.line1, line2: null, zipCode: res.zipCode, city: res.city });
                                    if (failedPickupAddress)
                                        setFailedPickupAddress(false);
                                    setPickupCoordinates(pickupCoord);
                                });
                        }
                    }
                    if (sourcesDeliveryCoordinates) {
                        deliveryCoord = { latitude: sourcesDeliveryCoordinates[1], longitude: sourcesDeliveryCoordinates[0] };
                        if (selectedShape.getId() === deliveryPinId) {
                            MapsService.GetAddressFromCoordinates(deliveryCoord)
                                .then((res: Address) => {
                                    setDeliveryAddress({ freeFormAddress: res.freeformAddress, line1: res.line1, line2: null, zipCode: res.zipCode, city: res.city });
                                    if (failedDeliveryAddress)
                                        setFailedDeliveryAddress(false);
                                    setDeliveryCoordinates(deliveryCoord);
                                });
                        }
                    }

                    const existingRoute: Shape = dataSource.getShapeById(routeId);
                    if (sourcesPickupCoordinates && sourcesDeliveryCoordinates && existingRoute) {
                        //update route
                        getRouteAndEstimation(pickupCoord, deliveryCoord, dataSource, existingRoute, map);
                    }
                }

                selectedShape = null;
                map.setUserInteraction({
                    dragPanInteraction: true
                });
            });
            map.events.add('click', (e: MapMouseEvent) => {
                setClickedMapPosition({ latitude: e.position[1], longitude: e.position[0] });
            });

            const existingRoute: Shape = dataSource.getShapeById(routeId);
            if (pickupCoordinates && deliveryCoordinates)
                getRouteAndEstimation(pickupCoordinates, deliveryCoordinates, dataSource, existingRoute, map);
            else if (pickupCoordinates || deliveryCoordinates) {
                map.setCamera({
                    zoom: 10,
                    center: pickupCoordinates ? [pickupCoordinates.longitude, pickupCoordinates.latitude] : [deliveryCoordinates.longitude, deliveryCoordinates.latitude]
                });
            }
        });

        setMyMap(map);
    }

    const createMap = (zoom: number, center?: GpsCoordinates): Map => {
        return new Map('myMap', {
            zoom: zoom,
            center: center ? [center.longitude, center.latitude] : null,
            view: 'Auto',
            authOptions: {
                authType: AuthenticationType.subscriptionKey,
                subscriptionKey: SettingsProvider.Get().azureMapsApiKey
            },
        });
    }

    const addPin = (coordinates: GpsCoordinates, pinId: string, dataSource: source.DataSource): void => {
        const pin: data.Feature<data.Point, { title: string }> = new data.Feature(new data.Point([coordinates.longitude, coordinates.latitude]), { title: pinId === pickupPinId ? 'D' : 'A' }, pinId);
        dataSource.add(pin);
    }

    const createSymbolLayer = (dataSource: source.DataSource): layer.SymbolLayer => {
        return new layer.SymbolLayer(dataSource, "layer", {
            textOptions: {
                textField: ['get', 'title'],
                offset: [0, -1.2],
                color: 'white'
            },
            iconOptions: {
                ignorePlacement: true,
                allowOverlap: true
            },
            filter: ['any', ['==', ['geometry-type'], 'Point'], ['==', ['geometry-type'], 'MultiPoint']]
        });
    }

    const addLineLayer = (map: Map, dataSource: source.DataSource): void => {
        map.layers.add(new layer.LineLayer(dataSource, null, {
            strokeColor: '#2272B9',
            strokeWidth: 5,
            lineJoin: 'round',
            lineCap: 'round'
        }), 'labels');
    }

    const getCoordinatesFromLegs = (legs: Array<Leg>): number[][] => {
        let routeCoordinates: number[][] = [];
        for (let legIndex = 0; legIndex < legs.length; legIndex++) {
            const leg: Leg = legs[legIndex];
            const legCoordinates: number[][] = leg.points.map((point: GpsCoordinates) => {
                return [point.longitude, point.latitude];
            });
            routeCoordinates = routeCoordinates.concat(legCoordinates);
        }
        return routeCoordinates;
    }

    const getRouteAndEstimation = (pickupCoordinates: GpsCoordinates, deliveryCoordinates: GpsCoordinates, dataSource: source.DataSource, existingRoute: Shape, map: Map): void => {
        if ((arrivalTimeAtSenderSiteIsFixed === true && arrivalTimeAtSenderSite === null) || (arrivalTimeAtSenderSiteIsFixed === false && arrivalTimeAtReceiverSite === null)) {
            if (existingRoute) {
                dataSource.remove(existingRoute);
                setEstimateDistance(null);
                setEstimateDuration(null);
            }
        } else {
            MapsService.GetTripEstimation(pickupCoordinates, deliveryCoordinates, arrivalTimeAtSenderSiteIsFixed, arrivalTimeAtSenderSite, arrivalTimeAtReceiverSite)
                .then((res: TripEstimation) => {
                    if (res) {
                        const routeCoordinates: number[][] = getCoordinatesFromLegs(res.legs);
                        if (!existingRoute) {
                            const routeLine: Shape = new Shape(new data.LineString(routeCoordinates), routeId);
                            dataSource.add(routeLine);
                        } else {
                            existingRoute.setCoordinates(routeCoordinates);
                        }

                        setEstimateDistance(res.distanceInMeters);
                        setEstimateDuration(res.durationInSeconds);

                        //NOTE HGA Actuellement les eventHandlers basés sur les maps n'accède pas au state du fonctional composant. 
                        //"initialiseForm" est contournement qui indique si on est entrains d'initialiser le formulaire ou pas.
                        const initialiseForm = document.getElementById("initialiseForm") as HTMLInputElement;
                        if (initialiseForm && initialiseForm.value === 'true') {
                            initialiseForm.value = 'false';
                        } else {
                            //ODA Actuellement les eventHandlers basés sur les maps n'accède pas au state du fonctional composant. 
                            //Le contournement trouvé est de créer un champ caché qu'on synchronise à chaque modif du tripDuration
                            const tripDurationById = document.getElementById("tripDurationId") as HTMLInputElement;

                            if (tripDurationById) {
                                const tripDurationValue = Math.round(res.durationInSeconds / 60);
                                tripDurationById.value = tripDurationValue + '';
                                setTripDuration(tripDurationValue);
                                clearFieldNumberOfVehicles();
                            }
                        }

                        //zoom at route
                        map.setCamera({
                            bounds: data.BoundingBox.fromPositions([[pickupCoordinates.longitude, pickupCoordinates.latitude], [deliveryCoordinates.longitude, deliveryCoordinates.latitude]]),
                            padding: 50
                        });
                    } else {
                        if (existingRoute) {
                            dataSource.remove(existingRoute);
                            setEstimateDistance(null);
                            setEstimateDuration(null);
                        }
                    }
                });
        }
    }

    const handlePriceKindSelectChange = (e: SelectInputItemModel): void => {
        if (!e) {
            setSellPriceKindData(null);
            setQuotationRequest({ ...quotationRequest, priceGridKind: "" as PriceGridKindEnum });

            setBilledSellPrice({
                billedPriceValue: 0,
                billedUnitPriceValue: null,
                billedUnitPriceIsLocked: false
            });

            return;
        }

        setSellPriceKindData({ label: e.label, value: e.value as PriceGridKindEnum });
        setQuotationRequest({ ...quotationRequest, priceGridKind: e.value as PriceGridKindEnum });

        setBilledSellPrice({
            billedPriceValue: 0,
            billedUnitPriceValue: (!isJobSiteVehicleServiceKind && e.value !== PriceGridKindEnum.PerTimePeriod && e.value !== "") ? 0 : null,
            billedUnitPriceIsLocked: false
        });
    }

    const handleNegotiatedPurchasePriceKindSelectChange = (e: SelectInputItemModel): void => {
        if (!e) {
            setNegotiatedPurchasePriceValue(null);
            setNegotiatedPurchasePriceKindData(null);
            return;
        }

        const values = e.value?.split(":") ?? [];

        if (values.length > 1) {
            const selectedItem = FixedLists.negotiatedPurchasePriceKindsList.find(x => x.value === values[0]);
            setNegotiatedPurchasePriceKindData({ label: selectedItem?.label, value: selectedItem?.value });
            setNegotiatedPurchasePriceValue(Number(values[1]));
        }
        else {
            setNegotiatedPurchasePriceValue(null);
            setNegotiatedPurchasePriceKindData({ label: e.label, value: e.value as PurchasePriceKindEnum });
        }
    }

    const getPriceKindDataValue = (value: string): SelectInputItemModel => {
        switch (value) {
            case "PerTimePeriod":
                return { label: "Forfait", value: PriceGridKindEnum.PerTimePeriod };

            case "PerTon":
                return { label: "Tonne", value: PriceGridKindEnum.PerTon };

            case "PerTurn":
                return { label: "Tour", value: PriceGridKindEnum.PerTurn };

            default:
                return null;
        }
    }

    const getPurchasePriceKindDataValue = (value: string): SelectInputItemModel => {
        switch (value) {
            case "PerTon":
                return { label: "Tonne", value: PurchasePriceKindEnum.PerTon };

            case "PerTurn":
                return { label: "Tour", value: PurchasePriceKindEnum.PerTurn };

            case "PerHour":
                return { label: "Heure", value: PurchasePriceKindEnum.PerHour };

            case "Day":
                return { label: "Journée", value: PurchasePriceKindEnum.Day };

            case "HalfDay":
                return { label: "Demi-journée", value: PurchasePriceKindEnum.HalfDay };

            case "Night":
                return { label: "Nuit", value: PurchasePriceKindEnum.Night };

            default:
                return null;
        }
    }

    const refreshZephyrJobCode = (): void => {
        setLoadingContent(true);

        const isAutomaticPayer: boolean = payerSite?.value === "Automatic" || !payerSite;
        const payerEntity: string = isAutomaticPayer ? quotationResult.payerEntity?.toString() : SiteEntityTypeEnum.ProductionSite;
        const payerIdentifier: string = isAutomaticPayer ? quotationResult.payerIdentifier : payerSite?.value;
        const payerOrigin: string = isAutomaticPayer ? quotationResult.payerOrigin?.toString() : null;
        const payerOverriden = !isAutomaticPayer;

        GetJobCodeZephyr(payerEntity, payerIdentifier, payerOrigin, payerOverriden, true)
            .then((res) => {
                if (!res.data) {
                    ToastService.showErrorToast("Chantier non connu du référentiel chantier Colas.");
                }
                setPayerZephyrJobCode(res.data ? res.data : null);
                setLoadingContent(false);
            });
    }

    const GetJobCodeZephyr = (payerEntity: string, payerIdentifier: string, payerOrigin: string, payerOverriden: boolean, refreshCache: boolean): Promise<AxiosResponse<string>> => {

        const requestArgs = {} as TransportFlowPayerZephyrJobCodeRequestArgs;
        requestArgs.logisticsUnitId = logisticsUnit.selectedLogisticsUnit?.value;
        requestArgs.payerEntity = payerEntity;

        if (payerOverriden) {

            requestArgs.jobSiteMdmId = payerSite?.payerType == SiteEntityTypeEnum.Agency ? payerOverridenJobSiteIdmdm : null;
            requestArgs.workAgencyId = payerSite?.payerType == SiteEntityTypeEnum.Agency ? payerIdentifier : null;
            requestArgs.productionSiteId = payerSite?.payerType == SiteEntityTypeEnum.ProductionSite ? payerIdentifier : null;
            requestArgs.level2ActivityId = payerSite?.payerType == SiteEntityTypeEnum.ProductionSite ? payerLevel2Activity?.value : null;
            requestArgs.addressLine1 = null;
            requestArgs.addressZipCode = null;
            requestArgs.addressCity = null;
            requestArgs.latitude = null;
            requestArgs.longitude = null;

        } else {

            switch (payerOrigin) {

                case PayerSiteOriginEnum.Sender:
                    requestArgs.jobSiteMdmId = senderSite.jobSiteIdmdm?.trim() ? senderSite.jobSiteIdmdm?.trim() : null;
                    requestArgs.workAgencyId = !senderSite.selectedSiteAgency ? null : senderSite.selectedSiteAgency.value;
                    requestArgs.productionSiteId = !senderSite.productionSite ? null : senderSite.productionSite.value;
                    requestArgs.level2ActivityId = senderSite.productionSiteActivity?.value ? senderSite.productionSiteActivity.value : null;
                    requestArgs.addressLine1 = pickupAddress.line1?.trim() ? pickupAddress.line1?.trim() : null;
                    requestArgs.addressZipCode = pickupAddress.zipCode?.trim() ? pickupAddress.zipCode?.trim() : null;
                    requestArgs.addressCity = pickupAddress.city?.trim() ? pickupAddress.city?.trim() : null;
                    requestArgs.latitude = pickupCoordinates?.latitude;
                    requestArgs.longitude = pickupCoordinates?.longitude;
                    break;

                case PayerSiteOriginEnum.Receiver:
                    requestArgs.jobSiteMdmId = receiverSite.jobSiteIdmdm?.trim() ? receiverSite.jobSiteIdmdm?.trim() : null;
                    requestArgs.workAgencyId = !receiverSite.selectedSiteAgency ? null : receiverSite.selectedSiteAgency.value;
                    requestArgs.productionSiteId = !receiverSite.productionSite ? null : receiverSite.productionSite.value;
                    requestArgs.level2ActivityId = receiverSite.productionSiteActivity?.value ? receiverSite.productionSiteActivity.value : null;
                    requestArgs.addressLine1 = deliveryAddress.line1?.trim() ? deliveryAddress.line1?.trim() : null;
                    requestArgs.addressZipCode = deliveryAddress.zipCode?.trim() ? deliveryAddress.zipCode?.trim() : null;
                    requestArgs.addressCity = deliveryAddress.city?.trim() ? deliveryAddress.city?.trim() : null;
                    requestArgs.latitude = deliveryCoordinates?.latitude;
                    requestArgs.longitude = deliveryCoordinates?.longitude;
                    break;

                default:
            }
        }

        return TransportFlowApiClient.GetJobCodeZephyr(requestArgs, refreshCache);
    }

    const checkBeneficiaryChanged = (): boolean => {
        if (beneficiary.contactType?.value === internal && transportFlowData.beneficiaryIsExternal) { return true }
        if (beneficiary.contactType?.value === externalReferenced && !transportFlowData.beneficiaryContactId) { return true }
        if (beneficiary.contactType?.value === externalNotReferenced && transportFlowData.beneficiaryContactId) { return true }
        if ((!beneficiary.contact || beneficiary.contact?.label?.trim() === "" ? null : beneficiary.contact.label) !== (transportFlowData.beneficiaryName ? transportFlowData.beneficiaryName : null)) { return true }
        if (beneficiary.contactPhoneNumber !== transportFlowData.beneficiaryPhoneNumber) { return true }
        return false;
    }

    const checkRequesterChanged = (): boolean => {
        if (requestedBy.contactType?.value === internal && transportFlowData.requestedByIsExternal) { return true }
        if (requestedBy.contactType?.value === externalReferenced && !transportFlowData.requestedByContactId) { return true }
        if (requestedBy.contactType?.value === externalNotReferenced && transportFlowData.requestedByContactId) { return true }
        if ((!requestedBy.contact || requestedBy.contact?.label?.trim() === "" ? null : requestedBy.contact.label) !== (transportFlowData.requestedByName ? transportFlowData.requestedByName : null)) { return true }
        return false;
    }

    const checkSenderSiteChanged = (): boolean => {
        if (senderSite.siteKind?.value === productionSite &&
            (senderSite.productionSite?.value !== transportFlowData.senderSiteProductionSiteId ||
                (senderSite.productionSiteActivity?.value ? senderSite.productionSiteActivity.value : null) !== transportFlowData.senderSiteLevel2ActivityId)) { return true }
        if (senderSite.siteKind?.value === jobSite &&
            (senderSite.jobSiteIdmdm !== transportFlowData.senderSiteJobSiteMdmId || senderSite.jobSiteLabel !== transportFlowData.senderSiteJobSiteLabel || senderSite.selectedSiteAgency?.value !== transportFlowData.senderSiteAgencyId)) { return true }
        if (senderSite.siteKind?.value === referencedCustomer && senderSite.referencedSiteCustomer?.value !== transportFlowData.senderSiteCustomerMdmId) { return true }
        if (senderSite.siteKind?.value === unreferencedCustomer &&
            (senderSite.unReferencedSiteCustomer !== transportFlowData.senderSiteUnreferencedCustomer || senderSite.unReferencedSiteCustomerSite !== transportFlowData.senderSiteUnreferencedCustomerSite)) { return true }
        return false;
    }

    const checkReceiverSiteChanged = (): boolean => {
        if (receiverSite.siteKind?.value === productionSite &&
            (receiverSite.productionSite?.value !== transportFlowData.receiverSiteProductionSiteId ||
                (receiverSite.productionSiteActivity?.value ? receiverSite.productionSiteActivity.value : null) !== transportFlowData.receiverSiteLevel2ActivityId)) { return true }
        if (receiverSite.siteKind?.value === jobSite &&
            (receiverSite.jobSiteIdmdm !== transportFlowData.receiverSiteJobSiteMdmId || receiverSite.jobSiteLabel !== transportFlowData.receiverSiteJobSiteLabel || receiverSite.selectedSiteAgency?.value !== transportFlowData.receiverSiteAgencyId)) { return true }
        if (receiverSite.siteKind?.value === referencedCustomer && receiverSite.referencedSiteCustomer?.value !== transportFlowData.receiverSiteCustomerMdmId) { return true }
        if (receiverSite.siteKind?.value === unreferencedCustomer &&
            (receiverSite.unReferencedSiteCustomer !== transportFlowData.receiverSiteUnreferencedCustomer || receiverSite.unReferencedSiteCustomerSite !== transportFlowData.receiverSiteUnreferencedCustomerSite)) { return true }
        return false;
    }

    const checkJobSiteUnloadingDurtionChanged = (unloadingDuration: string): boolean => {
        const time: string[] = unloadingDuration.split(':');
        const h = Number(time[0]);
        const m = Number(time[1]);
        if (jobSiteVehicleUnloadingDuration.getHours() === h && jobSiteVehicleUnloadingDuration.getMinutes() === m) { return false }
        return true;
    }

    const setFlowTripsData = (trips: Array<TransportFlowTripsLightModel>): void => {
        const planningVehicleCount: number = size(groupBy(trips, x => x.planningVehicleId));

        setTripsData({
            ...tripsData,
            tripsCount: trips.length,
            planningVehicleCount: planningVehicleCount,
            tripsNeededCount: trips.filter(x => x.tripStatus === "TransportNeeded").length,
            tripsPlannedCount: trips.filter(x => x.tripStatus === "TransportPlanned").length,
            tripsConfirmedCount: trips.filter(x => x.tripStatus === "TransportConfirmed").length,
            tripsInProgressCount: trips.filter(x => x.tripStatus === "InProgress").length,
            tripsFinishedCount: trips.filter(x => x.tripStatus === "Finished").length,
            tripsCanceledCount: trips.filter(x => x.tripStatus === "Canceled").length
        });
    }

    const getTransportServiceKindValue = (value: string) => {
        switch (value) {
            case perishable:
            case notPerishable:
                return TransportServiceKindEnum.delivery;

            case removal:
                return TransportServiceKindEnum.removal;

            case jobsiteVehicle:
                return TransportServiceKindEnum.jobsiteVehicle;

            default:
                return null;
        }
    }

    const handlerAfterUploadAttachment = (attachments: AddedAttachmentResult[]): void => {
        const attachmentFilesArray = [...attachmentFiles];
        const toAddAttachments: AttachmentArgs[] = attachments.map(x => {
            return {
                attachmentId: x.attachmentId,
                name: x.name,
                status: AttachmentStatus.new
            }
        });

        Array.prototype.push.apply(attachmentFilesArray, toAddAttachments);
        setAttachmentFiles(attachmentFilesArray);
        setIsLoadingAttachment(false);
    }

    const handlerDisplayErrorsAfterUpload = (commandResults: CommandResultLight[]): void => {
        const errorAddedFileNumber: number = commandResults.length;
        const errorText: string = errorAddedFileNumber > 1 ? errorAddedFileNumber + " fichier(s) n'ont pas été ajouté" : " fichier n'a pas été ajouté";
        const lstErrorToastMessages: string[] = [];

        commandResults.forEach((command: CommandResultLight) => {
            command.businessErrors.forEach((businessError: BusinessErrorLight) => {
                const error = BusinessErrors.GetError({ type: businessError.type, name: businessError.name });
                if (!lstErrorToastMessages.includes(error))
                    lstErrorToastMessages.push(error);
            });
        });

        ToastService.showErrorToast(errorText, lstErrorToastMessages);
    }

    const handleDeleteAttachment = (attachmentId: string, state: AttachmentStatus): void => {
        setIsLoadingAttachment(true);
        if (state === AttachmentStatus.new) {
            deleteAttachment(attachmentId);
        } else {
            const attachmentFilesArray = [...attachmentFiles];
            const attachment = attachmentFilesArray.find(x => x.attachmentId === attachmentId);
            attachment.status = AttachmentStatus.deleted;
            setAttachmentFiles(attachmentFilesArray);
            setIsLoadingAttachment(false);
        }
    }

    const deleteAttachment = (attachmentId: string): void => {
        const requestArgs: DeleteAttachmentArgs = { attachmentIds: [attachmentId] };
        TransportFlowApiClient.DeleteAttachments(requestArgs)
            .then((res) => {
                const data = res?.data;
                const errors = BusinessErrors.Get(data);
                if (errors.length > 0) {
                    ToastService.showErrorToast("", errors);
                    setIsLoadingAttachment(false);
                    return;
                }

                const attachments = attachmentFiles.filter(x => x.attachmentId !== attachmentId);
                setAttachmentFiles(attachments);
                setIsLoadingAttachment(false);
            });
    }

    const handleAddAttachments = (e: UploadOnAddEvent): void => {
        e.affectedFiles.forEach((file: UploadFileInfo) => {
            if (file.size > 10000000) {
                ToastService.showErrorToast("Les fichiers déposés ne doivent pas dépasser la taille de 10 Mo");
                return;
            }
        });
    }

    const handlerBeforeUploadAttachment = (e: UploadOnBeforeUploadEvent): void => {
        setIsLoadingAttachment(true);
    }

    const cancel = (): void => {
        deleteNewAttachmentFiles(attachmentFiles);
        props.onClose();
    }

    const deleteNewAttachmentFiles = (attachmentFiles: AttachmentArgs[]): void => {
        if (attachmentFiles.length > 0) {
            const attachmentIds = attachmentFiles.filter(x => x.status === AttachmentStatus.new).map(x => x.attachmentId);
            if (attachmentIds.length > 0) {
                const requestArgs: DeleteAttachmentArgs = { attachmentIds: attachmentIds };
                TransportFlowApiClient.DeleteAttachments(requestArgs);
            }
        }
    }

    const commonValidationFields =
        serviceKind.selectedServiceKind &&
        !(!level3Segmentation.level3Seg && !isJobSiteVehicleServiceKind) &&
        !(!isJobSiteVehicleServiceKind && quantity === null) &&
        vehicleType.selectedVehicleType &&
        receiverSite.siteKind &&
        checkReceiverSite() &&
        deliveryAddress.freeFormAddress?.trim() &&
        deliveryAddress.zipCode?.trim() &&
        deliveryAddress.city?.trim() &&
        checkSenderSite() &&
        checkPickupAdress() &&
        !(!isJobSiteVehicleServiceKind && !payerSite) &&
        !(!isJobSiteVehicleServiceKind && payerSite?.value !== "Automatic" &&
            ((payerSite?.payerType === SiteEntityTypeEnum.ProductionSite && !payerLevel2Activity)
                || (payerSite?.payerType === SiteEntityTypeEnum.Agency && (!payerOverridenJobSiteIdmdm?.trim() || !payerOverridenBeneficiary)))) &&
        !(!isJobSiteVehicleServiceKind && payerSite?.value !== "Automatic" && !transportBillingMode) &&
        !(!isJobSiteVehicleServiceKind && unloadingDuration === null);

    const validForm: boolean =
        commonValidationFields &&
        priority !== null &&
        startDateValid &&
        endDateValid &&
        logisticsUnit.selectedLogisticsUnit?.value &&
        !(coatingApplicationMode === null && !isJobSiteVehicleServiceKind) &&
        !(!isJobSiteVehicleServiceKind && arrivalTimeAtReceiverSite === null) &&
        arrivalTimeAtSenderSite !== null &&
        quotationResult?.numberOfVehicles &&
        beneficiary.contactType &&
        checkBeneficiaryValidation() &&
        checkRequiredQuotationApi() &&
        !(tripDuration === null && !isJobSiteVehicleServiceKind) &&
        !beneficiary.invalidPhoneNumber &&
        !(isJobSiteVehicleServiceKind && jobSiteVehicleUnloadingDuration === null) &&
        billedSellPrice.billedPriceValue !== null && billedSellPrice.billedPriceValue !== 0 &&
        sellPriceKindData &&
        checkArrivalEndTimeAtReceiverSite() &&
        checkNegotiatedPurchasePrice();

    const isCallCotationEnabled: boolean =
        commonValidationFields &&
        !(!isJobSiteVehicleServiceKind && tripDuration === null) &&
        (arrivalTimeAtSenderSiteIsFixed === true ? arrivalTimeAtSenderSite !== null : arrivalTimeAtReceiverSite !== null) &&
        (isJobSiteVehicleServiceKind ? jobSiteVehicleUnloadingDuration !== null : true) &&
        !failedDeliveryAddress &&
        !failedPickupAddress &&
        (isJobSiteVehicleServiceKind ? (quotationResult && quotationResult.numberOfVehicles !== null) : true);

    const priceUnavailabilityReasonMessage: string = quotationResult ? priceUnavailabilityReasons.get(`priceUnavailabilityReason.${quotationResult.priceUnavailabilityReason}`) : null;
    const priceUnavailabilityReason: string = priceUnavailabilityReasonMessage ? priceUnavailabilityReasonMessage : (quotationResult ? quotationResult.priceUnavailabilityReason : null);

    const enableReset: boolean = (props.mode === updateMode && transportFlowData !== null && !loadingPopup) ? (
        (transportFlowData.coatingApplicationMode ? CoatingApplicationModeEnum[transportFlowData.coatingApplicationMode] !== coatingApplicationMode : false) ||
        transportFlowData.productLevel3SegmentationId !== (!level3Segmentation.level3Seg ? null : level3Segmentation.level3Seg.value) ||
        transportFlowData.productCode !== (productLabel === "" ? null : productLabel) ||
        transportFlowData.requestedQuantity !== quantity ||
        transportFlowData.isNightWork !== isNightWork ||
        checkSenderSiteChanged() ||
        transportFlowData.pickupAddressCity !== pickupAddress.city ||
        transportFlowData.pickupAddressZipCode !== pickupAddress.zipCode ||
        transportFlowData.pickupAddressLine1 !== pickupAddress.line1 ||
        transportFlowData.pickupFreeFormAddress !== pickupAddress.freeFormAddress ||
        checkReceiverSiteChanged() ||
        transportFlowData.deliveryAddressCity !== deliveryAddress.city ||
        transportFlowData.deliveryAddressZipCode !== deliveryAddress.zipCode ||
        transportFlowData.deliveryAddressLine1 !== deliveryAddress.line1 ||
        transportFlowData.deliveryFreeFormAddress !== deliveryAddress.freeFormAddress ||
        checkRequesterChanged() ||
        checkBeneficiaryChanged() ||
        transportFlowData.transportersInstructions !== transportersInstructions ||
        !Date.equals(transportFlowData.arrivalTimeAtReceiverSite, arrivalTimeAtReceiverSite) ||
        toMinutes(transportFlowData.arrivalSlotDuration) !== arrivalSlotDuration ||
        !Date.equals(transportFlowData.arrivalTimeAtSenderSite, arrivalTimeAtSenderSite) ||
        transportFlowData.arrivalTimeAtSenderSiteIsFixed !== arrivalTimeAtSenderSiteIsFixed ||
        transportFlowData.numberOfVehicles !== quotationResult?.numberOfVehicles ||
        toMinutes(transportFlowData.estimatedTripDuration) !== tripDuration ||
        (isJobSiteVehicleServiceKind ? checkJobSiteUnloadingDurtionChanged(transportFlowData.unloadingDuration) : (toMinutes(transportFlowData.unloadingDuration) !== unloadingDuration)) ||
        transportFlowData.isUnloadingContinuityRequired !== isUnloadingContinuityRequired ||
        quotationRequest.priceGridKind !== ModelMappings.mapQuotationPriceGridKind(quotationResult?.priceGridKind) ||
        transportFlowData.billedTransportPrice !== billedSellPrice?.billedPriceValue ||
        (transportFlowData.payerOverriden ? (transportFlowData.payerIdentifier !== payerSite?.value) : false) ||
        (transportFlowData.payerOverriden ? (transportFlowData.payerLevel2ActivityId !== payerLevel2Activity?.value) : false) ||
        (transportFlowData.payerOverriden ? (transportFlowData.transportBillingMode !== transportBillingMode?.value) : false) ||
        transportFlowData.zephyrOrderNumber !== zephyrOrderNumber ||
        !Date.equals(transportFlowData.deliveryEndDate, dateConfig.endDateValue) ||
        !isEqual(transportFlowData.attachments.map(x => x.attachmentId), attachmentFiles.map(x => x.attachmentId)) ||
        transportFlowData.negotiatedPurchasePriceKind !== (negotiatedPurchasePriceKindData?.value ?? null) ||
        transportFlowData.negotiatedPurchaseUnitPrice !== negotiatedPurchasePriceValue
    ) : false;

    const quotationRequestChanged: boolean = (quotationRequest.coatingApplicationMode !== coatingApplicationMode ||
        quotationRequest.level3Segmentation !== (!level3Segmentation.level3Seg ? null : level3Segmentation.level3Seg.value) ||
        quotationRequest.quantity !== quantity ||
        quotationRequest.tripDuration !== tripDuration ||
        (!isJobSiteVehicleServiceKind && quotationCall.arrivalTimeAtSenderSiteIsFixed === false && !Date.equals(quotationRequest.arrivalTimeAtReceiverSite, arrivalTimeAtReceiverSite)) ||
        (!isJobSiteVehicleServiceKind && quotationCall.arrivalTimeAtSenderSiteIsFixed === true && !Date.equals(quotationRequest.arrivalTimeAtSenderSite, arrivalTimeAtSenderSite)) ||
        (isJobSiteVehicleServiceKind && !Date.equals(quotationRequest.arrivalTimeAtReceiverSite, arrivalTimeAtReceiverSite)) ||
        quotationRequest.numberOfVehicles !== quotationResult?.numberOfVehicles ||
        quotationRequest.isUnloadingContinuityRequired !== isUnloadingContinuityRequired ||
        (isJobSiteVehicleServiceKind ? !Date.equals(quotationRequest.unloadingDuration, jobSiteVehicleUnloadingDuration) : quotationRequest.unloadingDuration !== unloadingDuration) ||
        quotationRequest.senderSite.agencyId !== (!senderSite.selectedSiteAgency ? null : senderSite.selectedSiteAgency.value) ||
        quotationRequest.senderSite.customerMdmId !== (!senderSite.referencedSiteCustomer ? null : senderSite.referencedSiteCustomer.value) ||
        quotationRequest.senderSite.jobSiteMdmId !== senderSite.jobSiteIdmdm ||
        quotationRequest.senderSite.productionSiteId !== (!senderSite.productionSite ? null : senderSite.productionSite.value) ||
        quotationRequest.senderSite.unreferencedCustomer !== senderSite.unReferencedSiteCustomer ||
        quotationRequest.senderSite.productionSiteActivity !== (senderSite.productionSiteActivity?.value ? senderSite.productionSiteActivity.value : null) ||
        quotationRequest.pickupAddress.line1 !== pickupAddress.line1 ||
        quotationRequest.pickupAddress.zipCode !== pickupAddress.zipCode ||
        quotationRequest.pickupAddress.city !== pickupAddress.city ||
        quotationRequest.receiverSite.agencyId !== (!receiverSite.selectedSiteAgency ? null : receiverSite.selectedSiteAgency.value) ||
        quotationRequest.receiverSite.customerMdmId !== (!receiverSite.referencedSiteCustomer ? null : receiverSite.referencedSiteCustomer.value) ||
        quotationRequest.receiverSite.jobSiteMdmId !== receiverSite.jobSiteIdmdm ||
        quotationRequest.receiverSite.productionSiteId !== (!receiverSite.productionSite ? null : receiverSite.productionSite.value) ||
        quotationRequest.receiverSite.unreferencedCustomer !== receiverSite.unReferencedSiteCustomer ||
        quotationRequest.receiverSite.productionSiteActivity !== (receiverSite.productionSiteActivity?.value ? receiverSite.productionSiteActivity.value : null) ||
        quotationRequest.deliveryAddress.line1 !== deliveryAddress.line1 ||
        quotationRequest.deliveryAddress.zipCode !== deliveryAddress.zipCode ||
        quotationRequest.deliveryAddress.city !== deliveryAddress.city ||
        quotationRequest.payerSite !== (!payerSite || payerSite?.value === "Automatic" ? null : payerSite.value) ||
        quotationRequest.payerActivity !== (!payerLevel2Activity ? null : payerLevel2Activity.value) ||
        quotationRequest.payerJobSiteMdmId !== (payerOverridenJobSiteIdmdm === "" ? null : payerOverridenJobSiteIdmdm) ||
        quotationRequest.transportBillingMode !== (!transportBillingMode ? null : transportBillingMode.value) ||
        quotationRequest.priceGridKind !== ModelMappings.mapQuotationPriceGridKind(quotationResult?.priceGridKind));

    const disableCalculateButton: boolean = props.mode === updateMode ? !isCallCotationEnabled || !quotationRequestChanged : !isCallCotationEnabled;
    const disableValidButton: boolean = (!validForm || quotationRequestChanged || loadingContent);

    return (
        <form>
            <input id="arrivalTimesChangedAfterQuotationCall" hidden={true} />
            <input id="initialiseForm" hidden={true} />
            <input id="tripDurationId" hidden={true} />
            {loadingPopup &&
                <Box className="sweet-loading main-spinner">
                    <ScaleLoader
                        width={5}
                        height={20}
                        radius={50}
                        color={'#000000'}
                        loading={loadingPopup}
                    />
                </Box>
            }
            <Box className="transport-flow" display={loadingPopup ? "none" : "flex"} flexDirection="column">
                <Box display="flex" flexDirection="column" className="transport-flow-dialog-content">
                    <FlowDatesAndPrestationContent
                        logisticsUnit={logisticsUnit}
                        serviceKind={serviceKind}
                        sourceApplicationIsPlaneo={sourceApplicationIsPlaneo}
                        initiallyRequestedTransportationLineId={initiallyRequestedTransportationLineId}
                        mode={props.mode}
                        dateConfig={dateConfig}
                        priority={priority}
                        transportRequest={transportRequest}
                        isJobSiteVehicleServiceKind={isJobSiteVehicleServiceKind}
                        startDateValid={startDateValid}
                        endDateValid={endDateValid}
                        startDateRef={startDateRef}
                        endDateRef={endDateRef}
                        zephyrOrderNumber={zephyrOrderNumber}
                        handleLogisticsUnitChange={handleLogisticsUnitChange}
                        handleServiceKindChange={handleServiceKindChange}
                        handleCLickPriority={handleCLickPriority}
                        handleStartDateChange={handleStartDateChange}
                        handleEndDateChange={handleEndDateChange}
                        setZephyrOrderNumber={setZephyrOrderNumber}
                        handleTransportRequestChange={handleTransportRequestChange}
                    />
                    <JobSiteAndProductContent
                        coatingApplicationMode={coatingApplicationMode}
                        isJobSiteVehicleServiceKind={isJobSiteVehicleServiceKind}
                        level3Segmentation={level3Segmentation}
                        productLabel={productLabel}
                        quantity={quantity}
                        isNightWork={isNightWork}
                        setProductLabel={setProductLabel}
                        handleQuantityChanged={handleQuantityChanged}
                        setNightWork={setNightWork}
                        handleCoatingApplicationModeChanged={handleCoatingApplicationModeChanged}
                        handleLevel3SegmentationChanged={handleLevel3SegmentationChanged}
                    />
                    <AddressesContent
                        pickupCoordinates={pickupCoordinates}
                        isDeliveryMapMarkerActive={isDeliveryMapMarkerActive}
                        isJobSiteVehicleServiceKind={isJobSiteVehicleServiceKind}
                        senderSite={senderSite}
                        failedPickupAddress={failedPickupAddress}
                        pickupAddress={pickupAddress}
                        deliveryCoordinates={deliveryCoordinates}
                        isPickupMapMarkerActive={isPickupMapMarkerActive}
                        receiverSite={receiverSite}
                        deliveryAddress={deliveryAddress}
                        failedDeliveryAddress={failedDeliveryAddress}
                        estimateDuration={estimateDuration}
                        estimateDistance={estimateDistance}
                        payerSiteList={payerSiteList}
                        payerSite={payerSite}
                        logisticsUnitId={logisticsUnit.selectedLogisticsUnit?.value}
                        payerLevel2Activity={payerLevel2Activity}
                        payerLevel2ActivityList={payerLevel2ActivityList}
                        transportBillingMode={transportBillingMode}
                        mode={props.mode}
                        productionSitesList={productionSiteList}
                        agenciesList={agenciesList}
                        payerOverridenBeneficiary={payerOverridenBeneficiary}
                        payerOverridenBeneficiaryList={payerOverridenBeneficiaryList}
                        payerOverridenjobSiteIdmdm={payerOverridenJobSiteIdmdm}
                        handlePickupAddressChanged={handlePickupAddressChanged}
                        handleDeliveryAddressChanged={handleDeliveryAddressChanged}
                        setIsPickupMapMarkerActive={setIsPickupMapMarkerActive}
                        setIsDeliveryMapMarkerActive={setIsDeliveryMapMarkerActive}
                        setPayerSiteList={setPayerSiteList}
                        setPickupPin={setPickupPin}
                        setDeliveryPin={setDeliveryPin}
                        setFailedPickupAddress={setFailedPickupAddress}
                        setFailedDeliveryAddress={setFailedDeliveryAddress}
                        handleSenderSiteKindChanged={handleSenderSiteKindChanged}
                        handleUnReferencedSenderSiteCustomerChange={handleUnReferencedSenderSiteCustomerChange}
                        handleUnReferencedSenderSiteCustomerSiteChange={handleUnReferencedSenderSiteCustomerSiteChange}
                        handleSenderSiteAgencyChanged={handleSenderSiteAgencyChanged}
                        handleSenderJobSiteIdmdmChanged={handleSenderJobSiteIdmdmChanged}
                        handleSenderjobSiteLabelChanged={handleSenderjobSiteLabelChanged}
                        handleSenderProductionSiteChanged={handleSenderProductionSiteChanged}
                        handleSenderProductionSiteActivityChanged={handleSenderProductionSiteActivityChanged}
                        handleReferencedSenderSiteCustomerChanged={handleReferencedSenderSiteCustomerChanged}
                        handleReceiverSiteKindChanged={handleReceiverSiteKindChanged}
                        handleUnReferencedReceiverSiteCustomerChange={handleUnReferencedReceiverSiteCustomerChange}
                        handleUnReferencedReceiverSiteCustomerSiteChange={handleUnReferencedReceiverSiteCustomerSiteChange}
                        handleReceiverSiteAgencyChanged={handleReceiverSiteAgencyChanged}
                        handleReceiverJobSiteIdmdmChanged={handleReceiverJobSiteIdmdmChanged}
                        handleReceiverjobSiteLabelChanged={handleReceiverjobSiteLabelChanged}
                        handleReceiverProductionSiteChanged={handleReceiverProductionSiteChanged}
                        handleReceiverProductionSiteActivityChanged={handleReceiverProductionSiteActivityChanged}
                        handleReferencedReceiverSiteCustomerChanged={handleReferencedReceiverSiteCustomerChanged}
                        handlePayerSiteChanged={handlePayerSiteChanged}
                        handlePayerLevel2ActivityChanged={handlePayerLevel2ActivityChanged}
                        handlePayerOverridenBeneficiaryChanged={handleOverridenPayerBeneficiaryChanged}
                        handlePayerOverridenjobSiteIdmdmChanged={handleOverridenPayerjobSiteIdmdmChanged}
                        handleTransportBillingModeChanged={handleTransportBillingModeChanged}
                        reInitialisePayerContent={reInitialisePayerContent}
                        handleReverseSites={handleReverseSites}
                        handleVerifyJobMdmId={handleVerifyJobMdmId}
                    />
                    <Box display="flex" flexDirection="column" className="section">
                        <ContactsContent
                            requestedBy={requestedBy}
                            beneficiary={beneficiary}
                            handleRequestedByTypeChange={handleRequestedByTypeChange}
                            handleRequestedByContactChanged={handleRequestedByContactChanged}
                            handleRequestedByContactLabel={handleRequestedByContactLabel}
                            handleBeneficiairyTypeChange={handleBeneficiairyTypeChange}
                            handleBeneficiairyContactChanged={handleBeneficiairyContactChanged}
                            handleBeneficiairyContactLabel={handleBeneficiairyContactLabel}
                            handleChangeBeneficiairyPhoneNumber={handleChangeBeneficiairyPhoneNumber}
                            handleBlurBeneficiairyPhoneNumber={handleBlurBeneficiairyPhoneNumber}
                        />
                        <Box display="flex" flexDirection="row" width="100%" justifyContent="flex-start">
                            <Box width="70%">
                                <Box display="flex" flexDirection="column" mr="20px" width="100%" height="100%">
                                    <Box className="label">Instructions aux transporteurs</Box>
                                    <Box display="flex" flexDirection="row" width="100%" height="100%">
                                        <TextField
                                            multiline
                                            rows="4"
                                            fullWidth={true}
                                            placeholder="Commentaire libre"
                                            className="instructions-field"
                                            value={transportersInstructions === null ? "" : transportersInstructions}
                                            onChange={(e) => setTransportersInstructions(e.target.value)}
                                        />
                                    </Box>
                                </Box>
                            </Box>
                            <Box width="26%" ml="2%" mr="2%">
                                <AttachmentsComponent
                                    attachmentFiles={attachmentFiles}
                                    isLoadingAttachments={isLoadingAttachment}
                                    handleAdd={handleAddAttachments}
                                    handleDeleteAttachment={handleDeleteAttachment}
                                    handlerAfterUploadAttachment={handlerAfterUploadAttachment}
                                    handlerBeforeUpload={handlerBeforeUploadAttachment}
                                    handlerDisplayErrorsAfterUpload={handlerDisplayErrorsAfterUpload}
                                />
                            </Box>
                        </Box>
                    </Box>
                    {loadingContent &&
                        <Box className="sweet-loading main-spinner">
                            <ScaleLoader
                                width={5}
                                height={20}
                                radius={50}
                                color={'#000000'}
                                loading={loadingContent}
                            />
                        </Box>
                    }
                    <Box display="flex" flexDirection="column" className="section">
                        <VehicleAndPriceContent
                            priority={priority}
                            arrivalTimeAtSenderSite={arrivalTimeAtSenderSite}
                            arrivalTimeAtReceiverSite={arrivalTimeAtReceiverSite}
                            arrivalEndTimeAtReceiverSite={arrivalEndTimeAtReceiverSite}
                            startDateValid={startDateValid}
                            quotationResult={quotationResult}
                            isJobSiteVehicleServiceKind={isJobSiteVehicleServiceKind}
                            vehicleType={vehicleType}
                            mode={props.mode}
                            unloadingDuration={unloadingDuration}
                            tripDuration={tripDuration}
                            jobSiteVehicleUnloadingDuration={jobSiteVehicleUnloadingDuration}
                            isUnloadingContinuityRequired={isUnloadingContinuityRequired}
                            sellPriceKindData={sellPriceKindData}
                            billedSellPrice={billedSellPrice}
                            dateConfig={dateConfig}
                            negotiatedPurchasePriceKindData={negotiatedPurchasePriceKindData}
                            quotationArrivalTimeAtSenderSiteIsFixed={quotationCall.arrivalTimeAtSenderSiteIsFixed}
                            isNegotiatedSellPrice={isNegotiatedSellPrice}
                            negotiatedPurchasePrice={negotiatedPurchasePriceValue}
                            negotiatedPurchasePrices={negotiatedPurchasePrices}
                            negotiatedPurchasePriceAppliesToExternalTransporters={negotiatedPurchasePriceAppliesToExternalTransporters}
                            negotiatedPurchasePriceAppliesToInternalTransporters={negotiatedPurchasePriceAppliesToInternalTransporters}
                            setArrivalTimeAtSenderSiteIsFixed={setArrivalTimeAtSenderSiteIsFixed}
                            setArrivalTimeAtSenderSite={setArrivalTimeAtSenderSite}
                            setArrivalTimeAtReceiverSite={setArrivalTimeAtReceiverSite}
                            setArrivalEndTimeAtReceiverSite={setArrivalEndTimeAtReceiverSite}
                            setArrivalSlotDuration={setArrivalSlotDuration}
                            handleUnloadingDurationChanged={handleUnloadingDurationChanged}
                            setJobSiteVehicleUnloadingDuration={setJobSiteVehicleUnloadingDuration}
                            handleIsUnloadingContinuityRequiredChanged={handleIsUnloadingContinuityRequiredChanged}
                            handleTripDurationChanged={handleTripDurationChanged}
                            handleNumberOfVehiclesChange={handleNumberOfVehiclesChange}
                            handleVehicleTypeChanged={handleVehicleTypeChanged}
                            handlePriceKindSelectChange={handlePriceKindSelectChange}
                            handleNegotiatedPurchasePriceKindSelectChange={handleNegotiatedPurchasePriceKindSelectChange}
                            handleUnitBilledPriceChange={handleUnitBilledPriceChange}
                            handleBilledPriceChange={handleBilledPriceChange}
                            handleNegotiatedUnitPurchasePriceChange={handleUnitPurchasePriceChange}
                            handleChangeBilledUnitPriceIsLocked={handleChangeBilledUnitPriceIsLocked}
                            handleNegotiatedPurchasePriceAppliesToExternalTransportersChange={handleNegotiatedPurchasePriceAppliesToExternalTransportersChange}
                            handleNegotiatedPurchasePriceAppliesToInternalTransportersChange={handleNegotiatedPurchasePriceAppliesToInternalTransportersChange}
                        />
                        <QuotationSettingsComponent quotationResult={quotationResult}
                            quotationCall={quotationCall}
                            priceUnavailabilityReason={priceUnavailabilityReason}
                            payerLabel={payerLabel}
                            isZephyrJobCodeDisabled={isZephyrJobCodeDisabled}
                            payerZephyrJobCode={payerZephyrJobCode}
                            isPayerAutomatic={payerSite?.value === "Automatic" || !payerSite}
                            handleQuotationDetailsExpandedChange={() => handleQuotationDetailsExpandedChange()}
                            refreshZephyrJobCode={refreshZephyrJobCode}
                        />
                    </Box>
                </Box>
                <Box display="flex" flexDirection="row" pb={"20px"} pt={"20px"} width="100%">
                    <Box width="50%">
                        {props.mode === updateMode &&
                            <Box display="flex" flexDirection="column" justifyContent="flex-start" pb={"20px"}>
                                <Box> {`${tripsData.tripsCount} Tour(s) associé(s) au Flux sur ${tripsData.planningVehicleCount} camion(s)`}</Box>
                                {tripsData.tripsNeededCount > 0 && <Box><span><b> - {tripsData.tripsNeededCount}  Générique(s) </b></span> {' => La planification peut être recalculée.'}</Box>}
                                {tripsData.tripsPlannedCount > 0 && <Box><span className="plannedStatus"><b> - {tripsData.tripsPlannedCount} Planifié(s) </b></span>{' => La planification peut être recalculée, les actions préalablement effectuées seront perdues.'}</Box>}
                                {tripsData.tripsConfirmedCount > 0 && <Box><span className="confirmedStatus"><b> - {tripsData.tripsConfirmedCount} Confirmé(s) </b></span>{' => La planification peut être recalculée, les actions préalablement effectuées seront perdues.'}</Box>}
                                {tripsData.tripsInProgressCount > 0 && <Box><span className="inProgressStatus"><b> - {tripsData.tripsInProgressCount} En cours </b></span>{' => La planification ne peut pas être recalculée automatiquement.'}</Box>}
                                {tripsData.tripsFinishedCount > 0 && <Box><span className="finishedStatus"><b> - {tripsData.tripsFinishedCount} Terminé(s) </b></span>{' => La planification ne peut pas être recalculée automatiquement.'}</Box>}
                                {tripsData.tripsCanceledCount > 0 && <Box><span className="canceledStatus"><b> - {tripsData.tripsCanceledCount} Annulé(s)</b></span>. </Box>}
                            </Box>
                        }
                    </Box>
                    <Box display="flex" flexDirection="column" justifyContent="flex-end" width="50%" alignItems="start">
                        {props.mode === updateMode &&
                            <Box display="flex" flexDirection="row" justifyContent="flex-end" width="100%">
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={recalculatePlanning}
                                            onChange={(e) => setRecalculatePlanning(e.target.checked)}
                                            color="primary"
                                            disabled={priority !== TransportPriorityEnum.high || tripsData.tripsInProgressCount > 0 || tripsData.tripsFinishedCount > 0}
                                        />
                                    }
                                    label="Recalculer la planification"
                                />
                            </Box>
                        }
                        <Box display="flex" flexDirection="row" justifyContent="flex-end" width="100%">
                            {props.mode === updateMode &&
                                <Button className={!enableReset ? "btn-selected disabled reset-button" : "btn-selected reset-button"} onClick={() => resetForm()}
                                    disabled={!enableReset}>
                                    RÉINITIALISER
                                </Button>
                            }
                            <Button className="btn-selected" onClick={() => cancel()}>Annuler</Button>
                            <Button className={disableCalculateButton ? "calculate-button disabled" : "calculate-button"}
                                onClick={() => getQuotation()}
                                disabled={disableCalculateButton}
                            >
                                <FontAwesomeIcon className="fa-calculator-icon" size="lg" icon={faCalculator} />
                                Calculer
                            </Button>
                            <Button className={!disableValidButton ? "btn-selected" : "btn-selected disabled"}
                                disabled={disableValidButton}
                                onClick={() => validateClickHandler()}
                            >
                                Valider
                            </Button>
                        </Box>
                    </Box>
                </Box>
                <ConfirmValidationDialogComponent open={isConfirmValidationDialogOpened} handleClose={handleCloseConfirmValidationDialog} validate={confirmValidate} />
                {isConfirmValidationExistingSpecificOrNegotiatedPriceDialogOpened &&
                    <Dialog
                        open={isConfirmValidationExistingSpecificOrNegotiatedPriceDialogOpened}
                        onClose={() => setIsConfirmValidationExistingSpecificOrNegotiatedPriceDialogOpened(false)}
                        aria-describedby="alert-dialog-description"
                        className="confirm-validation-dialog"
                    >
                        <DialogTitle>ATTENTION</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                Le Flux est déjà associé à un tarif achat négocié pour lequel il existe au moins une surcharge dans l'écran Achats. En cas de confirmation ces surcharges ne seront plus prises en compte.
                                <br />
                                Confirmez-vous l'action ?
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => setIsConfirmValidationExistingSpecificOrNegotiatedPriceDialogOpened(false)} color="primary">
                                ANNULER
                            </Button>
                            <Button onClick={handleConfirmValidationExistingSpecificOrNegotiatedPrice} color="primary">
                                CONFIRMER
                            </Button>
                        </DialogActions>
                    </Dialog>}
                {isConfirmValidationExistingSpecificOrNegotiatedPriceOnInternalExternalDialogOpened &&
                    <Dialog
                        open={isConfirmValidationExistingSpecificOrNegotiatedPriceOnInternalExternalDialogOpened}
                        onClose={() => setIsConfirmValidationExistingSpecificOrNegotiatedPriceOnInternalExternalDialogOpened(false)}
                        aria-describedby="alert-dialog-description"
                        className="confirm-validation-dialog"
                    >
                        <DialogTitle>ATTENTION</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                Au moins un tour de ce Flux est associé à un transporteur sur le planning. La validation peut entrainer la modification de son tarif d'achat et la perte de son éventuelle surcharge.
                                <br />
                                Confirmez-vous l'action ?
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => setIsConfirmValidationExistingSpecificOrNegotiatedPriceOnInternalExternalDialogOpened(false)} color="primary">
                                ANNULER
                            </Button>
                            <Button onClick={handleConfirmValidationExistingSpecificOrNegotiatedPriceOnInternalExternal} color="primary">
                                CONFIRMER
                            </Button>
                        </DialogActions>
                    </Dialog>}
            </Box>
        </form>
    );
}
