import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { UploadOnBeforeUploadEvent, UploadOnStatusChangeEvent, UploadResponse } from '@progress/kendo-react-upload';
import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import ToastService from '../../ToastService';
import BusinessErrors from '../../utils/BusinessErrors';
import Utilities from '../../utils/Utilities';
import { BusinessErrorLight, CommandResultLight, WebAppActionResultEx } from '../models/WebAppActionResult';
import { UserSignatureFieldsComponent } from './components/UserSignatureFieldsComponent';
import { UserSignatureFieldTypeMenuComponent } from './components/UserSignatureFieldTypeMenuComponent';
import { UserSignatureLogoChoiceComponent } from './components/UserSignatureLogoChoiceComponent';
import { UserSignatureAttachment } from './models/UserSignatureAttachment';
import { UserSignatureField } from './models/UserSignatureField';
import { AddedAttachmentResult } from './services/dataContracts/controller/AddedAttachmentResult';
import { AttachmentStatus } from './services/dataContracts/controller/AttachmentStatus';
import { UserSignatureFieldsRequestArgs } from './services/dataContracts/controller/UserSignatureFieldsRequestArgs';
import { UserSignatureFieldType } from './services/dataContracts/controller/UserSignatureFieldType';
import { UserSignatureRequestArgs } from './services/dataContracts/controller/UserSignatureRequestArgs';
import { UserSignatureApiClient } from './services/UserSignatureApiClient';
import './UserSignatureDialogStyles.scss';

interface UserSignatureDialogComponentProperties {
    isUserSignatureDialogOpened: boolean,
    userName: string,
    userEmail: string,
    userPhoneNumber: string,
    onClose: () => void,
    handleAfterSignatureAddedOrUpdated?: () => void
}

export const UserSignatureDialogComponent = (props: UserSignatureDialogComponentProperties): JSX.Element => {

    const [signaturePopupHtml, setSignaturePopupHtml] = useState<string>(null);

    const [defaultImages, setDefaultImages] = useState<{ id: string, html: string }[]>([]);
    const [userAttachments, setUserAttachments] = useState<UserSignatureAttachment[]>([]);
    const [selectedUserAttachment, setSelectedUserAttachment] = useState<UserSignatureAttachment>(null);
    const [attachmentIdChoice, setAttachmentIdChoice] = useState<string>("-1");
    const [allAttachments, setAllAttachments] = useState<Map<string, string>>(new Map<string, string>());

    const [signatureFieldsData, setSignatureFieldsData] = React.useState<UserSignatureField[]>([]);

    const [showConfirmationDeleteSignatureModal, setShowConfirmationDeleteSignatureModal] = React.useState<boolean>(false);

    useEffect(() => {
        Promise.all([
            UserSignatureApiClient.GetUserSignature(props.userName),
            UserSignatureApiClient.GetUserSignatureDefaultImages()
        ])
            .then((res) => {
                let attachments = new Map<string, string>();
                let userSignatureResData = res[0].data;
                if (userSignatureResData) {
                    if (userSignatureResData.attachmentId && userSignatureResData.attachmentName) {
                        attachments.set(userSignatureResData.attachmentId, userSignatureResData.attachmentName);
                    }
                    let html = userSignatureResData.html;
                    if (userSignatureResData.attachmentId) {
                        const imgElement = getHtmlPortion(html, "<img", "/>");
                        html = html.replace(imgElement, `<img src="api/UserSignature/GetImageContent?attachmentId=${userSignatureResData.attachmentId}" style="margin-right:20px;" />`);
                    }
                    setSignaturePopupHtml(html);

                    let fields: UserSignatureField[] = [];
                    userSignatureResData.fields.forEach((field) => {
                        let fieldType: UserSignatureFieldType = null;
                        switch (field.fieldType) {
                            case "Text":
                                fieldType = UserSignatureFieldType.text;
                                break;

                            case "Email":
                                fieldType = UserSignatureFieldType.email;
                                break;

                            case "PhoneNumber":
                                fieldType = UserSignatureFieldType.phoneNumber;
                                break;

                            default:
                                break;
                        }

                        fields.push({
                            id: field.id,
                            content: field.content,
                            fieldType: fieldType,
                            isBold: field.isBold,
                            isItalic: field.isItalic
                        });
                    });
                    setSignatureFieldsData(fields);
                }

                let attachmentChoice = "-1";
                const defaultImagesResData = res[1].data;
                if (defaultImagesResData) {
                    let defaultImagesArray: { id: string, html: string }[] = [];
                    defaultImagesResData.forEach((elem) => {
                        defaultImagesArray.push(
                            {
                                id: elem.attachmentId,
                                html: `<img src="api/UserSignature/GetImageContent?attachmentId=${elem.attachmentId}" alt="${elem.label}" />`
                            });

                        attachments.set(elem.attachmentId, elem.attachmentName);

                        if (userSignatureResData && elem.attachmentId === userSignatureResData.attachmentId) {
                            attachmentChoice = elem.attachmentId;
                        }
                    });

                    setAllAttachments(attachments);
                    setDefaultImages(defaultImagesArray)
                }

                if (userSignatureResData && attachmentChoice === "-1" && userSignatureResData.attachmentId !== null) {
                    attachmentChoice = userSignatureResData.attachmentId;

                    const attachment: UserSignatureAttachment = {
                        attachmentId: userSignatureResData.attachmentId,
                        label: userSignatureResData.attachmentName,
                        status: AttachmentStatus.unchanged
                    };
                    setSelectedUserAttachment(attachment);
                    setUserAttachments([attachment]);
                }

                setAttachmentIdChoice(attachmentChoice);
            });
    }, []);

    const handlerBeforeUploadImageAttachment = (e: UploadOnBeforeUploadEvent): void => {
        let name = e.files[0].name;
        const replacement = '_';

        name = name.replaceAll(' ', replacement);
        name = name.replaceAll('%', replacement);

        e.files[0].name = name;
    }

    const onUploadStatusChange = (event: UploadOnStatusChangeEvent): void => {
        const res: UploadResponse = event.response;
        if (res?.status == 200) {
            const data: WebAppActionResultEx<AddedAttachmentResult[]> = res?.response;
            const commandResultsWithErrors = data.commandResults.filter(x => x.hasBusinessErrors === true);
            if (commandResultsWithErrors.length > 0) {
                handlerDisplayErrorsAfterUpload(commandResultsWithErrors);
                return;
            }

            let attachments = userAttachments;
            attachments.forEach(async (attachment) => {
                if (attachment.status === AttachmentStatus.unchanged) {
                    attachment.status = AttachmentStatus.deleted;
                }
                else if (attachment.status === AttachmentStatus.new) {
                    await onDeleteAttachment(attachment.attachmentId);
                }
            });

            attachments = attachments.filter(x => x.status !== AttachmentStatus.new);

            const uploadedAttachment = data.extraResult[0];
            const newAttachment: UserSignatureAttachment = {
                attachmentId: uploadedAttachment.attachmentId,
                label: uploadedAttachment.name,
                status: AttachmentStatus.new
            };
            attachments.push(newAttachment);
            setUserAttachments(attachments);

            let attachmentsSet = allAttachments;
            attachmentsSet.set(newAttachment.attachmentId, newAttachment.label);
            setAllAttachments(attachmentsSet);
            setSelectedUserAttachment(newAttachment);
            setAttachmentIdChoice(newAttachment.attachmentId);

            const logoHtml = `<!-- DEBUT LOGO --><img src="api/UserSignature/GetImageContent?attachmentId=${newAttachment.attachmentId}" alt="${newAttachment.label}" /><!-- FIN LOGO -->`;
            updateSignatureHtmlAfterLogoChanged(logoHtml);
        }
    }

    const handlerDisplayErrorsAfterUpload = (commandResults: CommandResultLight[]): void => {
        const errorAddedFileNumber: number = commandResults.length;
        const errorText: string = errorAddedFileNumber > 1 ? errorAddedFileNumber + " fichier(s) n'ont pas été ajouté" : " fichier n'a pas été ajouté";
        const lstErrorToastMessages: string[] = [];

        commandResults.forEach((command: CommandResultLight) => {
            command.businessErrors.forEach((businessError: BusinessErrorLight) => {
                const error = BusinessErrors.GetError({ type: businessError.type, name: businessError.name });
                if (!lstErrorToastMessages.includes(error))
                    lstErrorToastMessages.push(error);
            });
        });

        ToastService.showErrorToast(errorText, lstErrorToastMessages);
    }

    const onDeleteAttachment = async (attachmentId: string): Promise<void> => {
        await UserSignatureApiClient.DeleteAttachment(attachmentId);
    }

    const handleDeleteAttachment = async (attachmentId: string): Promise<void> => {
        let attachment = userAttachments.find(x => x.attachmentId === attachmentId);
        if (attachment.status === AttachmentStatus.new) {
            await onDeleteAttachment(attachmentId);
            let attachments = userAttachments.filter(x => x.attachmentId !== attachmentId);
            setUserAttachments(attachments);
        }
        else {
            attachment.status = AttachmentStatus.deleted;
        }

        let attachmentsSet = allAttachments;
        attachmentsSet.delete(attachment.attachmentId);
        setAllAttachments(attachmentsSet);

        setSelectedUserAttachment(null);
        setAttachmentIdChoice("-1");

        let logoHtml = '<!-- DEBUT LOGO --><!-- FIN LOGO -->';
        updateSignatureHtmlAfterLogoChanged(logoHtml);
    }

    const handleSelectedAttachmentChoiceChanged = (event: React.ChangeEvent<HTMLInputElement>): void => {
        let value = event.target.value;
        if (value === "0") {
            value = "-1";
        }

        setAttachmentIdChoice(value);

        let logoHtml = '';
        if (value === "-1") {
            logoHtml = '<!-- DEBUT LOGO --><!-- FIN LOGO -->';
        }
        else {
            let attachmentlabel = allAttachments.get(value);
            logoHtml = `<!-- DEBUT LOGO --><img src="api/UserSignature/GetImageContent?attachmentId=${value}" alt="${attachmentlabel}" /><!-- FIN LOGO -->`;
        }
        updateSignatureHtmlAfterLogoChanged(logoHtml);
    }

    const handleAddSignatureFieldLine = (type: UserSignatureFieldType): void => {
        let content = '';
        if (type === UserSignatureFieldType.email) {
            content = props.userEmail;
        }
        else if (type === UserSignatureFieldType.phoneNumber) {
            content = props.userPhoneNumber;
        }

        let fields = signatureFieldsData;
        fields.push({
            id: new Date().getTime(),
            content: content,
            isBold: false,
            isItalic: false,
            fieldType: type
        });

        updateSignatureFieldsAndHtml(fields);
    }

    const handleDeleteField = (id: number): void => {
        let fields = [...signatureFieldsData];
        let index = fields.findIndex(x => x.id === id);
        fields.splice(index, 1);

        updateSignatureFieldsAndHtml(fields);
    }

    const handleIsBoldChanged = (id: number): void => {
        let fields = [...signatureFieldsData];
        let index = fields.findIndex(x => x.id === id);
        let field = fields[index];
        field.isBold = !field.isBold;

        updateSignatureFieldsAndHtml(fields);
    }

    const handleIsItalicChanged = (id: number): void => {
        let fields = [...signatureFieldsData];
        let index = fields.findIndex(x => x.id === id);
        let field = fields[index];
        field.isItalic = !field.isItalic;

        updateSignatureFieldsAndHtml(fields);
    }

    const handleTextChanged = (id: number, value: string): void => {
        let fields = [...signatureFieldsData];
        let index = fields.findIndex(x => x.id === id);
        let field = fields[index];
        field.content = value;

        setSignatureFieldsData(fields);
    }

    const handleBlurText = (id: number, value: string): void => {
        let fields = [...signatureFieldsData];
        let index = fields.findIndex(x => x.id === id);
        let field = fields[index];
        field.content = value;

        if (field.fieldType === UserSignatureFieldType.email) {
            const validEmail = Utilities.validateEmailAddress(value);
            field.isValid = validEmail;
        }

        if (field.fieldType === UserSignatureFieldType.phoneNumber) {
            const validPhoneNumber = value?.trim() === '' || Utilities.isValidPhoneNumber(value, false);
            field.isValid = validPhoneNumber;
        }

        updateSignatureFieldsAndHtml(fields);
    }

    const updateSignatureFieldsAndHtml = (fields: UserSignatureField[]): void => {
        setSignatureFieldsData(fields);

        let htmlText = generateHtmlTextFromFields(fields);
        updateSignatureHtmlAfterTextChanged(htmlText);
    }

    const getHtmlPortion = (html: string, preString: string, searchString: string): string => {
        let preIndex = html.indexOf(preString);
        let searchIndex = preIndex + html.substring(preIndex).indexOf(searchString);
        let portion = html.substring(preIndex, searchIndex + searchString.length);
        return portion;
    }

    const setLogoInHtmlSignature = (htmlSignature: string, logoHtml: string): string => {
        if (htmlSignature !== null && htmlSignature.contains("<!-- DEBUT LOGO -->") && htmlSignature.contains("<!-- FIN LOGO -->")) {
            let imgElement = getHtmlPortion(htmlSignature, "<!-- DEBUT LOGO -->", "<!-- FIN LOGO -->");
            htmlSignature = htmlSignature.replace(imgElement, logoHtml);
        }
        else {
            htmlSignature = `<body>
                <table style="font-family: sans-serif;">
                    <tr>
                        <td>${logoHtml}</td>
                        <td><!-- DEBUT TEXT --><!-- FIN TEXT --></td>
		            </tr>
	            </table>
            </body>`
        }

        return htmlSignature;
    }

    const updateSignatureHtmlAfterLogoChanged = (logoHtml: string): void => {
        let html = signaturePopupHtml;
        let userSignatureHtml = setLogoInHtmlSignature(html, logoHtml);
        setSignaturePopupHtml(userSignatureHtml);
    }

    const setHtmlSignatureText = (htmlSignature: string, text: string): string => {
        if (htmlSignature !== null && htmlSignature.contains("<!-- DEBUT TEXT -->") && htmlSignature.contains("<!-- FIN TEXT -->")) {
            let textElement = getHtmlPortion(htmlSignature, "<!-- DEBUT TEXT -->", "<!-- FIN TEXT -->");
            htmlSignature = htmlSignature.replace(textElement, text);
        }
        else {
            let logoHtmlPortion = `<!-- DEBUT LOGO --><!-- FIN LOGO -->`;

            if (attachmentIdChoice !== "-1") {
                let attachmentlabel = allAttachments.get(attachmentIdChoice);
                logoHtmlPortion = `<!-- DEBUT LOGO --><img src="api/UserSignature/GetImageContent?attachmentId=${attachmentIdChoice}" alt="${attachmentlabel}" /><!-- FIN LOGO -->`;
            }

            htmlSignature = `<body>
                <table style="font-family: sans-serif;">
                    <tr>
                        <td>${logoHtmlPortion}</td>
                        <td>${text}</td>
		            </tr>
	            </table>
            </body>`
        }

        return htmlSignature;
    }

    const updateSignatureHtmlAfterTextChanged = (text: string): void => {
        let html = signaturePopupHtml;
        let userSignatureHtml = setHtmlSignatureText(html, text);
        setSignaturePopupHtml(userSignatureHtml);
    }

    const generateHtmlTextFromFields = (fields: UserSignatureField[]): string => {
        let htmlText = '<!-- DEBUT TEXT -->';
        fields.forEach((field, index) => {
            let text = '';
            switch (field.fieldType) {
                case UserSignatureFieldType.text:
                case UserSignatureFieldType.phoneNumber:
                    text += `<span style="font-size:10px;">${field.content}</span>`;
                    break;

                case UserSignatureFieldType.email:
                    text += `<a href="mailto:${field.content}" style="font-size:10px;">${field.content}</a>`;
                    break;

                default:
                    break;
            }

            if (field.isBold) {
                text = `<b>${text}</b>`;
            }

            if (field.isItalic) {
                text = `<i>${text}</i>`;
            }

            if (index < signatureFieldsData.length - 1) {
                text += `<br>`;
            }

            htmlText += text;
        });

        return `${htmlText}<!-- FIN TEXT -->`;
    }

    const handleCanceled = (): void => {
        userAttachments.forEach(async (attachment) => {
            if (attachment.status === AttachmentStatus.new) {
                await onDeleteAttachment(attachment.attachmentId);
            }
        });
        props.onClose();
    }

    const handleSave = (): void => {
        if (attachmentIdChoice === "-1" && signatureFieldsData.length === 0) {
            setShowConfirmationDeleteSignatureModal(true);
        }
        else {
            AddOrUpdateUserSignature();
        }
    }

    const confirmDeleteSignature = (): void => {
        AddOrUpdateUserSignature();
    }

    const AddOrUpdateUserSignature = (): void => {
        let fieldsArgs: UserSignatureFieldsRequestArgs[] = [];
        signatureFieldsData.forEach((field, index) => {
            fieldsArgs.push({
                fieldType: field.fieldType,
                content: field.content,
                isBold: field.isBold,
                isItalic: field.isItalic,
                order: index
            });
        });

        userAttachments.forEach(async (attachment) => {
            if (attachment.status === AttachmentStatus.new && attachment.attachmentId !== attachmentIdChoice) {
                await onDeleteAttachment(attachment.attachmentId);
            }
        });

        let signatureHtml = signaturePopupHtml;

        if (attachmentIdChoice && attachmentIdChoice !== "-1" && attachmentIdChoice !== "0") {
            let imgElement = getHtmlPortion(signatureHtml, "<img", "/>");
            let attachmentLabel = allAttachments.get(attachmentIdChoice);
            signatureHtml = signatureHtml.replace(imgElement, `<img src="cid:${attachmentLabel}" style="margin-right:20px;" />`);
        }

        let requestArgs: UserSignatureRequestArgs = {
            userName: props.userName,
            html: signatureHtml,
            attachmentId: attachmentIdChoice === "-1" || attachmentIdChoice === "0" ? null : attachmentIdChoice,
            fields: fieldsArgs
        };

        UserSignatureApiClient.AddOrUpdateUserSignature(requestArgs)
            .then((res) => {
                props.onClose();

                if (props.handleAfterSignatureAddedOrUpdated) {
                    props.handleAfterSignatureAddedOrUpdated();
                }
            });
    }

    const isSaveButtonDisabled = signatureFieldsData.some(x => x.isValid === false);

    return (
        <Dialog
            disableEscapeKeyDown
            aria-modal="false"
            open={props.isUserSignatureDialogOpened}
            scroll="paper"
            className="user-signature"
        >
            <DialogTitle>
                <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
                    Paramètres utilisateur
                    <FontAwesomeIcon icon={faTimes} onClick={handleCanceled} cursor="pointer" />
                </Box>
            </DialogTitle>
            <DialogContent dividers>
                <Box display="flex" flexDirection="column" height="100%">
                    <Box display="flex" flexDirection="row">
                        {`Signature logisticien : `}
                        <UserSignatureFieldTypeMenuComponent handleAddSignatureFieldLine={handleAddSignatureFieldLine} />
                    </Box>
                    <Box display="flex" flexDirection="row" mb="10px" className="signature-fields-and-preview">
                        <Box width="50%">
                            <UserSignatureFieldsComponent
                                signatureFieldsData={signatureFieldsData}
                                updateSignatureFieldsAndHtml={updateSignatureFieldsAndHtml}
                                handleDeleteField={handleDeleteField}
                                handleIsBoldChanged={handleIsBoldChanged}
                                handleIsItalicChanged={handleIsItalicChanged}
                                handleTextChanged={handleTextChanged}
                                handleBlurText={handleBlurText}
                            />
                        </Box>
                        <Box className="preview">
                            <fieldset>
                                <legend>Aperçu</legend>
                                {signaturePopupHtml !== null ? (
                                    <div style={{ lineHeight: "normal" }} dangerouslySetInnerHTML={{ __html: signaturePopupHtml }}></div>
                                ) : ('')}
                            </fieldset>
                        </Box>
                    </Box>
                    <br />
                    Sélectionner le logo de votre signature : <br />
                    <Box display="flex" flexDirection="row" alignItems="center">
                        <UserSignatureLogoChoiceComponent
                            attachmentIdChoice={attachmentIdChoice}
                            selectedUserAttachment={selectedUserAttachment}
                            defaultImages={defaultImages}
                            handleSelectedAttachmentChoiceChanged={handleSelectedAttachmentChoiceChanged}
                            handlerBeforeUpload={handlerBeforeUploadImageAttachment}
                            onUploadStatusChange={onUploadStatusChange}
                            handleDeleteAttachment={handleDeleteAttachment}
                        />
                    </Box>
                    <Modal show={showConfirmationDeleteSignatureModal} className='delete-signature-modal' backdropClassName="delete-signature-modal-backdrop">
                        <Modal.Header closeButton>
                            <Modal.Title>ATTENTION</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            Confirmez-vous vouloir supprimer la signature ?
                        </Modal.Body>
                        <Modal.Footer>
                            <Button onClick={() => setShowConfirmationDeleteSignatureModal(false)}>
                                NON
                            </Button>
                            <Button onClick={confirmDeleteSignature}>
                                OUI
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCanceled} color="primary">
                    Annuler
                </Button>
                <Button onClick={handleSave} color="primary" disabled={isSaveButtonDisabled}>
                    Enregistrer
                </Button>
            </DialogActions>
        </Dialog>
    );
}
