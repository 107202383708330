import { EventMessage, EventType, IPublicClientApplication, PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { load } from '@progress/kendo-react-intl';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.css';
import { createBrowserHistory } from 'history';
import React from 'react';
import 'react-app-polyfill/ie11'; //react-app-polyfill/ie11 et react-app-polyfill/stable sont nécessaires les 2 pour le support IE11
import 'react-app-polyfill/stable';
import { createRoot } from "react-dom/client";
import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider } from 'react-router-dom';
import ToastService from 'src/ToastService';
import App from "./App";
import { appInsightsReactPlugin } from './appInsightsReactPlugin';
import buildinfo from './config/buildinfo.json';
import ExternalApp from "./ExternalApp";
import './index.scss';
import { MsalConfig } from './MsalConfig';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { SettingsProvider } from './SettingsProvider';
import { SettingsApiClient } from './shared/Settings/SettingsApiClient';
import './utils/JSON';
import { SessionStorage } from "./utils/Storage";

window.document.title += ` v2.${buildinfo.versionMajorMinor}.${buildinfo.build.buildNumber}`;

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

JSON.enableCustomDateParser();
JSON.enableCustomDateSerializer();

const currencies = require('cldr-numbers-full/main/fr/currencies.json');
load(currencies);

const displayGenericError = (err): void => {
    ToastService.showErrorToast("L'action a échoué en raison d'une erreur technique. Si ce problème persiste, veuillez contacter le support.");
}

const setupAppInsights = () => {
    const instrumentationKey: string = SettingsProvider.Get().applicationInsights?.instrumentationKey;

    //pour les developeurs, on ne configure pas de valeur pour eviter les "spam" avec localhost
    if (instrumentationKey !== "") {

        const browserHistory = createBrowserHistory();
        const appInsights = new ApplicationInsights({
            config: {
                instrumentationKey: instrumentationKey,
                extensions: [appInsightsReactPlugin as any],
                extensionConfig: {
                    [appInsightsReactPlugin.identifier]: { history: browserHistory }
                },
                enableAutoRouteTracking: true
            }
        });

        appInsights.loadAppInsights();
    }
}

//External App
if (window.location.pathname.startsWith("/External-")) {

    const renderExternalApp = () => {

        const router = createBrowserRouter(createRoutesFromElements(<Route path='*' element={<ExternalApp />} />));

        root.render(
            <React.StrictMode>
                <RouterProvider router={router} />
            </React.StrictMode>);
    }

    const setupLinkTokenAxiosInterceptors = (genericErrorHandler: (error: any) => void) => {

        axios.interceptors.request.use(

            (config) => {

                const url_string = window.location.href;
                const url = new URL(url_string);
                const linkToken = url.searchParams.get("K");

                if (linkToken) {
                    config.headers.Authorization = `colas-lorie ${linkToken}`;
                }

                return config;
            },

            (error) => {
                return Promise.reject(error);
            }
        );

        axios.interceptors.response.use(

            undefined,

            (error) => {
                if (!error.config.__colas_lorie_disableGenericErrorHandler) {
                    genericErrorHandler(error);
                }

                return Promise.reject(error);
            }
        );
    }

    setupLinkTokenAxiosInterceptors(displayGenericError);

    SettingsApiClient.Get()
        .then((res) => {
            if (res?.data) {
                const settings = res?.data;
                SettingsProvider.Setup(settings);

                setupAppInsights();
                renderExternalApp();
            }
        });
}

//Internal App
else {

    const renderInternalApp = (msalInstance: PublicClientApplication) => {

        const router = createBrowserRouter(createRoutesFromElements(<Route path='*' element={<App />} />));

        root.render(
            <MsalProvider instance={msalInstance}>
                <React.StrictMode>
                    <RouterProvider router={router} />
                </React.StrictMode>
            </MsalProvider>);
    }

    const setupMsalBearerAxiosInterceptors = (msalInstance: IPublicClientApplication, genericErrorHandler: (error: any) => void) => {

        axios.interceptors.request.use(

            (config) => {

                const account = msalInstance.getActiveAccount();
                if (!account) {
                    throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
                }

                return new Promise((resolve, reject) => {

                    const acquireTokenRequest = { scopes: MsalConfig.GetAcquireTokenScopes(), account: account };

                    return msalInstance.acquireTokenSilent(acquireTokenRequest)

                        .then((response) => {

                            if (response?.accessToken) {

                                SessionStorage.SetLastKnownApiAccessToken(response.accessToken);

                                config.headers.Authorization = `Bearer ${response.accessToken}`
                                resolve(config)
                            }
                            else {
                                reject(config)
                            }
                        });
                })
            },

            (error) => {
                return Promise.reject(error)
            }
        );

        //En cas de session expirée, l'utilisateur est redirigé vers la page d'authentification
        axios.interceptors.response.use(

            undefined,

            (error) => {

                if (error && error.response && error.response.status === 401) {
                    msalInstance.logoutRedirect({ postLogoutRedirectUri: "/" });
                }

                if (!error.config?.__colas_lorie_disableGenericErrorHandler) {
                    genericErrorHandler(error);
                }

                return Promise.reject(error);
            }
        );
    }

    const msalSettingsPromise = new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.open("GET", 'config/msal.json');
        xhr.onload = () => resolve(xhr.responseText);
        xhr.onerror = () => reject(xhr.statusText);
        xhr.send();
    });

    msalSettingsPromise.then((res) => {

        MsalConfig.Setup(res);

        const msalInstance = MsalConfig.GetPublicClientApplication();

        //NOTE CMA technique inspirée et adaptée d'ici : "MSAL.js for React TypeScript Sample"
        //https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/samples/msal-react-samples/typescript-sample/src/index.tsx
        msalInstance.addEventCallback((event: EventMessage) => {
            switch (event.eventType) {

                case EventType.HANDLE_REDIRECT_END:
                    {
                        const accounts = msalInstance.getAllAccounts();
                        if (accounts.length > 0) {
                            msalInstance.setActiveAccount(accounts[0]);

                            setupMsalBearerAxiosInterceptors(msalInstance, displayGenericError);

                            SettingsApiClient.Get()
                                .then((res) => {
                                    if (res?.data) {

                                        const settings = res?.data;
                                        SettingsProvider.Setup(settings);

                                        setupAppInsights();
                                        renderInternalApp(msalInstance);
                                    }
                                });
                        }

                        break;
                    }

                default:
                    break;
            }
        });

        renderInternalApp(msalInstance);
    });
}

//NOTE CMA dans les versions de début de l'application on avait laissé le "service worker" activé par défaut.
//Vu qu'il n'était pas utile dans notre cas et qu'il nous posait des problèmes de cache et de mises à jour, on 
//avait viré le code associé... de manière un peu trop "brutale".
//L'appel de unregister() ici permet de l'enlever proprement des navigateurs ou il est encore enregistré et d’éviter 
//des erreurs 404 sur / service-worker.js
serviceWorkerRegistration.unregister();