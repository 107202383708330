import moment from 'moment';

export default class Utilities {

    public static getDateRangeOfWeek(date: Date) {
        const startOfWeek = moment(date).startOf('isoWeek');
        const endOfWeek = moment(date).endOf('isoWeek');

        const days = [];
        let day = startOfWeek;

        while (day <= endOfWeek) {
            days.push(day.toDate());
            day = day.clone().add(1, 'd');
        }

        return days;
    }

    public static groupBy(collection, property: string) {
        let i = 0, val, index,
            values = [], result = [];
        for (; i < collection.length; i++) {
            val = collection[i][property];
            index = values.indexOf(val);
            if (index > -1)
                result[index].push(collection[i]);
            else {
                values.push(val);
                result.push([collection[i]]);
            }
        }
        return result;
    }

    public static updateFilteredStyle(tds: HTMLCollectionOf<Element>, isFiltered: boolean) {
        for (let i = 0; i < tds.length; i++) {
            const divTd: Element = tds[i];
            if (isFiltered) {
                divTd.classList.add("filtered");
            }
            else {
                divTd.classList.remove("filtered");
            }
        }
    }

    // Les code pays à formater sur 8 ou 9 chiffres en ajoutant des espaces entre chaque 2 chiffres
    private static _phoneNumberCountryCodeToFormatInEightOrNineNumber = [
        '+32', '0032' /*Belgique*/
    ];

    public static formatPhoneNumber = (text: string): string => {
        if (!text)
            return null;

        const cleanText = text.trim().split(' ').join('');

        // Check if cleanText starts with any of the country codes in the array
        const countryCodeMatch = this._phoneNumberCountryCodeToFormatInEightOrNineNumber.find(code => cleanText.startsWith(code));
        if (countryCodeMatch) {
            return Utilities.formatPhoneTextEightAndNineNumbers(cleanText);
        } else {
            return Utilities.formatPhoneText(cleanText);
        }
    }

    private static formatPhoneText = (text: string): string => {
        let newValue = "";
        let j = 0;
        for (let i = text.length - 1; i >= 0; i--) {
            if ((i <= text.length - 2) && (j % 2 == 0)) {
                newValue = " " + newValue;
            }
            newValue = text.charAt(i) + newValue;
            j++;
        }
        return newValue;
    }

    // Nouveau formatage pour les numéros à formater sur 8 et 9 chiffres
    private static formatPhoneTextEightAndNineNumbers = (text: string): string => {
        let prefix = ""
        let phoneNumber = ""
        let finalPhonNumber = ""
        if (text.startsWith('+')) {
            prefix = text.substring(0, 3);
            phoneNumber = text.substring(3, text.length);
        } else {
            prefix = text.substring(0, 4);
            phoneNumber = text.substring(4, text.length);
        }
        for (let i = 1; i <= phoneNumber.length; i++) {
            if (i % 2 == 0) {
                finalPhonNumber = finalPhonNumber + phoneNumber.charAt(i - 2) + phoneNumber.charAt(i - 1) + " "
            }
            if (i % 2 != 0 && i == phoneNumber.length) {
                finalPhonNumber = finalPhonNumber + phoneNumber.charAt(phoneNumber.length - 1)
            }
        }
        finalPhonNumber = prefix + " " + finalPhonNumber
        return finalPhonNumber;
    }

    public static formatAddress = (line1: string, line2: string, zipCode: string, city: string): string => {
        line1 = line1?.trim();
        line2 = line2?.trim();
        zipCode = zipCode?.trim();
        city = city?.trim();

        let part1 = '';
        if (line1 && line2)
            part1 = line1 + ', ' + line2;

        else if (line1 && !line2)
            part1 = line1;

        else if (!line1 && line2)
            part1 = line2;

        let part2 = '';
        if (zipCode && city)
            part2 = zipCode + " " + city;

        else if (zipCode && !city)
            part2 = zipCode;

        else if (!zipCode && city)
            part2 = city;

        if (part1 && part2)
            return part1 + ", " + part2;

        if (!part1 && part2)
            return part2;

        if (part1 && !part2)
            return part1;

        return null;
    }

    private static _validPhoneNumberLengthsByCountryCode = new Map<string, number[]>([
        ["33", [9]], // FR
        ["34", [9]], // ES
        ["39", [10]], // IT
        ["32", [8, 9]], // BE
        ["352", [6]], // LU
        ["49", [11]], // DE
        ["41", [11]], // CH
        ["377", [8]], // MC
        ["376", [6]], // AD
        ["44", [10, 11]], // GB
        ["351", [9]] // PT
    ]);

    private static _validPhoneNumberCountryCodePrefixes = ['+', '00'];

    public static isValidPhoneNumber = (phoneNumber: string, validateMobilePhoneNumber = false): boolean => {
        if (!phoneNumber)
            return false;

        // On permet les espaces ' ' car on formate les numéros avec des espaces et 
        // on utilise la valeur formatée à certains endroits pour la validation.
        phoneNumber = phoneNumber.replace(/ /g, '');

        const phoneCountryCodePrefix = Utilities._validPhoneNumberCountryCodePrefixes.find(x => phoneNumber.startsWith(x));

        if (!phoneCountryCodePrefix) {
            // Pas de format avec indicatif pays
            // alors on teste pour un numéro FR
            return validateMobilePhoneNumber
                ? /^0[6,7][0-9]{8}$/.test(phoneNumber)
                : /^0[1-9][0-9]{8}$/.test(phoneNumber);
        }

        const phoneNumberWithoutCountryCodePrefix = phoneNumber.slice(phoneCountryCodePrefix.length);
        if (!/^[0-9]{6,15}$/.test(phoneNumberWithoutCountryCodePrefix)) {
            // Règle la plus générale qui contient les règles spécifiques aux indicatifs connus :
            // Tous les charactères du numéro doivent être des chiffres.
            // Il faut entre 6 et 15 caractères.
            return false;
        }

        // On peut avoir des indicatifs connus de longueur 2 ou 3 sans conflit entre eux.
        // A surveiller si on ajoute d'autres indicatifs connus dans la liste.
        const [countryCode, countryCodeValidLengths] = getCountryCodeValidLengths(2, phoneNumberWithoutCountryCodePrefix)
            ?? getCountryCodeValidLengths(3, phoneNumberWithoutCountryCodePrefix)
            ?? [null, null];

        if (countryCode && countryCodeValidLengths) {
            // ici on sait déjà que le numéro a un indicatif connu.
            if (countryCodeValidLengths.some(
                (validLength) => phoneNumberWithoutCountryCodePrefix.length === validLength + countryCode.length)) {

                // On ne vérifie que les numéros portables FR.
                // A mettre dans la config de chaque pays quand on voudra vérifier les portables d'autres pays.
                if (countryCode == '33' &&
                    validateMobilePhoneNumber &&
                    !phoneNumberWithoutCountryCodePrefix.startsWith('336') &&
                    !phoneNumberWithoutCountryCodePrefix.startsWith('337')) {
                    return false;
                }

                return true;
            }

            // numéro avec indiciatif connu
            // mais ne satisfait aucune longueur valide pour cet indicatif.
            return false;
        }

        // Ici on a passé la règle générale (indicatif + [6;15] chiffres)
        // et aucune règle spécifique ne s'est appliquée.
        return true;

        function getCountryCodeValidLengths(countryCodeLength: number, phoneNumber: string): [string, number[]] {
            const countryCode = phoneNumber.slice(0, countryCodeLength);
            const validLengths = Utilities._validPhoneNumberLengthsByCountryCode.get(countryCode);

            if (!validLengths)
                return null;

            return [countryCode, validLengths];
        }
    }

    public static validateEmailAddress = (email: string): boolean => {
        //https://regexr.com/3e48o
        const regExp = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
        return regExp.test(email.trim());
    }

    public static validateZipCodeAddress = (zipCode: string): boolean => {
        const regExp = /^(?:[0-8]\d|9[0-8])\d{3}$/;
        return regExp.test(zipCode.trim());
    }

    public static distinct = (value: string, index: number, array: string[]): boolean => {
        return array.indexOf(value) === index;
    }

    public static sum = (items: Array<any>, prop: string) => {
        return items.reduce(function (a, b) {
            return (a ?? 0) + (b[prop] ?? 0);
        }, 0);
    }

    public static count = <T>(items: Array<T>, criteria: (item: T) => boolean) => {
        return items.reduce(function (a, b) {
            return (a ?? 0) + (criteria(b) == true ? 1 : 0);
        }, 0);
    }
}