import { Box } from '@mui/material';
import { orderBy, SortDescriptor } from '@progress/kendo-data-query';
import { Grid, GridCellProps, GridColumn, GridColumnReorderEvent, GridColumnResizeEvent, GridHeaderCellProps, GridPageChangeEvent, GridSortChangeEvent } from '@progress/kendo-react-grid';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import { countBy } from 'lodash';
import React, { useState } from 'react';
import { AppModule, LocalStorage } from '../../../../utils/Storage';
import { CustomCheckboxCell } from '../../Common/CustomCheckboxCell';
import { CustomHeaderCellComponent } from '../../Common/CustomHeaderCellComponent';
import { SizingUtilities } from '../../SizingUtilities';
import { TransporterPurchasePriceLightModelExtended } from '../models/TransporterPurchasePriceLightModelExtended';
import { LogisticsUnitChoiceOfTransportPurchasePricesLightModel } from '../services/dataContracts/queryStack/LogisticsUnitChoiceOfTransportPurchasePricesLightModel';
import { TransportPurchasePricesReferentialApiClient } from '../services/TransportPurchasePricesReferentialApiClient';
import { CustomHeaderCheckBoxCellComponent } from './CustomHeaderCheckBoxCellComponent';

interface TransportPurchasePricesProperties {
    isForInternalTransporters: boolean,
    transporterId: string,
    sort: SortDescriptor[],
    purcharsePriceLogisticsUnitsChoices: Array<LogisticsUnitChoiceOfTransportPurchasePricesLightModel>,
    selectedTransporterPurchasesPricesMap: Map<number, TransporterPurchasePriceLightModelExtended>
    selectedTransporterPurchasesPricesArray: Array<TransporterPurchasePriceLightModelExtended>,
    handleSortChange: (e: GridSortChangeEvent) => void,
    handleSortColumnChange: (sortItems: SortDescriptor[]) => void
}

function useForceUpdate() {
    const [, setTick] = React.useState(0);
    const update = React.useCallback(() => {
        setTick(tick => tick + 1);
    }, [])
    return update;
}

const TransportPurchasePricesGridName = 'transportPurchasePrices';
const ModuleKey = AppModule.ExternalTransportPurchasePricesReferential;

export const TransportPurchasePricesComponent = (props: TransportPurchasePricesProperties) => {
    const [purchasePricesChoicesData] = useState<Map<number, TransporterPurchasePriceLightModelExtended>>(props.selectedTransporterPurchasesPricesMap);
    const [skip, setSkip] = useState<number>(0);

    const forceUpdate = useForceUpdate();

    const onResizeHandler = (event: GridColumnResizeEvent): void => {
        const currentColumn = event.columns.find(c => c.id == event.targetColumnId);
        
        LocalStorage.SetGridColumnWidth(
            ModuleKey,
            TransportPurchasePricesGridName,
            currentColumn.field,
            currentColumn.width);
    }

    const onReorderHandler = (event: GridColumnReorderEvent): void => {
        LocalStorage.SetGridColumnsOrderIndexes(ModuleKey, TransportPurchasePricesGridName, event.columns);
        forceUpdate();
    }

    const getTransporterPriceLogisticsUnitsWidth = (fieldName: string, columnWidth: number): number => {
        return LocalStorage.GetGridColumnWidth(ModuleKey, TransportPurchasePricesGridName, fieldName, columnWidth);
    }

    const getTransporterPriceGridOrderIndexColumn = (fieldName: string, defaultIndex: number): number => {
        return LocalStorage.GetGridColumnOrderIndex(ModuleKey, TransportPurchasePricesGridName, fieldName, defaultIndex);
    }

    const updatePurchasePricesChoicesLogisticsUnit = (checked: boolean, logisticsUnit: string, transportPurchasePriceId: number): void => {
        TransportPurchasePricesReferentialApiClient.UpdatePurchasePriceChoiceLogisticsUnit({
            transporterId: props.transporterId,
            transportPurchasePriceId: transportPurchasePriceId,
            logisticsUnitId: logisticsUnit,
            isChecked: checked
        })
            .then(() => {
                const item = purchasePricesChoicesData.get(transportPurchasePriceId as number);
                item[logisticsUnit] = checked;

                let checkedSelect = true;
                let indeterminateAll = true;
                let numberOfChecked = 0;
                let numberOfUnchecked = 0;
                props.purcharsePriceLogisticsUnitsChoices.forEach((purchasePrice: LogisticsUnitChoiceOfTransportPurchasePricesLightModel) => {
                    if (!item[purchasePrice.logisticsUnitId]) {
                        checkedSelect = false;
                        numberOfUnchecked += 1;
                    } else {
                        numberOfChecked += 1;
                    }
                });
                if (props.purcharsePriceLogisticsUnitsChoices.length === numberOfUnchecked || props.purcharsePriceLogisticsUnitsChoices.length === numberOfChecked) {
                    indeterminateAll = false;
                }

                item.isSelectedAll = checkedSelect ? true : (indeterminateAll ? null : false);
                item.isIndeterminateAll = indeterminateAll;

                forceUpdate();
            });
    }

    const updatePurchasePricesChoicesAllLogisticsUnits = (checked: boolean, transportPurchasePriceId: number): void => {
        const purchasePriceLogisticsUnitIds: Array<string> = [];
        props.purcharsePriceLogisticsUnitsChoices.forEach((element: LogisticsUnitChoiceOfTransportPurchasePricesLightModel) => {
            purchasePriceLogisticsUnitIds.push(element.logisticsUnitId);
        });

        TransportPurchasePricesReferentialApiClient.SetOrUnsetPurchasePriceChoiceToAllLogisticsUnits({
            transporterId: props.transporterId,
            transportPurchasePriceId: transportPurchasePriceId,
            isChecked: checked,
            purchasePriceLogisticsUnitIds: purchasePriceLogisticsUnitIds
        })
            .then(() => {
                const item = purchasePricesChoicesData.get(transportPurchasePriceId);
                purchasePriceLogisticsUnitIds.forEach((purchasePriceLogisticsUnitId: string) => {
                    item[purchasePriceLogisticsUnitId] = checked;
                });
                item.isSelectedAll = checked ? true : false;
                item.isIndeterminateAll = false;

                forceUpdate();
            });
    }

    const updateAllElementsChoicesLogisticsUnit = (checked: boolean, logisticsUnitId: string): void => {
        TransportPurchasePricesReferentialApiClient.SetOrUnsetAllPurchasePricesChoicesForLogisticsUnit({
            transporterId: props.transporterId,
            logisticsUnitId: logisticsUnitId,
            shouldSet: checked
        })
            .then(() => {
                props.selectedTransporterPurchasesPricesArray.forEach(p => {
                    p[logisticsUnitId] = checked;
                });

                forceUpdate();
            });
    }

    const pageChange = (event: GridPageChangeEvent): void => {
        setSkip(event.page.skip);
    }

    const gridOffsetFromWindowTop: number = SizingUtilities.gridOffsetFromWindowTop();
    let gridHeight: number = SizingUtilities.computeGridHeight(gridOffsetFromWindowTop);
    const rowHeight: number = SizingUtilities.rowHeight;
    let gridPageSize: number = SizingUtilities.computeGridPageSize(gridHeight, rowHeight);
    const totalGrid: number = props.selectedTransporterPurchasesPricesArray.length;
    const gridStyle: React.CSSProperties = { height: gridHeight };
    const resize = (): void => {
        gridHeight = window.innerHeight - gridOffsetFromWindowTop;
        gridPageSize = Number((gridHeight / rowHeight).toFixed(0));
        forceUpdate();
    }
    window.onresize = resize;

    const dataGrid: TransporterPurchasePriceLightModelExtended[] = orderBy(props.selectedTransporterPurchasesPricesArray, props.sort).slice(skip, skip + gridPageSize);

    return (
        <Box display="flex" flexDirection="row" flex="wrap" className="colas_transporters-prices-grid">
            <LocalizationProvider language="fr-FR">
                <IntlProvider locale="fr" >
                    <Grid
                        className="transporters-prices-grid"
                        data={dataGrid}
                        selectedField="selected"
                        sortable
                        reorderable
                        resizable
                        sort={props.sort}
                        onColumnResize={onResizeHandler}
                        onColumnReorder={(e) => onReorderHandler(e)}
                        onSortChange={props.handleSortChange}
                        editField="inEdit"
                        rowHeight={rowHeight}
                        scrollable="virtual"
                        skip={skip}
                        total={totalGrid}
                        pageSize={gridPageSize}
                        onPageChange={pageChange}
                        style={gridStyle}
                    >
                        <GridColumn field="isSelectedAll" orderIndex={getTransporterPriceGridOrderIndexColumn("isSelectedAll", 0)} width={getTransporterPriceLogisticsUnitsWidth("isSelectedAll", 60)} title="Sélection"
                            cell={(p: GridCellProps) =>
                                <CustomCheckboxCell
                                    {...p}
                                    keyName="transportPurchasePriceId"
                                    updateChoicesAllLogisticsUnits={updatePurchasePricesChoicesAllLogisticsUnits}
                                />}
                            headerCell={(headerProps: GridHeaderCellProps) =>
                                <CustomHeaderCellComponent tooltip="Activer/Désactiver un tarif pour toutes les zones logistiques" title={headerProps.title} field="isSelectedAll" sort={props.sort}
                                    handleSortColumnChange={props.handleSortColumnChange} />
                            }
                        />
                        <GridColumn field="vehicleTypeLabel" orderIndex={getTransporterPriceGridOrderIndexColumn("vehicleTypeLabel", 1)} width={getTransporterPriceLogisticsUnitsWidth("vehicleTypeLabel", 120)} title="Type du véhicule" editable={false}
                            cell={(p: GridCellProps) =>
                                <td><div className={`vehicle-title${!p.dataItem.vehicleTypeIsEnabled ? " vehicle-disabled" : ""}`}>{p.dataItem.vehicleTypeLabel}</div></td>
                            }
                        />
                        <GridColumn field="lblPrice" orderIndex={getTransporterPriceGridOrderIndexColumn("lblPrice", 2)} width={getTransporterPriceLogisticsUnitsWidth("lblPrice", 120)} title="Type du tarif" editable={false} />
                        <GridColumn field="price" orderIndex={getTransporterPriceGridOrderIndexColumn("price", 3)} width={getTransporterPriceLogisticsUnitsWidth("price", 120)} title="Coût" editable={false} />
                        <GridColumn field="description" orderIndex={getTransporterPriceGridOrderIndexColumn("description", 4)} width={getTransporterPriceLogisticsUnitsWidth("description", 250)} title="Description" editable={false} />
                        {!props.isForInternalTransporters && <GridColumn field="contractNumber" orderIndex={getTransporterPriceGridOrderIndexColumn("contractNumber", 5)} width={getTransporterPriceLogisticsUnitsWidth("contractNumber", 150)} headerClassName="pac-field" title="PAC" editable={false} />}
                        {!props.isForInternalTransporters && <GridColumn field="lblUsedContractedNumberOfDays" orderIndex={getTransporterPriceGridOrderIndexColumn("lblUsedContractedNumberOfDays", 6)} width={getTransporterPriceLogisticsUnitsWidth("lblUsedContractedNumberOfDays", 150)} headerClassName="pac-field" title="Utilisation" editable={false} />}
                        {!props.isForInternalTransporters && <GridColumn field="lblContractedNumberOfDays" orderIndex={getTransporterPriceGridOrderIndexColumn("lblContractedNumberOfDays", 7)} width={getTransporterPriceLogisticsUnitsWidth("lblContractedNumberOfDays", 150)} headerClassName="pac-field" title="Nbr jours contrat" editable={false} />}
                        {!props.isForInternalTransporters && <GridColumn field="lblRemainingNumberOfWorkDays" orderIndex={getTransporterPriceGridOrderIndexColumn("lblRemainingNumberOfWorkDays", 8)} width={getTransporterPriceLogisticsUnitsWidth("lblRemainingNumberOfWorkDays", 150)} headerClassName="pac-field" title="Jours ouvrés restants" editable={false} />}
                        <GridColumn field="startDate" orderIndex={getTransporterPriceGridOrderIndexColumn("startDate", props.isForInternalTransporters ? 5 : 9)} width={getTransporterPriceLogisticsUnitsWidth("startDate", 120)} format="{0:dd-MM-yyyy}" editable={false} title="Date début" />
                        <GridColumn field="endDate" orderIndex={getTransporterPriceGridOrderIndexColumn("endDate", props.isForInternalTransporters ? 6 : 10)} width={getTransporterPriceLogisticsUnitsWidth("endDate", 120)} format="{0:dd-MM-yyyy}" editable={false} title="Date fin" />
                        {props.purcharsePriceLogisticsUnitsChoices.map((purchasePrice: LogisticsUnitChoiceOfTransportPurchasePricesLightModel, i: number) => {
                            const countSelectedLogisticsUnit = countBy(props.selectedTransporterPurchasesPricesArray, p => p[purchasePrice.logisticsUnitId] == true);
                            const checkedAll = countSelectedLogisticsUnit.true == props.selectedTransporterPurchasesPricesArray.length;
                            const indeterminateAll = countSelectedLogisticsUnit.true > 0 && countSelectedLogisticsUnit.false > 0;
                            return (
                                <GridColumn key={i} field={purchasePrice.logisticsUnitId} orderIndex={getTransporterPriceGridOrderIndexColumn(purchasePrice.logisticsUnitId, props.isForInternalTransporters ? 7 + i : 11 + i)}
                                    width={getTransporterPriceLogisticsUnitsWidth(purchasePrice.logisticsUnitId, 150)} title={purchasePrice.label}
                                    cell={(p: GridCellProps) =>
                                        <CustomCheckboxCell
                                            {...p}
                                            keyName="transportPurchasePriceId"
                                            updateChoicesLogisticsUnit={updatePurchasePricesChoicesLogisticsUnit}
                                        />}
                                    headerCell={(headerProps: GridHeaderCellProps) =>
                                        <CustomHeaderCheckBoxCellComponent
                                            logisticsUnitId={purchasePrice.logisticsUnitId}
                                            label={purchasePrice.label}
                                            checkedAll={checkedAll}
                                            indeterminateAll={indeterminateAll}
                                            sort={props.sort}
                                            handleSortColumnChange={props.handleSortColumnChange}
                                            tooltipMessage="Activer/Désactiver tous les tarifs pour cette zone logistique"
                                            updateAllElementsChoicesLogisticsUnit={updateAllElementsChoicesLogisticsUnit}
                                        />
                                    }
                                />)
                        }
                        )}
                    </Grid>
                </IntlProvider>
            </LocalizationProvider>
        </Box>
    );
}