import axios, { AxiosResponse } from 'axios';
import { WebAppActionResult } from 'src/shared/models/WebAppActionResult';
import { ActivitiesRequestArgs } from './dataContracts/controller/ActivitiesRequestArgs';
import { EditProductionSiteLogisticUnitRequestArgs } from './dataContracts/controller/EditProductionSiteLogisticUnitRequestArgs';
import { ProductionSiteCandidateToAdd } from './dataContracts/controller/ProductionSiteCandidateToAdd';
import { ProductionSiteChoiceLogisticsUnitRequestArgs } from './dataContracts/controller/ProductionSiteChoiceLogisticsUnitRequestArgs';
import { ProductionSiteChoiceLogisticsUnitsRequestArgs } from './dataContracts/controller/ProductionSiteChoiceLogisticsUnitsRequestArgs';
import { ProductionSiteChoicesExportRequestArgs } from './dataContracts/controller/ProductionSiteChoicesExportRequestArgs';
import { ActivityLightModel } from './dataContracts/queryStack/ActivityLightModel';
import { LogisticsUnitChoiceOfProductionSitesLightModel } from './dataContracts/queryStack/LogisticsUnitChoiceOfProductionSitesLightModel';
import { ProductionSiteLightModel } from './dataContracts/queryStack/ProductionSiteLightModel';

const controllerUrl = 'api/ProductionSitesReferential/';

export class ProductionSitesReferentialApiClient {
    public static GetProductionSites = (searchText: string)
        : Promise<AxiosResponse<Array<ProductionSiteLightModel>>> => {
        return axios.get(`${controllerUrl}GetProductionSites?searchText=${encodeURIComponent(searchText)}`);
    }

    public static GetProductionSitesLogistiqueZone = (searchText: string)
        : Promise<AxiosResponse<Array<ProductionSiteLightModel>>> => {
        return axios.get(`${controllerUrl}GetProductionSitesLogistiqueZone?searchText=${encodeURIComponent(searchText)}`);
    }

    public static GetProductionSiteChoicesLogisticsUnit = ()
        : Promise<AxiosResponse<Array<LogisticsUnitChoiceOfProductionSitesLightModel>>> => {
        return axios.get(`${controllerUrl}GetProductionSiteChoicesLogisticsUnit`);
    }

    public static GetProductionSitesWithLogisticsUnitChoicesLogisticsUnit = (searchText: string)
        : Promise<[AxiosResponse<Array<ProductionSiteLightModel>>, AxiosResponse<Array<LogisticsUnitChoiceOfProductionSitesLightModel>>]> => {
        return Promise.all(
            [
                ProductionSitesReferentialApiClient.GetProductionSites(searchText),
                ProductionSitesReferentialApiClient.GetProductionSiteChoicesLogisticsUnit()
            ]);
    }

    public static GetActivities = ()
        : Promise<AxiosResponse<Array<ActivityLightModel>>> => {
        return axios.get(`${controllerUrl}GetActivities`);
    }

    public static GetBusinessUnitIds = ()
        : Promise<AxiosResponse<Array<string>>> => {
        return axios.get(`${controllerUrl}GetBusinessUnitIds`);
    }    

    public static AddOrRemoveProductionSiteChoice = (productionSiteChoiceLogisticsUnits: ProductionSiteChoiceLogisticsUnitRequestArgs)
        : Promise<AxiosResponse<WebAppActionResult>> => {
        return axios({
            method: 'POST',
            url: controllerUrl + 'AddOrRemoveProductionSiteChoice',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: JSON.stringify(productionSiteChoiceLogisticsUnits)
        });
    }

    public static AddOrRemoveProductionSiteChoices = (productionSiteChoiceLogisticsUnits: ProductionSiteChoiceLogisticsUnitsRequestArgs)
        : Promise<AxiosResponse<WebAppActionResult>> => {
        return axios({
            method: 'POST',
            url: controllerUrl + 'AddOrRemoveProductionSiteChoices',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: JSON.stringify(productionSiteChoiceLogisticsUnits)
        });
    }

    public static EditLogisticUnit = (logisticUnit: EditProductionSiteLogisticUnitRequestArgs)
        : Promise<AxiosResponse<WebAppActionResult>> => {
        return axios({
            method: 'POST',
            url: controllerUrl + 'EditLogisticsUnit',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: JSON.stringify(logisticUnit)
        });
    }

    public static ExportProductionSiteChoices = (productionSiteChoicesExportRequestArgs: ProductionSiteChoicesExportRequestArgs)
        : Promise<AxiosResponse<Blob>> => {
        const _headers = {
            'Accept': 'application/vnd.ms-excel',
            'Content-Type': 'application/json'
        };

        return axios.post(controllerUrl + 'ExportProductionSiteChoices', productionSiteChoicesExportRequestArgs, { headers: _headers, responseType: 'blob' });
    }

    public static GetImportUrl = (): string => {
        return controllerUrl + 'ImportFile';
    }

    public static ProductionSiteExists = (productionSiteId: string)
        : Promise<AxiosResponse<boolean>> => {
        return axios.get(`${controllerUrl}ProductionSiteExists?productionSiteId=${productionSiteId}`);
    }

    public static SearchProductionSiteByIdentifier = (productionSiteId: string)
        : Promise<AxiosResponse<ProductionSiteCandidateToAdd>> => {
        return axios({
            method: 'POST',
            url: `${controllerUrl}SearchProductionSiteByIdentifier?productionSiteId=${productionSiteId}`,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });
    }

    public static AddProductionSite = (productionSiteId: string, productionSiteIsLogisticZone: boolean)
        : Promise<AxiosResponse<WebAppActionResult>> => {
        return axios({
            method: 'POST',
            url: `${controllerUrl}AddProductionSite?productionSiteId=${productionSiteId}&productionSiteIsLogisticZone=${productionSiteIsLogisticZone}`,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });
    }

    public static AddOrUpdateProductionSiteActivities = (productionSiteId: string, activities: ActivitiesRequestArgs)
        : Promise<AxiosResponse<WebAppActionResult>> => {
        return axios({
            method: 'POST',
            url: `${controllerUrl}AddOrUpdateProductionSiteActivities?productionSiteId=${productionSiteId}`,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: JSON.stringify(activities)
        });
    }
}
