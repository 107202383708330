import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { SortDescriptor } from '@progress/kendo-data-query';
import { GridSortChangeEvent } from '@progress/kendo-react-grid';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import { UploadOnBeforeUploadEvent, UploadResponse } from '@progress/kendo-react-upload';
import { debounce, filter, map, sortBy } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import ToastService from 'src/ToastService';
import { downloadFileFromBlob, getFileName } from 'src/utils/DownloadFile';
import { ScaleLoaderComponent } from '../../../shared/components/Common/ScaleLoaderComponent';
import SimpleDialog from '../../../shared/components/Common/SimpleDialog';
import { LineImportErrors } from '../../../shared/models/ImportErrorsExtraResult';
import { LogisticsUnitChoice } from '../../../shared/models/LogisticsUnitChoice';
import { WebAppActionResultEx } from '../../../shared/models/WebAppActionResult';
import BusinessErrors from '../../../utils/BusinessErrors';
import Utilities from '../../../utils/Utilities';
import '../ReferentialsStyles.scss';
import { AddExistingPurchasePricesTemplateComponent } from './components/AddExistingPurchasePricesTemplateComponent';
import { AddNewPurchasePricesTemplateComponent } from './components/AddNewPurchasePricesTemplateComponent';
import { AddTransporterDialogComponent } from './components/AddTransporterDialog/AddTransporterDialogComponent';
import { EditTransporterDialogComponent } from './components/EditTransporterDialogComponent';
import { HeaderContentComponent } from './components/HeaderContentComponent';
import { ImportCoupaPurchasePricesComponent } from './components/ImportCoupaPurchasePrices/ImportCoupaPurchasePricesComponent';
import { ImportPurchasePricesComponent } from './components/ImportPurchasePricesComponent';
import { LogisticsUnitsComponent } from './components/LogisticsUnitsComponent';
import { TransportPurchasePricesComponent } from './components/TransportPurchasePricesComponent';
import { DialogTypeEnum } from './models/DialogTypeEnum';
import { ImportErrorsWithCountersExtraResult } from './models/ImportErrorsWithCountersExtraResult';
import { ImportNewPurchasePriceDataModel } from './models/ImportNewPurchasePriceDataModel';
import { PurchasePricesDataModel } from './models/PurchasePricesDataModel';
import { PurchasePricesImportExtraResult } from './models/PurchasePricesImportExtraResult';
import { SelectedTransporterModel } from './models/SelectedTransporterModel';
import { SelectOptionModel } from './models/SelectOptionModel';
import { SimpleDialogModel } from './models/SimpleDialogModel';
import { TransporterCandidateToAddExtended } from './models/TransporterCandidateToAddExtended';
import { TransporterPurchasePriceDataModel } from './models/TransporterPurchasePriceDataModel';
import { TransporterPurchasePriceLightModelExtended } from './models/TransporterPurchasePriceLightModelExtended';
import { TransportPurchaseLightModelExtended } from './models/TransportPurchaseLightModelExtended';
import { AddTransporterRequestArgs } from './services/dataContracts/controller/AddTransporterRequestArgs';
import { ExportExistingPurchasePricesRequestArgs } from './services/dataContracts/controller/ExportExistingPurchasePricesRequestArgs';
import { ExportNewPurchasePricesRequestArgs } from './services/dataContracts/controller/ExportNewPurchasePricesRequestArgs';
import { ImportNewPurchasePricesRequestArgs } from './services/dataContracts/controller/ImportNewPurchasePricesRequestArgs';
import { PurchasePriceLineRequestArgs } from './services/dataContracts/controller/PurchasePriceLineRequestArgs';
import { TransporterCandidateToAdd } from './services/dataContracts/controller/TransporterCandidateToAdd';
import { UpdateExternalTransporterRequestArgs } from './services/dataContracts/controller/UpdateExternalTransporterRequestArgs';
import { LogisticsUnitChoiceOfTransportersLightModel } from './services/dataContracts/queryStack/LogisticsUnitChoiceOfTransportersLightModel';
import { LogisticsUnitChoiceOfTransportPurchasePricesLightModel } from './services/dataContracts/queryStack/LogisticsUnitChoiceOfTransportPurchasePricesLightModel';
import { TransporterPurchasePriceLightModel } from './services/dataContracts/queryStack/TransporterPurchasePriceLightModel';
import { TransportPurchasePricesReferentialApiClient } from './services/TransportPurchasePricesReferentialApiClient';
import './TransportPurchasePricesReferentialStyles.scss';

interface TransportPurchasePricesReferentialProps {
    isForInternalTransporters: boolean,
    logisticsUnits: Array<LogisticsUnitChoice>
}

interface TransportPurchasePriceReferentialState {
    isDataValid: boolean,
    purchasePriceLines: Array<PurchasePriceLineRequestArgs>
}

const IMPORT_TRANSPORTERS_CHOICES = 'TransportersChoices';
const IMPORT_PURCHASEPRICES = 'PurchasePrices';

export const TransportPurchasePricesReferentialView = (props: TransportPurchasePricesReferentialProps): JSX.Element => {
    const inputSearchTransportersRef: React.RefObject<HTMLInputElement> = React.useRef(null);
    const initialSortTransporter: SortDescriptor[] = [{ field: 'transporterName', dir: 'asc' }];
    const initialSortPurchasePrice: SortDescriptor[] = [{ field: 'VehicleTypeId', dir: 'asc' }];

    const [transportersMap, setTransportersMap] = useState<Map<string, TransportPurchaseLightModelExtended>>(new Map<string, TransportPurchaseLightModelExtended>());
    const [transportersArray, setTransportersArray] = useState<Array<TransportPurchaseLightModelExtended>>([]);
    const [transporterLogisticsUnitsChoices, setTransporterLogisticsUnitsChoices] = useState<Array<LogisticsUnitChoiceOfTransportersLightModel>>([]);
    const [purcharsePriceLogisticsUnitsChoices, setPurcharsePriceLogisticsUnitsChoices] = useState<Array<LogisticsUnitChoiceOfTransportPurchasePricesLightModel>>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [isGlobalSearch, setIsGlobalSearch] = useState<boolean>(false);
    const [loadingTransportPurchasePrice, setLoadingTransportPurchasePrice] = useState<boolean>(false);
    const [sortTransporter, setSortTransporter] = useState<SortDescriptor[]>(initialSortTransporter);
    const [sortPurchasePrice, setsortPurchasePrice] = useState<SortDescriptor[]>(initialSortPurchasePrice);
    const [selectedTransporter, setSelectedTransporter] = useState<SelectedTransporterModel>({ transporterId: null, transporterName: null });
    const [selectedTransporterPurchasesPricesMap, setSelectedTransporterPurchasesPricesMap] = useState<Map<number, TransporterPurchasePriceLightModelExtended>>(new Map<number, TransporterPurchasePriceLightModelExtended>());
    const [selectedTransporterPurchasesPricesArray, setSelectedTransporterPurchasesPricesArray] = useState<Array<TransporterPurchasePriceLightModelExtended>>([]);
    const [showAllTransportLogisticsUnitsPurchasePrices, setShowAllLogisticsUnitsPurchasePrices] = useState<boolean>(false);
    const [showOnlyContractedPrices, setShowOnlyContractedPrices] = useState<boolean>(false);
    const [searchText, setSearchText] = useState<string>('');
    const [simpleDialog, setSimpleDialog] = useState<SimpleDialogModel>({
        isDialogOpened: false,
        dialogType: DialogTypeEnum.ExistingPrice,
        popupContentComponent: <></>,
        dialogTitle: ''
    });
    const [vehicleTypes, setVehicleTypes] = useState<Array<SelectOptionModel>>([]);
    const [selectedExistingTransportersIdsToExport, setSelectedExistingTransportersIdsToExport] = useState<Array<string>>([]);
    const [newPurchasePriceState, setNewPurchasePriceState] = useState<TransportPurchasePriceReferentialState>(
        {
            isDataValid: false,
            purchasePriceLines: [{
                transporterId: null,
                transporterName: null,
                vehicleTypes: [],
                pricesLabelKind: [],
                numberOfLinesToCreate: 1
            }]
        }
    );
    const [importNewPurchasePriceData, setImportNewPurchasePriceData] = useState<ImportNewPurchasePriceDataModel>(
        {
            containerPath: '',
            fileName: ''
        }
    );

    const [allLogisticsUnits] = useState<Array<SelectOptionModel>>(props.logisticsUnits.map(t => {
        return { value: t.logisticsUnitId, label: t.label }
    }));

    const [selectedLogisticsUnits] = useState<Array<string>>(map(filter(props.logisticsUnits, t => t.checked), t => t.logisticsUnitId));
    const [selectedLogisticsUnitIdsToImport, setSelectedLogisticsUnitIdsToImport] = useState<Array<string>>(selectedLogisticsUnits);
    const [isAddTransporterDialogOpened, setIsAddTransporterDialogOpened] = useState<boolean>(false);
    const [isEditTransporterDialogOpened, setIsEditTransporterDialogOpened] = useState<boolean>(false);
    const [activeStep, setActiveStep] = useState<number>(0);
    const [transporterIdMdm, setTransporterIdMdm] = useState<string>('');
    const [transporterName, setTransporterName] = useState<string>('');
    const [transporterSearchLoading, setTransporterSearchLoading] = useState<boolean>(false);
    const [transporterChoices, setTransporterChoices] = useState<TransporterCandidateToAddExtended[]>([]);
    const [transporterToAdd, setTransporterToAdd] = useState<TransporterCandidateToAdd>(null);

    const [isImportCoupaPurchasePricesOpened, setIsImportCoupaPurchasePricesOpened] = useState<boolean>(false);

    useEffect(() => {
        if (selectedTransporter?.transporterId)
            handleSelectedTransporter(selectedTransporter.transporterId, selectedTransporter.transporterName);
    }, [showAllTransportLogisticsUnitsPurchasePrices, showOnlyContractedPrices]);

    useEffect(() => {
        getTransportersWithTransporterChoicesLogisticsUnit('', false);
    }, []);

    const clearSearchText = (): void => {
        setIsGlobalSearch(false);
        inputSearchTransportersRef.current.value = "";
        transportersKeyPressedDebounce("");
    }

    const transportersKeyPressedDebounce = debounce((text: string): void => {
        if (text.length >= 3) {
            transportersKeyPressed(text, isGlobalSearch);
        } else if (text.length === 0) {
            setIsGlobalSearch(false);
            transportersKeyPressed(text, false);
        }
    }, 500);

    const transportersKeyPressed = (text: string, isGlobalSearch: boolean): void => {
        getTransportersWithTransporterChoicesLogisticsUnit(text, isGlobalSearch);
    }

    const getTransportersChoicesData = (data: Array<TransportPurchaseLightModelExtended>, logisticsUnits: Array<LogisticsUnitChoiceOfTransportersLightModel>): PurchasePricesDataModel => {
        const dataDictionary = new Map<string, TransportPurchaseLightModelExtended>();
        const dataArray: Array<TransportPurchaseLightModelExtended> = [];
        data.forEach((transporter: TransportPurchaseLightModelExtended) => {
            const element: TransportPurchaseLightModelExtended = { ...transporter };
            let checked = true;
            let indeterminateAll = true;
            let numberOfChecked = 0;
            let numberOfUnchecked = 0;
            logisticsUnits.forEach((logisticsUnit: LogisticsUnitChoiceOfTransportersLightModel) => {
                element[logisticsUnit.logisticsUnitId] = logisticsUnit.transporterChoices.includes(element.transporterId);
                if (!logisticsUnit.transporterChoices.includes(element.transporterId)) {
                    checked = false;
                    numberOfUnchecked += 1;
                } else {
                    numberOfChecked += 1;
                }
            });
            if (logisticsUnits.length === numberOfUnchecked || logisticsUnits.length === numberOfChecked) {
                indeterminateAll = false;
            }
            element.isIndeterminateAll = indeterminateAll;
            element.isSelectedAll = checked ? true : (indeterminateAll ? null : false);
            element.address = Utilities.formatAddress(element.addressLine1, element.addressLine2, element.addressZipCode, element.addressCity);
            element.hasGeographicCoordinates = element.coordinatesLatitude != null && element.coordinatesLongitude != null;

            dataDictionary.set(element.transporterId, element);
            dataArray.push(element);
        });
        return {
            map: dataDictionary,
            array: dataArray
        };
    }

    const getPurchasePricesChoicesData = (data: Array<TransporterPurchasePriceLightModelExtended>, logisticsUnits: Array<LogisticsUnitChoiceOfTransportPurchasePricesLightModel>): TransporterPurchasePriceDataModel => {
        const dataDictionary: Map<number, TransporterPurchasePriceLightModelExtended> = new Map<number, TransporterPurchasePriceLightModelExtended>();
        const dataArray: Array<TransporterPurchasePriceLightModelExtended> = [];
        data.forEach((purchasePrice: TransporterPurchasePriceLightModelExtended) => {
            const element: TransporterPurchasePriceLightModelExtended = { ...purchasePrice };

            element.lblPrice = purchasePrice.priceKind === "Day" ? "Jour"
                : purchasePrice.priceKind === "Night" ? "Nuit"
                    : purchasePrice.priceKind === "HalfDay" ? "Demi-journée"
                        : purchasePrice.priceKind === "PerTon" ? "Tonne"
                            : purchasePrice.priceKind === "PerHour" ? "Heure"
                                : purchasePrice.priceKind === "PerTurn" ? "Tour" : "";

            element.startDate = !purchasePrice.startDate ? null : new Date(purchasePrice.startDate);
            element.endDate = !purchasePrice.endDate ? null : new Date(purchasePrice.endDate);
            element.isSelectedAll = false;
            element.lblContractedNumberOfDays = element.contractedNumberOfDays ? element.contractedNumberOfDays + " j" : ""
            element.lblUsedContractedNumberOfDays = element.usedContractedNumberOfDays ? element.usedContractedNumberOfDays + " j" : ""
            element.lblRemainingNumberOfWorkDays = element.remainingNumberOfWorkDays ? element.remainingNumberOfWorkDays + " j" : ""

            let checked = true;
            let disabled = true;
            let numberOfChecked = 0;
            let numberOfUnchecked = 0;
            logisticsUnits.forEach((logisticsUnit: LogisticsUnitChoiceOfTransportPurchasePricesLightModel) => {
                element[logisticsUnit.logisticsUnitId] = logisticsUnit.purchasePriceChoices.includes(element.transportPurchasePriceId);
                if (!logisticsUnit.purchasePriceChoices.includes(element.transportPurchasePriceId)) {
                    checked = false;
                    numberOfUnchecked += 1;
                } else {
                    numberOfChecked += 1;
                }
            });
            if (logisticsUnits.length === numberOfUnchecked || logisticsUnits.length === numberOfChecked) {
                disabled = false;
            }
            element.isIndeterminateAll = disabled;

            element.isSelectedAll = checked ? true : (disabled ? null : false);

            dataDictionary.set(element.transportPurchasePriceId, element);
            dataArray.push(element);
        });
        return {
            map: dataDictionary,
            array: dataArray
        };
    }

    const getTransportersWithTransporterChoicesLogisticsUnit = (searchText: string, isGlobalSearch: boolean, successMessage?: string, errorMessage?: string, endMessage?: string, listErrorMessage?: Array<string>): void => {
        setLoading(true);
        setSearchText(searchText);
        const logisticsUnits: Array<string> = TransportPurchasePricesReferentialApiClient.GetChosenLogisticsUnits(props.logisticsUnits);

        TransportPurchasePricesReferentialApiClient.GetTransportersWithTransporterChoicesLogisticsUnit(logisticsUnits, searchText, isGlobalSearch, props.isForInternalTransporters)
            .then(response => {

                if (errorMessage)
                    ToastService.showErrorToast(errorMessage, listErrorMessage, endMessage);
                else if (successMessage)
                    ToastService.showSuccessToast(successMessage, endMessage);

                const transportersData: PurchasePricesDataModel = getTransportersChoicesData(response[0].data, response[1].data);
                setTransportersMap(transportersData.map);
                setTransportersArray(transportersData.array);

                setTransporterLogisticsUnitsChoices(response[1].data);
                setSelectedTransporter({ transporterId: null, transporterName: null });
            })
            .finally(() => {
                setLoading(false);
            });
    }

    const handleSortTransporterChange = (e: GridSortChangeEvent): void => {
        setSortTransporter(e.sort);
    }

    const handleSortPurchasePriceChange = (e: GridSortChangeEvent): void => {
        setsortPurchasePrice(e.sort);
    }

    const handleSortTransporterColumnChange = (sortItems: SortDescriptor[]): void => {
        setSortTransporter(sortItems);
    }

    const handleSortPurchasePriceColumnChange = (sortItems: SortDescriptor[]): void => {
        setsortPurchasePrice(sortItems);
    }

    const handleSelectedTransporter = (transporterId: string, transporterName: string): void => {
        if (selectedTransporter.transporterId)
            transportersMap.get(selectedTransporter.transporterId).selected = false;

        if (transporterId)
            transportersMap.get(transporterId).selected = true;


        setSelectedTransporter({ transporterId: transporterId, transporterName: transporterName });
        if (transporterId) {
            setLoadingTransportPurchasePrice(true);
            const logisticsUnits: Array<string> = TransportPurchasePricesReferentialApiClient.GetChosenLogisticsUnits(props.logisticsUnits);

            TransportPurchasePricesReferentialApiClient.GetPurchasePricesByTransporterWithPurchasePriceChoicesLogisticsUnit(logisticsUnits, !showAllTransportLogisticsUnitsPurchasePrices, showOnlyContractedPrices, transporterId)
                .then(response => {
                    let transporterPurchasePrices: Array<TransporterPurchasePriceLightModel> = response[0].data;

                    if (!transporterPurchasePrices)
                        transporterPurchasePrices = [];

                    const purchasePricesData: TransporterPurchasePriceDataModel = getPurchasePricesChoicesData(transporterPurchasePrices, response[1].data);
                    setSelectedTransporterPurchasesPricesMap(purchasePricesData.map);
                    setSelectedTransporterPurchasesPricesArray(purchasePricesData.array);

                    setPurcharsePriceLogisticsUnitsChoices(response[1].data);
                })
                .finally(() => {
                    setLoadingTransportPurchasePrice(false);
                });
        }
    }

    const handlerAfterUpload = (response: UploadResponse): void => {
        const responseResult: WebAppActionResultEx<PurchasePricesImportExtraResult> = response ? response.response : null;

        const listErrorMessage: string[] = [];
        let errorMessage = '';
        let successMessage = '';
        let endMessage = '';

        if (response.status === 200 && responseResult) {
            if (responseResult.extraResult.importName == IMPORT_PURCHASEPRICES) {
                setImportNewPurchasePriceData({
                    containerPath: responseResult.extraResult.containerPath,
                    fileName: responseResult.extraResult.fileName
                });
                setLoading(false);
                setSimpleDialog({
                    isDialogOpened: true,
                    dialogType: DialogTypeEnum.ImportPrice
                    , dialogTitle: 'Import des nouveaux tarifs'
                    , popupContentComponent: <ImportPurchasePricesComponent
                        handleChangeLogisticsUnitsSelected={handleChangeLogisticsUnitIdsSelected}
                        options={allLogisticsUnits}
                        defaultSelectedOptions={selectedLogisticsUnits}
                    />
                });
            } else {
                const importLines: Array<LineImportErrors> = responseResult.extraResult.linesWithErrors;
                if (importLines.length > 0) {
                    importLines.forEach(e => {
                        e.errors.forEach(eError => {
                            let libMsg: string = BusinessErrors.GetError(eError);
                            if (!libMsg && eError)
                                libMsg = eError;
                            listErrorMessage.push(`- Ligne ${e.rowIndex} - ${libMsg}`);
                        });
                    });

                    if (responseResult.extraResult.importName === IMPORT_TRANSPORTERS_CHOICES) {
                        errorMessage = 'Erreur lors de l\'import de la visibilité des transporteurs:';
                        endMessage = 'Pour rappel, vous ne pouvez importer que la visibilité des transporteurs sur vos zones logistiques';
                    } else {
                        errorMessage = 'Erreur lors de l\'import de la visibilité des tarifs achats du transporteur:';
                        endMessage = 'Pour rappel, vous ne pouvez importer que la visibilité des tarifs achats d\'un transporteur sur vos zones logistiques';
                    }
                } else {
                    if (responseResult.extraResult.importName === IMPORT_TRANSPORTERS_CHOICES)
                        successMessage = 'La visibilité des transporteurs pour vos zones logistiques a bien été intégrée';
                    else
                        successMessage = 'La visibilité des tarifs achats du transporteur pour vos zones logistiques a bien été intégrée';
                }
            }
        } else {
            errorMessage = 'Erreur lors de l\'import de la visibilité des transporteurs ou tarifs Colas, veuillez vérifier votre fichier';
        }

        getTransportersWithTransporterChoicesLogisticsUnit(searchText, isGlobalSearch, successMessage, errorMessage, endMessage, listErrorMessage);
    }

    const handlerBeforeUpload = (e: UploadOnBeforeUploadEvent): void => {
        setLoading(true);
    }

    const handleShowAllTransportLogisticsUnitsPurchasePrices = (): void => {
        setShowAllLogisticsUnitsPurchasePrices(!showAllTransportLogisticsUnitsPurchasePrices);
    }

    const handleShowOnlyContractedPrices = (): void => {
        setShowOnlyContractedPrices(!showOnlyContractedPrices);
    }

    const handleChangeExistingTransportersSelected = (selectedTransportersIds: Array<string>): void => {
        setSelectedExistingTransportersIdsToExport(selectedTransportersIds);
    }

    const handleChangeLogisticsUnitIdsSelected = (selectedLogisticsUnitIds: Array<string>): void => {
        setSelectedLogisticsUnitIdsToImport(selectedLogisticsUnitIds);
    }

    const handleClickOpenExportExistingPrices = (): void => {
        setSimpleDialog({
            isDialogOpened: true,
            dialogType: DialogTypeEnum.ExistingPrice
            , dialogTitle: 'Export des tarifs existants'
            , popupContentComponent: <AddExistingPurchasePricesTemplateComponent onClose={() => handleClickCloseDialog()}
                handleChangeTransportersSelected={handleChangeExistingTransportersSelected}
                options={
                    sortBy(transportersArray.map(t => {
                        return { value: t.transporterId, label: t.transporterName }
                    }), x => x.label)} />
        });
    }

    const handleClickOpenExportNewPrices = (): void => {
        setSimpleDialog({
            isDialogOpened: true,
            dialogType: DialogTypeEnum.NewPrice
            , dialogTitle: 'Export des nouveaux tarifs'
            , popupContentComponent: <AddNewPurchasePricesTemplateComponent onClose={() => handleClickCloseDialog()}
                purchasePriceLines={newPurchasePriceState.purchasePriceLines}
                transportersOptions={
                    sortBy(transportersArray.map(t => {
                        return { value: t.transporterId, label: t.transporterName }
                    }), x => x.label)}
                vehicleTypesOptions={vehicleTypes}
                handleRefreshVehicleTypesList={handleRefreshVehicleTypesList}
                handleRefreshPurchasePriceLines={handleRefreshPurchasePriceLines}
            />
        });
    }

    const handleClickCloseDialog = (reason?: string) => {
        if (reason !== "backdropClick") {
            setSimpleDialog({
                ...simpleDialog,
                isDialogOpened: false
            });
        }
    }

    const exportPurchasePricesFromExisting = (): void => {
        handleClickCloseDialog();

        const requestArgs: ExportExistingPurchasePricesRequestArgs = {
            isForInternalTransporters: props.isForInternalTransporters,
            transporterIds: selectedExistingTransportersIdsToExport,
            sortField: sortPurchasePrice.length > 0 ? sortPurchasePrice[0].field : null,
            sortDirection: sortPurchasePrice.length > 0 ? sortPurchasePrice[0].dir : null,
            logisticsUnits: TransportPurchasePricesReferentialApiClient.GetChosenLogisticsUnits(props.logisticsUnits)
        };

        TransportPurchasePricesReferentialApiClient.ExportPurchasePricesTemplateFromExisting(requestArgs)
            .then(response => {
                const blobPurchasePrices: Blob = new Blob([response.data]);
                const fileNamePurchasePrices: string = getFileName(response);

                downloadFileFromBlob(blobPurchasePrices, fileNamePurchasePrices);
            });
    }

    const exportNewPurchasePricesFromNew = (): void => {
        handleClickCloseDialog();

        const requestArgs: ExportNewPurchasePricesRequestArgs = {
            isForInternalTransporters: props.isForInternalTransporters,
            purchasePriceLines: newPurchasePriceState.purchasePriceLines
        };

        TransportPurchasePricesReferentialApiClient.ExportPurchasePricesTemplateFromNew(requestArgs)
            .then(response => {
                const blobPurchasePrices: Blob = new Blob([response.data]);
                const fileNamePurchasePrices: string = getFileName(response);

                downloadFileFromBlob(blobPurchasePrices, fileNamePurchasePrices);
            });
    }

    const handleClickImport = (): void => {
        const requestArgs: ImportNewPurchasePricesRequestArgs = {
            isForInternalTransporters: props.isForInternalTransporters,
            containerPath: importNewPurchasePriceData.containerPath,
            fileName: importNewPurchasePriceData.fileName,
            selectedLogisticsUnits: selectedLogisticsUnitIdsToImport
        };

        TransportPurchasePricesReferentialApiClient.ImportNewPurchasePrices(requestArgs)
            .then(response => {
                const data: WebAppActionResultEx<ImportErrorsWithCountersExtraResult> = response.data;
                const lineErrors = data.extraResult?.linesWithErrors;
                const listErrorMessage: string[] = [];
                let errorMessage = '';
                let successMessage = '';
                let endMessage = '';

                const businessErrors: string[] = BusinessErrors.GetEx(data);

                if (lineErrors?.length > 0) {
                    lineErrors.forEach(e => {
                        e.errors.forEach(eError => {
                            let libMsg: string = BusinessErrors.GetError(eError);
                            if (!libMsg && eError)
                                libMsg = eError;
                            listErrorMessage.push(`- Ligne ${e.rowIndex} - ${libMsg}`);
                        });
                    });
                    errorMessage = 'Erreur lors de l\'import des tarifs:';
                }
                else if (businessErrors.length > 0) {
                    ToastService.showErrorToast('Erreur lors de l\'import de tarifs Colas, veuillez vérifier votre fichier', businessErrors);
                }
                else {
                    successMessage = 'L\'import de tarif avec les visibilités sur les zones logistiques sélectionnées a bien été intégré: ';
                    endMessage = `${data.extraResult?.importedCount} tarif(s) a(ont) été intégré(s) et ${data.extraResult?.ignoredCount} a(ont) été ignoré(s)`;
                }
                getTransportersWithTransporterChoicesLogisticsUnit(searchText, isGlobalSearch, successMessage, errorMessage, endMessage, listErrorMessage);
            })
            .catch(err => {
                if (err.response.status == 403) {
                    ToastService.showErrorToast("Autorisation refusée.", [], "Certains sites de transport ne font pas (ou plus) partie de votre périmètre.");
                }
                else {
                    ToastService.showErrorToast('Erreur lors de l\'import de tarifs Colas, veuillez vérifier votre fichier');
                }
            })
            .finally(() => {
                handleClickCloseDialog();
            });
    }

    const handleRefreshPurchasePriceLines = (purchasePriceLines: Array<PurchasePriceLineRequestArgs>): void => {
        const isDataValid: boolean = purchasePriceLines.every(line => line.transporterId &&
            line.vehicleTypes.length > 0 &&
            line.pricesLabelKind.length > 0 &&
            line.numberOfLinesToCreate > 0 &&
            line.numberOfLinesToCreate < 10 &&
            line.numberOfLinesToCreate !== null);

        setNewPurchasePriceState({
            isDataValid: isDataValid,
            purchasePriceLines: purchasePriceLines
        });
    }

    const handleRefreshVehicleTypesList = (vehicleTypes: Array<SelectOptionModel>): void => {
        setVehicleTypes(vehicleTypes);
    }

    const handleClickExport = (): void => {
        if (simpleDialog.dialogType == DialogTypeEnum.ExistingPrice)
            exportPurchasePricesFromExisting();
        else
            exportNewPurchasePricesFromNew();
    }

    const inputSearchColasVehiclesValue: string = inputSearchTransportersRef.current === null || inputSearchTransportersRef.current === undefined ? '' : inputSearchTransportersRef.current.value;

    const handleIsGlobalSearchChanged = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const checked: boolean = event.target.checked;
        setIsGlobalSearch(checked);
        getTransportersWithTransporterChoicesLogisticsUnit(inputSearchColasVehiclesValue, checked);
    }

    const handleAddContactDialogClick = (): void => {
        setIsAddTransporterDialogOpened(true);
    }

    const handleEditContactDialogClick = (): void => {
        setIsEditTransporterDialogOpened(true);
    }

    const handleCloseAddTransporterDialog = (): void => {
        setActiveStep(0);
        setTransporterIdMdm('');
        setTransporterName('');
        setTransporterChoices([]);
        setTransporterToAdd(null);
        setIsAddTransporterDialogOpened(false);
    }

    const handleCloseEditTransporterDialog = (): void => {
        setIsEditTransporterDialogOpened(false);
    }

    const searchTransporterByMdmId = (mdmId: string): void => {
        setTransporterSearchLoading(true);

        TransportPurchasePricesReferentialApiClient.SearchTransporterByMdmId(mdmId.trim())
            .then((res) => {

                setTransporterSearchLoading(false);
                const transporterCandidateToAdd: TransporterCandidateToAdd = res.data;
                if (transporterCandidateToAdd) {
                    switch (transporterCandidateToAdd.existInLorieDb) {
                        case true:
                            handleSearchInReferentialGrid(transporterCandidateToAdd.mdmId);
                            return;

                        case false:
                            var transporterCandidateToAddExtended: TransporterCandidateToAddExtended = transporterCandidateToAdd;
                            transporterCandidateToAddExtended.selected = false;
                            setTransporterChoices([transporterCandidateToAddExtended]);
                            setActiveStep(1);
                            return;

                        default:
                            return;
                    }
                }

                ToastService.showErrorToast("Aucun transporteur trouvé dans le référentiel");
            });
    }

    const searchTransporterByName = (transporterName: string): void => {
        setTransporterSearchLoading(true);

        TransportPurchasePricesReferentialApiClient.SearchTransporterByName(transporterName.trim())
            .then((res) => {

                setTransporterSearchLoading(false);
                const transporterCandidatesToAdd: TransporterCandidateToAdd[] = res.data;
                if (transporterCandidatesToAdd.length > 0) {
                    if (transporterCandidatesToAdd.length >= 10) {
                        ToastService.showErrorToast("Trop de transporteurs. Merci d'affiner la recherche par un nom plus précis");
                        return;

                    } else {
                        transporterCandidatesToAdd.forEach((item: TransporterCandidateToAddExtended) => item.selected = false);
                        setTransporterChoices(transporterCandidatesToAdd);
                        setActiveStep(1);
                        return;
                    }
                }

                ToastService.showErrorToast("Aucun transporteur trouvé dans le référentiel");
            });
    }

    const handleSearchInReferentialGrid = (transporterMdmId: string): void => {
        inputSearchTransportersRef.current.value = transporterMdmId;
        setIsGlobalSearch(true);
        handleCloseAddTransporterDialog();
        getTransportersWithTransporterChoicesLogisticsUnit(transporterMdmId, true);

        ToastService.showErrorToast("Ce transporteur est déjà présent dans LORIE, il faut vérifier que ce dernier soit bien visible pour votre zone logistique");
    }

    const handleConfirmStep = (transporter: TransporterCandidateToAddExtended): void => {
        setTransporterToAdd(transporter);
        setActiveStep(2);
    }

    const confirmAddTransporter = (transporterMainEmail: string, secondsEmails: string, transporterPhoneNumber: string): void => {
        const requestArgs: AddTransporterRequestArgs = {
            transporterMdmId: transporterToAdd.mdmId,
            label: transporterToAdd.longName,
            shortLabel: transporterToAdd.customLongName,
            isEnabled: transporterToAdd.isEnable,
            companyName: transporterToAdd.companyName,
            businessIdentifier: transporterToAdd.businessIdentifier,
            addressLine1: transporterToAdd.addressLine1,
            addressLine2: transporterToAdd.addressLine2,
            addressZipCode: transporterToAdd.addressZipCode,
            addressCity: transporterToAdd.addressCity,
            userName: transporterMainEmail,
            email: secondsEmails,
            phoneNumber: transporterPhoneNumber
        };

        TransportPurchasePricesReferentialApiClient.AddTransporter(requestArgs)
            .then((res) => {

                const data = res?.data;
                const errors = BusinessErrors.Get(data);
                if (errors.length > 0) {
                    ToastService.showErrorToast("", errors);
                    return;
                }

                inputSearchTransportersRef.current.value = transporterToAdd.mdmId;
                setIsGlobalSearch(true);
                handleCloseAddTransporterDialog();
                getTransportersWithTransporterChoicesLogisticsUnit(transporterToAdd.mdmId, true);
            });
    }

    const handlePreviousStep = (): void => {
        setActiveStep(activeStep - 1);
    }

    const handleTransporterIdMdmChange = (value: string) => {
        setTransporterIdMdm(value);
    }

    const handleTransporterNameChange = (value: string) => {
        setTransporterName(value);
    }

    const handleEditTransporterClick = (transporterRequestArgs: UpdateExternalTransporterRequestArgs): void => {
        setIsEditTransporterDialogOpened(false);
        TransportPurchasePricesReferentialApiClient.UpdateExternalTransporter(transporterRequestArgs)
            .then((res) => {
                const errors: string[] = BusinessErrors.Get(res.data);
                if (errors.length > 0) {
                    ToastService.showErrorToast("Erreur lors de la mise à jour du transporteur: ", errors);
                }
                else {
                    const transporter = transportersMap.get(transporterRequestArgs.transporterMdmId);
                    transporter.addressLine1 = transporterRequestArgs.addressLine1;
                    transporter.addressLine2 = transporterRequestArgs.addressLine2;
                    transporter.addressZipCode = transporterRequestArgs.addressZipCode;
                    transporter.addressCity = transporterRequestArgs.addressCity;
                    transporter.coordinatesLatitude = transporterRequestArgs.coordinatesLatitude;
                    transporter.coordinatesLongitude = transporterRequestArgs.coordinatesLongitude;
                    transporter.addressFreeFormAddress = transporterRequestArgs.addressFreeFormAddress;
                    transporter.managerEmail = transporterRequestArgs.managerEmail;
                    transporter.phoneNumber = transporterRequestArgs.phoneNumber;
                    transporter.address = Utilities.formatAddress(transporterRequestArgs.addressLine1, transporterRequestArgs.addressLine2, transporterRequestArgs.addressZipCode, transporterRequestArgs.addressCity);
                    transporter.hasGeographicCoordinates = transporter.coordinatesLatitude != null && transporter.coordinatesLongitude != null;

                    const transporters = [...transportersArray];
                    const transporterElement = transporters.find(x => x.transporterId == transporterRequestArgs.transporterMdmId);
                    transporterElement.addressLine1 = transporterRequestArgs.addressLine1;
                    transporterElement.addressLine2 = transporterRequestArgs.addressLine2;
                    transporterElement.addressZipCode = transporterRequestArgs.addressZipCode;
                    transporterElement.addressCity = transporterRequestArgs.addressCity;
                    transporterElement.coordinatesLatitude = transporterRequestArgs.coordinatesLatitude;
                    transporterElement.coordinatesLongitude = transporterRequestArgs.coordinatesLongitude;
                    transporterElement.addressFreeFormAddress = transporterRequestArgs.addressFreeFormAddress;
                    transporterElement.managerEmail = transporterRequestArgs.managerEmail;
                    transporterElement.address = transporter.address;
                    transporterElement.phoneNumber = transporter.phoneNumber;
                    transporterElement.hasGeographicCoordinates = transporter.hasGeographicCoordinates;
                    setTransportersArray(transporters);
                }
            });
    }

    const handleImportTarifsCoupaClicked = (): void => {
        setIsImportCoupaPurchasePricesOpened(true);
    }

    const handleImportTarifsCoupaClosed = (hasImportSuccess: boolean): void => {
        if (hasImportSuccess) {
            handleSelectedTransporter(selectedTransporter.transporterId, selectedTransporter.transporterName);
        }
        setIsImportCoupaPurchasePricesOpened(false);
    }

    const headerContentComponent: JSX.Element = useMemo(() =>
        <HeaderContentComponent
            isForInternalTransporters={props.isForInternalTransporters}
            inputSearchPurchasePricesValue={inputSearchColasVehiclesValue}
            inputSearchPurchasePricesRef={inputSearchTransportersRef}
            handlePurchasePricesKeyPress={transportersKeyPressedDebounce}
            handleClearSearchText={clearSearchText}
            logisticsUnits={props.logisticsUnits}
            showAllTransportLogisticsUnitsPurchasePrices={showAllTransportLogisticsUnitsPurchasePrices}
            showOnlyContractedPrices={showOnlyContractedPrices}
            sortTransporter={sortTransporter}
            sortPurchasePrice={sortPurchasePrice}
            selectedTransporter={selectedTransporter}
            hasTransporters={transportersArray.length > 0}
            isGlobalSearch={isGlobalSearch}
            handlerAfterUpload={handlerAfterUpload}
            handlerBeforeUpload={handlerBeforeUpload}
            handleShowAllTransportLogisticsUnitsPurchasePrices={handleShowAllTransportLogisticsUnitsPurchasePrices}
            handleShowOnlyContractedPrices={handleShowOnlyContractedPrices}
            handleClickOpenExportExistingPrices={handleClickOpenExportExistingPrices}
            handleClickOpenExportNewPrices={handleClickOpenExportNewPrices}
            handleIsGlobalSearchChanged={handleIsGlobalSearchChanged}
            handleAddTransporterDialogClick={handleAddContactDialogClick}
            handleEditTransporterDialogClick={handleEditContactDialogClick}
            handleImportTarifsCoupaClicked={handleImportTarifsCoupaClicked}
        />, [inputSearchColasVehiclesValue, inputSearchTransportersRef, props.logisticsUnits,
        showAllTransportLogisticsUnitsPurchasePrices, sortTransporter, sortPurchasePrice, selectedTransporter, transportersArray.length > 0, isGlobalSearch]);

    const logisticsUnitsComponent: JSX.Element = useMemo(() =>
        <LogisticsUnitsComponent
            isForInternalTransporters={props.isForInternalTransporters}
            transportersMap={transportersMap}
            transportersArray={transportersArray}
            transporterLogisticsUnitsChoices={transporterLogisticsUnitsChoices}
            sort={sortTransporter}
            handleSortChange={handleSortTransporterChange}
            handleSortColumnChange={handleSortTransporterColumnChange}
            handleSelectedTransporter={handleSelectedTransporter}
        />, [transportersMap, transportersArray, transporterLogisticsUnitsChoices, selectedTransporter, sortTransporter]);

    const transportPurchasePricesComponent: JSX.Element = useMemo(() =>
        <TransportPurchasePricesComponent
            isForInternalTransporters={props.isForInternalTransporters}
            transporterId={selectedTransporter.transporterId}
            sort={sortPurchasePrice}
            handleSortChange={handleSortPurchasePriceChange}
            handleSortColumnChange={handleSortPurchasePriceColumnChange}
            selectedTransporterPurchasesPricesMap={selectedTransporterPurchasesPricesMap}
            selectedTransporterPurchasesPricesArray={selectedTransporterPurchasesPricesArray}
            purcharsePriceLogisticsUnitsChoices={purcharsePriceLogisticsUnitsChoices}
        />, [selectedTransporter.transporterId, sortPurchasePrice, selectedTransporterPurchasesPricesMap, selectedTransporterPurchasesPricesArray, purcharsePriceLogisticsUnitsChoices]);

    const enabledExportBtn: boolean = simpleDialog.dialogType == DialogTypeEnum.ExistingPrice
        ? selectedExistingTransportersIdsToExport.length > 0
        : newPurchasePriceState.isDataValid;

    return (
        <>
            <Box className="colasTransporters referencial">
                <Box display="flex" flexDirection="row" flex="wrap" className="view-title">
                    {props.isForInternalTransporters ? "Tarifs Achat transporteurs internes" : "Tarifs Achat transporteurs externes"}
                </Box>
                <LocalizationProvider language="fr-FR">
                    <IntlProvider locale="fr" >
                        <Box display="flex" flexDirection="column" flex="wrap">
                            {headerContentComponent}
                            {(loading ?
                                <ScaleLoaderComponent loading={loading} />
                                :
                                (<Box display='flex' flexDirection="row" width='100%'>
                                    <Box width='40%'>
                                        {logisticsUnitsComponent}
                                    </Box>
                                    <Box pl={2} width='60%'>
                                        {
                                            loadingTransportPurchasePrice ?
                                                <ScaleLoaderComponent loading={loadingTransportPurchasePrice} />
                                                :
                                                selectedTransporter.transporterId &&
                                                transportPurchasePricesComponent
                                        }
                                    </Box>
                                </Box>
                                )
                            )}
                        </Box>
                    </IntlProvider>
                </LocalizationProvider>
            </Box>
            <Dialog
                disableEscapeKeyDown
                aria-modal="false"
                open={simpleDialog.isDialogOpened}
                onClose={(_event, reason) => handleClickCloseDialog(reason)}
                scroll="paper"
                className="simple-dialog-purchase-price"
            >
                <DialogTitle id="scroll-dialog-title">
                    {simpleDialog.dialogTitle}
                </DialogTitle>
                <DialogContent dividers>
                    {simpleDialog.popupContentComponent}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleClickCloseDialog()} color="primary">
                        Annuler
                    </Button>
                    {simpleDialog.dialogType == DialogTypeEnum.ExistingPrice || simpleDialog.dialogType == DialogTypeEnum.NewPrice
                        ? (<Button onClick={handleClickExport} disabled={!enabledExportBtn} color="primary">
                            Exporter
                        </Button>)
                        : (<Button onClick={handleClickImport} color="primary">
                            Importer
                        </Button>)
                    }
                </DialogActions>
            </Dialog>
            {!props.isForInternalTransporters &&
                <>
                    <SimpleDialog isOpen={isAddTransporterDialogOpened}
                        onClose={() => handleCloseAddTransporterDialog()}
                        closeIcon={true}
                        dialogTitle="Ajouter un transporteur"
                        classNameVal="add-transporter-dialog"
                        component={
                            <AddTransporterDialogComponent
                                activeStep={activeStep}
                                transporterIdMdm={transporterIdMdm}
                                transporterName={transporterName}
                                handleTransporterIdMdmChange={handleTransporterIdMdmChange}
                                handleTransporterNameChange={handleTransporterNameChange}
                                transporterSearchLoading={transporterSearchLoading}
                                transporterChoices={transporterChoices}
                                searchTransporterByMdmId={searchTransporterByMdmId}
                                searchTransporterByName={searchTransporterByName}
                                handleSearchInReferentialGrid={handleSearchInReferentialGrid}
                                handleConfirmStep={handleConfirmStep}
                                confirmAddTransporter={confirmAddTransporter}
                                handlePreviousStep={handlePreviousStep}
                            />
                        }
                    />
                    {selectedTransporter.transporterId && <SimpleDialog isOpen={isEditTransporterDialogOpened}
                        onClose={() => handleCloseEditTransporterDialog()}
                        closeIcon={true}
                        dialogTitle="Modifier un transporteur externe"
                        classNameVal="edit-transporter-dialog"
                        component={
                            <EditTransporterDialogComponent
                                transporterSelected={transportersMap.get(selectedTransporter.transporterId)}
                                handleClose={handleCloseEditTransporterDialog}
                                handleEditTransporterClick={handleEditTransporterClick}
                            />
                        }
                    />}
                </>
            }
            {isImportCoupaPurchasePricesOpened &&
                <ImportCoupaPurchasePricesComponent open={isImportCoupaPurchasePricesOpened}
                    transporterId={selectedTransporter.transporterId}
                    handleClose={(hasImportSuccess: boolean) => handleImportTarifsCoupaClosed(hasImportSuccess)}
                    allLogisticsUnits={allLogisticsUnits}
                    selectedLogisticsUnits={selectedLogisticsUnits}
                />
            }
        </>
    );
}
